import React from 'react'
import { RuiFooter } from '@atd/one-slingshot.components.footer'

import {getDomain} from '../lib/redirect'

export default function Footer() {
    return (
        <RuiFooter
            copyRight={{
                name: '',
                href: `https://portal.${getDomain()}/v3/eula`,
            }}
        />
    )
}
