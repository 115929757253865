import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { format } from 'date-fns'

import Avatar from 'material-ui/Avatar'
import {Card, CardHeader, CardText} from 'material-ui/Card'
import IconButton from 'material-ui/IconButton'

import CloseIcon from 'material-ui/svg-icons/content/add-box'
import OpenIcon from 'material-ui/svg-icons/toggle/indeterminate-check-box'
import CircularProgress from 'material-ui/CircularProgress'

import DispatchDialog from './dispatch/dispatchDialog'
import {jobStates, getJobStatus} from './jobStatus'
import * as workOrderActions from './workOrderActions'

import './jobCardStyles.css'

function mapStateToProps(state, ownProps) {
    const wo = state.workOrder.workOrders.find((wo) => {
        return wo.guid == ownProps.guid
    }) || {}
    if (!wo.metadata) wo.metadata = {}

    return {
        mixes: wo.metadata.mixes || [],
        rxFiles: wo.metadata.rxFiles || [],
        workOrder: wo,
        grower: wo.metadata.grower || {name: 'No Grower'},
        farm: wo.metadata.farm || {name: 'No Farm'},
        field: wo.metadata.field || {name: 'No Field'},
        currentProducts: wo.metadata.products || [],
        timestamp: format(new Date(wo.createdTime), 'P p'),
        boundaryPoints: wo.metadata.boundaryPoints || null,
        centroid: wo.metadata.centroid || null,
        entrancePoints: wo.metadata.entrancePoints || null,
        participants: wo.participants || [],
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(workOrderActions, dispatch),
    }
}

class JobCard extends React.Component {
    static defaultProps = {
        name: '',
        guid: '',
    }

    constructor(props) {
        super(props)
        this.state = {
            dispatchOpen: false,
            touched: false,
            fetchingFiles: false,
            jobReportEnabled: false,
        }
    }

    changeArchiveStatus = () => {
        this.props.setWorkOrderArchived(this.props.workOrder.id, !this.props.workOrder.archived)
    }
    dispatchRequested = () => {
        this.setState({dispatchOpen: true})
    }
    handleClose = () => {
        this.setState({dispatchOpen: false})
    }
    isAsAppliedJob = () => {
        return !this.props.workOrder.cloudJob &&
            (this.props.workOrder.originationId == 'FC' || this.props.workOrder.originationId == 'USB')
    }

    loadJobFiles() {
        if (!this.state.touched) {
            this.setState({ touched: true, fetchingFiles: true })
            this.props
                .loadWorkOrderApplicationFiles(this.props.workOrder.id)
                .then((files) => {
                    this.setState({
                        fetchingFiles: false,
                        jobReportEnabled: files.length > 0,
                    })
                })
        }
    }

    renderProducts = () => {
        let products
        if (!this.props.currentProducts.length) {
            products = <span>No Products</span>
        }
        else {
            products = (
                <table style={{width: '100%'}}>
                    <tbody>
                        {this.props.currentProducts.map(this.renderProductLine)}
                    </tbody>
                </table>
            )
        }

        return (
            <Card style={{marginBottom: 5}}>
                <CardHeader
                    style={this.props.currentProducts.length ? {backgroundColor: 'var(--primary)'} : {backgroundColor: '#EEEEEE'}}
                    title={`Products (${this.props.currentProducts.length})`}
                    titleColor={this.props.currentProducts.length ? 'white' : 'black'}
                    actAsExpander={!!this.props.currentProducts.length}
                    showExpandableButton={!!this.props.currentProducts.length}
                    closeIcon={<CloseIcon color="white"/>}
                    openIcon={<OpenIcon color="white"/>}
                />
                <CardText expandable={true}>
                    {products}
                </CardText>
            </Card>
        )
    }
    renderProductLine = (data, i) => {
        let rateLabel = ''
        if (data.rxMapColumnId) {
            rateLabel = '(Rx Map)'
        }
        else {
            rateLabel = `(${data.displayRate} ${data.userDisplayUnit})`
        }

        return (
            <tr key={`${data.id}-${i}`}>
                <td>{ data.mix ? data.mix.name : 'N/A'}</td>
                <td>{rateLabel}</td>
            </tr>
        )
    }
    renderParticipants = () => {
        let participants
        if (!this.props.participants.length) {
            participants = <span>No Participants</span>
        }
        else {
            participants = (
                <table style={{width: '100%'}}>
                    <tbody>
                        {this.props.participants.map(this.renderParticipantLine)}
                    </tbody>
                </table>
            )
        }

        return (
            <Card style={{marginBottom: 5}}>
                <CardHeader
                    style={this.props.participants.length ? {backgroundColor: 'var(--primary)'} : {backgroundColor: '#EEEEEE'}}
                    title={`Participants (${this.props.participants.length})`}
                    titleColor={this.props.participants.length ? 'white' : 'black'}
                    actAsExpander={!!this.props.participants.length}
                    showExpandableButton={!!this.props.participants.length}
                    closeIcon={<CloseIcon color="white"/>}
                    openIcon={<OpenIcon color="white"/>}
                />
                <CardText expandable={true}>
                    {participants}
                </CardText>
            </Card>
        )
    }
    renderParticipantLine = (data, i) => {
        return (
            <tr key={data.id}>
                <td>{data.name || data.id || 'N/A'}</td>
                <td>{data.status}</td>
            </tr>
        )
    }
    renderLiveButton = () => {
        if (!this.props.workOrder.archived && this.props.workOrder.cloudJob) {
            return (
                <IconButton
                    href={`/jobs/live/${this.props.workOrder.guid}`}
                    name="liveButton"
                    tooltip="Go To Live Job"
                >
                    <img src={`${process.env.PUBLIC_URL}/images/jobCard/JobCard_LiveView.svg`}/>
                </IconButton>
            )
        }
    }
    renderDispatchButton = () => {
        // only dispatch non-archived pre-populated jobs & cloud jobs (not as applied-jobs)
        if (!this.props.workOrder.archived && !this.isAsAppliedJob()) {
            const dispatcher = this.renderDispatcher()

            return (
                <IconButton
                    onClick={this.dispatchRequested}
                    name="dispatchButton"
                    tooltip="Dispatch"
                >
                    <img src={`${process.env.PUBLIC_URL}/images/jobCard/JobCard_BtnDispatch.svg`}/>
                    {dispatcher}
                </IconButton>
            )
        }
    }
    renderDetailsButton = () => {
        // only show details for pre-populated jobs & cloud jobs (not as applied-jobs)
        if (!this.isAsAppliedJob()) {
            return (
                <IconButton
                    href={`/jobs/details/${this.props.workOrder.id}`}
                    name="detailsButton"
                    tooltip="Details"
                >
                    <img src={`${process.env.PUBLIC_URL}/images/jobCard/JobCard_BtnDetails.svg`}/>
                </IconButton>
            )
        }
    }
    renderOpenReportButton = () => {
        return (
            <React.Fragment>
                {this.state.fetchingFiles && <CircularProgress size={24} style={{ marginLeft: '10px'}} />}
                {!this.state.fetchingFiles && this.state.jobReportEnabled && (
                    <IconButton
                        href={`/jobs/applicationreport/${this.props.workOrder.guid}`}
                        name="goToReportButton"
                        tooltip="Go to Job Report"
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/images/jobCard/JobCard_ViewReport.svg`}
                        />
                    </IconButton>
                )}
                {!this.state.fetchingFiles && !this.state.jobReportEnabled && (
                    <IconButton
                        disableTouchRipple
                        style={{ cursor: 'default' }}
                        name="goToReportButton"
                        tooltip="Job Report Unavailable"
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/images/jobCard/JobCard_ViewReportDisabled.svg`}
                        />
                    </IconButton>
                )}
            </React.Fragment>
        )
    }
    renderDispatcher = () => {
        if (this.state.dispatchOpen) {
            return <DispatchDialog
                guid={this.props.guid}
                open={true}
                onClose={this.handleClose}
                onDispatch={this.handleClose}
                boundaryPoints={this.props.boundaryPoints}
                entrancePoints={this.props.entrancePoints}
                centroid={this.props.centroid}
            />
        }
    }
    getInfoIcons() {
        const icons = []
        if (this.props.workOrder.originationId === 'PORTAL' || this.props.workOrder.originationId === 'API') {
            icons.push(
                <img src={`${process.env.PUBLIC_URL}/images/jobCard/JobCard_StatusPPJob.svg`}
                    style={{height: '30px', width: '30px'}}
                    key={`ppj-${this.props.workOrder.id}`}
                />)
        }
        if (this.props.workOrder.cloudJob) {
            icons.push(
                <img src={`${process.env.PUBLIC_URL}/images/jobCard/JobCard_StatusCloudJob.svg`}
                    style={{height: '30px', width: '30px'}}
                    key={`cloudStatus-${this.props.workOrder.id}`}
                />)
        }

        return (
            <div className="statusIcons">
                {icons}
            </div>
        )
    }
    getBackgroundColor() {
        switch (getJobStatus(this.props.workOrder)) {
            case jobStates.ARCHIVED: return '#DDDDDC'
            case jobStates.IN_PROGRESS: return '#d6e4f8'
            case jobStates.NEW_JOB:
            case jobStates.INACTIVE:
            default: return 'white'
        }
    }
    getJobStatusIcon() {
        const status = getJobStatus(this.props.workOrder)
        let iconString = ''
        switch (status) {
            case jobStates.ARCHIVED:
                iconString = 'Archived'
                break
            case jobStates.IN_PROGRESS:
                iconString = 'Active'
                break
            case jobStates.NEW_JOB:
                iconString = 'New'
                break
            case jobStates.INACTIVE:
            default:
                iconString = null
                break
        }
        if (iconString) {
            return (
                <Avatar
                    src={`${process.env.PUBLIC_URL}/images/jobCard/JobCard_Status${iconString}.svg`}
                    className="avatarStyle"
                />
            )
        }
    }
    render() {
        return (
            <div className="jobCard" name={this.props.workOrder.id} onClick={() => this.loadJobFiles()}>
                <Card style={{backgroundColor: this.getBackgroundColor()}} containerStyle={{paddingBottom: 0}}>
                    <CardHeader
                        title={`${this.props.workOrder.name}`}
                        titleStyle={{paddingRight: 25}}
                        actAsExpander={true}
                        className="cardHeader"
                    >
                        <div className="cardSubtitle" name="timestamp">
                            {this.props.timestamp}
                        </div>
                        <div className="cardSubtitle" name="gffInfo">
                            {`${this.props.grower.name} > ${this.props.farm.name} > ${this.props.field.name}`}
                        </div>
                        {this.getJobStatusIcon()}
                        {this.getInfoIcons()}
                    </CardHeader>
                    <CardText expandable={true} name="jobInfo" style={{paddingBottom: 0}}>
                        {this.renderProducts()}
                        {this.renderParticipants()}
                        <div style={{display: 'inline-flex', justifyContent: 'space-evenly', alignItems: 'baseline'}}>
                            {this.renderLiveButton()}
                            {this.renderDispatchButton()}
                            {this.renderDetailsButton()}
                            <IconButton
                                onClick={this.changeArchiveStatus}
                                name="archiveButton"
                                tooltip={this.props.workOrder.archived ? 'Unarchive' : 'Archive'}
                            >
                                <img src={`${process.env.PUBLIC_URL}/images/jobCard/JobCard_Btn${this.props.workOrder.archived ? 'Unarchive' : 'Archive'}Job.svg`}/>
                            </IconButton>
                            {this.renderOpenReportButton()}
                        </div>
                    </CardText>
                </Card>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(JobCard)
