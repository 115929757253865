module.exports = {
    ACTUAL_VOLUME_CONTENT_DDI: 72,
    ACTUAL_MASS_CONTENT_DDI: 75,
    ACTUAL_COUNT_CONTENT_DDI: 78,

    SETPOINT_VOLUME_PER_AREA_DDI: 1,
    SETPOINT_MASS_PER_AREA_DDI: 6,
    SETPOINT_COUNT_PER_AREA_DDI: 11,

    DEFAULT_VOLUME_PER_AREA_DDI: 3,
    DEFAULT_MASS_PER_AREA_DDI: 8,
    DEFAULT_COUNT_PER_AREA_DDI: 13,

    TOTAL_AREA_DDI: 116,
}
