import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {buttonPrototype} from '../../commonComponents/buttonBar'
import DispatchBar from './dispatchBar'
import * as workOrderActions from '../workOrderActions'

function mapStateToProps(state, ownProps) {
    return {}
}
function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(workOrderActions, dispatch),
    }
}
class JobDetailsBar extends React.Component {

    static defaultProps = {
        archived: true,
        onArchiveChanged: () => {},
        disabled: false, // for the entire bar, overrides other disabled states
        workOrderId: 0,
        workOrderGuid: '',
    }

    changeArchiveStatus = async() => {
        this.props.setWorkOrderArchived(this.props.workOrderId, !this.props.archived)
            .then(() => this.props.onArchiveChanged())
    }

    render() {
        return (
            <DispatchBar name="JobDetailsBar"
                saved={true}
                jobReportAvailable={this.props.jobReportAvailable}
                disabled={this.props.disabled}
                archived={this.props.archived}
                frontChildren={[
                    buttonPrototype({
                        label: 'Live View',
                        iconPath: `jobCard/JobCard_LiveViewWhite`,
                        mobileIconPath: `jobCard/JobCard_LiveView`,
                        disabledIconPath: `jobCard/JobCard_LiveViewDisabled`,
                        href: `/jobs/live/${this.props.workOrderGuid}`,
                        visible: !this.props.archived,
                        disabled: !this.props.workOrderGuid,
                    }),
                ]}
                backChildren={[
                    buttonPrototype({
                        label: 'Archive',
                        onClick: this.changeArchiveStatus,
                        iconPath: `jobCard/JobCard_BtnArchiveJobWhite`,
                        mobileIconPath: `jobCard/JobCard_BtnArchiveJob`,
                        disabledIconPath: `jobCard/JobCard_BtnArchiveJobDisabled`,
                        visible: !this.props.archived,
                        disabled: !this.props.workOrderGuid,
                    }),
                    buttonPrototype({
                        label: 'Unarchive',
                        onClick: this.changeArchiveStatus,
                        iconPath: `jobCard/JobCard_BtnUnarchiveJobWhite`,
                        mobileIconPath: `jobCard/JobCard_BtnUnarchiveJob`,
                        disabledIconPath: `jobCard/JobCard_BtnUnarchiveJobDisabled`,
                        visible: this.props.archived,
                        disabled: !this.props.workOrderGuid,
                    }),
                ]}
                templateOverrides={{href: `/jobs/template/${this.props.workOrderId}`, disabled: !this.props.workOrderId}}
            />
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(JobDetailsBar)
