import React from 'react'

import Dialog from 'material-ui/Dialog'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton'
import RaisedButton from 'material-ui/RaisedButton'
import TextField from 'material-ui/TextField'

import { mixEntryModeFromDdi } from '../lib/ddiConversions'

import './productConfigStyles.css'

class MixReview extends React.Component {
    static defaultProps = {
        data: {
            id: -1,
            name: '',
            carrierId: null,
            carrierQuantity: 0,
            carrierDisplayQuantity: 0,
            userDisplayUnit: 'gal/ac',
            mixIngredients: [],
            ddiId: 1,
        },
        disabled: true,
    }

    state = {
        ...this.props.data,
        entryMode: mixEntryModeFromDdi(this.props.data.ddiId),
    }

    renderIngredient = (data, i) => {
        if (!data.ingredient) data.ingredient = {name: ''}

        return (
            <div className="mixFlex" name="mixIngredientRow" key={data.id + ' ' + data.ingredientId + data.userDisplayUnit}>
                <TextField
                    className="selectFieldInline"
                    value={data.ingredient.name}
                    name="ingredientSelect"
                    disabled={this.props.disabled}
                />
                <TextField
                    floatingLabelText={this.props.mode === 'rateMode' ? 'Rate' : 'Amount'}
                    defaultValue={data.displayQuantity}
                    className="flexSmall"
                    name="displayQuantity"
                    disabled={this.props.disabled}
                />
                <TextField
                    className="unitSelectionInline"
                    value={data.userDisplayUnit}
                    disabled={this.props.disabled}
                    name="displayUnit"
                />
            </div>
        )
    }
    getCarrierSection = () => {
        if (this.state.carrier || this.state.carrierId) {
            return this.renderCarrierLine()
        }

        return this.renderNoCarrierInfo()
    }

    renderNoCarrierInfo = () => {

        return (
            <div className="mixFlex" name="noCarrierSection">
                <div style={{marginTop: '20px'}}>{`Mix Units: `}</div>
                <TextField
                    className="unitSelectionInline"
                    value={this.state.userDisplayUnit}
                    style={{top: '0px'}}
                    disabled={this.props.disabled}
                    name="mixUnitSelect"
                />
            </div>
        )
    }
    renderCarrierLine = () => {
        let name = ''
        if (this.state.carrier) {
            name = this.state.carrier.name
        }

        return (
            <div className="mixFlex" name="carrierSection">
                <TextField
                    floatingLabelText={'Carrier'}
                    className="selectFieldInline"
                    value={name}
                    style={{top: '0px'}}
                    name="carrierSelect"
                    disabled={this.props.disabled}
                />
                <TextField
                    floatingLabelText={this.state.entryMode === 'rateMode' ? 'Mix Rate' : 'Volume'}
                    value={this.state.carrierDisplayQuantity}
                    name="carrierDisplayQuantity"
                    className="flexSmall"
                    disabled={this.props.disabled}
                />
                <TextField
                    className="unitSelectionInline"
                    value={this.state.userDisplayUnit}
                    onChange={this.handleUserDisplayUnit}
                    disabled={this.props.disabled}
                    name="carrierUnits"
                />
            </div>
        )
    }
    handleClose = () => {
        this.props.onClose()
    }
    renderDialogActions = () => {
        return [
            <RaisedButton label="Close" primary={true} onClick={this.handleClose} />,
        ]
    }

    render() {
        const ingredientList = this.state.mixIngredients.map(this.renderIngredient)
        const dialogActions = this.renderDialogActions()
        const carrierSection = this.getCarrierSection()

        return (
            <Dialog
                actions={dialogActions}
                title="Mix Information"
                modal={true}
                open={this.props.open}
                onRequestClose={this.handleSubmit}
                autoScrollBodyContent={true}
                contentClassName="wodialog"
            >
                <RadioButtonGroup
                    name="entryMode"
                    valueSelected={this.state.entryMode}
                    style={{ display: 'inline-block' }}
                >
                    <RadioButton
                        value="rateMode"
                        label="Rate"
                        style={{ display: 'inline-block', width: '100px' }}
                        name="rateEntryMode"
                        disabled={this.props.disabled}
                    />
                    <RadioButton
                        value="qtyMode"
                        label={'Quantity'}
                        style={{ display: 'inline-block', width: '100px' }}
                        name="QuantityEntryMode"
                        disabled={this.props.disabled}
                    />
                </RadioButtonGroup>
                <TextField
                    floatingLabelText="Mix Name"
                    fullWidth={true}
                    value={this.state.name}
                    name="name"
                    disabled={this.props.disabled}
                />
                {carrierSection}
                {ingredientList}
            </Dialog>
        )
    }
}
export default (MixReview)
