import {checkFieldLimits, checkProductLimits} from '../lib/ddiUnits'

function CreateWorkOrderValidator() {
    function validateWorkOrder({products, workOrderName, fieldDisplayArea, fieldAreaDisplayUnit}) {
        const hasValidWorkOrderName = validateWorkOrderName(workOrderName)
        const hasValidFieldLimits = validateFieldLimits(fieldDisplayArea, fieldAreaDisplayUnit)
        const hasValidProduct = validateProducts(products)

        if (!hasValidWorkOrderName ||
            !hasValidFieldLimits ||
            !hasValidProduct
        ) {
            return [false, 'Invalid Data']
        }

        return [true, '']
    }

    function validateWorkOrderName(workOrderName) {
        return workOrderName && workOrderName.length < 33
    }

    function validateFieldLimits(fieldDisplayArea, fieldAreaDisplayUnit) {
        const errorText = checkFieldLimits(fieldDisplayArea, fieldAreaDisplayUnit)

        return !errorText
    }

    function validateProducts(products) {
        for (const prod of products) {
            if (prod.mixId <= 0 || !prod.userDisplayUnit ||
                checkProductLimits(prod.displayRate, prod.userDisplayUnit, prod.mode === 'rxMap')
            ) {

                return false
            }
        }

        return true
    }

    return {
        validateWorkOrder,
    }
}

export default CreateWorkOrderValidator()
