const geojsonWinder = require('geojson-rewind')

// /////////////////////////////////////////////////////////////
// Front end types (numeric arrays) to Backend types (geojson)
// /////////////////////////////////////////////////////////////
export function centerPrototype(feature) {
    if (feature && feature.type) {

        return Object.assign({}, feature)
    }
    if (!Array.isArray(feature) || feature.length !== 2) {
        return null
    }

    return pointArrayToGeoJsonPoint(feature)
}

export function entrancePrototype(feature) {
    if (feature && feature.type) {

        return Object.assign({}, feature)
    }
    if (!Array.isArray(feature) || feature.length == 0) {
        return null
    }

    if (!Array.isArray(feature[0])) {
        return pointArrayToGeoJsonPoint(feature)
    }

    const features = feature.map(pointArrayToGeoJsonPoint)

    return {
        type: 'FeatureCollection',
        features: features,
    }
}

export function boundaryPrototype(features) {
    // already GeoJson, copy to new object & validate winding
    if (features && features.type) {
        let collection = null
        if (features.type == 'FeatureCollection') {
            collection = Object.assign({}, features)
        }
        else {
            collection = {
                type: 'FeatureCollection',
                features: [Object.assign({}, features)],
            }
        }

        return geojsonWinder(collection, false)
    }
    // GeoJson requires 4 points to a polygon and the opening & closing points to match
    if (!Array.isArray(features) || features.length < 3) {
        return null
    }
    // Compare lat & long portions of coordinate to determine if the point is the same. JS doesn't support array comparison
    if (features.length == 3 || features[0][0] != features[features.length - 1][0] || features[0][1] != features[features.length - 1][1]) {
        features.push([...features[0]])
    }

    const proto = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'Polygon',
                    coordinates: [
                        [
                            ...features,
                        ],
                    ],
                },
            },
        ],
    }

    // ensure polygon points follow the right hand rule
    return geojsonWinder(proto, false)
}

function pointArrayToGeoJsonPoint(coordinates) {
    return {
        type: 'Feature',
        properties: {},
        geometry: {
            type: 'Point',
            coordinates: [...coordinates],
        },
    }
}

// /////////////////////////////////////////////////////////////
// Backend types (geojson) to front end types (numeric arrays)
// /////////////////////////////////////////////////////////////
export function centroidPrototype(feature) {
    if (Array.isArray(feature)) {
        return [...feature]
    }
    if (!feature || !feature.type) return []
    if (feature.type == 'Feature' && feature.geometry.type == 'Point') {
        return [...feature.geometry.coordinates]
    }

    return []
}

export function entrancePointsPrototype(feature) {
    if (Array.isArray(feature)) {
        if (!feature.length) {
            return []
        }
        else if (Array.isArray(feature[0])) {
            return [...feature]
        }

        return [[...feature]]
    }

    if (!feature || !feature.type) return []
    if (feature.type == 'Feature' && feature.geometry.type == 'Point') {
        return [[...feature.geometry.coordinates]]
    }
    if (feature.type == 'FeatureCollection') {
        return feature.features.map(f => { return [...f.geometry.coordinates] })
    }

    return []
}

export function boundaryPointsPrototype(feature) {
    if (Array.isArray(feature)) {
        return flattenPoints(feature)
    }

    if (!feature || !feature.type) return []
    if (feature.type == 'Feature') {
        return flattenPoints(feature.geometry.coordinates)
    }
    if (feature.type == 'FeatureCollection' && feature.features.length) {
        const geometries = []
        feature.features.forEach(f => {
            if (f.type == 'Feature') {
                geometries.push(f.geometry.coordinates)
            }
        })

        return flattenPoints(geometries)
    }

    return []
}

// Call with the CONTENTS of a GeoJson geometry, not the entire GeoJson object
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat
// modified to leave an extra level
export function flattenPoints(array) {
    if (!Array.isArray(array) || array.length < 1) return []
    if (!Array.isArray(array[0])) return [[...array]]

    const flattend = []

    function flattenArray(array) {
        array.forEach((el) => {
            if (Array.isArray(el) && el.length && Array.isArray(el[0])) {
                flattenArray(el)
            }
            else {
                flattend.push(el)
            }
        })
    }
    flattenArray(array)

    return flattend
}
