import axios from '../lib/axios'
import {centerPrototype, entrancePrototype, boundaryPrototype,
    centroidPrototype, entrancePointsPrototype, boundaryPointsPrototype,
} from './scoutPrototypes'
import {convertToBase} from '../lib/ddiUnits'
import {errorCheck, errorCatch} from '../lib/actionErrorHandler'
import {fetchFields} from '../gffManager/fieldActions'

export function updateFieldScoutInfo(stateStore) {
    const fieldId = stateStore.gff.selectedField.id
    const fieldInfo = {
        id: fieldId,
        boundaries: boundaryPrototype(stateStore.scout.boundaryGeoJson),
        center: centerPrototype(stateStore.scout.selectedCentroid),
        entrances: entrancePrototype(stateStore.scout.entrancePoints),
        area: convertToBase(stateStore.scout.fieldDisplayArea, stateStore.scout.fieldAreaDisplayUnit),
        areaDisplayUnit: stateStore.scout.fieldAreaDisplayUnit,
    }

    // TODO save these somewhere for workorders where they put down a boundary but not a specific field
    if (fieldId > 0) {
        return dispatch => {
            return axios({
                method: 'put',
                url: `/jobs/api/fields/${fieldId}`,
                withCredentials: true,
                data: fieldInfo,
            })
                .then(() => dispatch(fetchFields()))
                .catch(err => {
                    errorCheck(err.response)
                    errorCatch(err)
                })
        }
    }

    return dispatch => {}

}

export function selectCentroid(centroid) {
    return {
        type: 'SELECT_CENTROID',
        centroid: centroidPrototype(centroid),
    }
}

export function setEntrancePoints(entrancePoints) {
    return {
        type: 'SET_ENTRANCE_POINTS',
        entrancePoints: entrancePointsPrototype(entrancePoints),
    }
}
// Convenience access to individual points of the boundaryGeoJson
// as a single array.
// Use only for things like calculating bounding box & rendering
// boundary corners as icons. May not reflect true shape of boundary
export function setBoundaryPoints(boundaryPoints) {
    return {
        type: 'SET_BOUNDARY_POINTS',
        boundaryPoints: boundaryPointsPrototype(boundaryPoints),
    }
}
// Use for drawing full shape of boundary & calculations such as area
// boundaryPoints should be based off of the boundaryGeoJson
export function setBoundaryGeoJson(boundary) {
    return {
        type: 'SET_BOUNDARY_GEOJSON',
        boundaryGeoJson: boundaryPrototype(boundary),
    }
}

export function setFieldDisplayArea(fieldDisplayArea) {
    return {
        type: 'SET_FIELD_AREA',
        fieldDisplayArea: fieldDisplayArea,
    }
}

export function setFieldAreaDisplayUnit(fieldAreaDisplayUnit) {
    return {
        type: 'SET_FIELD_AREA_DISPLAY_UNIT',
        fieldAreaDisplayUnit: fieldAreaDisplayUnit,
    }
}
