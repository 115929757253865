export const participantActivityValues = [
    'all',
    'none',
    'in progress',
    'inactive',
]

export const dateConstantValues = [
    0,
    1,
    7,
    30,
    365,
]

export function getDefaultFilterState() {
    return {
        endDate: null,
        startDate: null,
        selectedDateConstant: 0,
        selectedAvailability: '0',
        selectedParticipantActivity: 'all',
        selectedGrowers: [],
        selectedFarms: [],
        selectedFields: [],
        noGFFonly: false,
        selectedMixes: [],
        selectedSystems: [],
        selectedCompanyId: 0,
        sortOrder: 0,
        error: {value: ''},
    }
}

export function buildURLBase() {
    return window.location.protocol + '//' + window.location.host + window.location.pathname
}

export function buildQueryString(filter) {
    let url = buildURLBase()
    let queryString = ''
    filter.error.value = ''

    if (filter.sortOrder < 0 || filter.sortOrder > 3 || isNaN(filter.sortOrder)) {
        filter.sortOrder = 0
    }
    queryString += `sortOrder=${filter.sortOrder}&`
    if (filter.selectedCompanyId) {
        queryString += `selectedCompanyId=${filter.selectedCompanyId}&`
    }
    if (filter.selectedAvailability !== '0') {
        queryString += `selectedAvailability=${filter.selectedAvailability}&`
    }
    if (filter.selectedGrowers.length > 0) {
        queryString += buildArrayParams(filter.selectedGrowers, 'selectedGrowers')
    }
    if (filter.selectedFarms.length > 0) {
        queryString += buildArrayParams(filter.selectedFarms, 'selectedFarms')
    }
    if (filter.selectedFields.length > 0) {
        queryString += buildArrayParams(filter.selectedFields, 'selectedFields')
    }
    if (filter.noGFFonly) {
        queryString += `noGFFonly=${filter.noGFFonly}&`
    }
    if (filter.selectedMixes.length > 0) {
        queryString += buildArrayParams(filter.selectedMixes, 'selectedMixes')
    }
    if (filter.selectedSystems.length > 0) {
        queryString += buildArrayParams(filter.selectedSystems, 'selectedSystems')
    }
    if (filter.selectedParticipantActivity !== 'all') {
        queryString += `selectedParticipantActivity=${filter.selectedParticipantActivity}&`
    }
    if (filter.selectedDateConstant === 0) {
        if (filter.startDate) {
            queryString += `startDate=${formatDateString(filter.startDate)}&`
        }
        if (filter.endDate) {
            queryString += `endDate=${formatDateString(filter.endDate)}&`
        }
    }
    else if (filter.selectedDateConstant) {
        queryString += `selectedDateConstant=${filter.selectedDateConstant}&`
    }
    if (queryString) {
        url += '?' + queryString.substring(queryString.length - 1, 0)
    }
    window.history.pushState({ path: url }, '', url)
}

function buildArrayParams(items, urlParam) {
    let queryString = ''
    for (let i = 0; i < items.length; i++) {
        queryString += `${urlParam}=${items[i]}&`
    }

    return queryString
}

function pushToArray(value, array) {
    if (!array.includes(value)) {
        array.push(value)
    }
}

function formatDateString(date) {
    if (isNaN(date)) {
        return date
    }

    return date.toISOString().substring(0, 10)
}

function checkValidInt(value, field, error) {
    const intValue = castToInt(value)
    if (isNaN(intValue) || intValue < 0) {
        error.value += buildErrorMessage(field, value)

        return value
    }

    return intValue
}

function checkValidBool(value, field, error) {
    value = value.toString().toLowerCase()
    if (value !== 'true' && value !== 'false') {
        error.value += buildErrorMessage(field, value)

        return value
    }

    return (value === 'true')
}

function checkValidDate(value, field, error) {
    if (isNaN(value)) {
        error.value += buildErrorMessage(field, value)
    }

    return value
}

function checkDateString(date) {
    const newDate = new Date(date + 'T00:00:00')
    if (isNaN(newDate)) {
        return date
    }

    return newDate
}

function checkValidParticipantActivity(value, error) {
    const withSpaceValue = value.toString().replace('%20', ' ')
    const match = participantActivityValues.find(s => s === withSpaceValue)
    if (!(match)) {
        error.value += buildErrorMessage('Participant Activity', value)

        return value
    }

    return match
}

function checkValidRange(value, field, error, min, max) {
    value = checkValidInt(value, field, error)
    if (error.value.indexOf(field) == -1) {
        if (!(value >= min && value <= max)) {
            error.value += `${buildErrorMessage(field, value)} value must be between ${min} and ${max} \n`
        }
    }

    return value
}

function checkValidDateConstant(value, error) {
    const field = 'Date Constant'
    value = checkValidInt(value, field, error)
    const match = dateConstantValues.find(s => s === value)
    if (!(match)) {
        error.value += buildErrorMessage(field, value)
    }

    return value
}

function castToInt(value) {
    const intValue = parseInt(value, 10)
    if (isNaN(intValue)) {
        return value
    }

    return intValue
}

function buildErrorMessage(field, value) {
    return `${field} value of '${value}' is invalid \n`
}

export function buildDatePickerDates(jobListFilter) {
    const date = new Date()
    jobListFilter.endDate = new Date()
    switch (jobListFilter.selectedDateConstant) {
        case dateConstantValues[1]:
        case dateConstantValues[2]:
            jobListFilter.startDate = new Date(date.setDate(jobListFilter.endDate.getDate() - jobListFilter.selectedDateConstant))
            break
        case dateConstantValues[3]:
            jobListFilter.startDate = new Date(date.setMonth(jobListFilter.endDate.getMonth() - 1))
            break
        case dateConstantValues[4]:
            jobListFilter.startDate = new Date(date.setFullYear(jobListFilter.endDate.getFullYear() - 1))
            break
        default:
            jobListFilter.startDate = jobListFilter.endDate
    }
}

export function setFilterFromQueryString(filter) {
    const url = window.location.search.substring(1)
    if (url) {
        const params = url.split('&')
        let dateConstantFound = false
        for (let i = 0; i < params.length; i++) {
            const param = params[i].split('=')
            if (param.length === 2) {
                const filterName = param[0]
                const filterValue = param[1]

                switch (filterName) {
                    case 'selectedAvailability' :
                        filter.selectedAvailability = filterValue
                        break

                    case 'selectedGrowers' :
                        pushToArray(filterValue, filter.selectedGrowers)
                        break

                    case 'selectedFarms' :
                        pushToArray(filterValue, filter.selectedFarms)
                        break

                    case 'selectedFields' :
                        pushToArray(filterValue, filter.selectedFields)
                        break

                    case 'noGFFonly' :
                        filter.noGFFonly = filterValue
                        break

                    case 'startDate' :
                        filter.startDate = checkDateString(filterValue)
                        break

                    case 'endDate' :
                        filter.endDate = checkDateString(filterValue)
                        break

                    case 'selectedMixes' :
                        pushToArray(filterValue, filter.selectedMixes)
                        break

                    case 'selectedSystems' :
                        pushToArray(filterValue, filter.selectedSystems)
                        break

                    case 'selectedParticipantActivity' :
                        filter.selectedParticipantActivity = filterValue
                        break

                    case 'sortOrder' :
                        filter.sortOrder = castToInt(filterValue)
                        break

                    case 'selectedDateConstant':
                        filter.selectedDateConstant = castToInt(filterValue)
                        dateConstantFound = true
                        break

                    default :
                        break
                }
            }
        }
        if (dateConstantFound) {
            buildDatePickerDates(filter)
        }
    }

    return filter
}

export function validateQueryDataTypes(filter) {
    if (filter.selectedCompanyId) {
        filter.selectedCompanyId = checkValidInt(filter.selectedCompanyId, 'Selected Company ID', filter.error)
    }
    if (filter.sortOrder) {
        filter.sortOrder = checkValidRange(filter.sortOrder, 'Sort Order', filter.error, 0, 3)
    }
    if (filter.selectedAvailability !== '0') {
        filter.selectedAvailability = checkValidRange(filter.selectedAvailability, 'Selected Availability', filter.error, 0, 2)
    }
    if (filter.selectedGrowers.length > 0) {
        for (let i = 0; i < filter.selectedGrowers.length; i++) {
            filter.selectedGrowers[i] = checkValidInt(filter.selectedGrowers[i], 'Selected Growers', filter.error)
        }
    }
    if (filter.selectedFarms.length > 0) {
        for (let i = 0; i < filter.selectedFarms.length; i++) {
            filter.selectedFarms[i] = checkValidInt(filter.selectedFarms[i], 'Selected Farms', filter.error)
        }
    }
    if (filter.selectedFields.length > 0) {
        for (let i = 0; i < filter.selectedFields.length; i++) {
            filter.selectedFields[i] = checkValidInt(filter.selectedFields[i], 'Selected Fields', filter.error)
        }
    }
    if (filter.noGFFonly) {
        filter.noGFFonly = checkValidBool(filter.noGFFonly, 'No Grower/Farm/Field', filter.error)
    }
    if (filter.startDate) {
        filter.startDate = checkValidDate(filter.startDate, 'Start Date', filter.error)
    }
    if (filter.endDate) {
        filter.endDate = checkValidDate(filter.endDate, 'End Date', filter.error)
    }
    if (filter.selectedMixes.length > 0) {
        for (let i = 0; i < filter.selectedMixes.length; i++) {
            filter.selectedMixes[i] = checkValidInt(filter.selectedMixes[i], 'Selected Mixes', filter.error)
        }
    }
    if (filter.selectedSystems.length > 0) {
        for (let i = 0; i < filter.selectedSystems.length; i++) {
            filter.selectedSystems[i] = checkValidInt(filter.selectedSystems[i], 'Selected Systems', filter.error)
        }
    }
    if (filter.selectedParticipantActivity !== 'all') {
        filter.selectedParticipantActivity = checkValidParticipantActivity(filter.selectedParticipantActivity, filter.error)
    }
    if (filter.selectedDateConstant) {
        filter.selectedDateConstant = checkValidDateConstant(filter.selectedDateConstant, filter.error)
    }
}

export function searchQueryString(param) {
    const url = window.location.search.substring(1)
    if (url) {
        const match = url.split('&').find(s => s.includes(param))
        if (match) {
            return match.split('=')[1]
        }
    }

    return null
}
