import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as rxMapActions from './rxMapActions'

import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import PropTypes from 'prop-types'

import WarningLabel from '../commonComponents/warningLabel'

function mapStateToProps(state, ownProps) {
    return {
        rxMapEditDialog: state.product.rxMapEditDialog,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(rxMapActions, dispatch)
}


class RxMapEdit extends React.Component {
    state = {
        name: 'Select an RxMap for Upload',
        ...this.props.data,
    }
    static propTypes = {
        data: PropTypes.object,
        onRxUpload: PropTypes.func,
    }

    static defaultProps = {
        data: {
            filePath: '',
        },
        onRxUpload: () => {},
        open: true,
    }
    openFileDialog = () => {
        const inputDom = React.findDOMNode(this.refs.FileUpload)
        inputDom.click()
    }
    renderWarning = () => {
        return this.props.rxMapEditDialog.error ? <WarningLabel message={this.props.rxMapEditDialog.error.message} /> : ''
    }
    handleFilePath = (event, value) => {
        if (event.target.files[0]) {
            this.setState({ files: event.target.files, name: event.target.files[0].name })
        }
    }

    handleSubmit = async() => {
        if (this.state.files) {
            const fd = new FormData()
            for (const fl of this.state.files) {
                fd.append('file', fl)
            }
            const rxMaps = await this.props.sendRxMapFile(fd)
            if (!rxMaps) { return }
            if (!rxMaps.error) {
                await this.props.fetchRxMaps()
                if (rxMaps.length) {
                    this.props.onRxUpload(rxMaps[0])
                    this.setState({name: 'Select an RxMap for Upload'})
                }
            }
            this.props.setRxMapEditDialog({ open: (rxMaps.error !== undefined), error: rxMaps})
        }
    }
    handleCancel = () => {
        this.setState({
            name: 'Select an RxMap for Upload',
            open: false,
            filePath: '',
        })
        this.props.onCancel({ ...this.state })
    }

    render() {
        const editActions = [
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.handleCancel}
                name="cancelButton"
            />,
            <RaisedButton
                icon={<img className="standardIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_SaveWhite.svg`}/>}
                label={'Save'}
                primary={true}
                onClick={this.handleSubmit}
                className="wodialog"
                name="saveButton"
            />,
        ]

        return (
            <Dialog
                actions={editActions}
                modal={false}
                open={this.props.open}
                onRequestClose={this.handleCancel}
                autoScrollBodyContent={true}
                name="rxUpload"
            >
                {this.renderWarning()}
                <FlatButton
                    containerElement="label"
                    icon={<img className="standardIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_UploadBlue.svg`}/>}
                    label={this.state.name}
                    primary={true}
                    name="uploadButton"
                >
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        accept=".zip"
                        onChange={this.handleFilePath}
                    />
                </FlatButton>
            </Dialog>

        )
    }
}

// be sure to connect the component to the Redux state so it can use it!
export default connect(mapStateToProps, mapDispatchToProps)(RxMapEdit)
