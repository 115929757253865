import * as hardwareActions from '../../hardwareManager/hardwareActions'
import * as workOrderActions from '../workOrderActions'

import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table'
import { formatDistanceToNow, isBefore, subMinutes } from 'date-fns'

import AppBar from 'material-ui/AppBar'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import geolib from 'geolib'

function mapStateToProps(state, ownProps) {
    return {
        boundaryPoints: ownProps.boundaryPoints || state.scout.boundaryPoints,
        systems: state.hardware.dispatchSystems || [],
        selectedCentroid: ownProps.centroid || state.scout.selectedCentroid,
        entrancePoints: ownProps.entrancePoints || state.scout.entrancePoints,
        workOrderUuid: ownProps.guid || state.workOrder.workOrderUuid,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(hardwareActions, dispatch),
        ...bindActionCreators(workOrderActions, dispatch),
    }
}

class DispatchDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedOnlineSystems: [],
            selectedOfflineSystems: [],
            onlineSystems: [],
            offlineSystems: [],
            message: '',
        }
    }

    componentDidMount() {
        this.props.fetchDispatchSystems()
        this.sortSystemLists(this.props.systems)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.systems !== this.props.systems ||
            prevProps.selectedCentroid !== this.props.selectedCentroid ||
            prevProps.entrancePoints !== this.props.entrancePoints) {
            this.sortSystemLists(this.props.systems)
        }
    }

    sortSystemLists = (systems) => {
        const online = []
        const offline = []
        const fiveMinAgo = subMinutes(new Date(), 5)

        for (const system of systems) {
            if (isBefore((new Date(system.lastSeen)), fiveMinAgo)) {
                offline.push(system)
            }
            else {
                online.push(system)
            }
        }
        this.sortByDistance(online)
        this.sortByDistance(offline)
        this.setState({onlineSystems: [...online], offlineSystems: [...offline]})
    }

    sortByDistance = (systems) => {
        if (systems.length === 0) return systems

        let refCoord = null

        // TODO HANDLE HERE
        if (this.props.entrancePoints.length === 1) { // array of entrances
            refCoord = {latitude: this.props.entrancePoints[0][1], longitude: this.props.entrancePoints[0][0]}
        }
        else if (this.props.selectedCentroid.length > 0) { // only one center allowed
            refCoord = {latitude: this.props.selectedCentroid[1], longitude: this.props.selectedCentroid[0]}
        }
        else if (this.props.boundaryPoints.length || this.props.entrancePoints.length) {
            const eCoords = this.props.entrancePoints.map(point => {
                return {latitude: point[1], longitude: point[0]}
            })
            const bCoords = this.props.boundaryPoints.map(point => {
                return {latitude: point[1], longitude: point[0]}
            })
            refCoord = geolib.getCenter([...eCoords, ...bCoords])
        }
        else {
            return systems
        }

        for (const system of systems) {
            if (system.gpsLatitude && system.gpsLongitude) {
                // get distance from field in meters, convert to km
                system.distance = geolib.getDistance(refCoord, {latitude: system.gpsLatitude, longitude: system.gpsLongitude}) / 1000
            }
        }
        systems.sort((a, b) => {
            if (!a.distance && !b.distance) return 0
            if (!a.distance) return 1
            if (!b.distance) return -1

            return a.distance - b.distance
        })
    }

    handleChange = (event, index, value) => {
        this.setState({value})
    }

    handleDispatch = () => {
        const systems = [...this.state.selectedOnlineSystems, ...this.state.selectedOfflineSystems]
        this.props.dispatchWorkOrder(this.props.workOrderUuid, systems).then(rVal => {
            if (!rVal) {
                this.setState({message: 'Error while dispatching'})
            }
            else {
                this.props.onDispatch()
            }
        })
    }
    handleCloseDialog = () => {
        this.props.onClose()
    }
    handleOnlineRowSelect = (selection) => {
        let systems = []
        // If called from the 'check all' box selection will contain 'all' or 'none'
        // If called from checking an individual row, selection will contain an array of selected row indexes
        if (selection === 'all') {
            systems = this.state.onlineSystems.map(system => { return system.id })
        }
        else if (Array.isArray(selection)) {
            for (const row of selection) {
                systems.push(this.state.onlineSystems[row].id)
            }
        }
        this.setState({selectedOnlineSystems: systems})
    }
    handleOfflineRowSelect = (selection) => {
        let systems = []
        // If called from the 'check all' box selection will contain 'all' or 'none'
        // If called from checking an individual row, selection will contain an array of selected row indexes
        if (selection === 'all') {
            systems = this.state.offlineSystems.map(system => { return system.id })
        }
        else if (Array.isArray(selection)) {
            for (const row of selection) {
                systems.push(this.state.offlineSystems[row].id)
            }
        }
        this.setState({selectedOfflineSystems: systems})
    }

    mapOnlineToTableRows = (system, index) => {
        return (
            <TableRow key={system.id} selected={this.state.selectedOnlineSystems.includes(system.id)} name={system.id}>
                {this.createSystemRowColumns(system)}
            </TableRow>
        )
    }
    mapOfflineToTableRows = (system, index) => {
        return (
            <TableRow key={system.id} selected={this.state.selectedOfflineSystems.includes(system.id)} name={system.id}>
                {this.createSystemRowColumns(system)}
            </TableRow>
        )
    }
    createSystemRowColumns = (system) => {
        return [
            <TableRowColumn key="name">
                { system.systemName || system.name || system.systemId || system.id }
            </TableRowColumn>,
            <TableRowColumn key="distance">
                {`${!isNaN(system.distance) ? system.distance.toFixed(2) : 'unknown'}`}
            </TableRowColumn>,
            <TableRowColumn key="lastGps">
                {`${system.lastGps ? formatDistanceToNow(new Date(system.lastGps + 'Z'), { addSuffix: true }) : 'unknown'}`}
            </TableRowColumn>,
        ]
    }

    createOnlineTableSection = () => {
        let section = <h3>Online Systems</h3>
        if (this.state.onlineSystems.length > 0) {
            const systemRows = this.state.onlineSystems.map(this.mapOnlineToTableRows)
            section =
                <div name="onlineSystemSection">
                    <h3>Online Systems</h3>
                    <Table
                        multiSelectable={true}
                        onRowSelection={this.handleOnlineRowSelect}
                        name="onlineSystemTable"
                    >
                        {this.systemTableContent(systemRows)}
                    </Table>
                </div>
        }
        else {
            section = <div><h3>Online Systems</h3><p>No available Systems</p></div>
        }

        return section
    }
    createOfflineTableSection = () => {
        let section = <h3>Offline Systems</h3>
        if (this.state.offlineSystems.length > 0) {
            const systemRows = this.state.offlineSystems.map(this.mapOfflineToTableRows)
            section =
                <div name="offlineSystemSection">
                    <h3>Offline Systems</h3>
                    <Table
                        multiSelectable={true}
                        onRowSelection={this.handleOfflineRowSelect}
                        name="offlineSystemTable"
                    >
                        {this.systemTableContent(systemRows)}
                    </Table>
                </div>
        }
        else {
            section = <div><h3>Offline Systems</h3><p>No available Systems</p></div>
        }

        return section
    }
    systemTableContent = (systemRows) => {
        return [
            <TableHeader key="header">
                <TableRow>
                    <TableHeaderColumn>System</TableHeaderColumn>
                    <TableHeaderColumn>{'Distance (km)'}</TableHeaderColumn>
                    <TableHeaderColumn>{'Last GPS'}</TableHeaderColumn>
                </TableRow>
            </TableHeader>,
            <TableBody deselectOnClickaway={false} key="body">
                {systemRows}
            </TableBody>,
        ]
    }

    render() {
        const onlineSection = this.createOnlineTableSection()
        const offlineSection = this.createOfflineTableSection()
        const actions = [
            <FlatButton
                label="Cancel"
                secondary={true}
                onClick={this.handleCloseDialog}
                name="cancelDispatch"
            />,
            <FlatButton
                label="Send to System"
                primary={true}
                onClick={this.handleDispatch}
                disabled={this.state.selectedOnlineSystems.length === 0 && this.state.selectedOfflineSystems.length === 0}
                name="submitDispatch"
            />,
        ]

        return (
            <Dialog
                actions={actions}
                modal={true}
                open={this.props.open}
                autoScrollBodyContent={true}
                contentClassName="wodialog"
                name="dispatchDialog"
            >
                <AppBar
                    title="Send To The Selected System(s)"
                    showMenuIconButton={false}
                />
                {onlineSection}
                {offlineSection}
                <div style={{color: 'red'}}>{this.state.message}</div>
            </Dialog>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DispatchDialog)
