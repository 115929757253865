import 'react-datepicker/dist/react-datepicker.css'

import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'material-ui/Tabs'
import {
    buildDatePickerDates,
    buildQueryString,
    dateConstantValues,
    getDefaultFilterState,
    participantActivityValues,
    setFilterFromQueryString,
} from './queryString'
import {setFilteredJobs, setJobListFilter} from './workOrderActions'
import { useDispatch, useSelector } from 'react-redux'

import AddIcon from 'material-ui/svg-icons/content/add'
import ClearIcon from 'material-ui/svg-icons/navigation/cancel'
import DatePicker from 'react-datepicker'
import FlatButton from 'material-ui/FlatButton'
import FloatingActionButton from 'material-ui/FloatingActionButton'
import GffFilter from '../gffManager/gffFilter'
import IconButton from 'material-ui/IconButton'
import JobListSort from './jobListSort'
import MenuItem from 'material-ui/MenuItem'
import MinimizeIcon from 'material-ui/svg-icons/content/remove'
import RaisedButton from 'material-ui/RaisedButton'
import ResetFilters from './resetFilters'
import SelectField from 'material-ui/SelectField'
import {fetchDispatchSystems} from '../hardwareManager/hardwareActions'
import {fetchMixes} from '../productManager/mixActions'
import {selectMixes} from '../productManager/productReducer'

const JobListFilter = () => {
    const dispatch = useDispatch()

    const [filterCollapsed, setFilterCollapsed] = useState(false)
    const [filteredJobs, setFilteredJobsState] = useState([])

    const workOrderStore = useSelector(store => store.workOrder)
    const hardwareStore = useSelector(store => store.hardware)
    const systemStore = useSelector(store => store.system)

    const {dispatchSystems} = hardwareStore
    const mixes = useSelector(selectMixes)
    const {workOrders: jobs, isFetching, jobListFilter} = workOrderStore
    const {selectedCompanyId, isJobFilterFromURL} = systemStore

    let systems = []
    if (Array.isArray(dispatchSystems)) {
        systems = [...dispatchSystems]
        systems.sort((a, b) => {
            if (a.name < b.name) return -1
            if (a.name > b.name) return 1

            return 0
        })
    }

    const onJobsSorted = (sortedJobs, sortOrder, sortSelection, isSortFilterChanged) => {
        dispatch(setFilteredJobs(sortedJobs))
        if (jobListFilter.sortOrder.toString() !== sortOrder.toString()) {
            jobListFilter.sortOrder = sortOrder
            if (isSortFilterChanged) {
                buildQueryString(jobListFilter)
            }
        }
    }

    const onFilterChanged = (filter) => {
        const oldFilter = jobListFilter
        dispatch(setJobListFilter(Object.assign({}, oldFilter, filter), oldFilter))
    }

    const onExpandFilter = (event) => {
        event.preventDefault()
        setFilterCollapsed(!filterCollapsed)
    }
    // In each date change handler, make sure the dates end up with the
    // start date occuring before the end date. Other wise all jobs will be filtered out.
    const handleChangeStart = (date) => {
        jobListFilter.selectedDateConstant = 0
        if (jobListFilter.endDate && date > jobListFilter.endDate) {
            onFilterChanged({startDate: jobListFilter.endDate, endDate: date})
        }
        else {
            onFilterChanged({startDate: date})
        }
    }

    const handleChangeEnd = (date) => {
        jobListFilter.selectedDateConstant = 0
        if (jobListFilter.startDate && date < jobListFilter.startDate) {
            onFilterChanged({startDate: date, endDate: jobListFilter.startDate})
        }
        else {
            onFilterChanged({endDate: date})
        }
    }
    const handleCalenderClick = (date) => {
        jobListFilter.selectedDateConstant = 0
        if ((jobListFilter.startDate && jobListFilter.endDate) ||
            (jobListFilter.startDate === null && jobListFilter.endDate === null)
        ) {
            onFilterChanged({startDate: date, endDate: null})
        }
        else if (jobListFilter.startDate && date > jobListFilter.startDate) {
            onFilterChanged({endDate: date})
        }
        else if (jobListFilter.startDate && date < jobListFilter.startDate) {
            onFilterChanged({startDate: date, endDate: jobListFilter.startDate})
        }
        else if (jobListFilter.endDate && date < jobListFilter.endDate) {
            onFilterChanged({startDate: date})
        }
        else if (jobListFilter.endDate && date > jobListFilter.endDate) {
            onFilterChanged({startDate: jobListFilter.endDate, endDate: date})
        }
        else {
            onFilterChanged({startDate: date, endDate: date})
        }
    }

    const getSelectedAvailability = () => {
        switch (Number(jobListFilter.selectedAvailability)) {
            case 0:
                return 'Unarchived'
            case 1:
                return 'Archived'
            case 2:
                return 'All'
            default:
                return 'All'
        }
    }

    const getSelectedDateConstant = () => {
        let val = ''
        switch (jobListFilter.selectedDateConstant) {
            case dateConstantValues[0]:
                val = 'None Selected'
                break
            case dateConstantValues[1]:
                val = 'Day'
                break
            case dateConstantValues[2]:
                val = 'Week'
                break
            case dateConstantValues[3]:
                val = 'Month'
                break
            case dateConstantValues[4]:
                val = 'Year'
                break
            default:
                val = 'None Selected'
        }
        if (jobListFilter.selectedDateConstant != 0) {
            buildDatePickerDates(jobListFilter)
        }

        return val
    }

    const toMenuItem = (list, selectedItems, labelKey = 'name', altKey = 'externalDeviceId') => {
        return list.map((data, i) => {
            return (
                <MenuItem
                    key={data.id}
                    insetChildren={true}
                    value={data.id}
                    primaryText={data[labelKey] || data[altKey]}
                    name={data.id}
                    checked={selectedItems.includes(data.id)}
                >
                </MenuItem>
            )
        })
    }

    const selectionRenderer = (values, list) => {
        switch (values.length) {
            case 0:
                return ''
            case 1:
                return list[0].props.primaryText
            default:
                return `${values.length} selected`
        }
    }

    const buildURL = (filter) => {
        if (filter.selectedCompanyId !== selectedCompanyId) {
            filter.selectedCompanyId = selectedCompanyId
            buildQueryString(filter)
            onFilterChanged(filter)
        }
    }

    const resetDateParams = () => {
        jobListFilter.startDate = null
        jobListFilter.endDate = null
        jobListFilter.selectedDateConstant = 0
        onFilterChanged({selectedDateConstant: dateConstantValues[0]})
    }

    const renderFilterBody = () => {
        if (filterCollapsed) return
        const systemMenuItems = toMenuItem(systems, jobListFilter.selectedSystems)

        const InvisibleContainer = () => {
            return (
                <></>
            )
        }

        return (
            <div className="jobFilter">
                <div className="filterGroup">
                    <JobListSort
                        jobs={filteredJobs}
                        onJobsSorted={onJobsSorted}
                    />
                </div>
                <div className="groupDivider" />
                <div className="filterGroup">
                    <GffFilter
                        noGFFonly={jobListFilter.noGFFonly}
                        selectedGrowers={jobListFilter.selectedGrowers}
                        selectedFarms={jobListFilter.selectedFarms}
                        selectedFields={jobListFilter.selectedFields}
                        onGffFilterChanged={onFilterChanged}
                    />
                </div>
                <div className="groupDivider"/>
                <div className="filterGroup">
                    <div style={{display: 'flex', width: '95%'}}>
                        <SelectField
                            className="autoCompleteFilter"
                            value={jobListFilter.selectedSystems}
                            onChange={(event, index, selectedSystems) => { onFilterChanged({selectedSystems}) }}
                            name="systemDropdown"
                            floatingLabelText="Systems"
                            multiple={true}
                            selectionRenderer={selectionRenderer}
                            selectedMenuItemStyle={{color: 'var(--primary)'}}
                            underlineStyle={{borderColor: 'var(--secondary)'}}
                            iconStyle={{fill: 'var(--secondary)'}}
                        >
                            {systemMenuItems}
                        </SelectField>
                        <IconButton disabled={!jobListFilter.selectedSystems.length}
                            className="clearSelection"
                            name="clearSystems"
                            onClick={() => { onFilterChanged({selectedSystems: []}) }}
                        >
                            <ClearIcon/>
                        </IconButton>
                    </div>
                </div>
                <div className="groupDivider"/>
                <div className="filterGroup">
                    <div style={{display: 'flex', width: '95%'}}>
                        <SelectField
                            className="autoCompleteFilter"
                            value={jobListFilter.selectedMixes}
                            onChange={(event, index, selectedMixes) => { onFilterChanged({selectedMixes}) }}
                            name="productDropdown"
                            floatingLabelText="Product"
                            multiple={true}
                            selectionRenderer={selectionRenderer}
                            selectedMenuItemStyle={{color: 'var(--primary)'}}
                            underlineStyle={{borderColor: 'var(--secondary)'}}
                            iconStyle={{fill: 'var(--secondary)'}}
                        >
                            {toMenuItem(mixes, jobListFilter.selectedMixes)}
                        </SelectField>
                        <IconButton disabled={!jobListFilter.selectedMixes.length}
                            className="clearSelection"
                            name="clearProducts"
                            onClick={() => { onFilterChanged({selectedMixes: []}) }}
                        >
                            <ClearIcon/>
                        </IconButton>
                    </div>
                </div>
                <div className="groupDivider"/>
                <div className="filterGroup">
                    <div style={{alignSelf: 'flex-start'}}>Show Last: <span style={{color: 'var(--primary)'}}>{getSelectedDateConstant()}</span></div>
                    <div style={{display: 'flex', width: '100%'}}>
                        <Tabs
                            name="dateParameterConstantFilter"
                            className="radioFilterRow"
                            inkBarStyle={{display: 'none'}}
                            value={jobListFilter.selectedDateConstant}
                            onChange={(selectedDateConstant) => onFilterChanged({selectedDateConstant})}
                        >
                            <Tab
                                className={jobListFilter.selectedDateConstant === dateConstantValues[1] ? 'clickedOption' : 'clickableOption'}
                                value={dateConstantValues[1]}
                                label="Day"
                                name="lastDayButton"
                            />
                            <Tab
                                className={jobListFilter.selectedDateConstant === dateConstantValues[2] ? 'clickedOption' : 'clickableOption'}
                                value={dateConstantValues[2]}
                                label="Week"
                                name="lastWeekButton"
                            />
                            <Tab
                                className={jobListFilter.selectedDateConstant === dateConstantValues[3] ? 'clickedOption' : 'clickableOption'}
                                value={dateConstantValues[3]}
                                label="Month"
                                name="lastMonthButton"
                                style={{ width: '70px' }}
                            />
                            <Tab
                                className={jobListFilter.selectedDateConstant === dateConstantValues[4] ? 'clickedOption' : 'clickableOption'}
                                value={dateConstantValues[4]}
                                label="Year"
                                name="lastYearButton"
                            />
                        </Tabs>
                        <IconButton disabled={jobListFilter.selectedDateConstant === dateConstantValues[0]}
                            className="clearSelection"
                            name="clearDateParams"
                            onClick={() => { resetDateParams() }}
                            style={{ bottom: '6px' }}
                        >
                            <ClearIcon />
                        </IconButton>
                    </div>
                    <div className="flexArea">
                        <DatePicker
                            selected={jobListFilter.startDate}
                            selectsStart
                            startDate={jobListFilter.startDate}
                            endDate={jobListFilter.endDate}
                            isClearable={true}
                            maxDate={new Date()}
                            onChange={handleChangeStart}
                            className="flexItem"
                            calendarContainer={InvisibleContainer}
                        />
                        <DatePicker
                            selected={jobListFilter.endDate}
                            selectsEnd
                            startDate={jobListFilter.startDate}
                            endDate={jobListFilter.endDate}
                            isClearable={true}
                            maxDate={new Date()}
                            onChange={handleChangeEnd}
                            className="flexItem"
                            calendarContainer={InvisibleContainer}
                        />
                    </div>
                    <DatePicker
                        inline
                        startDate={jobListFilter.startDate}
                        endDate={jobListFilter.endDate}
                        maxDate={new Date()}
                        disabled={true}
                        onChange={handleCalenderClick}
                    />
                </div>
                <div className="groupDivider"/>
                <div className="filterGroup">
                    <div style={{alignSelf: 'flex-start'}}>Availability: <span style={{color: 'var(--primary)'}}>{getSelectedAvailability()}</span></div>
                    <Tabs
                        name="jobAvailabilityFilter"
                        className="radioFilterRow"
                        inkBarStyle={{display: 'none'}}
                        value={jobListFilter.selectedAvailability}
                        onChange={(selectedAvailability) => onFilterChanged({selectedAvailability})}
                    >
                        <Tab
                            className={jobListFilter.selectedAvailability == 2 ? 'clickedOption' : 'clickableOption'}
                            value={2}
                            label="All"
                            name="allAvailabilityButton"
                        />
                        <Tab
                            className={jobListFilter.selectedAvailability == 0 ? 'clickedOption' : 'clickableOption'}
                            value={0}
                            name="summaryViewButton"
                            icon={<img className="filterOption" src={`${process.env.PUBLIC_URL}/images/jobCard/JobCard_BtnUnarchiveJobWhite.svg`}/>}
                        />
                        <Tab
                            className={jobListFilter.selectedAvailability == 1 ? 'clickedOption' : 'clickableOption'}
                            value={1}
                            name="summaryViewButton"
                            icon={<img className="filterOption" src={`${process.env.PUBLIC_URL}/images/jobCard/JobCard_BtnArchiveJobWhite.svg`}/>}
                        />
                    </Tabs>
                    <div style={{ alignSelf: 'flex-start' }}>Participant Activity: <span style={{ color: 'var(--primary)' }}>{jobListFilter.selectedParticipantActivity}</span></div>
                    <Tabs
                        name="jobActivityFilter"
                        className="radioFilterRow"
                        inkBarStyle={{display: 'none'}}
                        value={jobListFilter.selectedParticipantActivity}
                        onChange={(selectedParticipantActivity) => onFilterChanged({selectedParticipantActivity})}
                    >
                        <Tab
                            className={jobListFilter.selectedParticipantActivity === participantActivityValues[0] ? 'clickedOption' : 'clickableOption'} // all
                            value="all"
                            label="All"
                            name="allActivityFilterButton"
                        />
                        <Tab
                            className={jobListFilter.selectedParticipantActivity === participantActivityValues[1] ? 'clickedOption' : 'clickableOption'} // none
                            value="none"
                            name="newFilterButton"
                            icon={<img className="filterOption" src={`${process.env.PUBLIC_URL}/images/jobCard/JobCard_StatusNewWhite.svg`}/>}
                        />
                        <Tab
                            className={jobListFilter.selectedParticipantActivity === participantActivityValues[2] ? 'clickedOption' : 'clickableOption'} // in progress
                            value="in progress"
                            name="inProgressFilterButton"
                            icon={<img className="filterOption" src={`${process.env.PUBLIC_URL}/images/jobCard/JobCard_StatusActiveWhite.svg`}/>}
                        />
                        <Tab
                            className={jobListFilter.selectedParticipantActivity === participantActivityValues[3] ? 'clickedOption' : 'clickableOption'} // inactive
                            value="inactive"
                            name="inactiveFilterButton"
                            label="Inactive"
                        />
                    </Tabs>
                </div>
                <div className="groupDivider"/>
                <ResetFilters />
            </div>
        )
    }

    useEffect(() => {
        dispatch(fetchMixes())
    }, [])

    useEffect(() => {
        if (!isFetching) {
            setFilteredJobsState([...jobs])
        }
    }, [jobs])

    // To apply the current filters when a company is changed
    useEffect(() => {
        dispatch(fetchDispatchSystems())
        if (selectedCompanyId) {
            if (isJobFilterFromURL) {
                jobListFilter.selectedDateConstant = 0
                const filter = setFilterFromQueryString(getDefaultFilterState())
                buildURL(filter)
            }
            else {
                jobListFilter.selectedGrowers = []
                jobListFilter.selectedFarms = []
                jobListFilter.selectedFields = []
                jobListFilter.selectedSystems = []
                jobListFilter.selectedMixes = []
                buildURL(jobListFilter)
            }
        }
    }, [selectedCompanyId, isJobFilterFromURL])


    return (
        <div className="filterArea">
            <div className="floatingAdd" >
                <RaisedButton
                    label="Create"
                    href={'/jobs/create'}
                    icon={<AddIcon/>}
                    primary={true}
                    className={filterCollapsed ? 'hidden' : ''}
                    fullWidth={true}
                />
                <FloatingActionButton
                    href={'/jobs/create'}
                    className={filterCollapsed ? 'floatingAdd' : 'hidden'}
                >
                    <AddIcon/>
                </FloatingActionButton>
            </div>
            <div
                style={{backgroundColor: 'var(--primary)', border: '1px solid var(--foreground)', display: 'flex', justifyContent: 'space-between'}}
                className={filterCollapsed ? 'filterCollapsed' : ''}
                onClick={onExpandFilter}
            >
                <FlatButton
                    label="Filter"
                    onClick={onExpandFilter}
                    labelStyle={{color: 'white'}}
                    icon={<img className="standardIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_Filter.svg`}/>}
                    className={filterCollapsed ? 'filterCollapsed' : ''}
                />
                <FlatButton
                    onClick={onExpandFilter}
                    labelStyle={{color: 'white'}}
                    icon={<MinimizeIcon color="white"/>}
                    style={{minWidth: '48px'}}
                    className={filterCollapsed ? 'filterCollapsed' : ''}
                />
            </div>
            <FloatingActionButton
                onClick={onExpandFilter}
                className={filterCollapsed ? 'filterIconButton' : 'hidden'}
            >
                <img style={{height: '24px'}} src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_Filter.svg`}/>
            </FloatingActionButton>
            {renderFilterBody()}
        </div>
    )
}

export default JobListFilter
