import './stepperStyles.css'

import * as workOrderActions from './workOrderActions'

import {
    Step,
    StepContent,
    StepLabel,
    Stepper,
} from 'material-ui/Stepper'

import JobDetailsBar from './dispatch/jobDetailsBar'
import RaisedButton from 'material-ui/RaisedButton'
import React from 'react'
import TextField from 'material-ui/TextField'
import WorkOrderGFF from '../gffManager/gffDetails'
import WorkOrderRates from '../productManager/rateReview'
import WorkOrderScoutFeatures from '../scoutManager/scoutFeatureMap'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { format } from 'date-fns'
import { getHost } from '../lib/redirect'

const styles = {
    defaultContentcolor: {
        backgroundColor: 'rgba(234,234,234, 0.25)',
    },
    iconStyle: {
        position: 'absolute',
        zIndex: 5,
        paddingLeft: '5px',
    },
}

const stepValues = {
    FIRST_STEP: 0,
    WO_NAME: 0,
    GFF_SELECTION: 1,
    PRODUCT_INFO: 2,
    SCOUT_FEATURES: 3,
    JOB_FILES: 4,
    LAST_STEP: 4,
}

function mapStateToProps(state, ownProps) {
    return {
        selectedCompanyId: state.system.selectedCompanyId,
        fileTypes: state.system.fileTypes,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(workOrderActions, dispatch),
    }
}

class WorkOrderStepper extends React.Component {
    state = {
        saved: true,
        products: [],
        error: null,
        name: '',
        associatedFiles: [],
        jobReportAvailable: false,
    }

    componentDidMount() {
        this.load()
    }

    componentDidUpdate(prevProps) {
        // When the selected company changes we need to reload base information
        // or clear the state to prevent the user from viewing a workorder
        // they don't have access to
        if (this.props.selectedCompanyId !== prevProps.selectedCompanyId) {
            this.load()
        }
    }
    getDisabled = () => {
        return !this.props.selectedCompanyId
    }
    load = () => {
        this.props.loadWorkOrderDetails(this.props.existingWorkOrderId).then(
            woDetails => {
                if (!woDetails.error) {
                    this.setState({
                        ...woDetails,
                        ...woDetails.metadata,
                        error: null,
                        createdTimestamp: format(woDetails.createdTime, 'P p'),
                        updatedTimestamp: format(woDetails.updatedTime, 'P p'),
                    })
                }
                else {
                    this.setState({error: woDetails.message})
                }
            }
        )
        this.props.loadWorkOrderAssociatedFiles(this.props.existingWorkOrderId).then(
            woFiles => {
                if (!woFiles.error && Array.isArray(woFiles)) {
                    this.setState({
                        associatedFiles: woFiles,
                    })
                }
            }
        )
        this.props.loadWorkOrderApplicationFiles(this.props.existingWorkOrderId).then(
            files => {
                if (!files.error && Array.isArray(files) && files.length > 0) {
                    this.setState({
                        jobReportAvailable: true,
                    })
                }
            }
        )
    }
    renderAssociatedFiles = () => {
        let fileContent
        if (!this.state.associatedFiles || !this.state.associatedFiles.length) {
            fileContent = <span>{`No associated Files`}</span>
        }
        else {

            fileContent = (
                <table style={{width: '100%', paddingBottom: 20}}>
                    <tbody>
                        {this.state.associatedFiles.map(this.renderFileLine)}
                    </tbody>
                </table>
            )
        }

        return fileContent
    }
    renderFileLine = (data, i) => {
        const fileType = this.props.fileTypes.find(f => f.id == data.fileTypeId)
        let displayName = data.name || data.id
        if (fileType && fileType.hasDetailView) {
            // we are feature flagging the new rx map file viewer with the conversion flag
            const href = data.fileTypeId === 4 && !fileType.requiresConversion ? `/jobs/rxmap/${data.id}` : `https://${getHost()}/index.php?r=fileMgmt/viewFile&id=${data.id}`

            displayName = <a
                href={href}
                target={`File_${data.id}`}
            >
                {displayName}
            </a>
        }

        return (
            <tr key={`File-${i}`}>
                <td>
                    {displayName}
                </td>
                <td>{fileType ? fileType.typeName : 'Unknown'}</td>
            </tr>
        )
    }
    getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case stepValues.WO_NAME:
                return (
                    <div name="nameSection" className="stepContent-Indented">
                        <TextField
                            floatingLabelText="Job Name"
                            fullWidth={true}
                            value={this.state.name}
                            disabled={this.state.saved}
                            name="nameEntry"
                        />
                    </div>
                )
            case stepValues.GFF_SELECTION:
                return (
                    <div name="gffSectionContent" className="stepContent-Indented">
                        <WorkOrderGFF disabled={this.state.saved} grower={this.state.grower} farm={this.state.farm} field={this.state.field}/>
                    </div>
                )
            case stepValues.PRODUCT_INFO:
                return (
                    <div name="productSectionContent" className="stepContent-Indented">
                        <WorkOrderRates disabled={this.state.saved} products={this.state.products}/>
                    </div>
                )
            case stepValues.SCOUT_FEATURES:
                return (
                    <div name="scoutSectionContent">
                        <TextField className="stepContent-Indented"
                            value={`${this.state.fieldDisplayArea} ${this.state.fieldAreaDisplayUnit}`}
                            disabled={this.state.saved}
                            floatingLabelText={'Field Area'}
                            fullWidth={true}
                            name="fieldArea"
                        />
                        <WorkOrderScoutFeatures
                            interactive={this.state.saved}
                            style={{top: '-15px'}}
                        />
                    </div>
                )
            case stepValues.JOB_FILES:
                return (
                    <div name="jobFilesSectionContent">
                        <div className="stepContent-Indented">
                            {this.renderAssociatedFiles()}
                        </div>
                    </div>
                )
            default:
                return '404: Page not found - stepper out of bounds'
        }
    }
    renderStep = (stepLabel, stepValue) => {
        return (
            <Step active={true} className="stepperStep">
                <StepLabel
                    iconContainerStyle={styles.iconStyle}
                    name={`${stepLabel}-Label`}
                    className="stepHeader"
                >
                    <RaisedButton
                        fullWidth={true}
                        label={stepLabel}
                        buttonStyle= {{textAlign: 'left'}}
                        labelStyle= {{marginLeft: '20px'}}
                        secondary={true}
                        name={`${stepLabel}-StepButton`}
                    />
                </StepLabel>
                <StepContent>{this.getStepContent(stepValue)}</StepContent>
            </Step>
        )
    }
    render() {
        if (this.props.existingWorkOrderId && this.state.originationId == 'FC' && !this.state.cloudJob) {
            throw new Error('Cannot show as-applied job as pre-populated job details')
        }

        if (this.state.error) {
            return (
                <label>{this.state.error}</label>
            )
        }

        return (
            <div
                className="stepper"
                name="verticalStepper"
            >
                <div style={{display: 'flex', flexWrap: 'wrap', marginBottom: 5}}>
                    <div style={{flexGrow: 1}}>{`Created: ${this.state.createdTimestamp}`}</div>
                    <div style={{flexGrow: 1}}>{`Last Update: ${this.state.updatedTimestamp}`}</div>
                </div>
                <label>
                    NOTE: This page reflects the job as it was generated.
                    Information such as mix content may have since been updated.
                </label>
                <Stepper
                    linear={false}
                    orientation="vertical"
                    style={styles.defaultContentcolor}
                >
                    {this.renderStep('Job Name', stepValues.WO_NAME)}
                    {this.renderStep('Grower, Farm, Field', stepValues.GFF_SELECTION)}
                    {this.renderStep('Product Config', stepValues.PRODUCT_INFO)}
                    {this.renderStep('Field Information', stepValues.SCOUT_FEATURES)}
                    {this.renderStep('Associated Files', stepValues.JOB_FILES)}
                </Stepper>
                <JobDetailsBar
                    jobReportAvailable={this.state.jobReportAvailable}
                    archived={this.state.archived}
                    disabled={this.getDisabled()}
                    workOrderId={this.state.id}
                    onArchiveChanged={this.load}
                    workOrderGuid={this.state.guid}
                />
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderStepper)
