import axios from '../lib/axios'
import {errorCheck, errorCatch} from '../lib/actionErrorHandler'
import {centerPrototype, entrancePrototype, boundaryPrototype,
    centroidPrototype, entrancePointsPrototype, boundaryPointsPrototype} from '../scoutManager/scoutPrototypes'

import * as scoutActions from '../scoutManager/scoutActions'
import * as farmActions from '../gffManager/farmActions'
import * as fieldActions from '../gffManager/fieldActions'
import * as growerActions from '../gffManager/growerActions'
import * as mixActions from '../productManager/mixActions'
import * as rxMapActions from '../productManager/rxMapActions'

import { buildDatePickerDates, buildQueryString, validateQueryDataTypes } from './queryString'
import { productPrototype } from '../productManager/productPrototypes'
import { convertToBase, convertToDisplay } from '../lib/ddiUnits'
import { productModeFromDdi } from '../lib/ddiConversions'
import { getSelectedCompanyCookie } from '../system/systemActions'

let controller

export function fetchWorkOrders(params) {

    return dispatch => {
        dispatch({type: 'REQUEST_WORK_ORDERS'})
        if (controller) {
            controller.abort()
        }
        controller = new AbortController()
        const url = '/jobs/api/workorders'
        const urlparams = new URLSearchParams(params)

        if (!getSelectedCompanyCookie()) {
            return
        }

        return axios.get(url, {
            params: urlparams,
            signal: controller.signal,
        })
            .then(response => response.data)
            .then(json => dispatch(receiveWorkOrders(json)))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                if (err.code != 'ERR_CANCELED') {
                    dispatch(receiveWorkOrders([]))
                }
            })
    }
}

function receiveWorkOrders(workOrders) {
    if (!Array.isArray(workOrders)) { workOrders = [] }
    for (const wo of workOrders) {
        processMetadata(wo)
    }

    return {
        type: 'RECEIVE_WORK_ORDERS',
        workOrders: workOrders,
    }
}

export function saveWorkOrder(store) {
    const stateStore = JSON.parse(JSON.stringify(store))
    const woObject = {
        name: stateStore.workOrder.workOrderName,
        guid: stateStore.workOrder.workOrderUuid,
        growerId: stateStore.gff.selectedGrower.id,
        farmId: stateStore.gff.selectedFarm.id,
        fieldId: stateStore.gff.selectedField.id,
        products: stateStore.product.currentProducts,
        cloudJob: stateStore.workOrder.cloudJob,
        fieldArea: convertToBase(stateStore.scout.fieldDisplayArea, stateStore.scout.fieldAreaDisplayUnit),
        fieldAreaDisplayUnit: stateStore.scout.fieldAreaDisplayUnit,
        boundaries: boundaryPrototype(stateStore.scout.boundaryGeoJson),
        center: centerPrototype(stateStore.scout.selectedCentroid),
        entrances: entrancePrototype(stateStore.scout.entrancePoints),
        originationId: 'PORTAL',
    }
    if (woObject.growerId < 0) delete woObject.growerId
    if (woObject.farmId < 0) delete woObject.farmId
    if (woObject.fieldId < 0) delete woObject.fieldId

    for (const product of woObject.products) {
        product.assignedRate = convertToBase(product.displayRate, product.userDisplayUnit)
        if (product.guid) delete product.guid
        if (product.mode === 'targetRate') {
            if (product.rxMapId) delete product.rxMapId
            if (product.rxMapColumnId) delete product.rxMapColumnId
            if (product.rxMapUnit) delete product.rxMapUnit
        }
    }

    return dispatch => {
        dispatch(stateUpdate('PROCESSING_WORK_ORDER'))
        dispatch(scoutActions.updateFieldScoutInfo(stateStore))

        return axios.post('/jobs/api/workorders/', {...woObject})
            .then(response => response.data)
            .then((json) => {
                dispatch(stateUpdate('PROCESSING_WORK_ORDER_COMPLETED'))

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(stateUpdate('PROCESSING_WORK_ORDER_ERROR'))

                return {error: true, errorCode: err.response.statusText}
            })

    }
}

export function downloadWorkPackage(uuid) {
    return dispatch => {
        dispatch(stateUpdate('DOWNLOADING_WORK_ORDER'))
        window.open(`/jobs/api/workorders/uuid/${uuid}/file`, '_blank')

        return dispatch(stateUpdate('DOWNLOADING_WORK_ORDER_COMPLETED'))
    }
}

export function dispatchWorkOrder(uuid, selectedSystemIds = []) {
    return dispatch => {
        dispatch(stateUpdate('DISPATCHING_WORK_ORDER'))

        return axios.post(`/jobs/api/workorders/uuid/${uuid}/dispatch?systems=true`, {systemIds: selectedSystemIds})
            .then(response => response.data)
            .then((json) => {
                dispatch(stateUpdate('DISPATCHING_WORK_ORDER_COMPLETED'))

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(stateUpdate('DISPATCHING_WORK_ORDER_FAILED'))
            })
    }
}

export function setWorkOrderArchived(id, archived) {
    return async dispatch => {
        dispatch({type: 'UPDATE_WORK_ORDER', workOrder: {id, archived}})

        return axios.put(`/jobs/api/workorders/${id}/archived`, {archived})
            .then(response => response.data)
            .then((json) => {
                dispatch(stateUpdate('UPDATE_WORK_ORDER_COMPLETED'))

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(stateUpdate('UPDATE_WORK_ORDER_FAILED'))

                return err
            })
    }
}

export function deleteWorkOrder(id) {
    return dispatch => {
        dispatch({type: 'DELETE_WORK_ORDER', workOrder: {id: id}})

        return axios.delete(`/jobs/api/workorders/${id}`)
            .then(response => response.data)
            .then((json) => {
                dispatch(stateUpdate('DELETE_WORK_ORDER_COMPLETED'))

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(stateUpdate('DELETE_WORK_ORDER_FAILED'))
            })
    }
}

function stateUpdate(actionType) {
    return {
        type: actionType,
    }
}

export function setWorkOrderUuid(uuid) {
    return {
        type: 'SET_WORK_ORDER_UUID',
        workOrderUuid: uuid,
    }
}

export function generateWorkOrderUuid() {
    return {
        type: 'GENERATE_WORK_ORDER_UUID',
    }
}

export function setProducts(currentProducts) {
    return {
        type: 'SET_CURRENT_PRODUCTS',
        currentProducts,
    }
}

export function setFilteredJobs(filteredJobs) {
    return {
        type: 'SET_FILTERED_JOBS',
        filteredJobs,
    }
}

export function clearWorkOrder() {
    return dispatch => {
        dispatch(farmActions.selectFarm({ id: -1 }))
        dispatch(fieldActions.selectField({ id: -1 }))
        dispatch(growerActions.selectGrower({ id: -1 }))
        dispatch(scoutActions.selectCentroid([]))
        dispatch(scoutActions.setEntrancePoints([]))
        dispatch(scoutActions.setBoundaryGeoJson(null))
        dispatch(scoutActions.setBoundaryPoints([]))
        dispatch(scoutActions.setFieldDisplayArea(1))
        dispatch(scoutActions.setFieldAreaDisplayUnit('ac'))
        dispatch(setProducts([new productPrototype()]))
        dispatch(setWorkOrderName(''))
        dispatch(generateWorkOrderUuid())
    }
}

export function setCloudJob(cloudJob = false) {
    return {
        type: 'SET_CLOUD_JOB',
        cloudJob,
    }
}

export function setOriginationId(originationId = 'PORTAL') {
    return {
        type: 'SET_ORIGINATION_ID',
        originationId,
    }
}

function processMetadata(wo) {
    if (!wo.metadata) wo.metadata = {products: [], mixes: []}
    wo.updatedTime = new Date(wo.updatedTime)
    wo.createdTime = new Date(wo.createdTime)
    wo.metadata.mixes = wo.metadata.mixes.map(mixActions.mixDisplayCalculations)

    for (const product of wo.metadata.products) {
        product.displayRate = convertToDisplay(product.assignedRate, product.userDisplayUnit).toFixed(2)
        product.mode = productModeFromDdi(product.ddiId)
        if (product.mix) { continue }
        for (const mix of wo.metadata.mixes) {
            if (mix.id === product.mixId) {
                product.mix = mix
            }
        }
    }

    wo.metadata.boundaryPoints = boundaryPointsPrototype(wo.metadata.boundaries)
    wo.metadata.boundaryGeoJson = boundaryPrototype(wo.metadata.boundaries)
    wo.metadata.entrancePoints = entrancePointsPrototype(wo.metadata.entrances)
    wo.metadata.centroid = centroidPrototype(wo.metadata.center)
    // convert field area to display using the known unit
    if (wo.metadata.fieldArea && wo.metadata.fieldAreaDisplayUnit) {
        wo.metadata.fieldDisplayArea = convertToDisplay(wo.metadata.fieldArea, wo.metadata.fieldAreaDisplayUnit).toFixed(2)
    }
    // have a field area set without a unit, default to the base unit - unexpected but don't want to ignore the data
    else if (wo.metadata.fieldArea) {
        wo.metadata.fieldDisplayArea = wo.metadata.fieldArea
        wo.metadata.fieldAreaDisplayUnit = 'm2'
    }
    // old job without an area in the metadata
    else {
        wo.metadata.fieldDisplayArea = 'n/a'
        wo.metadata.fieldAreaDisplayUnit = ''
    }
}

export function loadWorkOrderDetails(id) {
    return dispatch => {
        const url = `/jobs/api/workorders/${id}`

        return dispatch(_loadJobDetails(url))
    }
}


export function loadJobDetailsByUuid(uuid) {
    return dispatch => {
        const url = `/jobs/api/workorders/uuid/${uuid}`

        return dispatch(_loadJobDetails(url))
    }
}

function _loadJobDetails(url) {
    return dispatch => {
        return axios.get(url)
            .then(response => response.data)
            .then(wo => {
                processMetadata(wo)
                dispatch(setWorkOrderInStore(wo.metadata))
                dispatch(setWorkOrderName(wo.name))
                dispatch(setWorkOrderUuid(wo.guid))

                return wo
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)

                return err
            })
    }
}

export function loadWorkOrderAssociatedFiles(id) {
    return disaptch => {

        return axios.get(`/jobs/net-api/jobs/${id}/associated-files`)
            .then(response => response.data)
            .then(r => {
                if (Array.isArray(r)) {
                    return r
                }

                return []
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)

                return err
            })
    }
}

export function loadWorkOrderApplicationFiles(id) {
    return disaptch => {
        return axios.get(`/jobs/net-api/jobs/${id}/application-files`)
            .then(response => response.data)
            .then(r => {
                if (Array.isArray(r)) {
                    return r
                }

                return []
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)

                return err
            })
    }
}

export function loadWorkOrder(id) {
    return dispatch => {
        axios.get(`/jobs/api/workorders/${id}`)
            .then(response => response.data)
            .then(json => {
                dispatch(setWorkOrderInStore(json))
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
            })
    }
}

function setWorkOrderInStore(woInfo) {
    return dispatch => {
        const rxMapIds = []
        for (const product of woInfo.products) {
            product.displayRate = convertToDisplay(product.assignedRate, product.userDisplayUnit).toFixed(2)
            product.mode = productModeFromDdi(product.ddiId)
            if (product.rxMapId) {
                rxMapIds.push(product.rxMapId)
            }
        }
        if (rxMapIds.length) {
            // make sure we have rxmap information loaded, and that these maps have been parsed
            dispatch(rxMapActions.fetchRxMaps())
                .then(() => {
                    for (const id of rxMapIds) {
                        dispatch(rxMapActions.fetchRxMapMeta(id))
                    }
                })

        }
        if (woInfo.grower) {
            dispatch(growerActions.selectGrower(woInfo.grower))
        }
        if (woInfo.field) {
            dispatch(fieldActions.selectField(woInfo.field))
        }
        if (woInfo.farm) {
            dispatch(farmActions.selectFarm(woInfo.farm))
        }

        dispatch(setCloudJob(woInfo.cloudJob))
        dispatch(setOriginationId(woInfo.originationId))
        dispatch(scoutActions.selectCentroid(woInfo.center))
        dispatch(scoutActions.setEntrancePoints(woInfo.entrances))
        dispatch(scoutActions.setBoundaryGeoJson(woInfo.boundaries))
        dispatch(scoutActions.setBoundaryPoints(woInfo.boundaries))

        if (woInfo.fieldDisplayArea && woInfo.fieldAreaDisplayUnit) {
            dispatch(scoutActions.setFieldDisplayArea(woInfo.fieldDisplayArea))
            dispatch(scoutActions.setFieldAreaDisplayUnit(woInfo.fieldAreaDisplayUnit))
        }
        else if (woInfo.metadata && woInfo.metadata.fieldArea && woInfo.metadata.fieldAreaDisplayUnit) {
            dispatch(scoutActions.setFieldDisplayArea(convertToDisplay(woInfo.metadata.fieldArea, woInfo.metadata.fieldAreaDisplayUnit).toFixed(2)))
            dispatch(scoutActions.setFieldAreaDisplayUnit(woInfo.metadata.fieldAreaDisplayUnit))
        }
        else {
            dispatch(scoutActions.setFieldDisplayArea('n/a'))
            dispatch(scoutActions.setFieldAreaDisplayUnit('ac'))
        }

        dispatch(setProducts(woInfo.products))
    }
}

export function setWorkOrderName(workOrderName) {
    return {
        type: 'SET_WORK_ORDER_NAME',
        workOrderName,
    }
}

export function setJobListFilter(filter, oldFilter) {
    let changed = 0
    if (oldFilter) {
        if (filter.selectedAvailability !== oldFilter.selectedAvailability) {
            changed = 1
        }
        if (filter.selectedGrowers.length !== oldFilter.selectedGrowers.length) {
            changed = 1
        }
        if (filter.selectedFarms.length !== oldFilter.selectedFarms.length) {
            changed = 1
        }
        if (filter.selectedFields.length !== oldFilter.selectedFields.length) {
            changed = 1
        }
        if (filter.noGFFonly !== oldFilter.noGFFonly) {
            changed = 1
        }
        if (filter.endDate !== oldFilter.endDate) {
            changed = 1
        }
        if (filter.startDate !== oldFilter.startDate) {
            changed = 1
        }
        if (filter.selectedMixes.length !== oldFilter.selectedMixes.length) {
            changed = 1
        }
        if (filter.selectedSystems.length !== oldFilter.selectedSystems.length) {
            changed = 1
        }
        if (filter.selectedParticipantActivity !== oldFilter.selectedParticipantActivity) {
            changed = 1
        }
        if (filter.selectedDateConstant !== oldFilter.selectedDateConstant) {
            changed = 1
            buildDatePickerDates(filter)
        }
    }

    return dispatch => {
        dispatch({type: 'SET_JOB_LIST_FILTER',
            filter})
        if (changed == 1) {
            buildQueryString(filter)
            validateQueryDataTypes(filter)
            // remove invalid dates so they don't break the datepicker
            if (filter.error.value.indexOf('End Date') > -1) {
                filter.endDate = null
            }
            if (filter.error.value.indexOf('Start Date') > -1) {
                filter.startDate = null
            }

            return dispatch(fetchWorkOrders(filter))
        }
    }
}
