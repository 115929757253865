import './App.css'
import './ravenStyles.css'
import {MuiThemeProvider, getMuiTheme} from 'material-ui/styles'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import store, {persistor} from './store'
import { unregister } from './registerServiceWorker'
import App from './App'
import ErrorBoundary from './commonComponents/errorBoundary'
import {PersistGate} from 'redux-persist/integration/react'
require('trackjs')

unregister()

const ravenBlue = 'var(--primary)'
const darkBlue = '#022e47'

const styles = {
    container: {
        paddingTop: 5,
        paddingLeft: 5,
        paddingRight: 5,
    },
}

const muiTheme = getMuiTheme({
    fontFamily: 'Open Sans, sans-serif',
    palette: {
        primary1Color: ravenBlue,
        primary2Color: darkBlue,
        primary3Color: darkBlue,
        accent1Color: darkBlue,
        accent2Color: darkBlue,
        accent3Color: darkBlue,
    },
    appBar: {
        color: ravenBlue,
    },
})

ReactDOM.render(
    <ErrorBoundary>
        <MuiThemeProvider muiTheme={muiTheme}>
            <PersistGate loading={null} persistor={persistor}>
                <Provider store={store} style={styles.container}>
                    <App/>
                </Provider>
            </PersistGate>
        </MuiThemeProvider>
    </ErrorBoundary>
    , document.getElementById('root'))
