import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import IconButton from 'material-ui/IconButton'
import MenuItem from 'material-ui/MenuItem'
import RaisedButton from 'material-ui/RaisedButton'
import SelectField from 'material-ui/SelectField'

import AddIcon from 'material-ui/svg-icons/content/add'
import TextField from 'material-ui/TextField'

import * as mixActions from './mixActions'
import { selectIngredients, selectMixes } from './productReducer'
import { ddiFromDdiAndEntryMode } from '../lib/ddiConversions'
import { checkProductLimits } from '../lib/ddiUnits'
import { getMenuItemsForProductDDI } from '../commonComponents/unitMenuItems'

function mapStateToProps(state, ownProps) {
    return {
        mixes: selectMixes(state),
        ingredients: selectIngredients(state),
        carriers: state.product.carriers,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(mixActions, dispatch)
}

class MixIngredient extends React.Component {
    handleIngredientSelect = (event, index, value) => {
        if (value >= 0) {
            this.props.selectIngredient(this.props.data.id, value)
        }
        else {
            this.handleAddIngredient()
        }
    }
    handleTextChange = (event) => {
        this.props.setIngredientProperty(this.props.data.id, event.target.name, event.target.value)
    }
    handleUserDisplayUnit = (event, index, value) => {
        this.props.setIngredientProperty(this.props.data.id, 'userDisplayUnit', value)
    }
    handleDelete = event => {
        this.props.delete(this.props.data.id)
    }
    handleAddIngredient = () => {
        this.props.addIngredient(this.props.data.id)
    }

    createIngredientDropDownList = () => {
        const addButton = (
            <MenuItem value={-1} key={'new'} label={'Select an Ingredient'} name="newIngredient">
                <RaisedButton primary={true} fullWidth={true} icon={<AddIcon />} onClick={this.handleAddIngredient} name="newIngredientButton"/>
            </MenuItem>
        )

        return [
            addButton,
            ...this.props.ingredients.map((data, i) => {
                return <MenuItem key={data.id} value={data.id} primaryText={data.name} name={data.id}/>
            }),
        ]
    }

    getUnitMenuItems = () => {
        let ing = this.props.ingredients.find(ing => { return this.props.data.ingredientId === ing.id })
        if (ing === undefined) {
            ing = {ddiId: -1}
        }

        const ddi = ddiFromDdiAndEntryMode(ing.ddiId, this.props.mode)

        return getMenuItemsForProductDDI(ddi)
    }

    renderIngredientSelection = () => {
        // For new ingredient lines or ones still available in the standard list show a dropdown
        if (!this.props.data.ingredient ||
            this.props.ingredients.find(ing => { return this.props.data.ingredientId === ing.id })
        ) {
            const availIngredients = this.createIngredientDropDownList()

            return (
                <SelectField
                    className="selectFieldInline"
                    value={this.props.data.ingredientId}
                    onChange={this.handleIngredientSelect}
                    errorText={this.props.data.ingredientId <= 0 && 'Required Field'}
                    name="ingredientSelect"
                    disabled={this.props.disabled}
                >
                    {availIngredients}
                </SelectField>
            )
        }

        // Otherwise we are in a mix edit with a deleted ingredient, but we still want
        // to show this information to the user.

        return (
            <TextField
                className="textFieldInline"
                value={this.props.data.ingredient.name}
                errorText={!this.props.data.ingredient.name && 'Deleted Ingredient'}
                name="ingredientSelect"
                floatingLabelText="Archived Ingredient"
                disabled={true}
            />
        )
    }
    renderDelete = () => {
        if (!this.props.disabled) {
            return (
                <IconButton
                    className="ingredientDelete"
                    name="deleteMixIngredient"
                    onClick={this.handleDelete}
                >
                    <img src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_DeleteBlack.svg`}/>
                </IconButton>
            )
        }
    }

    render() {
        const ingredientSelection = this.renderIngredientSelection()
        const units = this.getUnitMenuItems()
        const deleteButton = this.renderDelete()
        const errorText = checkProductLimits(this.props.data.displayQuantity, this.props.data.userDisplayUnit)

        return (
            <div className="mixFlex" name="mixIngredientRow">
                {ingredientSelection}
                <TextField
                    floatingLabelText={this.props.mode === 'rateMode' ? 'Rate' : 'Amount'}
                    onChange={this.handleTextChange}
                    defaultValue={this.props.data.displayQuantity}
                    className="flexSmall"
                    name="displayQuantity"
                    errorText={errorText}
                    disabled={this.props.disabled}
                />
                <SelectField
                    className="unitSelectionInline"
                    value={this.props.data.userDisplayUnit}
                    onChange={this.handleUserDisplayUnit}
                    errorText={!this.props.data.userDisplayUnit && 'Required Field'}
                    disabled={this.props.disabled}
                    name="displayUnit"
                >
                    {units}
                </SelectField>
                {deleteButton}
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MixIngredient)
