import React from 'react'
import * as ddiConstants from '../lib/ddiConstants'
import {volumeUnits, volumePerAreaUnits, massUnits, massPerAreaUnits, areaUnits} from '../lib/units'
import MenuItem from 'material-ui/MenuItem'

function toMenuItem(data, i) {
    return <MenuItem value={data.abbreviation} key={data.abbreviation} primaryText={data.abbreviation} />
}

const volumeMenuItems = volumeUnits.map(toMenuItem)
const volumePerAreaMenuItems = volumePerAreaUnits.map(toMenuItem)
const massMenuItems = massUnits.map(toMenuItem)
const massPerAreaMenuItems = massPerAreaUnits.map(toMenuItem)
const rateUnitMenuItems = [...volumePerAreaUnits, ...massPerAreaUnits].map(toMenuItem)
const quantityUnitMenuItems = [...volumeUnits, ...massUnits].map(toMenuItem)
const productMenuItems = [...volumeUnits, ...massUnits, ...volumePerAreaUnits, ...massPerAreaUnits].map(toMenuItem)

export function getMenuItemsForProductDDI(ddiId) {
    switch (ddiId) {
        case ddiConstants.ACTUAL_VOLUME_CONTENT_DDI:
            return volumeMenuItems
        case ddiConstants.ACTUAL_MASS_CONTENT_DDI:
            return massMenuItems
        case ddiConstants.SETPOINT_VOLUME_PER_AREA_DDI:
        case ddiConstants.DEFAULT_VOLUME_PER_AREA_DDI:
            return volumePerAreaMenuItems
        case ddiConstants.SETPOINT_MASS_PER_AREA_DDI:
        case ddiConstants.DEFAULT_MASS_PER_AREA_DDI:
            return massPerAreaMenuItems
        default:
            return productMenuItems
    }
}

export function getMenuItemsForProductEntryMode(entryMode) {
    if (entryMode === 'rateMode') {
        return rateUnitMenuItems
    }
    else if (entryMode === 'qtyMode') {
        return quantityUnitMenuItems
    }

    return productMenuItems
}

export const areaMenuItems = areaUnits.map(toMenuItem)
