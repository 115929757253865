import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { Card, CardActions, CardHeader } from 'material-ui/Card'
import FlatButton from 'material-ui/FlatButton'
import AddIcon from 'material-ui/svg-icons/content/add'
import EditIcon from 'material-ui/svg-icons/content/create'
import InfoIcon from 'material-ui/svg-icons/action/info'

import DeleteButton from '../commonComponents/deleteDialogButton'
import GrowerEdit from './groweredit'

import * as growerActions from './growerActions'


function mapStateToProps(state, ownProps) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(growerActions, dispatch),
    }
}

// TODO fix actions details -- use class? (Class unused at this point)
class GrowerCard extends React.Component {
    static propTypes = {
        data: PropTypes.object,
        disabled: PropTypes.bool,
    }

    static defaultProps = {
        data: {},
        disabled: false,
    }

    constructor(props) {
        super(props)
        this.state = {
            expanded: false,
            editDialogOpen: false,
        }
    }

    handleExpandChange = expanded => {
        this.setState({ expanded: expanded })
    }

    handleToggle = () => {
        this.setState({ expanded: !this.state.expanded })
    }

    handleDelete = () => {
        this.props.deleteGrower(this.props.data.id)
    }

    handleAdd = () => {
        this.props.addFarm(this.props.data.id)
    }

    handleEditRequest = () => {
        this.setState({ editDialogOpen: true })
    }
    handleCancel = () => {
        this.setState({
            editDialogOpen: false,
        })
    }
    handleSave = newData => {
        this.props.updateGrower({...newData})
        this.setState({
            editDialogOpen: false,
        })
    }

    render() {
        return (
            <Card
                key={this.props.data.id}
                name={this.props.data.id}
            >
                <CardHeader
                    title={this.props.data.name}
                    subtitle="Grower"
                    avatar={<InfoIcon color="#BCBCBC"/>}
                />
                <CardActions>
                    <FlatButton
                        icon={<EditIcon />}
                        label="Details"
                        onClick={this.handleEditRequest}
                        disabled={this.props.disabled}
                        primary={true}
                        name="openGrowerDialog"
                    />
                    <DeleteButton
                        itemType="grower"
                        deleteFunction={this.handleDelete}
                        disabled={this.props.disabled}
                        primary={true}
                        customContent="Are you sure you wish to delete this grower and all of its farms and fields?"
                    />
                    <FlatButton
                        icon={<AddIcon />}
                        label="Add Farm"
                        onClick={this.handleAdd}
                        disabled={this.props.disabled}
                        primary={true}
                        name="addFarm"
                    />
                    <GrowerEdit
                        data={this.props.data}
                        onCancel={this.handleCancel}
                        onSubmit={this.handleSave}
                        open={this.state.editDialogOpen}
                    />
                </CardActions>
            </Card>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GrowerCard)
