
export function selectGeolocation(feature) {
    return {
        type: 'SELECT_GEOLOCATION',
        geolocation: feature,
    }
}

export function fetchCurrentPosition() {
    return dispatch => {

        function currentPositionReceived(position) {
            dispatch({
                type: 'RECEIVE_POSITION',
                currentPosition: [position.coords.longitude, position.coords.latitude],
            })
        }
        function currentPositionError(err) {
            dispatch({
                type: 'GEOLOCATION_UNAVAILABLE',
            })
        }

        navigator.geolocation.getCurrentPosition(currentPositionReceived, currentPositionError)
    }
}
