import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import MenuItem from 'material-ui/MenuItem'
import RaisedButton from 'material-ui/RaisedButton'
import SelectField from 'material-ui/SelectField'

import AddIcon from 'material-ui/svg-icons/content/add'
import SearchIcon from 'material-ui/svg-icons/action/search'

import RxMapEdit from './rxMapEdit'
import RxMapSearch from './rxMapSearch'

import * as rxMapActions from './rxMapActions'

const styles = {
    newRxButtonSize: {
        minWidth: '11vw',
        maxWidth: '25vw',
    },
}

function mapStateToProps(state, ownProps) {
    return {
        rxFiles: state.product.rxFiles,
        currentProducts: state.product.currentProducts,
        rxMapEditDialog: state.product.rxMapEditDialog,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(rxMapActions, dispatch),
    }
}

const HIGH_SHAPE_LIMIT = 50000
class OneProductRx extends React.Component {
    static defaultProps = {
        data: {},
        rateUnits: [],
        setProductProperty: () => { console.log('OneProductRx::setProductProperty Default') },
        disabled: false,
    }

    state = {
        rxMap: null,
        rxSearchOpen: false,
        rxUploadOpen: false,
        warningDialogOpen: false,
    }


    handleRxUploadRequest = () => {
        this.setState({rxUploadOpen: true})
        this.props.setRxMapEditDialog({open: true, productId: this.props.data.id})
    }
    handleRxSearchRequest = () => {
        this.setState({rxSearchOpen: true})
    }
    handleWarning = (open = false, rxMap = null) => {
        this.setState({
            warningDialogOpen: open,
            rxMap,
        })
    }
    closeDialogs = () => {
        this.setState({
            rxSearchOpen: false,
            rxUploadOpen: false,
            warningDialogOpen: false,
        })
    }
    switchToNewRx = () => {
        this.closeDialogs()
        this.handleRxUploadRequest()
    }
    renderHighShapeWarning = () => {
        if (!this.state.warningDialogOpen) return

        let rxMapName = ''
        if (this.state.rxMap && this.state.rxMap.clientFileName) { rxMapName = this.state.rxMap.clientFileName }

        return (<Dialog
            actions = {[
                <FlatButton
                    label="Ok"
                    primary={true}
                    onClick={this.closeDialogs}
                    name="okPolygonWarning"
                />,
            ]}
            modal={true}
            open={true}
            autoScrollBodyContent={true}
            name="shapeWarningDialog"
        >
            <h3> High Shape Count Detected</h3>
            <p> RxMap {rxMapName} contains a high number of shapes. Rendering it within the map has been disabled. Field Computer performance may not be ideal. </p>
        </Dialog>)
    }
    handleRxSelect = (event, index, value) => {
        if (value != 0) {
            const rxMap = this.props.rxFiles.find(file => file.id === value)
            this.onRxSelect(rxMap)
        }
    }
    onRxSelect = (rxMap) => {
        this.setState({rxMap})
        this.closeDialogs()
        if (rxMap.shapes && rxMap.shapes.features && rxMap.shapes.features.length > HIGH_SHAPE_LIMIT) {
            this.setState({
                warningDialogOpen: true,
                highShapeCount: true,
            })
        }
        else {
            this.setState({highShapeCount: false})
        }
        this.props.setProductProperty(this.props.data.id, 'rxMapId', rxMap.id)
        this.props.setProductProperty(this.props.data.id, 'rxMapName', rxMap.clientFileName)
        if (rxMap.columns.length === 1) {
            this.props.setProductProperty(this.props.data.id, 'rxMapColumnId', rxMap.columns[0])
        }
        else {
            this.props.setProductProperty(this.props.data.id, 'rxMapColumnId', '')
        }
    }
    handleColumn = (event, index, value) => {
        this.props.setProductProperty(this.props.data.id, 'rxMapColumnId', value)
    }
    handleMapUnit = (event, index, value) => {
        this.props.setProductProperty(this.props.data.id, 'rxMapUnit', value)
    }

    createRxMapDropDownList = () => {
        const addButton = (
            <MenuItem value={0} key={'none'} label={'Select an RxMap'} name="newRx">
                <div className="dropdownButtonRow" style={{ display: 'inline' }}>
                    <RaisedButton style={styles.newRxButtonSize} primary={true} className="dropdownButton" icon={<AddIcon />} onClick={this.handleRxUploadRequest} name="addRxButton"/>
                    <RaisedButton style={styles.newRxButtonSize} primary={true} className="dropdownButton" icon={<SearchIcon />} onClick={this.handleRxSearchRequest} name="searchRxButton"/>
                </div>
            </MenuItem>
        )

        return [
            addButton,
            ...this.props.rxFiles.map((data) => {
                return (<MenuItem value={data.id} key={data.id} primaryText={data.clientFileName} name="data.id"/>)
            }),
        ]
    }
    createRxMapColumnDropDownList = () => {
        const selectedMap = this.props.rxFiles.find(map => map.id === this.props.data.rxMapId) || {}
        const columns = [<MenuItem value={''} key={'none'} primaryText={'Select a Rate'} name="noColumn"/>]

        // If we don't have meta information, request a reparse
        if (selectedMap.id &&
            (!selectedMap.columns.length ||
             !selectedMap.shapes ||
             !selectedMap.shapes.features ||
             !selectedMap.shapes.features.length ||
             !selectedMap.boundingBox.length
            )
        ) {
            this.props.fetchRxMapMeta(selectedMap.id)
        }
        if (selectedMap.columns) {
            columns.push(
                ...selectedMap.columns.map((data, i) => {
                    return <MenuItem value={data} key={i} primaryText={data} name={data}/>
                })
            )
        }

        return columns
    }
    renderRxSearch = () => {
        if (!this.state.rxSearchOpen) return

        return (
            <RxMapSearch
                onCancel={this.closeDialogs}
                onNewRxRequest={this.switchToNewRx}
                onRxSelect={this.onRxSelect}
                initRxMapId={this.state.rxMap ? this.state.rxMap.id : null}
            />
        )
    }
    renderRxUpload = () => {
        if (!this.state.rxUploadOpen) return

        return (
            <RxMapEdit
                onCancel={this.closeDialogs}
                onRxUpload={this.onRxSelect}
                open={this.props.rxMapEditDialog.open}
            />
        )
    }
    render() {

        return (
            <div>
                <SelectField
                    className="selectField"
                    value={this.props.data.rxMapId}
                    onChange={this.handleRxSelect}
                    floatingLabelText="Rx Map"
                    errorText={(!this.props.data.rxMapId && 'Required Field') || (this.state.highShapeCount && 'High Shape Count')}
                    errorStyle={this.state.highShapeCount ? {color: 'orange'} : {}}
                    disabled={this.props.disabled}
                    name="rxMapSelect"
                >
                    {this.createRxMapDropDownList()}
                </SelectField>
                <SelectField
                    className="selectField"
                    floatingLabelText="Map Column"
                    floatingLabelFixed={true}
                    value={this.props.data.rxMapColumnId}
                    onChange={this.handleColumn}
                    errorText={!this.props.data.rxMapColumnId && 'Required Field'}
                    disabled={this.props.disabled}
                    name="rxMapColumn"
                >
                    {this.createRxMapColumnDropDownList()}
                </SelectField>
                <SelectField
                    className="selectField"
                    floatingLabelText="Map Unit"
                    value={this.props.data.rxMapUnit}
                    onChange={this.handleMapUnit}
                    errorText={!this.props.data.rxMapUnit && 'Required Field'}
                    disabled={this.props.disabled}
                    name="rxMapUnit"
                >
                    {this.props.rateUnits}
                </SelectField>
                {this.renderRxSearch()}
                {this.renderRxUpload()}
                {this.renderHighShapeWarning()}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OneProductRx)
