function getHost() {
    const host = window.location.hostname
    if (host === 'localhost') { return 'localhost:8443' }
    else if (host === 'local.ravenslingshot.com') { return 'local.ravenslingshot.com:8443' }

    return `portal${host.substring(host.indexOf('.'))}`
}

function getApps() {
    const host = window.location.hostname
    if (host === 'localhost') { return 'localhost:3000' }
    else if (host === 'local.ravenslingshot.com') { return 'local.ravenslingshot.com:3000' }

    return `apps${host.substring(host.indexOf('.'))}`
}

function getDomain() {
    const host = window.location.hostname
    if (host.endsWith('ravenslingshot.com')) { return 'ravenslingshot.com' }
    else if (host.endsWith('raven.engineering')) { return 'raven.engineering' }

    return host
}

function getPortalUrl() {
    const host = getHost()
    const workordersUrl = encodeURIComponent(window.location.href)
    const portalUrl = `https://${host}/index.php?r=site/login&refer=${workordersUrl}`

    return portalUrl
}

function toPortal() {
    window.location = getPortalUrl()
}

module.exports = {
    getApps: getApps,
    getDomain: getDomain,
    getHost: getHost,
    toPortal: toPortal,
}
