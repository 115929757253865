import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import RaisedButton from 'material-ui/RaisedButton'
import SelectField from 'material-ui/SelectField'
import TextField from 'material-ui/TextField'
import { Tab, Tabs } from 'material-ui/Tabs'

import UndoIcon from 'material-ui/svg-icons/content/undo'

import { areaMenuItems } from '../commonComponents/unitMenuItems'
import GeocodeSearch from './geocoder'
import ScoutFeatureMap from './scoutFeatureMap'

import * as scoutActions from './scoutActions'
import { checkFieldLimits } from '../lib/ddiUnits'

function mapStateToProps(state, ownProps) {
    // Check for multipart boundaries, and boundaries with holes.
    let complexBoundary = false
    if (state.scout.boundaryGeoJson) {
        for (const feature of state.scout.boundaryGeoJson.features) {
            if ((feature.geometry.type == 'MultiPolygon') ||
            (feature.geometry.type == 'Polygon' && feature.geometry.coordinates.length > 1)
            ) {
                complexBoundary = true
                break
            }
        }
    }
    let multipleEntrances = false
    if (state.scout.entrancePoints && state.scout.entrancePoints.length > 1) {
        // check if single coordinate, or array of coordinates
        multipleEntrances = Array.isArray(state.scout.entrancePoints[0])
    }

    return {
        complexBoundary: complexBoundary,
        multipleEntrances: multipleEntrances,

        boundaryPoints: state.scout.boundaryPoints,
        boundaryGeoJson: state.scout.boundaryGeoJson,
        selectedCentroid: state.scout.selectedCentroid,
        entrancePoints: state.scout.entrancePoints,
        fieldDisplayArea: state.scout.fieldDisplayArea,
        fieldAreaDisplayUnit: state.scout.fieldAreaDisplayUnit,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(scoutActions, dispatch),
    }
}


class ScoutFeatureSelection extends React.Component {
    state = {
        mode: 'field_location',
        lastChange: [],
    }

    handleClick = (map, event) => {
        if (this.props.disabled) { return }
        if (this.state.mode === 'field_location') {
            this.setState({ lastChange: [...this.state.lastChange, this.props.selectedCentroid]})
            this.props.selectCentroid([event.lngLat.lng, event.lngLat.lat])
        }
        else if (this.state.mode === 'field_entry') {
            if (this.props.multipleEntrances) return

            // this UI only supports setting a single entrance point
            this.setState({ lastChange: [...this.state.lastChange, this.props.entrancePoints]})
            this.props.setEntrancePoints([event.lngLat.lng, event.lngLat.lat])
        }
        else if (this.state.mode === 'boundary_free') {
            if (this.props.complexBoundary) return

            // this UI only supports setting a single polygon boundary with no holes
            this.setState({ lastChange: [...this.state.lastChange, {points: this.props.boundaryPoints, geoJson: this.props.boundaryGeoJson}]})
            this.props.setBoundaryPoints([...this.props.boundaryPoints, [event.lngLat.lng, event.lngLat.lat]])
            this.props.setBoundaryGeoJson([...this.props.boundaryPoints, [event.lngLat.lng, event.lngLat.lat]])
        }
    }
    handleModeChange = (value) => {
        this.setState({
            mode: value,
            lastChange: [],
        })
    }
    deleteFeature = () => {
        if (this.state.mode === 'field_location') {
            this.setState({ lastChange: [...this.state.lastChange, this.props.selectedCentroid] })
            this.props.selectCentroid([])
        }
        else if (this.state.mode === 'field_entry') {
            this.setState({ lastChange: [...this.state.lastChange, this.props.entrancePoints] })
            this.props.setEntrancePoints([])
        }
        else if (this.state.mode === 'boundary_free') {
            this.setState({ lastChange: [...this.state.lastChange, {points: this.props.boundaryPoints, geoJson: this.props.boundaryGeoJson}] })
            this.props.setBoundaryPoints([])
            this.props.setBoundaryGeoJson(null)
        }
    }
    startBoundary = () => {
        this.setState({ mode: 'boundary_free' })
    }
    undo = () => {
        if (this.state.mode === 'field_location' && this.state.lastChange.length != 0) {
            this.props.selectCentroid([...this.state.lastChange[this.state.lastChange.length - 1]])
            this.setState({lastChange: this.state.lastChange.slice(0, -1)})
        }
        else if (this.state.mode === 'field_entry' && this.state.lastChange.length != 0) {
            this.props.setEntrancePoints([...this.state.lastChange[this.state.lastChange.length - 1]])
            this.setState({lastChange: this.state.lastChange.slice(0, -1)})
        }
        else if (this.state.mode === 'boundary_free' && this.state.lastChange.length != 0) {
            this.props.setBoundaryPoints([...this.state.lastChange[this.state.lastChange.length - 1].points])
            this.props.setBoundaryGeoJson(this.state.lastChange[this.state.lastChange.length - 1].geoJson)

            this.setState({lastChange: this.state.lastChange.slice(0, -1)})
        }
    }

    renderTabs = () => {
        if (this.props.disabled) return

        return (
            <Tabs
                style={{paddingTop: 15 }}
                name="scoutTabs"
                onChange={this.handleModeChange}
            >
                <Tab
                    icon={<img className="standardIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_Location.svg`}/>}
                    label="Location"
                    value={'field_location'}
                    name="fieldLocationTab"
                    buttonStyle={this.state.mode === 'field_location' ? {} : {backgroundColor: 'var(--secondary)'}}
                >
                    <div className="mobileButtonGroup" name="locationActions">
                        <RaisedButton
                            icon={<img className="standardIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_Delete${this.props.selectedCentroid.length == 0 ? 'Disabled' : 'Black'}.svg`}/>}
                            label="Remove Location"
                            onClick={this.deleteFeature}
                            disabled={this.props.selectedCentroid.length == 0}
                            className="mobileButton"
                            name="removeLocation"
                        />
                        <RaisedButton
                            icon={<UndoIcon />}
                            label="Undo"
                            onClick={this.undo}
                            disabled={this.state.lastChange.length == 0}
                            className="mobileButton"
                            name="locationUndo"
                        />
                    </div>
                    <span>Click the map to add a field location marker</span>
                </Tab>
                <Tab
                    icon={<img className="standardIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_Entry.svg`}/>}
                    label="Entry"
                    style={{borderLeft: 'solid 2px #FFFFFF'}}
                    value={'field_entry'}
                    name="fieldEntranceTab"
                    buttonStyle={this.state.mode === 'field_entry' ? {} : {backgroundColor: 'var(--secondary)'}}
                >
                    <div className="mobileButtonGroup" name="entranceActions">
                        <RaisedButton
                            icon={<img className="standardIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_Delete${this.props.entrancePoints.length == 0 ? 'Disabled' : 'Black'}.svg`}/>}
                            label="Remove Entrance"
                            onClick={this.deleteFeature}
                            disabled={this.props.entrancePoints.length == 0 }
                            className="mobileButton"
                            name="removeEntrance"
                        />
                        <RaisedButton
                            icon={<UndoIcon />}
                            label="Undo"
                            onClick={this.undo}
                            disabled={this.state.lastChange.length == 0}
                            className="mobileButton"
                            name="entranceUndo"
                        />
                    </div>
                    {this.renderEntranceMessage()}

                </Tab>
                <Tab
                    icon={<img className="standardIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_Boundary.svg`}/>}
                    label="Boundary"
                    style={{borderLeft: 'solid 2px #FFFFFF'}}
                    value={'boundary_free'}
                    name="fieldBoundaryTab"
                    buttonStyle={this.state.mode === 'boundary_free' ? {} : {backgroundColor: 'var(--secondary)'}}
                >
                    <div className="mobileButtonGroup" name="boundaryActions">
                        <RaisedButton
                            icon={<img className="standardIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_Delete${this.props.boundaryPoints.length == 0 ? 'Disabled' : 'Black'}.svg`}/>}
                            label="Clear Boundary"
                            onClick={this.deleteFeature}
                            disabled={this.props.boundaryPoints.length == 0}
                            className="mobileButton"
                            name="removeBoundary"
                        />
                        <RaisedButton
                            icon={<UndoIcon />}
                            label="Undo"
                            onClick={this.undo}
                            disabled={this.state.lastChange.length == 0}
                            className="mobileButton"
                            name="boundaryUndo"
                        />
                    </div>
                    {this.renderBoundaryMessage()}
                </Tab>
            </Tabs>
        )
    }
    renderEntranceMessage = () => {
        if (this.props.multipleEntrances) {
            return (
                <span>Editing multiple entrances not supported. Please use the system the entrances originated on to modify them.</span>
            )
        }

        return (
            <span>Click the map add a field entrance marker</span>
        )
    }
    renderBoundaryMessage = () => {
        if (this.props.complexBoundary) {
            return (
                <span>Complex boundary editing not supported. Please use the system the boundary originated on to modify this boundary.</span>
            )
        }

        return (
            <span>Click to add points to the map - points will create a freeform boundary</span>
        )
    }


    renderGeoCoder = () => {
        if (this.props.disabled) return

        return (
            <GeocodeSearch/>
        )
    }

    render() {
        return (
            <div name="scoutSection">
                <div style={{display: 'flex'}} className="stepContent-Indented">
                    <TextField
                        floatingLabelText="Field Area"
                        onChange={(event, value) => { this.props.setFieldDisplayArea(value) }}
                        value={this.props.fieldDisplayArea}
                        name="fieldDisplayArea"
                        errorText={checkFieldLimits(this.props.fieldDisplayArea, this.props.fieldAreaDisplayUnit)}
                        disabled={this.props.disabled}
                        style={{marginTop: '0px', marginRight: '10px', flexGrow: 2, flexShrink: 0.5}}
                    />
                    <SelectField
                        className="unitSelectionInline"
                        style={{marginTop: '0px'}}
                        value={this.props.fieldAreaDisplayUnit}
                        onChange={(event, key, value) => { this.props.setFieldAreaDisplayUnit(value) }}
                        errorText={!this.props.fieldAreaDisplayUnit && 'Required Field'}
                        disabled={this.props.disabled}
                        name="fieldAreaDisplayUnit"
                    >
                        {areaMenuItems}
                    </SelectField>
                </div>
                {this.renderTabs()}
                {this.renderGeoCoder()}
                <ScoutFeatureMap
                    handleClick={this.handleClick}
                    interactive={!this.props.disabled}
                    style={{top: '-15px'}}
                />
                <div style={{ display: 'inline-block', height: '30px' }} />
            </div>
        )
    }
}

// be sure to connect the component to the Redux state so it can use it!
export default connect(mapStateToProps, mapDispatchToProps)(ScoutFeatureSelection)
