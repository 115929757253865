import axios from 'axios'
import store from '../store'

const createAxiosAuthInterceptor = () => {
    const axiosInstance = axios.create({
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    })

    axiosInstance.interceptors.request.use(config => {
        if (store) {
            const { auth0 } = store.getState()
            const {authTypeReducer, authTokenReducer} = auth0

            if (authTypeReducer.isAuth0Enabled) {
                const {authTokens} = authTokenReducer
                config.headers.authorization = 'Bearer ' + authTokens.accessToken
            }
        }

        return config
    })

    return axiosInstance
}

export default createAxiosAuthInterceptor()
