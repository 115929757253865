import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import SelectField from 'material-ui/SelectField'
import IconButton from 'material-ui/IconButton'
import RaisedButton from 'material-ui/RaisedButton'
import MenuItem from 'material-ui/MenuItem'

import AddIcon from 'material-ui/svg-icons/content/add-box'
import ClearIcon from 'material-ui/svg-icons/navigation/cancel'

import GrowerEdit from './groweredit'
import FarmEdit from './farmedit'
import FieldEdit from './fieldedit'

import { growerPrototype } from './growerDataPrototype'
import { farmPrototype } from './farmDataPrototype'
import { fieldPrototype } from './fieldDataPrototype'

import * as growerActions from './growerActions'
import * as farmActions from './farmActions'
import * as fieldActions from './fieldActions'
import * as scoutActions from '../scoutManager/scoutActions'

import './gffStyles.css'

function mapStateToProps(state, ownProps) {
    return {
        growers: state.gff.growers,
        farms: state.gff.farms,
        fields: state.gff.fields,

        selectedGrower: state.gff.selectedGrower,
        selectedFarm: state.gff.selectedFarm,
        selectedField: state.gff.selectedField,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(growerActions, dispatch),
        ...bindActionCreators(farmActions, dispatch),
        ...bindActionCreators(fieldActions, dispatch),
        ...bindActionCreators(scoutActions, dispatch),
    }
}

class GrowerFarmField extends React.Component {
    state = {
        addState: '',
        tempGffItem: {},
    }

    componentDidMount() {
        this.props.fetchGrowers()
        this.props.fetchFarms()
        this.props.fetchFields()
    }

    selectGrower = (event, index, value) => {
        if (value !== -1) {
            this.props.selectGrower(
                this.props.growers.find(element => {
                    return element.id === value
                })
            )
            const gFarms = this.props.farms.filter(farm => farm.growerId === value)
            if (gFarms.length === 1) {
                this.selectFarm({}, 1, gFarms[0].id)
            }
            else {
                this.props.selectFarm()
                this.props.selectField()
            }
        }
        else { this.addGrower() }
    }
    clearGrower = () => {
        this.props.selectGrower()
        this.props.selectFarm()
        this.props.selectField()
    }
    createGrowerDropDownList = () => {
        const addButton = (
            <MenuItem value={-1} key={'new'} label={'Select a Grower'} name="addGrower">
                <RaisedButton
                    primary={true}
                    fullWidth={true}
                    icon={<AddIcon />}
                    onClick={this.addGrower}
                    name="newGrowerButton"
                />
            </MenuItem>
        )

        return [addButton, ...this.props.growers.map(this.gffToMenuItem)]
    }
    addGrower = () => {
        this.setState({
            addState: 'grower',
            tempGffItem: new growerPrototype(''),
        })
    }
    saveGrower = newData => {
        this.props.addGrower(newData)
        this.setState({ addState: '', tempGffItem: {} })
        this.props.selectFarm()
        this.props.selectField()
    }

    selectFarm = (event, index, value) => {
        if (value !== -1) {
            this.props.selectFarm(
                this.props.farms.find(element => {
                    return element.id === value
                })
            )
            const gFields = this.props.fields.filter(field => field.farmId === value)
            if (gFields.length === 1) {
                this.selectField({}, 1, gFields[0].id)
            }
            else {
                this.props.selectField()
            }
        }
        else { this.addFarm() }
    }
    clearFarm = () => {
        this.props.selectFarm()
        this.props.selectField()
    }
    createFarmDropDownList = () => {
        const addButton = (
            <MenuItem value={-1} key={'new'} label={'Select a Farm'} name="newFarm">
                <RaisedButton
                    primary={true}
                    fullWidth={true}
                    icon={<AddIcon />}
                    onClick={this.addFarm}
                    name="newFarmButton"
                />
            </MenuItem>
        )
        const farms = this.props.farms.filter(farm => farm.growerId === this.props.selectedGrower.id)

        return [addButton, ...farms.map(this.gffToMenuItem)]
    }
    addFarm = () => {
        this.setState({
            addState: 'farm',
            tempGffItem: new farmPrototype(this.props.selectedGrower.id, ''),
        })
    }
    saveFarm = newData => {
        this.props.addFarm(newData)
        this.setState({ addState: '', tempGffItem: {} })
        this.props.selectField()
    }

    selectField = (event, index, value) => {
        if (value !== -1) {
            const fieldAction = this.props.selectField(
                this.props.fields.find(element => {
                    return element.id === value
                })
            )
            this.props.selectCentroid(fieldAction.field.center)
            this.props.setEntrancePoints(fieldAction.field.entrances)
            this.props.setBoundaryGeoJson(fieldAction.field.boundaries)
            this.props.setBoundaryPoints(fieldAction.field.boundaries)
            this.props.setFieldDisplayArea(fieldAction.field.displayArea)
            this.props.setFieldAreaDisplayUnit(fieldAction.field.areaDisplayUnit)
        }
        else { this.addField() }
    }
    clearField = () => {
        this.props.selectField()
    }

    createFieldDropDownList = () => {
        const addButton = (
            <MenuItem value={-1} key={'new'} label={'Select a Field'} name="addField">
                <RaisedButton
                    primary={true}
                    fullWidth={true}
                    icon={<AddIcon />}
                    onClick={this.addField}
                    name="newFieldButton"
                />
            </MenuItem>
        )
        const fields = this.props.fields.filter(field => field.farmId === this.props.selectedFarm.id)

        return [addButton, ...fields.map(this.gffToMenuItem)]
    }
    addField = () => {
        this.setState({
            addState: 'field',
            tempGffItem: new fieldPrototype(this.props.selectedGrower.id, this.props.selectedFarm.id, ''),
        })
    }
    saveField = newData => {
        this.props.addField(newData)
        this.setState({ addState: '', tempGffItem: {} })
    }

    gffToMenuItem = (data, i) => {
        return <MenuItem key={data.id} value={data.id} primaryText={data.name} name={data.id}/>
    }
    cancelAdd = () => {
        this.setState({ addState: '', tempGffItem: {} })
    }


    renderGrowerSection = () => {
        const availGrowers = this.createGrowerDropDownList()
        let growerClearButton = null
        if (!this.props.disabled) {
            growerClearButton = (
                <IconButton disabled={this.props.selectedGrower.id < 0}
                    className="clearSelection"
                    name="clearGrower"
                    onClick={this.clearGrower}
                >
                    <ClearIcon/>
                </IconButton>
            )
        }

        return (
            <div style={{display: 'flex'}}>
                <SelectField
                    className="gffSelect"
                    value={this.props.selectedGrower.id}
                    onChange={this.selectGrower}
                    disabled={this.props.disabled}
                    name="growerDropDown"
                    floatingLabelText="Grower"
                >
                    {availGrowers}
                </SelectField>
                {growerClearButton}
            </div>
        )
    }
    renderFarmSection = () => {
        const availFarms = this.createFarmDropDownList()
        let farmClearButton = null
        if (!this.props.disabled) {
            farmClearButton = (
                <IconButton disabled={this.props.selectedFarm.id < 0}
                    className="clearSelection"
                    name="clearFarm"
                    onClick={this.clearFarm}
                >
                    <ClearIcon/>
                </IconButton>
            )
        }

        return (
            <div style={{display: 'flex'}}>
                <SelectField
                    className="gffSelect"
                    value={this.props.selectedFarm.id}
                    onChange={this.selectFarm}
                    disabled={this.props.selectedGrower.id === -1 || this.props.disabled}
                    name="farmDropdown"
                    floatingLabelText="Farm"
                >
                    {availFarms}
                </SelectField>
                {farmClearButton}
            </div>
        )
    }
    renderFieldSection = () => {
        const availFields = this.createFieldDropDownList()
        let fieldClearButton = null
        if (!this.props.disabled) {
            fieldClearButton = (
                <IconButton disabled={this.props.selectedField.id < 0}
                    className="clearSelection"
                    name="clearField"
                    onClick={this.clearField}
                >
                    <ClearIcon/>
                </IconButton>
            )
        }

        return (
            <div style={{display: 'flex'}}>
                <SelectField
                    className="gffSelect"
                    value={this.props.selectedField.id}
                    onChange={this.selectField}
                    disabled={this.props.selectedFarm.id === -1 || this.props.disabled}
                    name="fieldDropDown"
                    floatingLabelText="Field"
                >
                    {availFields}
                </SelectField>
                {fieldClearButton}
            </div>
        )
    }
    getAddDialog = () => {
        if (this.state.addState === 'grower') {
            return (
                <GrowerEdit
                    data={this.state.tempGffItem}
                    onCancel={this.cancelAdd}
                    onSubmit={this.saveGrower}
                    open={true}
                />
            )
        }
        else if (this.state.addState === 'farm') {
            return (
                <FarmEdit
                    data={this.state.tempGffItem}
                    onCancel={this.cancelAdd}
                    onSubmit={this.saveFarm}
                    open={true}
                />
            )
        }
        else if (this.state.addState === 'field') {
            return (
                <FieldEdit
                    data={this.state.tempGffItem}
                    onCancel={this.cancelAdd}
                    onSubmit={this.saveField}
                    open={true}
                />
            )
        }
    }
    render() {
        const growerSection = this.renderGrowerSection()
        const farmSection = this.renderFarmSection()
        const fieldSection = this.renderFieldSection()
        const addDialog = this.getAddDialog()

        return (
            <div className="sectionContainer">
                <div>If left empty none will be assumed.</div>
                {growerSection}
                {farmSection}
                {fieldSection}
                {addDialog}
            </div>
        )
    }
}

// be sure to connect the component to the Redux state so it can use it!
export default connect(mapStateToProps, mapDispatchToProps)(GrowerFarmField)
