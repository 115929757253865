import axios from '../lib/axios'
import {errorCheck, errorCatch} from '../lib/actionErrorHandler'
import {convertToBase, convertToDisplay} from '../lib/ddiUnits'

export function selectField(field = {id: -1}) {

    return {
        type: 'SELECT_FIELD',
        field,
    }
}

export function addField(field) {
    return dispatch => {
        field.area = convertToBase(field.displayArea, field.areaDisplayUnit)

        return axios.post('/jobs/api/fields/', field)
            .then(response => response.data)
            .then(savedField => dispatch(selectField(savedField)))
            .then(() => dispatch(fetchFields()))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
            })
    }
}

export function updateField(field) {
    return dispatch => {
        field.area = convertToBase(field.displayArea, field.areaDisplayUnit)

        return axios.put(`/jobs/api/fields/${field.id}`, field)
            .then(response => response.data)
            .then(() => dispatch(fetchFields()))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
            })
    }
}

export function deleteField(fieldId) {
    return dispatch => {

        return axios.delete(`/jobs/api/fields/${fieldId}`)
            .then(() => dispatch(fetchFields()))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
            })
    }
}

export function fetchFields() {
    return dispatch => {
        dispatch(requestFields())

        return axios.get('/jobs/api/fields')
            .then(response => response.data)
            .then(json => dispatch(receiveFields(json)))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(receiveFields([]))
            })
    }
}

function requestFields() {
    return {
        type: 'REQUEST_FIELDS',
    }
}

function receiveFields(fields) {
    if (!Array.isArray(fields)) { fields = [] }
    fields.forEach((field) => {
        field.displayArea = convertToDisplay(field.area, field.areaDisplayUnit).toFixed(2)
    })

    return {
        type: 'RECEIVE_FIELDS',
        fields: fields,
    }
}
