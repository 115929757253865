import React from 'react'
import {TableRowColumn} from 'material-ui'

// Use this class to prevent default actions on a specific table column
// Ex. you want to put a button or dropdown in a column, and don't want
// clicking that component to also call row selection.
class TableRowColumnWrapper extends React.Component {

    render() {
        return (
            <TableRowColumn className={this.props.className} style={this.props.style} onClick={this.props.onCellClick}>
                {this.props.children}
            </TableRowColumn>
        )
    }
}

export default TableRowColumnWrapper
