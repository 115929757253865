import React from 'react'
import TextField from 'material-ui/TextField'
import './gffStyles.css'

class GrowerFarmField extends React.Component {
    render() {
        return (
            <div className="sectionContainer">
                <TextField
                    className="gffSelect"
                    value={this.props.grower ? this.props.grower.name : 'No Grower'}
                    disabled={this.props.disabled}
                    name="fieldDropDown"
                    floatingLabelText="Grower"
                />
                <TextField
                    className="gffSelect"
                    value={this.props.farm ? this.props.farm.name : 'No Farm'}
                    disabled={this.props.disabled}
                    name="fieldDropDown"
                    floatingLabelText="Farm"
                />
                <TextField
                    className="gffSelect"
                    value={this.props.field ? this.props.field.name : 'No Field'}
                    disabled={this.props.disabled}
                    name="fieldDropDown"
                    floatingLabelText="Field"
                />
            </div>
        )
    }
}

export default (GrowerFarmField)
