import React from 'react'
import LinearProgress from 'material-ui/LinearProgress'

const ProductLoader = () => (
    <div style={{paddingTop: '40px'}}>
        <LinearProgress />
    </div>
)

export default ProductLoader
