// a reducer takes in two things:
// 1. the action (info about what happened)
// 2. a copy of the current state
import { combineReducers } from 'redux'
import update from 'immutability-helper'
import { v4 as uuidv4 } from 'uuid'
import { createTransform, persistReducer } from 'redux-persist'
import sessionStorage from 'redux-persist/lib/storage/session'

// Redux-persist stringify's items prior to storing them.  Dates need to be transformed back into appropriate types
const dateTransform = createTransform(
    null,
    (state) => {
        return {
            ...state,
            endDate: state.endDate ? new Date(state.endDate) : null,
            startDate: state.startDate ? new Date(state.startDate) : null,
        }
    },
    {}
)

// Explicitly persisting only the jobListFilter's by whitelisting them
const persistConfig = {
    key: 'jobListFilter',
    storage: sessionStorage,
    whitelist: ['jobListFilter'],
    transforms: [dateTransform],
}

function cloudJob(state = [], action) {
    switch (action.type) {
        case 'SET_CLOUD_JOB':
            return update(state, {
                $set: action.cloudJob,
            })
        default:
            return state
    }
}

function workOrderName(state = [], action) {
    switch (action.type) {
        case 'SET_WORK_ORDER_NAME':
            return update(state, {
                $set: action.workOrderName,
            })
        default:
            return state
    }
}

function originationId(state = [], action) {
    switch (action.type) {
        case 'SET_ORIGINATION_ID':
            return update(state, {
                $set: action.originationId,
            })
        default:
            return state
    }
}

function isFetching(state = true, action) {
    switch (action.type) {
        case 'SELECT_COMPANY':
            return true
        case 'RECEIVE_WORK_ORDERS':
            return false
        case 'SET_JOB_LIST_FILTER':
            return true
        default:
            return state
    }
}

function workOrders(state = [], action) {
    let index = -1
    switch (action.type) {
        case 'DISPATCH_TO_IN_PROGRESS':
            return update(state, {
                $set: action.workOrder,
            })
        case 'RECEIVE_WORK_ORDERS':
            return update(state, {
                $set: action.workOrders,
            })
        case 'UPDATE_WORK_ORDER':
            index = state.findIndex(wo => { return wo.id === action.workOrder.id || wo.guid === action.workOrder.guid })
            if (index === -1) {
                return update(state, {
                    $push: [action.workOrder],
                })
            }
            // remove the old element and insert the updated one

            return update(state, {
                $splice: [[index, 1,
                    update(state[index], {$merge: action.workOrder}),
                ]],
            })
        case 'DELETE_WORK_ORDER':
            index = state.findIndex(wo => { return wo.id === action.workOrder.id || wo.guid === action.workOrder.guid })
            if (index === -1) {
                return state
            }

            return update(state, {
                $splice: [[index, 1]],
            })
        default:
            return state
    }
}

function workOrderUuid(state = [], action) {
    switch (action.type) {
        case 'SET_WORK_ORDER_UUID':
            return update(state, {
                $set: action.workOrderUuid,
            })
        case 'GENERATE_WORK_ORDER_UUID':
            return update(state, {
                $set: uuidv4(),
            })
        default:
            return state
    }
}

function filteredJobs(state = [], action) {
    switch (action.type) {
        case 'SET_FILTERED_JOBS':
            return update(state, {
                $set: action.filteredJobs,
            })
        case 'SELECT_COMPANY':
            return []
        case 'SET_JOB_LIST_FILTER':
            return []
        default:
            return state
    }
}

function jobListFilter(state = {}, action) {
    const jobListFilter = action.filter
    switch (action.type) {
        case 'SET_JOB_LIST_FILTER':
            if (jobListFilter.filteredJobs) delete jobListFilter.filteredJobs
            if (jobListFilter.sortedJobs) delete jobListFilter.sortedJobs

            return update(state, {
                $set: jobListFilter,
            })
        default:
            return state
    }
}

export const defaultWorkOrder = {
    cloudJob: true,
    isFetching: true,
    filteredJobs: [],
    workOrderName: '',
    originationId: 'PORTAL',
    workOrders: [],
    workOrderUuid: '',
    jobListFilter: {
        endDate: null,
        startDate: null,
        selectedDateConstant: 0,
        selectedAvailability: 0,
        selectedParticipantActivity: 'all',
        selectedGrowers: [],
        selectedFarms: [],
        selectedFields: [],
        noGFFonly: false,
        selectedMixes: [],
        selectedSystems: [],
        selectedCompanyId: 0,
        sortOrder: 0,
        error: {value: ''},
    },
}
const workOrderReducer = combineReducers({
    cloudJob,
    isFetching,
    filteredJobs,
    workOrderName,
    originationId,
    workOrders,
    workOrderUuid,
    jobListFilter,
})
const persistedReducer = persistReducer(persistConfig, workOrderReducer)

export default persistedReducer
