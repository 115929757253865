import * as hardwareActions from '../../hardwareManager/hardwareActions'
import * as workOrderActions from '../workOrderActions'

import DispatchBar from './dispatchBar'
import NewIcon from 'material-ui/svg-icons/action/note-add'
import React from 'react'
import { TrackJS } from 'trackjs'
import { bindActionCreators } from 'redux'
import {buttonPrototype} from '../../commonComponents/buttonBar'
import { connect } from 'react-redux'
import errorConstants from '../../lib/errorConstants'
import workOrderValidator from '../workOrderValidator'

function mapStateToProps(state, ownProps) {
    return {
        currentProducts: state.product.currentProducts,
        stateStore: state,
        workOrderName: state.workOrder.workOrderName,
        workOrderUuid: state.workOrder.workOrderUuid,
        fieldDisplayArea: state.scout.fieldDisplayArea,
        fieldAreaDisplayUnit: state.scout.fieldAreaDisplayUnit,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(workOrderActions, dispatch),
        ...bindActionCreators(hardwareActions, dispatch),
    }
}
class JobProcessing extends React.Component {

    static defaultProps = {
        onReset: () => {},
        onTemplate: () => {},
        saved: false,
        disabled: false, // for the entire bar, overrides other disabled states
    }
    constructor(props) {
        super(props)
        this.state = {
            message: '',
        }
    }
    componentDidMount() {
        this.props.fetchDispatchSystems()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.workOrderUuid !== this.props.workOrderUuid) {
            this.setState({
                message: '',
            })
        }
    }

    saveWorkOrder = async() => {
        const workOrder = {
            products: this.props.currentProducts,
            workOrderName: this.props.workOrderName,
            fieldDisplayArea: this.props.fieldDisplayArea,
            fieldAreaDisplayUnit: this.props.fieldAreaDisplayUnit,
        }
        const [isValid, validationMessage] = workOrderValidator.validateWorkOrder(workOrder)
        this.setState({message: validationMessage})

        if (!isValid) {

            return false
        }

        const rVal = await this.props.saveWorkOrder(this.props.stateStore)
        if (rVal.error) {
            TrackJS.console.debug(this.props.stateStore)

            let message = ''
            switch (rVal.errorCode) {
                case 401:
                    message = errorConstants.accessUnauthorizedErrorMessage
                    break

                case 403:
                    message = errorConstants.accessForbiddenErrorMessage
                    break

                case 409:
                    message = errorConstants.duplicateEntryErrorMessage
                    break

                case 500:
                    message = errorConstants.internalServerErrorMessage
                    break

                default:
                    message = errorConstants.genericErrorMessage
            }
            TrackJS.track(`Failed to save job: ${message}`)
            this.setState({message: `Save failed: ${message} ${errorConstants.clickResetMessage}`})
        }
        else {
            this.props.onSaved()
            this.setState({message: 'Job saved successfully'})
        }

        return rVal
    }


    render() {

        return (
            <div name="jobCreateBar">
                <DispatchBar
                    cloudCheckboxDisabled={this.props.saved}
                    archived={false}
                    disabled={this.props.disabled}
                    frontChildren={[
                        buttonPrototype({
                            label: 'Save',
                            onClick: this.saveWorkOrder,
                            iconPath: `jobIcons/Job_SaveWhite`,
                            mobileIconPath: `jobIcons/Job_SaveBlue`,
                            disabledIconPath: `jobIcons/Job_SaveDisabled`,
                            disabled: this.props.saved,
                        }),
                    ]}
                    backChildren={[
                        buttonPrototype({
                            label: 'Reset',
                            icon: <NewIcon />,
                            iconStyle: {fill: 'var(--primary)'},
                            disabledIconStyle: {fill: '#AEAEAE'},
                            onClick: this.props.onReset,
                        }),
                    ]}
                    templateOverrides={{
                        label: 'Create Another',
                        onClick: this.props.onTemplate,
                        disabled: !this.props.saved,
                    }}

                    saved={this.props.saved}
                    validateAndSaveWorkOrder={this.saveWorkOrder}
                />
                <div style={{color: 'red'}}>{this.state.message}</div>
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(JobProcessing)
