import {errorCatch, errorCheck} from '../lib/actionErrorHandler'

import axios from '../lib/axios'

export function fetchHardwareById(id) {
    return async dispatch => {
        dispatch({
            type: 'REQUEST_HARDWARE',
        })

        return axios.get('/jobs/api/hardware/' + id)
            .then(response => response.data)
            .then(json => {
                if (!Array.isArray(json)) { json = [] }
                dispatch({
                    type: 'RECEIVE_HARDWARE',
                    hardware: json,
                })

                return {
                    id: json[0].id,
                    name: json[0].name,
                    latitude: json[0].gpsLatitude,
                    longitude: json[0].gpsLongitude,
                    lastUpdate: new Date().getTime(),
                }
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch({
                    type: 'RECEIVE_HARDWARE',
                    hardware: [],
                })
            })
    }
}
