// a reducer takes in two things:
// 1. the action (info about what happened)
// 2. a copy of the current state
import { combineReducers } from 'redux'
import update from 'immutability-helper'
import { currentPosition, geolocation, defaultLocation } from './locationReducer'

function selectedCentroid(state = [], action) {
    switch (action.type) {
        case 'SELECT_CENTROID':
            return update(state, {
                $set: action.centroid,
            })
        default:
            return state
    }
}

function entrancePoints(state = [], action) {
    switch (action.type) {
        case 'SET_ENTRANCE_POINTS':
            return update(state, {
                $set: action.entrancePoints,
            })
        default:
            return state
    }
}

// Convenience access to individual points of the boundaryGeoJson
// as a single array.
// Use only for things like calculating bounding box & rendering
// boundary corners as icons. May not reflect true shape of boundary
function boundaryPoints(state = [], action) {
    switch (action.type) {
        case 'SET_BOUNDARY_POINTS':
            return update(state, {
                $set: action.boundaryPoints,
            })
        default:
            return state
    }
}
// Use for drawing full shape of boundary & calculations such as area
// boundaryPoints should be based off of the boundaryGeoJson
function boundaryGeoJson(state = [], action) {
    switch (action.type) {
        case 'SET_BOUNDARY_GEOJSON':
            return update(state, {
                $set: action.boundaryGeoJson,
            })
        default:
            return state
    }
}

function fieldDisplayArea(state = [], action) {
    switch (action.type) {
        case 'SET_FIELD_AREA':
            return update(state, {
                $set: action.fieldDisplayArea,
            })
        default:
            return state
    }
}

function fieldAreaDisplayUnit(state = [], action) {
    switch (action.type) {
        case 'SET_FIELD_AREA_DISPLAY_UNIT':
            return update(state, {
                $set: action.fieldAreaDisplayUnit,
            })
        default:
            return state
    }
}

export const defaultScout = {
    boundaryPoints: [],
    boundaryGeoJson: null,
    fieldDisplayArea: 1,
    fieldAreaDisplayUnit: 'ac',
    selectedCentroid: [],
    entrancePoints: [],
    ...defaultLocation,
}
const scoutFeatureReducer = combineReducers({
    boundaryPoints,
    boundaryGeoJson,
    fieldDisplayArea,
    fieldAreaDisplayUnit,
    selectedCentroid,
    entrancePoints,

    currentPosition,
    geolocation,
})

export default scoutFeatureReducer
