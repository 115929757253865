/* eslint-disable */
import React from 'react'
import { connect } from 'react-redux'

import axios from './lib/axios'

function mapStateToProps(state, ownProps) {
    return {
        selectedCompanyId: state.system.selectedCompanyId,
    }
}

class Pendo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isInitialized: false,
        }
    }

    componentDidMount() {
        this.handlePendo();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selectedCompanyId !== this.props.selectedCompanyId) {
            this.handlePendo()
        }
    }

    async handlePendo() {
        try {
            const userPendoInfo = await this.getUserPendoInfo(this.props.selectedCompanyId)
            this.sendToPendo(userPendoInfo)
        }
        catch (error) {
            // Ignore pendo errors
        }
    }

    getUserPendoInfo(selectedCompanyId) {
        return new Promise((resolve, reject) => {
            axios.get(`/jobs/api/user/pendo?companyId=${selectedCompanyId}`)
                .then(response => response.data)
                .then(json => {
                    resolve(json)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    sendToPendo(pendoInfo) {
        const visitor = {
            id: pendoInfo.userId,
            email: pendoInfo.userEmail || '',
            roles: pendoInfo.roles
        }

        const account = {
            id: pendoInfo.companyId || '',
            name: pendoInfo.companyName || '',
            state: pendoInfo.companyStateName || '',
        }

        if (this.state.isInitialized) {
            window.pendo.updateOptions({visitor, account})
        }
        else {
            window.pendo.initialize({visitor, account})
            this.setState({isInitialized: true})
        }
    }

    render() {
        return (<div></div>)
    }
}

export default connect(mapStateToProps)(Pendo)
