import fetch from 'isomorphic-fetch'
import {
    AUTH0_TOKEN_PENDING,
    AUTH0_TOKEN_SUCCESS,
    AUTH0_TOKEN_FAILURE,
    AUTH0_FF_PENDING,
    AUTH0_FF_DONE,
} from './auth0Constants'

export function authTokenPending() {
    return {
        type: AUTH0_TOKEN_PENDING,
    }
}

export function authTokenSuccess(accessToken, idToken, graphAPIAuthToken) {
    return {
        type: AUTH0_TOKEN_SUCCESS,
        payload: {
            accessToken,
            idToken,
            graphAPIAuthToken,
        },
    }
}

export function authTokenFailure() {
    return {
        type: AUTH0_TOKEN_FAILURE,
    }
}

export function fetchAuth0FeatureFlag() {
    return dispatch => {
        dispatch(fetchPending())

        return fetch('/jobs/api/featureflag/auth0', {method: 'GET', credentials: 'same-origin'})
            .then(res => res.json())
            .then(res => {
                const isAuth0Enabled = (!!res && res.access === 'allow_all')
                dispatch(fetchDone(isAuth0Enabled))
            })
            .catch(e => {
                console.log('error fetching auth0 ff ', e)
                dispatch(fetchDone(false))
            })
    }
}

function fetchPending() {
    return {
        type: AUTH0_FF_PENDING,
    }
}

function fetchDone(isAuth0Enabled) {
    return {
        type: AUTH0_FF_DONE,
        payload: isAuth0Enabled,
    }
}

