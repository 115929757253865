import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {format} from 'date-fns'

import AutoComplete from 'material-ui/AutoComplete'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'

import * as rxMapActions from './rxMapActions'
import { selectMixes } from './productReducer'

import './productConfigStyles.css'

function mapStateToProps(state, ownProps) {
    return {
        mixes: selectMixes(state),
        rxFiles: state.product.rxFiles,
        currentProducts: state.product.currentProducts,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(rxMapActions, dispatch),
    }
}

class RxMapSearch extends React.Component {
    static defaultProps = {
        open: true,
        initRxMapId: null,
        onNewRxRequest: () => {},
        onCancel: () => {},
        onRxSelect: (rxMap) => {},
    }

    state = {
        searchString: '',
        rxMap: null,
    }

    componentDidMount() {
        if (this.props.initRxMapId) {
            const rxMap = this.props.rxFiles.find(file => file.id === this.props.initRxMapId)
            if (rxMap) {
                this.checkFileMeta(rxMap)
                this.setState({
                    rxMap,
                    searchString: rxMap.clientFileName,
                })
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        // no map is currently selected, so we have nothing to update
        if (!state.rxMap || !state.rxMap.id) return null

        // check if meta information about the map has been updated
        const nextMap = props.rxFiles.find(file => file.id === state.rxMap.id)
        if (!nextMap) return
        if ((!state.rxMap.columns && nextMap.columns) ||
            (state.rxMap.columns?.length != nextMap.columns?.length) ||
            (!state.rxMap.boundingBox && nextMap.boundingBox) ||
            (state.rxMap.boundingBox?.length != nextMap.boundingBox?.length) ||
            (!state.rxMap.shapes && nextMap.shapes) ||
            (!state.rxMap.shapes.features && nextMap.shapes.features) ||
            (state.rxMap.shapes.features?.length != nextMap.shapes.features?.length)
        ) {
            return {
                ...state,
                rxMap: nextMap,
            }
        }

        return null
    }

    checkFileMeta = (rxMap) => {
        if (rxMap.id &&
            (!rxMap.columns.length ||
             !rxMap.shapes ||
             !rxMap.shapes.features ||
             !rxMap.shapes.features.length ||
             !rxMap.boundingBox.length
            )
        ) {
            this.props.fetchRxMapMeta(rxMap.id)
        }
    }

    renderDialogActions = () => {
        return [
            <FlatButton
                label="Cancel"
                onClick={this.props.onCancel}
                primary={true}
            />,
            <FlatButton
                label="Upload"
                onClick={this.props.onNewRxRequest}
                name="addRxButton"
                primary={true}
            />,
            <RaisedButton
                label="Select"
                onClick={() => { this.props.onRxSelect(this.state.rxMap) }}
                disabled={!this.state.rxMap}
                primary={true}
            />,
        ]
    }
    handleNewSelection = (chosenRequest, index) => {
        if (index >= 0) {
            const rxMap = this.props.rxFiles[index]
            this.checkFileMeta(rxMap)
            this.setState({
                rxMap,
                searchString: rxMap.clientFileName,
            })
        }
        else {
            this.setState({
                rxMap: null,
                searchString: chosenRequest,
            })
        }
    }
    handleUpdateInput = (searchString) => {
        const rxMap = this.props.rxFiles.find(file => file.clientFileName === searchString)
        if (rxMap) { this.checkFileMeta(rxMap) }
        this.setState({
            searchString,
            rxMap,
        })
    }
    renderMapInformation = () => {
        if (!this.state.rxMap) return

        const infoStrings = []
        if (this.state.rxMap.lastModified) {
            // new job, go to products, select rx map, scroll to top, click search, pick a map
            infoStrings.push(`Received: ${format(new Date(this.state.rxMap.lastModified), 'P p')}`)
        }
        if (this.state.rxMap.columns) {
            infoStrings.push(`Columns: ${this.state.rxMap.columns.join(', ')}`)
        }
        if (this.state.rxMap.shapes && this.state.rxMap.shapes.features) {
            infoStrings.push(`Shapes: ${this.state.rxMap.shapes.features.length}`)
        }

        return infoStrings.map((data, i) => {
            return <div key={i}>{data}</div>
        })
    }
    render() {
        return (
            <Dialog
                actions={this.renderDialogActions()}
                title="Rx Map Search"
                open={this.props.open}
                onRequestClose={this.handleSubmit}
                autoScrollBodyContent={true}
                contentClassName="wodialog"
                bodyStyle={{minHeight: 165}}
            >
                <AutoComplete
                    dataSource={this.props.rxFiles}
                    dataSourceConfig={{text: 'clientFileName', value: 'id'}}
                    filter={AutoComplete.caseInsensitiveFilter}
                    onNewRequest={this.handleNewSelection}
                    onUpdateInput={this.handleUpdateInput}
                    searchText={this.state.searchString}
                    openOnFocus={true}
                    maxSearchResults={8}
                    floatingLabelText={'Rx Map Name'}
                    errorText={!this.state.rxMap && 'Invalid Map'}
                    fullWidth={true}
                    className="rxFileSelect"
                    name="rxMapSelect"
                />
                <div>{this.renderMapInformation()}</div>
            </Dialog>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(RxMapSearch)
