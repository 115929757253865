module.exports = {
    gffManager: '/gff-manager',
    map: '/map',
    mixManager: '/mix-manager',
    jobDetailsId: '/jobs/details/:id',
    jobDetailsGuid: '/jobs/guid/:guid',
    jobsList: '/jobs/list',
    jobsLive: '/jobs/live/:id',
    jobsQuick: '/jobs/quick',
    jobsCreate: '/jobs/create',
    jobsTemplate: '/jobs/template/:id',
    jobSync: '/jobs/job-sync-debug',
    consolidatedApplicationReport: '/jobs/applicationreport/:guid',
    applicationReport: '/jobs/:guid/applicationreport',
    rxMapViewer: '/jobs/rxmap/:id',
    auth0Callback: '/jobs/callback',
}
