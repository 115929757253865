import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import IconButton from 'material-ui/IconButton'
import RaisedButton from 'material-ui/RaisedButton'
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table'
import AddIcon from 'material-ui/svg-icons/content/add'
import EditIcon from 'material-ui/svg-icons/content/create'

import DeleteButton from '../commonComponents/deleteDialogButton'
import ListPaging from '../commonComponents/listPaging'
import IngredientEdit from './ingredientCreation'
import TableRowColumnWrapper from '../commonComponents/tableRowColumnWrapper'

import * as ingredientActions from './ingredientActions'
import { ingredientPrototype } from './productPrototypes'
import ProductLoader from './productLoader'
import EmptyState from '../commonComponents/emptyState'

function mapStateToProps(state, ownProps) {
    // Users are only allowed to manage/edit mixes that belong to their company
    // not universal mixes like water
    const ingredientProps = state.product.ingredients || []

    return {
        isLoading: ingredientProps.isLoading,
        error: ingredientProps.error,
        ingredients: ingredientProps.data.filter(m => m.ownerPartyId == state.system.selectedCompanyId),
        selectedCompanyId: state.system.selectedCompanyId,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(ingredientActions, dispatch),
    }
}

class IngredientManager extends React.Component {
    state = {
        editIngredientId: null,
        newIngredientOpen: false,
        selectedIngredients: [],
        viewList: [],
        startViewIndex: 0,
        lastViewIndex: 0,
    }

    componentDidMount() {
        this.props.fetchIngredients()
    }
    getDisabled = () => {
        return !this.props.selectedCompanyId
    }
    editIngredientRequest = (id) => {
        this.setState({editIngredientId: id})
    }
    onEditIngredientCancel = () => {
        this.setState({editIngredientId: null, newIngredientOpen: false})
    }
    onEditIngredientSave = (newIngredientData) => {
        this.props.updateIngredient(newIngredientData)
        this.setState({editIngredientId: null, selectedIngredients: []})
    }
    newIngredientRequest = () => {
        this.setState({ newIngredientOpen: true })
    }
    onNewIngredientSave = (newData) => {
        this.props.addIngredient(newData)
        this.setState({ newIngredientOpen: false, selectedIngredients: []})
    }
    onIngredientDelete = () => {
        this.props.deleteIngredients(this.state.selectedIngredients).then(rVal => {
            if (rVal && !rVal.error) {
                this.setState({selectedIngredients: []})
            }
        })
    }
    onViewListChanged = (newList, startIndex, lastIndex) => {
        this.setState({
            viewList: [...newList],
            startViewIndex: startIndex,
            lastViewIndex: lastIndex,
        })
    }

    handleIngredientRowSelect = (selection) => {
        // selection only contains information for the current page
        // we want to maintain any already selected ingredients
        const ingredients = this.props.ingredients.filter((ing, i) => {
            return ((i < this.state.startViewIndex || i > this.state.lastViewIndex) &&
                this.state.selectedIngredients.includes(ing.id)
            )
        }).map(ing => { return ing.id })
        // We don't support select all on this page so selection will
        // contain an array of the selected row indexes
        for (const row of selection) {
            ingredients.push(this.state.viewList[row].id)
        }
        this.setState({selectedIngredients: [...ingredients]})
    }
    mapIngredientsToTableRows = (ingredient, index) => {
        return (
            <TableRow
                key={ingredient.id}
                selected={this.state.selectedIngredients.includes(ingredient.id)}
                name={ingredient.id}
            >
                <TableRowColumn className="pmColumn">{ingredient.name}</TableRowColumn>
                <TableRowColumn className="pmColumn">
                    {ingredient.manufacturer ? ingredient.manufacturer : ''}
                </TableRowColumn>
                <TableRowColumn className="pmColumn">
                    {ingredient.typeId ? ingredient.typeId : ''}
                </TableRowColumn>
                <TableRowColumnWrapper className="pmColumn">
                    <IconButton
                        disabled={this.getDisabled()}
                        onClick={() => { this.editIngredientRequest(ingredient.id) }}
                        name={`edit-${ingredient.id}`}
                    ><EditIcon />
                    </IconButton>
                </TableRowColumnWrapper>
            </TableRow>
        )
    }
    renderIngredientEdit = () => {
        if (!this.state.editIngredientId && !this.state.newIngredientOpen) return
        let ingredient = {}

        if (this.state.editIngredientId) {
            ingredient = this.props.ingredients.find(element => {
                return element.id === this.state.editIngredientId
            })
        }
        else {
            ingredient = new ingredientPrototype('')
        }

        return (
            <IngredientEdit
                open={true}
                data={ingredient}
                onSave={this.state.editIngredientId ? this.onEditIngredientSave : this.onNewIngredientSave}
                onCancel={this.onEditIngredientCancel}
            />
        )
    }
    render() {
        const ingredientRows = this.state.viewList.map(this.mapIngredientsToTableRows)
        const ingredientEdit = this.renderIngredientEdit()

        if (this.props.isLoading && !this.props.error) {
            return (
                <ProductLoader />
            )
        }

        if (!this.props.isLoading && this.props.error) {
            return (
                <div style={{paddingTop: '40px', textAlign: 'center'}} >
                    <h3>{this.props.error}</h3>
                </div>
            )
        }

        if (!this.props.isLoading && !this.props.error && !this.props.ingredients.length) {

            return (
                <EmptyState>
                    <div style={{paddingTop: '40px'}} >
                        <h3>No ingredients found</h3>
                        <div style={{margin: '20px 0'}}>
                            <RaisedButton
                                label="Create Ingredient"
                                onClick={this.newIngredientRequest}
                                icon={<AddIcon/>}
                                primary={true}
                                fullWidth={false}
                            />
                        </div>
                        {ingredientEdit}
                    </div>
                </EmptyState>
            )
        }

        return (
            <div>
                <RaisedButton
                    primary={true}
                    icon={<AddIcon />}
                    label="Add Ingredient"
                    disabled={this.getDisabled()}
                    onClick={this.newIngredientRequest}
                    name="addIngredient"
                    style={{marginBottom: 10, marginRight: 10}}
                />
                <DeleteButton
                    itemType="ingredient"
                    deleteFunction={this.onIngredientDelete}
                    primary={true}
                    disabled={!this.state.selectedIngredients.length || this.getDisabled()}
                    customContent={'Are you sure you wish to delete these ingredients? This will not affect current mixes or generated jobs'}
                    flat={false}
                />
                <Table
                    fixedHeader={false}
                    className="pmTable"
                    style={{ tableLayout: 'auto', whiteSpace: 'normal' }}
                    selectable={!this.getDisabled()}
                    multiSelectable={true}
                    onRowSelection={this.handleIngredientRowSelect}
                    name="pmTable"
                >
                    <TableHeader displaySelectAll={false}>
                        <TableRow>
                            <TableHeaderColumn className="pmColumn">{'Name'}</TableHeaderColumn>
                            <TableHeaderColumn className="pmColumn">{'Manufacturer'}</TableHeaderColumn>
                            <TableHeaderColumn className="pmColumn">{'Type'}</TableHeaderColumn>
                            <TableHeaderColumn className="pmColumn">{''}</TableHeaderColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody deselectOnClickaway={false} stripedRows={true}>
                        {ingredientRows}
                    </TableBody>
                </Table>
                <ListPaging
                    countPerPage={15}
                    disabled={this.getDisabled()}
                    itemList={this.props.ingredients}
                    viewListChanged={this.onViewListChanged}
                />
                {ingredientEdit}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(IngredientManager)
