import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import TextField from 'material-ui/TextField'

import {alphanumCount} from '../lib/stringValidation'

class FarmEdit extends React.Component {
    static propTypes = {
        data: PropTypes.object,
    }

    static defaultProps = {
        data: {
            id: -1,
            growerId: -1,
            name: '',
            address: '',
            address2: '',
            city: '',
            county: '',
            region: '',
            country: '',
            postalCode: '',
            phoneNumber: '',
            mobileNumber: '',
            faxNumber: '',
            email: '',
            firstName: '',
            middleName: '',
            lastName: '',
            comments: '',
        },
        disabled: false,
    }

    state = {
        ...this.props.data,
        errorMessage: '',
    }

    handleChange = (event) => {
        const formData = {...this.state}
        formData[event.target.name] = event.target.value
        this.setState(formData)
    }

    // eslint-disable-next-line
    handleSubmit = () => {
        if (!this.state.name || this.state.name.length > 32 ||
            (this.state.firstName && this.state.firstName.length > 32) ||
            (this.state.middleName && this.state.middleName.length > 32) ||
            (this.state.lastName && this.state.lastName.length > 32) ||
            (this.state.phoneNumber && this.state.phoneNumber.length > 20) ||
            (this.state.mobileNumber && this.state.mobileNumber.length > 20) ||
            (this.state.faxNumber && this.state.faxNumber.length > 20) ||
            (this.state.email && this.state.email.length > 64) ||
            (this.state.address && this.state.address.length > 32) ||
            (this.state.address2 && this.state.address2.length > 32) ||
            (this.state.city && this.state.city.length > 32) ||
            (this.state.county && this.state.county.length > 32) ||
            (this.state.region && this.state.region.length > 32) ||
            alphanumCount(this.state.postalCode) > 10 ||
            (this.state.country && this.state.country.length > 32) ||
            (this.state.comments && this.state.comments.length > 330)
        ) {
            this.setState({errorMessage: 'Invalid Data'})
        }
        else {
            this.props.onSubmit({ ...this.state })
        }
    }
    handleCancel = () => {
        this.props.onCancel()
        this.setState({
            ...this.props.data,
            errorMessage: '',
        })
    }

    renderActions = () => {
        if (this.props.disabled) {
            return [
                <RaisedButton
                    label={'Close'}
                    primary={true}
                    onClick={this.handleSubmit}
                    name="closeButton"
                />,
            ]
        }

        return [
            <div style={{display: 'inline-block', color: 'red'}}>{this.state.errorMessage}</div>,
            <FlatButton label="Cancel"
                primary={true}
                onClick={this.handleCancel}
                name="cancelButton"
            />,
            <RaisedButton
                icon={<img className="standardIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_SaveWhite.svg`}/>}
                label={'Save'}
                primary={true}
                onClick={this.handleSubmit}
                name="saveButton"
            />,
        ]
    }

    renderContactFields = () => {
        return <Fragment>
            <h3>Contact Information</h3>
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="First Name"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.firstName}
                name="firstName"
                errorText={this.state.firstName && this.state.firstName.length > 32 && 'Max Length 32'}
            />
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="Middle Name"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.middleName}
                name="middleName"
                errorText={this.state.middleName && this.state.middleName.length > 32 && 'Max Length 32'}
            />
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="Last Name"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.lastName}
                name="lastName"
                errorText={this.state.lastName && this.state.lastName.length > 32 && 'Max Length 32'}
            />
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="Phone Number"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.phoneNumber}
                name="phoneNumber"
                errorText={this.state.phoneNumber && this.state.phoneNumber.length > 20 && 'Max Length 20'}
            />
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="Mobile Number"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.mobileNumber}
                name="mobileNumber"
                errorText={this.state.mobileNumber && this.state.mobileNumber.length > 20 && 'Max Length 20'}
            />
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="Fax Number"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.faxNumber}
                name="faxNumber"
                errorText={this.state.faxNumber && this.state.faxNumber.length > 20 && 'Max Length 20'}
            />
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="Email"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.email}
                name="email"
                errorText={this.state.email && this.state.email.length > 64 && 'Max Length 64'}
            />
        </Fragment>
    }

    renderAddressFields = () => {
        return <Fragment>
            <h3>Address</h3>
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="Address 1"
                floatingLabelFixed={true}
                hintText="Ex: 1234 Apple Lane"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.address}
                name="address"
                errorText={this.state.address && this.state.address.length > 32 && 'Max Length 32'}
            />
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="Address 2"
                floatingLabelFixed={true}
                hintText="Ex: Suite 72"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.address2}
                name="address2"
                errorText={this.state.address2 && this.state.address2.length > 32 && 'Max Length 32'}
            />
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="City"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.city}
                name="city"
                errorText={this.state.city && this.state.city.length > 32 && 'Max Length 32'}
            />
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="County"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.county}
                name="county"
                errorText={this.state.county && this.state.county.length > 32 && 'Max Length 32'}
            />
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="State"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.region}
                name="region"
                errorText={this.state.region && this.state.region.length > 32 && 'Max Length 32'}
            />
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="Zip Code"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.postalCode}
                name="postalCode"
                errorText={alphanumCount(this.state.postalCode) > 10 && 'Max 10 alphanum characters'}
            />
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="Country"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.country}
                name="country"
                errorText={this.state.country && this.state.country.length > 32 && 'Max Length 32'}
            />
        </Fragment>
    }

    render() {
        const editActions = this.renderActions()

        return (
            <Dialog
                actions={editActions}
                title="Farm Information"
                modal={true}
                open={this.props.open}
                onRequestClose={this.handleSubmit}
                autoScrollBodyContent={true}
                contentClassName="wodialog"
            >
                <TextField
                    disabled={this.props.disabled}
                    floatingLabelText="Farm Name"
                    fullWidth={true}
                    onChange={this.handleChange}
                    value={this.state.name}
                    name="name"
                    errorText={(!this.state.name && 'Required Field') || (this.state.name.length > 32 && 'Max Length 32')}
                />
                <br />
                {this.renderContactFields()}
                <br />
                {this.renderAddressFields()}
                <h3>Comments</h3>
                <TextField
                    disabled={this.props.disabled}
                    fullWidth={true}
                    onChange={this.handleChange}
                    value={this.state.comments}
                    multiLine={true}
                    name="comments"
                    errorText={this.state.comments && this.state.comments.length > 330 && 'Max Length 330'}
                />
            </Dialog>
        )
    }
}

// be sure to connect the component to the Redux state so it can use it!
export default FarmEdit
