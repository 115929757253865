import React, { Component } from 'react'
import ManagerTabs from './managerTabs'

const styles = {
    container: {
        paddingTop: 5,
        paddingLeft: 15,
        paddingRight: 15,
        height: '100vh',
    },
    background: {
        backgroundColor: 'white',
    },
}

class AppMixManager extends Component {

    render() {
        return (
            <div className="workorder-wrapper" style={styles.background}>
                <div style={styles.container}>
                    <ManagerTabs />
                </div>
            </div>
        )
    }
}

export default AppMixManager
