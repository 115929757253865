import React, {useEffect} from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import {jobsList} from '../frontEndRouteTemplates'

const Auth0Callback = () => {
    const { isAuthenticated } = useAuth0()
    const navigate = useNavigate()

    useEffect(() => {
        if (isAuthenticated) {
            const pathName = localStorage.getItem('callback_path') || jobsList
            navigate(pathName)
        }
    }, [isAuthenticated])

    return <div>Authentication successful, redirecting now...</div>
}

export default Auth0Callback
