import * as hardwareActions from '../../hardwareManager/hardwareActions'
import * as systemActions from '../../system/systemActions'
import * as workOrderActions from '../workOrderActions'

import ButtonBar, {buttonPrototype} from '../../commonComponents/buttonBar'

import Checkbox from 'material-ui/Checkbox'
import DispatchDialog from './dispatchDialog'
import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

function mapStateToProps(state, ownProps) {
    return {
        cloudJob: state.workOrder.cloudJob,
        workOrderUuid: state.workOrder.workOrderUuid,
    }
}


function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(workOrderActions, dispatch),
        ...bindActionCreators(hardwareActions, dispatch),
    }
}

class DispatchBar extends React.Component {
    static defaultProps = {
        archived: true,
        downloadOverrides: {},
        dispatchOverrides: {},
        templateOverrides: {},
        frontChildren: [],
        backChildren: [],
        cloudCheckboxDisabled: true,
        saved: false,
        disabled: false, // for the entire bar, overrides other disabled states
        validateAndSaveWorkOrder: async() => { return false },
    }
    constructor(props) {
        super(props)
        this.state = {
            dispatchOpen: false,
            message: '',
            downloadAccess: systemActions.userHasRavenServiceAccess(),
        }
    }
    componentDidMount() {
        this.props.fetchDispatchSystems()
        this.setState({
            dispatchOpen: false,
            message: '',
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.workOrderUuid !== this.props.workOrderUuid) {
            this.setState({
                message: '',
            })
        }
    }

    handleCloudJob = (event, checked) => {
        this.props.setCloudJob(checked)
    }

    dispatchRequested = async() => {
        if (!this.props.saved) {
            if (!(await this.props.validateAndSaveWorkOrder())) { return }
        }
        if (!this.props.archived) {
            this.setState({dispatchOpen: true})
        }
        else {
            this.setState({message: 'Cannot dispatch archived job'})
        }
    }
    renderDispatcher = () => {
        if (this.state.dispatchOpen) {
            return <DispatchDialog open={true} onClose={this.handleClose} onDispatch={this.dispatchWorkOrder}/>
        }
    }
    handleClose = () => {
        this.setState({dispatchOpen: false})
    }
    dispatchWorkOrder = () => {
        this.setState({dispatchOpen: false, message: 'Dispatch successful'})
    }

    downloadRequested = async() => {
        if (!this.props.saved) {
            if (!(await this.props.validateAndSaveWorkOrder())) { return }
        }
        this.props.downloadWorkPackage(this.props.workOrderUuid)
    }

    render() {
        return (
            <div name="dispatchBar" style={{paddingTop: 30}}>
                <Checkbox
                    label="Available as Cloud Job"
                    checked={this.props.cloudJob}
                    onCheck={this.handleCloudJob}
                    disabled={this.props.cloudCheckboxDisabled || this.props.disabled}
                    name="cloudJobAvailability"
                    iconStyle={{fill: 'var(--secondary)'}}
                />
                <ButtonBar disabled={this.props.disabled} children = {[
                    ...this.props.frontChildren,
                    buttonPrototype(Object.assign({
                        label: 'Dispatch',
                        iconPath: `jobIcons/Job_DispatchWhite`,
                        mobileIconPath: `jobCard/JobCard_BtnDispatch`,
                        disabledIconPath: `jobIcons/Job_DispatchDisabled`,
                        onClick: this.dispatchRequested,
                        visible: !this.props.archived,
                        disabled: !this.props.workOrderUuid,
                    }, this.props.dispatchOverrides)),
                    buttonPrototype(Object.assign({
                        label: 'Template',
                        iconPath: `jobIcons/Job_CreateAnotherWhite`,
                        mobileIconPath: `jobIcons/Job_CreateAnotherBlue`,
                        disabledIconPath: `jobIcons/Job_CreateAnotherDisabled`,
                        disabled: !this.props.workOrderUuid,
                    }, this.props.templateOverrides)),
                    buttonPrototype({
                        disabled: !this.props.jobReportAvailable,
                        label: 'Job Report',
                        iconPath: `jobIcons/Job_ViewReport`,
                        mobileIconPath: `jobIcons/Job_ViewReportBlue`,
                        disabledIconPath: `jobIcons/Job_ViewReportDisabled`,
                        href: `/jobs/applicationreport/${this.props.workOrderUuid}`,
                    }),
                    ...this.props.backChildren,

                    // We always put download at the back because it is internal only
                    buttonPrototype(Object.assign({
                        label: 'Download',
                        iconPath: `jobIcons/Job_Download`,
                        mobileIconPath: `jobIcons/Job_DownloadBlue`,
                        disabledIconPath: `jobIcons/Job_DownloadDisabled`,
                        onClick: this.downloadRequested,
                        visible: this.state.downloadAccess,
                        disabled: !this.props.workOrderUuid || !this.state.downloadAccess,
                    }, this.props.downloadOverrides)),
                ]}/>
                <div style={{color: 'red'}}>{this.state.message}</div>
                {this.renderDispatcher()}
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(DispatchBar)
