
import Cookies from 'universal-cookie'
const redirect = require('../lib/redirect')

const verify = store => next => action => {
    const { auth0 } = store.getState()
    const {authTypeReducer} = auth0
    const {isFetching, isAuth0Enabled} = authTypeReducer

    if (!isFetching && !isAuth0Enabled) {
        const cookies = new Cookies()
        if (!cookies.get('jwt')) {
            redirect.toPortal()
        }
    }

    return next(action)
}
export default verify
