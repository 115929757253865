import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { List, ListItem } from 'material-ui/List'
import RaisedButton from 'material-ui/RaisedButton'
import AddIcon from 'material-ui/svg-icons/content/add'

import GrowerCard from './growerCard'
import FarmCard from './farmCard'
import FieldCard from './fieldCard'
import GrowerCardLoader from './growerCardLoader'

import GrowerEdit from './groweredit'
import FarmEdit from './farmedit'
import FieldEdit from './fieldedit'

import ListPaging from '../commonComponents/listPaging'
import EmptyState from '../commonComponents/emptyState'

import { growerPrototype } from './growerDataPrototype'
import { farmPrototype } from './farmDataPrototype'
import { fieldPrototype } from './fieldDataPrototype'

import * as growerActions from './growerActions'
import * as farmActions from './farmActions'
import * as fieldActions from './fieldActions'

function mapStateToProps(state, ownProps) {
    return {
        growers: state.gff.growers,
        farms: state.gff.farms,
        fields: state.gff.fields,
        isFetching: state.gff.isFetching,
        selectedCompanyId: state.system.selectedCompanyId,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(growerActions, dispatch),
        ...bindActionCreators(farmActions, dispatch),
        ...bindActionCreators(fieldActions, dispatch),
    }
}

let gffListItems = []
const growerListItems = []
const farmListItems = []
const fieldListItems = []

// default text when no GFF have been created
growerListItems.push(<ListItem key={-1} primaryText="No Grower" />)
farmListItems.push(<ListItem key={-1} primaryText="No Farm" />)
fieldListItems.push(<ListItem key={-1} primaryText="No Field" />)

class GffViewer extends React.Component {
    componentDidMount() {
        this.props.fetchGrowers()
        this.props.fetchFarms()
        this.props.fetchFields()
    }

    state = {
        addState: '',
        tempGffItem: {},
        viewList: [],
    }
    getDisabled = () => {
        return !this.props.selectedCompanyId
    }

    onViewListChanged = (newList) => {
        this.setState({viewList: [...newList]})
    }
    createGrowerItem = (data, i) => {
        let growerChildren = this.props.farms.filter(farm => farm.growerId === data.id)
        growerChildren = growerChildren.map(this.createFarmItem)

        return (
            <ListItem key={data.id} nestedItems={growerChildren}>
                <GrowerCard
                    data={data}
                    disabled={this.getDisabled()}
                    addFarm={this.addFarm}
                />
            </ListItem>
        )
    }

    createFarmItem = (data, i) => {
        let farmChildren = this.props.fields.filter(field => field.farmId === data.id)
        farmChildren = farmChildren.map(this.createFieldItem)

        return (
            <ListItem key={data.id} nestedItems={farmChildren}>
                <FarmCard
                    data={data}
                    disabled={this.getDisabled()}
                    addField={this.addField}
                />
            </ListItem>
        )
    }

    createFieldItem = (data, i) => {
        return (
            <ListItem key={data.id}>
                <FieldCard
                    data={data}
                    disabled={this.getDisabled()}
                />
            </ListItem>
        )
    }

    addGrower = () => {
        this.setState({
            addState: 'grower',
            tempGffItem: new growerPrototype(''),
        })
    }
    saveGrower = newData => {
        this.props.addGrower(newData)
        this.setState({ addState: '', tempGffItem: {} })
    }

    addFarm = growerId => {
        this.setState({
            addState: 'farm',
            tempGffItem: new farmPrototype(growerId, ''),
        })
    }
    saveFarm = newData => {
        this.props.addFarm(newData)
        this.setState({ addState: '', tempGffItem: {}})
    }

    addField = (growerId, farmId) => {
        this.setState({
            addState: 'field',
            tempGffItem: new fieldPrototype(growerId, farmId, ''),
        })
    }
    saveField = newData => {
        this.props.addField(newData)
        this.setState({ addState: '', tempGffItem: {} })
    }

    cancelAdd = () => {
        this.setState({ addState: '', tempGffItem: {} })
    }
    getAddDialog = () => {
        if (this.state.addState === 'grower') {
            return (
                <GrowerEdit
                    data={this.state.tempGffItem}
                    onCancel={this.cancelAdd}
                    onSubmit={this.saveGrower}
                    open={true}
                />
            )
        }
        else if (this.state.addState === 'farm') {
            return (
                <FarmEdit
                    data={this.state.tempGffItem}
                    onCancel={this.cancelAdd}
                    onSubmit={this.saveFarm}
                    open={true}
                />
            )
        }
        else if (this.state.addState === 'field') {
            return (
                <FieldEdit
                    data={this.state.tempGffItem}
                    onCancel={this.cancelAdd}
                    onSubmit={this.saveField}
                    open={true}
                />
            )
        }

        return ''
    }

    renderEmptyState = () => {
        return (
            <EmptyState>
                <h3>No Growers Found</h3>
                <div style={{ width: '100%', maxWidth: 700, margin: 'auto' }}>
                    <RaisedButton
                        disabled={this.getDisabled()}
                        icon={<AddIcon />}
                        label="Add Grower"
                        labelPosition="after"
                        onClick={this.addGrower}
                        primary={true}
                    />
                    {this.getAddDialog()}
                </div>
            </EmptyState>
        )
    }

    renderLoadingState = () => {
        return (
            <>
                <RaisedButton
                    disabled={true}
                    icon={<AddIcon />}
                    label="Add Grower"
                    labelPosition="after"
                />
                <List name="loadingList">
                    {Array(10).fill().map((item, index) => (<ListItem><GrowerCardLoader key={index}/></ListItem>))}
                </List>
            </>
        )
    }

    renderGffList = (gffListItems) => {
        return (
            <>
                <RaisedButton
                    disabled={this.getDisabled()}
                    icon={<AddIcon />}
                    label="Add Grower"
                    labelPosition="after"
                    onClick={this.addGrower}
                    primary={true}
                />
                <List name="gffList">
                    {gffListItems}
                </List>
                <ListPaging
                    disabled={this.getDisabled()}
                    itemList={this.props.growers || []}
                    viewListChanged={this.onViewListChanged}
                />
                {this.getAddDialog()}
            </>
        )
    }

    render() {
        gffListItems = this.state.viewList.map(this.createGrowerItem)

        return (
            <div style={{ width: '100%', maxWidth: 700, margin: 'auto' }}>
                {!this.props.isFetching && this.props.growers.length === 0 && this.renderEmptyState()}
                {this.props.isFetching && this.renderLoadingState()}
                {!this.props.isFetching && this.props.growers.length > 0 && this.renderGffList(gffListItems)}
            </div>
        )
    }
}

// be sure to connect the component to the Redux state so it can use it!
export default connect(mapStateToProps, mapDispatchToProps)(GffViewer)
