import axios from '../lib/axios'
import {errorCheck, errorCatch} from '../lib/actionErrorHandler'
import {SETPOINT_VOLUME_PER_AREA_DDI, SETPOINT_MASS_PER_AREA_DDI, ACTUAL_MASS_CONTENT_DDI} from '../lib/ddiConstants'
import {convertToBase, convertToDisplay} from '../lib/ddiUnits'

export function fetchMixes() {
    return dispatch => {
        dispatch(requestMixes())

        return axios.get('/jobs/api/mixes')
            .then(response => response.data)
            .then(json => dispatch(receiveMixes(json)))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(errorMixes('Error retrieving mixes'))
            })
    }
}

export function addMix(mix) {
    const mixData = mixExportCalculations({...mix})

    return dispatch => {
        return axios.post('/jobs/api/mixes/', mixData)
            .then(response => response.data)
            .then(json => {
                dispatch(fetchMixes())

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)

                return null
            })
    }
}

function mixExportCalculations(mix) {
    let ingredientSum = 0
    for (const ingredient of mix.mixIngredients) {
        ingredient.quantity = convertToBase(ingredient.displayQuantity, ingredient.userDisplayUnit)
        if (ingredient.ddiId === SETPOINT_VOLUME_PER_AREA_DDI) {
            ingredientSum += ingredient.quantity
        }
    }

    if (mix.carrierId !== null) {
        mix.carrierQuantity = convertToBase(mix.carrierDisplayQuantity, mix.userDisplayUnit)
        if (mix.ddiId === SETPOINT_VOLUME_PER_AREA_DDI) {
            mix.carrierQuantity -= ingredientSum
        }
        else if (mix.ddiId === SETPOINT_MASS_PER_AREA_DDI || mix.ddiId === ACTUAL_MASS_CONTENT_DDI) {
            mix.carrierQuantity = 0
            delete mix.carrierId
        }
    }

    return mix
}

export function updateMix(mix) {
    const mixData = mixExportCalculations({...mix})

    return dispatch => {
        return axios.put(`/jobs/api/mixes/${mix.id}`, mixData)
            .then(response => response.data)
            .then(json => {
                dispatch(fetchMixes())

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)

                return null
            })
    }
}

export function deleteMixes(mixIds) {
    return dispatch => {
        const params = {data: {mixIds}}

        return axios.delete('/jobs/api/mixes/', params)
            .then(response => response.data)
            .then(json => {
                dispatch(fetchMixes())

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)

                return null
            })
    }
}

function requestMixes() {
    return {
        type: 'REQUEST_MIXES',
    }
}

function errorMixes(error) {
    return {
        type: 'ERROR_MIXES',
        error,
    }
}

function receiveMixes(mixes) {
    if (!Array.isArray(mixes)) { mixes = [] }
    mixes = mixes.map(mixDisplayCalculations)

    return {
        type: 'RECEIVE_MIXES',
        mixes: mixes,
    }
}

export function mixDisplayCalculations(mix) {
    let ingredientSum = 0
    for (const ingredient of mix.mixIngredients) {
        ingredient.displayQuantity = convertToDisplay(ingredient.quantity, ingredient.userDisplayUnit).toFixed(2)
        if (ingredient.ddiId === SETPOINT_VOLUME_PER_AREA_DDI) {
            ingredientSum += ingredient.quantity
        }
    }
    if (mix.ddiId === SETPOINT_VOLUME_PER_AREA_DDI) {
        mix.carrierQuantity += ingredientSum
    }
    mix.carrierDisplayQuantity = convertToDisplay(mix.carrierQuantity, mix.userDisplayUnit).toFixed(2)

    return mix
}
