import React from 'react'
import { connect, Provider, ReactReduxContext } from 'react-redux'
import { bindActionCreators } from 'redux'

import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import IconButton from 'material-ui/IconButton'
import MenuItem from 'material-ui/MenuItem'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton'
import RaisedButton from 'material-ui/RaisedButton'
import SelectField from 'material-ui/SelectField'

import AddIcon from 'material-ui/svg-icons/content/add'
import TextField from 'material-ui/TextField'

import IngredientEdit from './ingredientCreation'
import MixIngredient from './mixCreationIngredient'

import * as ingredientActions from './ingredientActions'
import * as mixActions from './mixActions'

import { mixIngredientPrototype, ingredientPrototype } from './productPrototypes'
import { ddiFromDdiAndEntryMode, mixEntryModeFromDdi } from '../lib/ddiConversions'
import {
    checkProductLimits,
    ddiFromUnit,
    ddiFromMixState,
    getUnitEquivalentForDDI,
    convertToBase,
} from '../lib/ddiUnits'
import { getMenuItemsForProductDDI, getMenuItemsForProductEntryMode } from '../commonComponents/unitMenuItems'

import './productConfigStyles.css'
import {selectIngredients} from './productReducer'

function mapStateToProps(state, ownProps) {
    return {
        ingredients: selectIngredients(state),
        carriers: state.product.carriers,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(ingredientActions, dispatch),
        ...bindActionCreators(mixActions, dispatch),
    }
}

class MixEdit extends React.Component {
    static defaultProps = {
        data: {
            id: -1,
            name: '',
            carrierId: null,
            carrierQuantity: 0,
            carrierDisplayQuantity: 0,
            userDisplayUnit: 'gal/ac',
            mixIngredients: [new mixIngredientPrototype('')],
            ddiId: 1,
        },
        disabled: false,
    }

    state = {
        ...this.props.data,
        entryMode: mixEntryModeFromDdi(this.props.data.ddiId),
        errorMessage: '',
        ingredientDialogOpen: false,
        mixIngredientId: null,
        showCarrier: (this.props.data.guid && this.props.data.carrierId),
        tempIngredient: {},
    }

    componentDidMount() {
        // add contextually unique identifiers to pre-existing mix ingredients
        if (this.state.mixIngredients) {
            let i = 0
            for (const mixIng of this.state.mixIngredients) {
                if (!mixIng.id) {
                    mixIng.id = `${mixIng.ingredientId}-${i}`
                    ++i
                }
            }
        }
    }

    renderIngredient = (data, i) => {
        return (
            <MixIngredient
                key={data.id + ' ' + data.ingredientId + data.userDisplayUnit}
                data={{...data}}
                mode={this.state.entryMode}
                selectIngredient={this.setIngredient}
                setIngredientProperty={this.setIngredientProperty}
                delete={this.handleDelete}
                addIngredient={this.handleNewIngredient}
                disabled={this.props.disabled}
            />
        )
    }

    handleNewIngredient = id => {
        this.setState({
            tempIngredient: new ingredientPrototype(''),
            ingredientDialogOpen: true,
            mixIngredientId: id,
        })
    }
    handleNewIngredientSave = newData => {
        this.props.addIngredient(newData).then(savedIngredient => {
            if (!savedIngredient || !savedIngredient.id) { return }
            const i = this.state.mixIngredients.findIndex(ing => { return ing.id === this.state.mixIngredientId })
            const tempIngredients = [...this.state.mixIngredients]
            if (i !== -1) {
                tempIngredients[i].ingredientId = savedIngredient.id
                tempIngredients[i].name = newData.name
                tempIngredients[i].ddiId = ddiFromDdiAndEntryMode(newData.ddiId, this.state.entryMode)
                tempIngredients[i].userDisplayUnit = getUnitEquivalentForDDI(newData.defaultDisplayUnit, tempIngredients[i].ddiId)
            }
            this.setState({
                ingredientDialogOpen: false,
                mixIngredientId: null,
                tempIngredient: {},
                mixIngredients: tempIngredients,
            })
        })
    }
    handleEditCancel = () => {
        this.setState({
            ingredientDialogOpen: false,
        })
    }
    handleTextChange = (event) => {
        const formData = {...this.state}
        formData[event.target.name] = event.target.value
        this.setState(formData)
    }
    handleDelete = id => {
        const tempIngredients = this.state.mixIngredients.filter(ingredient => ingredient.id !== id)
        this.setState({ mixIngredients: tempIngredients })
    }
    handleSubmit = () => {
        const ingSet = new Set([])
        this.state.mixIngredients.forEach(mi => ingSet.add(mi.ingredientId))
        if (this.state.mixIngredients.length != ingSet.size) {
            this.setState({errorMessage: 'Duplicate Ingredients'})

            return
        }

        let invalidIngredients = false
        let ingredientTotalInBaseUnits = 0
        const carrierQtyInBaseUnits = convertToBase(this.state.carrierDisplayQuantity, this.state.userDisplayUnit)
        for (const ing of this.state.mixIngredients) {
            if (ing.id <= 0 || !ing.userDisplayUnit ||
                checkProductLimits(ing.displayQuantity, ing.userDisplayUnit)
            ) {
                invalidIngredients = true
                break
            }
            ingredientTotalInBaseUnits += convertToBase(ing.displayQuantity, ing.userDisplayUnit)
        }

        if (invalidIngredients ||
            !this.state.name || this.state.name.length > 32 ||
            !this.state.userDisplayUnit ||
            (this.state.showCarrier &&
                (!this.state.carrierId ||
                checkProductLimits(this.state.carrierDisplayQuantity, this.state.userDisplayUnit)))
        ) {
            this.setState({errorMessage: 'Invalid Data'})
        }
        else if (this.state.showCarrier && (carrierQtyInBaseUnits < ingredientTotalInBaseUnits)) {
            this.setState({errorMessage: 'Ingredient Rates cannot exceed Mix Rate'})
        }
        else {
            this.props.onSave({ ...this.state })
        }
    }

    handleCancel = () => {
        this.setState({
            id: -1,
            name: '',
            mixIngredients: [new mixIngredientPrototype()],
            entryMode: 'rateMode',
        })
        this.props.onCancel()
    }
    addIngredient = () => {
        this.setState({ mixIngredients: [...this.state.mixIngredients, new mixIngredientPrototype()] })
    }
    setIngredient = (id, ingId) => {
        const tempIngredients = [...this.state.mixIngredients]
        const i = tempIngredients.findIndex(element => {
            return element.id === id
        })
        const selectedIngredient = this.props.ingredients.find(ing => { return ing.id === ingId })
        if (selectedIngredient === undefined) {
            return
        }
        tempIngredients[i].ingredientId = ingId
        tempIngredients[i].name = selectedIngredient.name
        tempIngredients[i].ddiId = ddiFromDdiAndEntryMode(selectedIngredient.ddiId, this.state.entryMode)
        tempIngredients[i].userDisplayUnit = getUnitEquivalentForDDI(selectedIngredient.defaultDisplayUnit, tempIngredients[i].ddiId)

        this.setState({ mixIngredients: tempIngredients })

        const ingSet = new Set([])
        tempIngredients.forEach(mi => ingSet.add(mi.ingredientId))
        if (tempIngredients.length != ingSet.size) {
            this.setState({errorMessage: 'Duplicate Ingredients'})
        }
    }

    setIngredientProperty = (id, property, value) => {
        const tempIngredients = [...this.state.mixIngredients]
        const i = tempIngredients.findIndex(element => {
            return element.id === id
        })
        tempIngredients[i][property] = value
        this.setState({ mixIngredients: tempIngredients })
    }

    handleModeChange = (event, entryMode) => {
        const tempIngredients = [...this.state.mixIngredients]
        for (const selectedIngredient of tempIngredients) {
            selectedIngredient.ddiId = ddiFromDdiAndEntryMode(selectedIngredient.ddiId, entryMode)
            selectedIngredient.userDisplayUnit = getUnitEquivalentForDDI(selectedIngredient.userDisplayUnit, selectedIngredient.ddiId)
        }
        const ddi = ddiFromDdiAndEntryMode(this.state.ddiId, entryMode)
        const displayUnit = getUnitEquivalentForDDI(this.state.userDisplayUnit, ddi)

        this.setState({ entryMode: entryMode, ddiId: ddi, userDisplayUnit: displayUnit, mixIngredients: tempIngredients })
    }

    handleCarrierId = (event, index, value) => {
        this.setState({ carrierId: value })
    }
    handleUserDisplayUnit = (event, index, value) => {
        const ddi = ddiFromUnit(value)

        this.setState({ userDisplayUnit: value, ddiId: ddi })
    }

    getDefaultCarrierId = () => {
        if (!this.props.carriers.length) { return null }
        const carrier = this.props.carriers.find(carrier => {
            return carrier.name == 'Water'
        })
        if (carrier) { return carrier.id }

        return this.props.carriers[0].id
    }
    toggleCarrier = () => {
        const showCarrier = !this.state.showCarrier
        const ddi = ddiFromMixState(this.state.entryMode, this.state.userDisplayUnit, showCarrier)
        const carrierId = showCarrier ? this.getDefaultCarrierId() : null
        const displayUnit = getUnitEquivalentForDDI(this.state.userDisplayUnit, ddi)
        this.setState({
            ddiId: ddi,
            showCarrier: showCarrier,
            carrierId: carrierId,
            carrierDisplayQuantity: 0.0,
            userDisplayUnit: displayUnit,
        })
    }

    ingredientDialog = () => {
        if (this.state.ingredientDialogOpen) {
            return (
                <IngredientEdit
                    data={this.state.tempIngredient}
                    open={this.state.ingredientDialogOpen}
                    onCancel={this.handleEditCancel}
                    onSave={this.handleNewIngredientSave}
                />
            )
        }
    }

    getCarrierSection = () => {
        if (this.state.showCarrier) {
            return this.renderCarrierLine()
        }

        return this.renderNoCarrierInfo()
    }

    renderNoCarrierInfo = () => {
        const units = getMenuItemsForProductEntryMode(this.state.entryMode)
        let addButton = null
        if (!this.props.disabled) {
            addButton = (
                <RaisedButton
                    icon={<AddIcon />}
                    label={'Add Carrier'}
                    onClick={this.toggleCarrier}
                    style={{marginBottom: '20px'}}
                    name="addCarrierButton"
                />
            )
        }

        return (
            <div className="mixFlex" name="noCarrierSection">
                <div style={{marginTop: '20px'}}>{`Mix Units: `}</div>
                <SelectField
                    className="unitSelectionInline"
                    value={this.state.userDisplayUnit}
                    onChange={this.handleUserDisplayUnit}
                    errorText={!this.state.userDisplayUnit && 'Required Field'}
                    style={{top: '0px'}}
                    disabled={this.props.disabled}
                    name="mixUnitSelect"
                >
                    {units}
                </SelectField>
                {addButton}
            </div>
        )
    }
    renderCarrierLine = () => {
        const carrierList = this.createCarrierDropDownList()
        const units = getMenuItemsForProductDDI(this.state.ddiId)
        const errorText = checkProductLimits(this.state.carrierDisplayQuantity, this.state.userDisplayUnit)

        let deleteButton = null
        if (!this.props.disabled) {
            deleteButton = (
                <IconButton
                    className="ingredientDelete"
                    name="removeCarrier"
                    onClick={this.toggleCarrier}
                >
                    <img src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_DeleteBlack.svg`}/>
                </IconButton>
            )
        }

        return (
            <div className="mixFlex" name="carrierSection">
                <SelectField
                    floatingLabelText={'Carrier'}
                    className="selectFieldInline"
                    value={this.state.carrierId}
                    onChange={this.handleCarrierId}
                    style={{top: '0px'}}
                    errorText={this.state.carrierId <= 0 && 'Required Field'}
                    name="carrierSelect"
                    disabled={this.props.disabled}
                >
                    {carrierList}
                </SelectField>
                <TextField
                    floatingLabelText={this.state.entryMode === 'rateMode' ? 'Mix Rate' : 'Volume'}
                    onChange={this.handleTextChange}
                    value={this.state.carrierDisplayQuantity}
                    name="carrierDisplayQuantity"
                    className="flexSmall"
                    errorText={errorText}
                    disabled={this.props.disabled}
                />
                <SelectField
                    className="unitSelectionInline"
                    value={this.state.userDisplayUnit}
                    onChange={this.handleUserDisplayUnit}
                    errorText={!this.state.userDisplayUnit && 'Required Field'}
                    disabled={this.props.disabled}
                    name="carrierUnits"
                >
                    {units}
                </SelectField>
                {deleteButton}
            </div>
        )
    }
    createCarrierDropDownList = () => {
        return [
            ...this.props.carriers.map((data, i) => {
                return <MenuItem key={data.id} value={data.id} primaryText={data.name} name={data.id}/>
            }),
        ]
    }

    renderDialogActions = () => {
        if (this.props.disabled) {
            return [
                <RaisedButton label="Close" primary={true} onClick={this.handleCancel} />,
            ]
        }

        return [
            <div style={{display: 'inline-block', color: 'red'}}>{this.state.errorMessage}</div>,
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.handleCancel}
                name="cancelButton"
            />,
            <RaisedButton
                icon={<img className="standardIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_SaveWhite.svg`}/>}
                label={'Save'}
                primary={true}
                onClick={this.handleSubmit}
                name="saveButton"
            />,
        ]
    }

    renderAddIngredient = () => {
        if (!this.props.disabled) {
            return (
                <RaisedButton
                    icon={<AddIcon />}
                    label={'Add Ingredient'}
                    onClick={this.addIngredient}
                    style={{marginTop: '20px'}}
                    name="addIngredient"
                />
            )
        }
    }
    render() {
        const ingredientList = this.state.mixIngredients.map(this.renderIngredient)
        const ingredientEditDialog = this.ingredientDialog()

        const dialogActions = this.renderDialogActions()
        const carrierSection = this.getCarrierSection()
        const addIngredient = this.renderAddIngredient()

        return (
            <ReactReduxContext.Consumer>
                {((ctx) => (
                    <Dialog
                        actions={dialogActions}
                        title="Mix Information"
                        modal={true}
                        open={this.props.open}
                        onRequestClose={this.handleSubmit}
                        autoScrollBodyContent={true}
                        contentClassName="wodialog"
                    >
                        <Provider store={ctx.store}>
                            <RadioButtonGroup
                                name="entryMode"
                                valueSelected={this.state.entryMode}
                                onChange={this.handleModeChange}
                                style={{ display: 'inline-block' }}
                            >
                                <RadioButton
                                    value="rateMode"
                                    label="Rate"
                                    style={{ display: 'inline-block', width: '100px' }}
                                    name="rateEntryMode"
                                    disabled={this.props.disabled}
                                />
                                <RadioButton
                                    value="qtyMode"
                                    label={'Quantity'}
                                    style={{ display: 'inline-block', width: '100px' }}
                                    name="QuantityEntryMode"
                                    disabled={this.props.disabled}
                                />
                            </RadioButtonGroup>
                            <TextField
                                floatingLabelText="Mix Name"
                                fullWidth={true}
                                onChange={this.handleTextChange}
                                value={this.state.name}
                                name="name"
                                disabled={this.props.disabled}
                                errorText={(!this.state.name && 'Required Field') || (this.state.name.length > 32 && 'Max Length 32')}
                            />
                            {carrierSection}
                            {ingredientList}
                            {addIngredient}
                            {ingredientEditDialog}
                        </Provider>
                    </Dialog>
                )).bind(this)
                }
            </ReactReduxContext.Consumer>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MixEdit)
