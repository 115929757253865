import React from 'react'
import { Tab, Tabs } from 'material-ui/Tabs'
import MixManager from './mixManager'
import IngredientManager from './ingredientManager'

class ProductManagerTabs extends React.Component {
    state = {
        activeManager: 'mix',
    }

    handleModeChange = (manager) => {
        this.setState({activeManager: manager})
    }
    render() {
        return (
            <div style={{width: '100%', maxWidth: 800, margin: 'auto', paddingBottom: 20}}>
                <Tabs name="productManagerTabs" contentContainerStyle={{marginTop: 15}}>
                    <Tab
                        label="Mixes"
                        onActive={() => this.handleModeChange('mix')}
                        name="mixManagerTab"
                        buttonStyle={this.state.activeManager === 'mix' ? {} : {backgroundColor: 'var(--secondary)'}}
                    >
                        <MixManager/>
                    </Tab>
                    <Tab
                        label="Ingredients"
                        style={{borderLeft: 'solid 2px #FFFFFF'}}
                        onActive={() => this.handleModeChange('ingredient')}
                        name="ingredientManagerTab"
                        buttonStyle={this.state.activeManager === 'ingredient' ? {} : {backgroundColor: 'var(--secondary)'}}
                    >
                        <IngredientManager/>
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

export default (ProductManagerTabs)
