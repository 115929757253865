import React from 'react'
import { connect } from 'react-redux'
import AutoComplete from 'material-ui/AutoComplete'
import MapboxClient from 'mapbox'
import * as locationActions from './locationActions'
import { bindActionCreators } from 'redux'

function mapStateToProps(state, ownProps) {
    return {
        geolocation: state.scout.geolocation,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(locationActions, dispatch),
    }
}

const mapboxClient = new MapboxClient(process.env.MAP_KEY)

class GeocodeSearch extends React.Component {
    state = {
        dataSource: [],
        searchText: '',
    }

    handleGeocodeRequest = (value) => {
        mapboxClient.geocodeForward(value, this.handleGeocodeResponse)
    }

    handleGeocodeResponse = (err, response) => {
        if (!err) {
            this.setState({ dataSource: [...response.features] })
        }
        else {
            this.setState({ dataSource: [] })
        }
    }

    handleGeocodeSelect = (chosenRequest, index) => {
        let feature
        if (index >= 0) {
            feature = this.state.dataSource[index]
        }
        else if (this.state.dataSource.length) {
            feature = this.state.dataSource[0]
        }
        if (feature) {
            this.props.selectGeolocation(feature)
            this.setState({dataSource: [], searchText: feature.place_name})
        }
    }

    render() {
        return (
            <AutoComplete
                hintText="Location Search"
                filter={AutoComplete.noFilter}
                openOnFocus={true}
                fullWidth={true}
                searchText={this.state.searchText}
                dataSource={this.state.dataSource}
                onUpdateInput={this.handleGeocodeRequest}
                onNewRequest={this.handleGeocodeSelect}
                dataSourceConfig={{
                    text: 'place_name',
                    value: 'center',
                }}
                disabled={this.props.disabled}
                name="locationSearch"
            />
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(GeocodeSearch)
