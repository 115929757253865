import { createStore, applyMiddleware, compose } from 'redux'
import { syncHistoryWithStore } from 'react-router-redux'
// import { browserHistory } from 'react-router'
import { createBrowserHistory } from 'history'

// import the root reducer
import rootReducer, {defaultState} from './rootReducer'
import thunkMiddleware from 'redux-thunk'
import trackJsLogger from './lib/trackJsLogger'
import verify from './system/verifyMiddleware'
import { persistStore } from 'redux-persist'

// import { composeWithDevTools } from 'redux-devtools-extension'
const store = createStore(
    rootReducer,
    defaultState,
    compose(
        applyMiddleware(trackJsLogger),
        applyMiddleware(verify),
        applyMiddleware(thunkMiddleware),
    ),
)

const browserHistory = createBrowserHistory()

export const history = syncHistoryWithStore(browserHistory, store)
export const persistor = persistStore(store)

export default store
