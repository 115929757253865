export function alphanumSanitize(value) {
    if (value) {
        const content = value.match(/[\da-zA-Z]{1}/g)
        if (content) {
            return content.join('')
        }
    }

    return ''
}

export function alphanumCount(value) {
    const san = alphanumSanitize(value)

    return san.length
}
