import React, { useEffect, useState } from 'react'

import FirstIcon from 'material-ui/svg-icons/navigation/first-page'
import NextIcon from 'material-ui/svg-icons/navigation/chevron-right'
import PreviousIcon from 'material-ui/svg-icons/navigation/chevron-left'
import LastIcon from 'material-ui/svg-icons/navigation/last-page'

import ButtonBar, {buttonPrototype} from './buttonBar'

// Usage: limit a large list of items to only a small set of viewable ones
// and render buttons to page through the entire list
//
// Arguments
// countPerPage: the number of items in the list to be rendered
// itemList: items to be paged
// viewListChanged: a function that will be called when the items to be rendered changed
//      Will be passed a subarray if itemList and their index ranges in the original array
//      Parent component then handles rendering these items
const ListPaging = ({
    countPerPage = 10,
    disabled = false,
    itemList = [],
    viewListChanged = () => {},
}) => {
    const [pageIndex, setPageIndex] = useState(0)

    const maxPageIndex = () => {
        const itemCount = itemList.length
        // Round down to the nearest whole number to determine the
        // maximum page index. In the event we have exactly enough to
        // fill every page, we go down one more.
        if (itemCount === 0) return 0
        if (itemCount % countPerPage) {
            return Math.floor(itemCount / countPerPage)
        }

        return (itemCount / countPerPage) - 1
    }


    const updatePageItems = (pageIndexRef) => {
        const viewableItems = []
        const firstItemIndex = pageIndexRef * countPerPage
        let lastItemIndex = (firstItemIndex + countPerPage) - 1
        if (lastItemIndex >= itemList.length) {
            lastItemIndex = itemList.length - 1
        }
        for (let i = firstItemIndex; i <= lastItemIndex; ++i) {
            viewableItems.push(itemList[i])
        }
        viewListChanged(viewableItems, firstItemIndex, lastItemIndex)
    }

    const firstPage = () => {
        updatePageItems(0)
        setPageIndex(0)
    }

    const previousPage = () => {
        if (pageIndex > 0) {
            updatePageItems(pageIndex - 1)
            setPageIndex(pageIndex - 1)
        }
    }

    const nextPage = () => {
        if (pageIndex < maxPageIndex()) {
            updatePageItems(pageIndex + 1)
            setPageIndex(pageIndex + 1)
        }
    }

    const lastPage = () => {
        updatePageItems(maxPageIndex())
        setPageIndex(maxPageIndex())
    }

    // only update the viewable item list when we get a change relevent to us
    // react calls this function even if props are not different in the event the
    // parent component re-renders
    useEffect(() => {
        let pageIndexRef = pageIndex
        if (pageIndexRef > maxPageIndex(itemList.length, countPerPage)) {
            pageIndexRef = maxPageIndex(itemList.length, countPerPage)
            setPageIndex(pageIndexRef)
        }
        updatePageItems(pageIndexRef, itemList, countPerPage)
    }, [itemList, countPerPage])

    return (
        <ButtonBar disabled={disabled}
            children = {[
                buttonPrototype({
                    label: 'First',
                    icon: <FirstIcon />,
                    onClick: firstPage,
                    disabled: pageIndex === 0,
                }),
                buttonPrototype({
                    label: 'Previous',
                    icon: <PreviousIcon />,
                    onClick: previousPage,
                    disabled: pageIndex === 0,
                }),
                buttonPrototype({
                    label: 'Next',
                    icon: <NextIcon />,
                    onClick: nextPage,
                    disabled: pageIndex === maxPageIndex(),
                }),
                buttonPrototype({
                    label: 'Last',
                    icon: <LastIcon />,
                    onClick: lastPage,
                    disabled: pageIndex === maxPageIndex(),
                }),
            ]}
        />
    )
}

export default ListPaging
