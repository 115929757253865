import axios from '../lib/axios'
import {errorCheck, errorCatch} from '../lib/actionErrorHandler'

export function fetchIngredients() {
    return dispatch => {
        dispatch(requestIngredients())

        return axios.get('/jobs/api/ingredients')
            .then(response => response.data)
            .then(json => dispatch(receiveIngredients(json)))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(errorIngredients('Error retrieving ingredients'))
            })
    }
}

export function addIngredient(ingredient) {
    return dispatch => {
        return axios.post('/jobs/api/ingredients/', ingredient)
            .then(response => response.data)
            .then((json) => {
                dispatch(fetchIngredients())

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)

                return null
            })
    }
}

export function updateIngredient(ingredient) {
    return dispatch => {
        return axios.put(`/jobs/api/ingredients/${ingredient.id}`, ingredient)
            .then(response => response.data)
            .then((json) => {
                dispatch(fetchIngredients())

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)

                return null
            })
    }
}

export function deleteIngredients(ingredientIds) {
    return dispatch => {
        const params = {
            data: {ingredientIds},
        }

        return axios.delete('/jobs/api/ingredients/', params)
            .then(response => response.data)
            .then(json => {
                dispatch(fetchIngredients())

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)

                return null
            })
    }
}

function requestIngredients() {
    return {
        type: 'REQUEST_INGREDIENTS',
    }
}

function receiveIngredients(ingredients) {
    if (!Array.isArray(ingredients)) { ingredients = [] }

    return {
        type: 'RECEIVE_INGREDIENTS',
        ingredients: ingredients,
    }
}

export function fetchCarriers() {
    return dispatch => {
        dispatch(requestCarriers())

        return axios.get('/jobs/api/carriers')
            .then(response => response.data)
            .then(json => dispatch(receiveCarriers(json)))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(receiveCarriers([]))
            })
    }
}

function requestCarriers() {
    return {
        type: 'REQUEST_CARRIERS',
    }
}

function receiveCarriers(carriers) {
    if (!Array.isArray(carriers)) { carriers = [] }

    return {
        type: 'RECEIVE_CARRIERS',
        carriers: carriers,
    }
}

function errorIngredients(error) {
    return {
        type: 'ERROR_INGREDIENTS',
        error,
    }
}
