import * as React from 'react'

import { Alert } from '@atd/rui-base.mui.alert'
import { Snackbar } from '@atd/rui-base.mui.snackbar'

function NoCoverageWarning({coverage, name}) {
    const [open, setOpen] = React.useState(true)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }

    return (
        <Snackbar
            open={!coverage && open}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <Alert severity="warning" onClose={handleClose}>
                Live View has not received the real-time location for all participants.
                <br/> Showing last known gps location for:
                <br /> {name.join(', ')}
            </Alert>
        </Snackbar>
    )
}

export default NoCoverageWarning
