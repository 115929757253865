import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { Card, CardActions, CardHeader } from 'material-ui/Card'
import FlatButton from 'material-ui/FlatButton'
import EditIcon from 'material-ui/svg-icons/content/create'
import InfoIcon from 'material-ui/svg-icons/action/info'

import DeleteButton from '../commonComponents/deleteDialogButton'
import FieldEdit from './fieldedit'

import * as fieldActions from './fieldActions'


function mapStateToProps(state, ownProps) {
    return {}
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(fieldActions, dispatch),
    }
}

// TODO fix actions details -- use class? (Class unused at this point)
class FieldCard extends React.Component {
    static propTypes = {
        data: PropTypes.object,
        disabled: PropTypes.bool,
    }

    static defaultProps = {
        data: {},
        disabled: false,
    }

    constructor(props) {
        super(props)
        this.state = {
            expanded: false,
            editDialogOpen: false,
        }
    }

    handleExpandChange = expanded => {
        this.setState({ expanded: expanded })
    }

    handleToggle = () => {
        this.setState({ expanded: !this.state.expanded })
    }

    handleDelete = () => {
        this.props.deleteField(this.props.data.id)
    }

    handleEditRequest = () => {
        this.setState({ editDialogOpen: true })
    }

    handleCancel = () => {
        this.setState({
            editDialogOpen: false,
        })
    }
    handleSave = newData => {
        this.props.updateField({...newData})
        this.setState({
            editDialogOpen: false,
        })
    }

    render() {
        return (
            <Card
                key={this.props.data.id}
                name={this.props.data.id}
            >
                <CardHeader
                    title={this.props.data.name}
                    subtitle="Field"
                    avatar={<InfoIcon color="#BCBCBC"/>}
                />
                <CardActions>
                    <FlatButton
                        icon={<EditIcon />}
                        label="Details"
                        onClick={this.handleEditRequest}
                        disabled={this.props.disabled}
                        primary={true}
                        name="openFieldDialog"
                    />
                    <DeleteButton
                        itemType="field"
                        deleteFunction={this.handleDelete}
                        disabled={this.props.disabled}
                        primary={true}
                    />
                    <FieldEdit
                        data={this.props.data}
                        onCancel={this.handleCancel}
                        onSubmit={this.handleSave}
                        open={this.state.editDialogOpen}
                    />
                </CardActions>
            </Card>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldCard)
