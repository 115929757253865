import ReactMapboxGl, { Feature, GeoJSONLayer, Layer, Popup, ZoomControl } from 'react-mapbox-gl'

import React from 'react'
import { connect } from 'react-redux'
import geolib from 'geolib'

function mapStateToProps(state, ownProps) {
    return {
        selectedCentroid: state.scout.selectedCentroid,
        entrancePoints: state.scout.entrancePoints,
        // Used for corners & calculations
        boundaryPoints: state.scout.boundaryPoints,
        // Used for lines
        boundaryGeoJson: state.scout.boundaryGeoJson,
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

const Map = ReactMapboxGl({
    accessToken: process.env.MAP_KEY,
})

class LiveJobMap extends React.Component {
    static defaultProps = {
        containerStyle: {
            width: '100%',
            minWidth: 200,
            height: '100%',
            minHeight: 200,
        },
        initCenter: [-98.5795, 39.8283],
        initBbox: [-124.771694, 24.446667, -66.949778, 49.384472],
        zoom: [3],
        bbox: null,
        participants: [],
        hardwareInfo: [],
    }

    state = {
        coverageBbox: null,
        fieldBbox: null,
        backupBbox: null,
        popUp: null,
    }

    static getDerivedStateFromProps(props, state) {
        let updatedFieldBbox = null
        let updatedCoverageBbox = null
        let updatedBackupBbox = null

        if (!state.fieldBbox && props.boundaryPoints && props.boundaryPoints.length) {
            updatedFieldBbox = LiveJobMap.getBounds(props)
        }
        if (!state.coverageBbox && props.bbox) {
            updatedCoverageBbox = props.bbox
        }
        if (!state.backupBbox && props.hardwareInfo && props.hardwareInfo.length) {
            updatedBackupBbox =
            [
                props.hardwareInfo[0].longitude - 0.001,
                props.hardwareInfo[0].latitude - 0.001,
                props.hardwareInfo[0].longitude + 0.001,
                props.hardwareInfo[0].latitude + 0.001,
            ]
        }

        return {
            ...state,
            fieldBbox: updatedFieldBbox ? updatedFieldBbox : state.fieldBbox,
            coverageBbox: updatedCoverageBbox ? updatedCoverageBbox : state.coverageBbox,
            backupBbox: updatedBackupBbox ? updatedBackupBbox : state.backupBbox,
        }
    }

    // Though this is a geojson object, we are going to render it using mapbox Layer not GeoJsonLayer
    // We can add Layer to the DOM immediately, even if there aren't points, which allows us to keep
    // the layers rendered at the proper Z-index. Using GeoJsonLayer the polygons would end up on
    // top of the corner icons. We only want to render an outline of the field. Mapbox requires
    // this to be done as one linestring per feature. A "Fill" layer with transparent center
    // only draws a 1px outline, which is not thick enough for our purposes.
    buildBoundaryFeatures = () => {
        if (!this.props.boundaryGeoJson || !this.props.boundaryGeoJson.features) {
            return
        }
        const features = []
        let key = 0
        for (const feature of this.props.boundaryGeoJson.features) {
            if (feature.geometry.type == 'Polygon') {
                for (const line of feature.geometry.coordinates) {
                    features.push(<Feature coordinates={line} key={key} />)
                    ++key
                }
            }
            else if (feature.geometry.type == 'MultiPolygon') {
                for (const poly of feature.geometry.coordinates) {
                    for (const line of poly) {
                        features.push(<Feature coordinates={line} key={key} />)
                        ++key
                    }
                }
            }
        }

        return features
    }
    getBoundaryCorners = () => {
        return this.props.boundaryPoints.map((data, i) => {

            return <Feature coordinates={data} key={`Flag${i}`} />
        })
    }


    static getBounds = (fieldData) => {
        const points = []

        if (fieldData.selectedCentroid && fieldData.selectedCentroid.length === 2) {
            points.push({longitude: fieldData.selectedCentroid[0], latitude: fieldData.selectedCentroid[1]})
        }
        if (fieldData.boundaryPoints && fieldData.boundaryPoints.length > 0) {
            for (const point of fieldData.boundaryPoints) {
                points.push({longitude: point[0], latitude: point[1]})
            }
        }
        if (fieldData.entrancePoints && fieldData.entrancePoints.length > 0) {
            for (const point of fieldData.entrancePoints) {
                points.push({longitude: point[0], latitude: point[1]})
            }
        }

        if (points.length) {
            const bounds = geolib.getBounds(points)
            if (!Number.isNaN(bounds.longitude) && !Number.isNaN(bounds.latitude)) {
                return [bounds.minLng, bounds.minLat, bounds.maxLng, bounds.maxLat]
            }
        }

        if (fieldData.initBbox && fieldData.initBbox.length) {
            return fieldData.initBbox
        }

        return null
    }

    resetMapView = () => {
        this.setState({
            // since we are reseting update the coverage bounding box
            coverageBbox: this.props.bbox ? [...this.props.bbox] : null,
            fieldBbox: this.state.fieldBbox ? [...this.state.fieldBbox] : null,
            backupBbox: this.state.backupBbox ? [...this.state.backupBbox] : null,
        })
    }

    buildFieldCenterLayer = () => {
        if (this.props.selectedCentroid.length === 2) {
            return (
                <Layer type="symbol" id="fieldLocation" layout={{ 'icon-image': 'Centroid' }}>
                    <Feature coordinates={this.props.selectedCentroid} />
                </Layer>
            )
        }
    }

    buildFieldEntranceLayer = () => {
        if (this.props.entrancePoints.length > 0) {
            const features = this.props.entrancePoints.map(
                (data, i) => { return <Feature coordinates={data} key={i} /> }
            )

            return (
                <Layer type="symbol" id="entrancePoints" layout={{ 'icon-image': 'FieldEntry' }}>
                    {features}
                </Layer>
            )
        }
    }

    buildParticipantLayers = () => {
        const layers = []
        for (const part of this.props.participants) {
            if (!part.geoJson || !part.geoJson.features.length) continue
            const geoId = `geo-${part.id}`
            layers.push(
                <GeoJSONLayer
                    id={geoId}
                    key={geoId}
                    data={part.geoJson}
                    fillPaint={{'fill-color': part.color, 'fill-opacity': 0.5 }}
                />
            )
        }
        // Add last location afterwards so it is on top of the fill layers
        for (const part of this.props.participants) {
            const positionId = `geo-loc-${part.id}`
            if (part.position && part.position.geometry.coordinates.length) {
                layers.push(
                    <GeoJSONLayer
                        id={positionId}
                        key={positionId}
                        data={part.position}
                        symbolLayout={{ 'icon-image': `${part.status == 'DISCONNECTED' ? 'PositionIndicator_Dc' : 'PositionIndicator'}`, 'icon-rotate': part.position.properties.cog, 'icon-allow-overlap': true }}
                        symbolOnMouseEnter={this.onHover.bind(this, part.name, positionId)}
                        symbolOnMouseLeave={this.offHover}
                    />
                )
            }
            // If position is not sent from m2m get last known position from database
            else if (this.props.hardwareInfo && this.props.hardwareInfo.length) {
                const hardware = this.props.hardwareInfo.find(h => h.id === part.id)
                if (!hardware) continue
                const data = {
                    type: 'Feature',
                    properties: { cog: 0 },
                    geometry: {
                        type: 'Point',
                        coordinates: [hardware.longitude, hardware.latitude],
                    },
                }
                layers.push(
                    <GeoJSONLayer
                        id={positionId}
                        key={positionId}
                        data={data}
                        symbolLayout={{ 'icon-image': `${part.status == 'DISCONNECTED' ? 'PositionIndicator_Dc' : 'PositionIndicator'}`, 'icon-rotate': data.properties.cog, 'icon-allow-overlap': true }}
                        symbolOnMouseEnter={this.onHover.bind(this, part.name, positionId)}
                        symbolOnMouseLeave={this.offHover}
                    />
                )
            }
        }

        return layers
    }

    onHover = (name, id, event) => {
        this.setState({
            popUp: {
                coordinates: event.features[0].geometry.coordinates,
                name: name,
                id: id,
            },
        })
    }

    offHover = () => {
        this.setState({popUp: null})
    }

    render() {
        // If available use the bounding box for the whole field.
        // If not center on known coverage, or center & entrance if the job hasn't started
        const bbox = this.state.fieldBbox || this.state.coverageBbox || this.state.backupBbox || LiveJobMap.getBounds(this.props)
        const boundaryCorners = this.getBoundaryCorners()
        const boundaryLines = this.buildBoundaryFeatures()
        const centerLayer = this.buildFieldCenterLayer()
        const entryLayer = this.buildFieldEntranceLayer()
        const coverage = this.buildParticipantLayers()

        /*  Styled to match and fit with the mapbox react zoom control
            https://github.com/alex3165/react-mapbox-gl/blob/master/src/zoom-control.tsx
        */
        return (
            <Map
                style="mapbox://styles/slingshot/cj795wknd7v162ro6waofdhl8"
                center={this.props.initCenter}
                fitBounds={bbox}
                fitBoundsOptions={{padding: 24}}
                interactive={false}
                className="liveMapContainer"
                zoom={this.props.zoom}
            >
                <link href="https://api.mapbox.com/mapbox-gl-js/v0.38.0/mapbox-gl.css" rel="stylesheet" key="link"/>
                <script src="https://api.tiles.mapbox.com/mapbox.js/v2.3.0/mapbox.js" key="style"/>
                <ZoomControl className="zoomControl"/>
                <button
                    type="button"
                    className="mapReset"
                    onClick={this.resetMapView}
                >
                    <img className="mapResetIcon" src={`${process.env.PUBLIC_URL}/images/mapbox/Map_CenterZoom.svg`}/>
                </button>
                <Layer
                    key="streetboundaryoutline"
                    id="boundaryOutline"
                    type="line"
                    paint={{ 'line-color': '#0026FF', 'line-width': 3}}
                >
                    {boundaryLines}
                </Layer>
                <Layer
                    key="boundaryCorners"
                    id="boundaryPoints"
                    type="symbol"
                    layout={{ 'icon-image': 'Flag', 'icon-allow-overlap': true, 'icon-anchor': 'bottom', 'icon-offset': [5, 5]}}
                >
                    {boundaryCorners}
                </Layer>
                {centerLayer}
                {entryLayer}
                {coverage}
                {this.state.popUp && (
                    <Popup key={this.state.popUp.id} coordinates={this.state.popUp.coordinates}>
                        <div>{this.state.popUp.name}</div>
                    </Popup>
                )}
            </Map>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LiveJobMap)
