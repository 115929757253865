import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Checkbox from 'material-ui/Checkbox'
import IconButton from 'material-ui/IconButton'
import MenuItem from 'material-ui/MenuItem'
import SelectField from 'material-ui/SelectField'

import ClearIcon from 'material-ui/svg-icons/navigation/cancel'

import * as growerActions from './growerActions'
import * as farmActions from './farmActions'
import * as fieldActions from './fieldActions'

function mapStateToProps(state, ownProps) {
    return {
        growers: state.gff.growers,
        farms: state.gff.farms,
        fields: state.gff.fields,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(growerActions, dispatch),
        ...bindActionCreators(farmActions, dispatch),
        ...bindActionCreators(fieldActions, dispatch),
    }
}

class GffFilter extends React.Component {
    static defaultProps = {
        selectedGrowers: [],
        selectedFarms: [],
        selectedFields: [],
        noGffOnly: false,
        onGffFilterChanged: () => {},
    }

    componentDidMount() {
        this.props.fetchGrowers()
        this.props.fetchFarms()
        this.props.fetchFields()
    }

    selectGrower = (event, index, values) => {
        if (this.props.selectedFarms) {
            // Ensure only farms and fields that match the current grower selection are selected
            // e.g. A grower is unselected, unselect related farms and fields
            const farms = this.props.farms.filter(farm => { return values.includes(farm.growerId) })
            const selectedFarms = this.props.selectedFarms.filter(id => { return farms.find(farm => { return farm.id === id }) })
            if (this.props.selectedFields) {
                const fields = this.props.fields.filter(field => { return farms.includes(field.farmId) })
                const selectedFields = this.props.selectedFields.filter(id => { return fields.find(field => { return field.id === id }) })

                this.props.onGffFilterChanged({
                    selectedGrowers: values,
                    selectedFarms: selectedFarms,
                    selectedFields: selectedFields,
                })
            }
            else {
                this.props.onGffFilterChanged({
                    selectedGrowers: values,
                    selectedFarms: selectedFarms,
                })
            }

        }
        else {
            this.props.onGffFilterChanged({selectedGrowers: values})
        }
    }
    selectFarm = (event, index, values) => {
        if (this.props.selectedFields) {
            // Ensure only fields that match the current farm selection are selected
            // e.g. A farm is unselected, unselect related fields
            const fields = this.props.fields.filter(field => { return values.includes(field.farmId) })
            const selectedFields = this.props.selectedFields.filter(id => { return fields.find(field => { return field.id === id }) })

            this.props.onGffFilterChanged({
                selectedFarms: values,
                selectedFields: selectedFields,
            })
        }
        else {
            this.props.onGffFilterChanged({selectedFarms: values})
        }
    }
    selectField = (event, index, values) => {
        this.props.onGffFilterChanged({selectedFields: values})
    }

    gffToMenuItem = (list, selectedItems) => {
        return list.map((data, i) => {
            return (
                <MenuItem
                    key={data.id}
                    insetChildren = {true}
                    value={data.id}
                    primaryText={data.name}
                    name={data.id}
                    checked={selectedItems.includes(data.id)}
                />
            )
        })
    }
    selectionRenderer = (values, list) => {
        switch (values.length) {
            case 0:
                return ''
            case 1:
                return list[0].props.primaryText
            default:
                return `${values.length} selected`
        }
    }

    growersToMenuItems = () => {
        return this.gffToMenuItem(this.props.growers, this.props.selectedGrowers)
    }
    farmsToMenuItems = () => {
        let farms = this.props.farms
        if (this.props.selectedGrowers.length) {
            farms = this.props.farms.filter(farm => this.props.selectedGrowers.includes(farm.growerId))
        }

        return this.gffToMenuItem(farms, this.props.selectedFarms)
    }
    fieldsToMenuItems = () => {
        let fields = this.props.fields
        if (this.props.selectedFarms.length) {
            fields = this.props.fields.filter(field => this.props.selectedFarms.includes(field.farmId))
        }

        return this.gffToMenuItem(fields, this.props.selectedFields)
    }
    render() {
        return (
            <div className="filterGroup">
                <div style={{display: 'flex', width: '95%'}}>
                    <SelectField
                        disabled={this.props.noGFFonly}
                        className="gffSelect"
                        value={this.props.selectedGrowers}
                        onChange={this.selectGrower}
                        name="growerDropDown"
                        floatingLabelText="Grower"
                        multiple={true}
                        selectionRenderer={this.selectionRenderer}
                        selectedMenuItemStyle={{color: 'var(--primary)'}}
                        underlineStyle={{borderColor: 'var(--secondary)'}}
                        iconStyle={{fill: 'var(--secondary)'}}
                    >
                        {this.growersToMenuItems()}
                    </SelectField>
                    <IconButton disabled={!this.props.selectedGrowers.length}
                        className="clearSelection"
                        name="clearGrower"
                        onClick={(event) => { this.selectGrower(event, null, []) }}
                    >
                        <ClearIcon/>
                    </IconButton>
                </div>

                <div style={{display: 'flex', width: '95%'}}>
                    <SelectField
                        disabled={this.props.noGFFonly}
                        className="gffSelect"
                        value={this.props.selectedFarms}
                        onChange={this.selectFarm}
                        name="farmDropdown"
                        floatingLabelText="Farm"
                        multiple={true}
                        selectionRenderer={this.selectionRenderer}
                        selectedMenuItemStyle={{color: 'var(--primary)'}}
                        underlineStyle={{borderColor: 'var(--secondary)'}}
                        iconStyle={{fill: 'var(--secondary)'}}
                    >
                        {this.farmsToMenuItems()}
                    </SelectField>
                    <IconButton disabled={!this.props.selectedFarms.length}
                        className="clearSelection"
                        name="clearFarm"
                        onClick={(event) => { this.selectFarm(event, null, []) }}
                    >
                        <ClearIcon/>
                    </IconButton>
                </div>

                <div style={{display: 'flex', width: '95%'}}>
                    <SelectField
                        disabled={this.props.noGFFonly}
                        className="gffSelect"
                        value={this.props.selectedFields}
                        onChange={this.selectField}
                        name="fieldDropdown"
                        floatingLabelText="Field"
                        multiple={true}
                        selectionRenderer={this.selectionRenderer}
                        selectedMenuItemStyle={{color: 'var(--primary)'}}
                        underlineStyle={{borderColor: 'var(--secondary)'}}
                        iconStyle={{fill: 'var(--secondary)'}}
                    >
                        {this.fieldsToMenuItems()}
                    </SelectField>
                    <IconButton disabled={!this.props.selectedFields.length}
                        className="clearSelection"
                        name="clearField"
                        onClick={(event) => { this.selectField(event, null, []) }}
                    >
                        <ClearIcon/>
                    </IconButton>
                </div>
                <Checkbox
                    label="No Grower/Farm/Field"
                    checked={this.props.noGFFonly}
                    onCheck={(event, noGFFonly) => this.props.onGffFilterChanged({noGFFonly})}
                    name="noGFFonly"
                />
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(GffFilter)
