import './liveViewStyles.css'

import * as React from 'react'
import * as liveViewActions from './liveViewActions'
import * as workOrderActions from '../workorder/workOrderActions'

import {errorCatch, errorCheck} from '../lib/actionErrorHandler'

import LiveJobMap from './liveMap'
import LiveJobSideBar from './liveSideBar'
import NoCoverageWarning from './noCoverageWarning'
import axios from 'axios'
import axiosIntercepted from '../lib/axios'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

const colors = ['#E23838', '#F78200', '#F9DD16', '#5EBD3E', '#009CDF', '#973999']

function mapStateToProps(state, ownProps) {
    return {
        fileTypes: state.system.fileTypes,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(workOrderActions, dispatch),
        ...bindActionCreators(liveViewActions, dispatch),
    }
}
class LiveView extends React.Component {
    static defaultProps = {
        jobUuid: 'rickroll',
    }

    state = {
        participants: [],
        products: [],
        associatedFiles: [],
        bbox: null,
        hardwareInfo: [],
        connectParams: {polygonEndpoint: ''},
        connected: false,
        jobStateTimer: null,
    }
    componentDidMount() {
        if (!this.props.jobUuid) return
        this.load()
        this.jobJoin()
    }

    jobJoin = () => {
        return axiosIntercepted.get(`/jobs/api/workorders/m2m/uuid/${this.props.jobUuid}`)
            .then(response => response.data)
            .then(json => {
                if (json !== this.state.connectParams) {
                    this.updateJobState({...json})
                    this.setState({connectParams: {...json}})
                }
            })
            .catch(err => {
                window.setTimeout(this.jobJoin, 5000)
                errorCheck(err.response)
                errorCatch(err)
            })
    }

    updateJobState = (connectParams = this.state.connectParams) => {
        const participants = this.state.participants.map(p => {
            return {
                id: p.id,
                index: (p.geoJson && p.geoJson.features) ? p.geoJson.features.length : 0,
            }
        })
        const uninterceptedAxiosInstance = axios.create()

        return uninterceptedAxiosInstance.post(`${connectParams.polygonEndpoint}/jobstate/${this.props.jobUuid}`, {
            headers: {
                'content-type': 'application/json',
            },
            participants,
            authorization: connectParams.authorization,
        })
            .then(response => response.data)
            .then(async(json) => {
                if (!Array.isArray(json.participants) || !json.participants.length) {
                    return
                }
                const existingHardwareInfo = [...this.state.hardwareInfo]
                const hardwareInfo = []

                let colorIndex = 0
                const parts = [...this.state.participants]

                for (const participant of json.participants) {
                    participant.color = colors[colorIndex]
                    ++colorIndex
                    if (colorIndex >= colors.length) colorIndex = 0
                    const currentPartHardwareState = existingHardwareInfo.find(h => h.id == participant.id)
                    const shouldUpdateHardware = !currentPartHardwareState || (new Date().getTime() - currentPartHardwareState.lastUpdate) > 60000
                    if (shouldUpdateHardware) {
                        const hardware = await this.props.fetchHardwareById(participant.id)
                        if (hardware) {
                            hardwareInfo.push(
                                {
                                    id: hardware.id,
                                    name: hardware.name,
                                    latitude: hardware.latitude,
                                    longitude: hardware.longitude,
                                    lastUpdate: hardware.lastUpdate,
                                }
                            )
                        }
                    }
                    else {
                        hardwareInfo.push({...currentPartHardwareState})
                    }

                    const refP = parts.findIndex(p => { return participant.id == p.id })
                    if (refP > -1) {
                        // Verify nothing else has modified state between sending the request and receiving the response
                        // Typically results from duplicate requests going out. But we don't want to save and render duplicates
                        // If the index is zero, we will accept the response as a replacement.
                        // This is the same index we sent out and expected back so we will append the response polygons
                        if (parts[refP].geoJson && (participant.index == parts[refP].geoJson.features.length)) {
                            participant.geoJson.features = parts[refP].geoJson.features.concat(participant.geoJson.features)
                        }
                        // Not our expected response or a complete set. Keep current geoJson.
                        else if (participant.index != 0) {
                            participant.geoJson = parts[refP].geoJson
                        }
                        parts[refP] = {...participant}
                    }
                    else {
                        parts.push({...participant})
                    }
                }
                this.setState({
                    participants: parts,
                    bbox: json.bbox,
                    hardwareInfo: hardwareInfo,
                })
            })
            .then(r => {
                window.setTimeout(this.updateJobState, 1500)

                return r
            })
            .catch(err => {
                // Something happened server side, call jobJoin again to make sure we are
                // connecting to the right endpoint and have authorization
                this.jobJoin()
                errorCheck(err.response)
                errorCatch(err)
            })
    }

    load = () => {
        this.props.loadJobDetailsByUuid(this.props.jobUuid).then(
            jobDetails => {
                if (!jobDetails.error) {
                    const jobData = {
                        ...jobDetails,
                        ...jobDetails.metadata,
                    }
                    this.loadAssociatedFiles(jobData.id)
                    this.setState(jobData)
                }
                else {
                    this.setState({error: jobDetails.message})
                }
            }
        )
    }
    loadAssociatedFiles = (jobId) => {
        this.props.loadWorkOrderAssociatedFiles(jobId).then(
            woFiles => {
                if (!woFiles.error && Array.isArray(woFiles)) {
                    const fileData = woFiles.map((file) => {
                        const fileType = this.props.fileTypes.find(f => f.id == file.fileTypeId)

                        return {
                            ...file,
                            typeName: fileType ? fileType.typeName : 'Unknown',
                            hasDetailView: fileType ? fileType.hasDetailView : false,
                            requiresConversion: fileType ? fileType.requiresConversion : false,
                        }
                    })
                    this.setState({
                        associatedFiles: fileData,
                    })
                }
            }
        )
    }
    render() {
        // get names of participants without a position
        const noPosition = this.state.participants.filter(p => !p.position).map(p => p.name)

        return (
            <div>
                <h3 className="liveViewHeader">{this.state.name}</h3>
                <div className="liveViewLayout">
                    <NoCoverageWarning
                        coverage = {!this.state.participants.length || this.state.participants.every(p => p.position)}
                        name={noPosition}/>
                    <LiveJobSideBar
                        participants={this.state.participants}
                        products={this.state.products}
                        files={this.state.associatedFiles}
                    />
                    <LiveJobMap
                        participants={this.state.participants}
                        bbox={this.state.bbox}
                        hardwareInfo={this.state.hardwareInfo}
                    />
                </div>
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LiveView)
