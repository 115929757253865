import {errorCatch, errorCheck} from '../lib/actionErrorHandler'

import axios from '../lib/axios'

export function fetchDispatchSystems() {
    return dispatch => {
        dispatch({
            type: 'REQUEST_DISPATCH_SYSTEMS',
        })

        return axios.get('/jobs/api/systems/dispatch-capable')
            .then(response => response.data)
            .then(json => {
                if (!Array.isArray(json)) { json = [] }
                dispatch({
                    type: 'RECEIVE_DISPATCH_SYSTEMS',
                    dispatchSystems: json,
                })
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch({
                    type: 'RECEIVE_DISPATCH_SYSTEMS',
                    dispatchSystems: [],
                })
            })
    }
}
