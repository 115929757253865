import RaisedButton from 'material-ui/RaisedButton'
import React from 'react'
import {buildURLBase, getDefaultFilterState} from './queryString'
import {setJobListFilter} from './workOrderActions'
import {useDispatch, useSelector} from 'react-redux'

const ResetFilters = () => {
    const systemStore = useSelector(store => store.system)
    const {selectedCompanyId} = systemStore
    const dispatch = useDispatch()

    const resetFilters = () => {
        const url = buildURLBase()
        window.history.pushState({path: url}, '', url)
        const defaultState = getDefaultFilterState()
        defaultState.selectedCompanyId = selectedCompanyId
        dispatch(setJobListFilter(defaultState, null))
    }

    return (
        <RaisedButton
            label="Reset Filters"
            onClick={resetFilters}
            primary={true}
            className="filterGroup"
        />
    )
}

export default ResetFilters
