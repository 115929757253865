import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import Pendo from './pendo'
import Routing from './App.routing'
import {Auth0Provider, useAuth0} from '@auth0/auth0-react'
import {CircularProgress} from '@atd/rui-base.mui.circular-progress'
import Cookies from 'universal-cookie'
import jwt from 'jsonwebtoken'
import {ApolloProvider, ApolloClient, createHttpLink, InMemoryCache} from '@apollo/client'
import {setContext} from '@apollo/client/link/context'
import redirect from './lib/redirect'
import {
    fetchAuth0FeatureFlag,
    authTokenFailure,
    authTokenPending,
    authTokenSuccess,
} from './auth0/auth0Actions'

const graphAPIURI = 'https://graphapi.raven.engineering/graphql'

// // Prod vs. Eng environment must be determined from URL as they are both built from the same Environment variables.
let trackJSEnv = ''
let isLocal = false
if (window.location.host.includes('local')) {
    trackJSEnv = 'development'
    isLocal = true
}
else if (window.location.host.includes('engineering')) {
    trackJSEnv = 'engineering'
}
else {
    trackJSEnv = 'production'
}

import {TrackJS} from 'trackjs'
TrackJS.install({token: '8b2c063932eb457fa3b49747058ab8b8', application: `wo-${trackJSEnv}`})

const Auth0Wrapper = ({audience}) => {
    const {isAuthenticated, loginWithRedirect, isLoading, error, getAccessTokenSilently, getIdTokenClaims} = useAuth0()
    const dispatch = useDispatch()
    const auth0Store = useSelector(store => store.auth0)
    const { isFetching, error: isTokenFetchError, authTokens } = auth0Store.authTokenReducer

    useEffect(() => {
        const getAccessToken = async() => {
            try {
                dispatch(authTokenPending())
                const accessToken = await getAccessTokenSilently({
                    audience,
                })

                const idToken = await getIdTokenClaims({
                    audience,
                })

                const graphAPIAuthToken = await getAccessTokenSilently({
                    audience: 'https://graphapi.raven.engineering',
                })

                dispatch(authTokenSuccess(accessToken, idToken.__raw, graphAPIAuthToken))
            }
            catch (e) {
                dispatch(authTokenFailure())
            }
        }

        if (isAuthenticated) {
            getAccessToken()
        }
    }, [isAuthenticated])

    if (isLoading || (isFetching && isAuthenticated)) return <CircularProgress sx={{marginLeft: '50%', marginTop: '25%'}}/>
    if (error || isTokenFetchError) return <div>Error: Something has gone wrong, please try again later.</div>
    if (!isAuthenticated) {
        localStorage.setItem('callback_path', window.location.pathname)

        loginWithRedirect()

        return <div>Redirecting to login...</div>
    }

    const {graphAPIAuthToken} = authTokens

    const httpLink = createHttpLink({
        uri: graphAPIURI,
    })

    const authLink = setContext(async(_, {headers}) => ({
        headers: {
            ...headers,
            jwt: graphAPIAuthToken,
        },
    }))

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
    })

    return <ApolloProvider client={client}>
        {!isLocal && <Pendo />}
        <Routing />
    </ApolloProvider>
}

export default function App() {
    const cookies = new Cookies()
    const scid = cookies.get('scid')
    if (scid) {
        TrackJS.addMetadata('selectedCompanyId', scid)
    }

    const dispatch = useDispatch()
    const auth0Store = useSelector(store => store.auth0)
    const { isFetching, isAuth0Enabled } = auth0Store.authTypeReducer

    useEffect(() => {
        dispatch(fetchAuth0FeatureFlag())
    }, [])

    if (isFetching) return <CircularProgress sx={{marginLeft: '50%', marginTop: '25%'}}/>
    else if (isAuth0Enabled) {
        const host = window.location.hostname
        const isProd = host.includes('apps.ravenslingshot')
        const domain = isProd ? 'auth.ravenslingshot.com' : 'auth.raven.engineering'
        const clientId = isProd ? 'JZTFc4v36GMkkLx7chh43dhWyHSHJLjf' : 'BCHaOy0cMx8pqoz4gmrKxPc6VCqQTRoq'
        const audience = isProd ? 'https://apps.ravenslingshot.com' : 'https://apps.raven.engineering'
        let callbackURL = 'https://local.ravenslingshot.com:3000/jobs/callback'

        if (isProd) callbackURL = 'https://apps.ravenslingshot.com/jobs/callback'
        else if (host.includes('raven.engineering')) callbackURL = 'https://apps.raven.engineering/jobs/callback'

        return <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={callbackURL}
            audience={audience}>
            <Auth0Wrapper audience={audience}/>
        </Auth0Provider>
    }

    const userJwt = cookies.get('jwt')
    if (!userJwt) {
        redirect.toPortal()

        return <div>Redirecting to login...</div>
    }

    TrackJS.addMetadata('jwt', jwt.decode(userJwt))

    const client = new ApolloClient({
        uri: graphAPIURI,
        cache: new InMemoryCache(),
    })

    return <ApolloProvider client={client}>
        {!isLocal && <Pendo />}
        <Routing />
    </ApolloProvider>
}
