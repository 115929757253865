import PropTypes from 'prop-types'
import React from 'react'
import WarningIcon from 'material-ui/svg-icons/alert/warning'
import { red500 } from 'material-ui/styles/colors'

class WarningLabel extends React.Component {
    static propTypes = {
        message: PropTypes.string,
    }
    static defaultProps = {
        message: 'Something went wrong',
    }

    render() {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <WarningIcon color={red500} />
                <p
                    style={{color: red500}}>
                    {this.props.message}
                </p>
            </div>
        )
    }
}

export default WarningLabel
