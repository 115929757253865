import React from 'react'
import { useParams } from 'react-router-dom'
import JobCreate from './jobCreate'

const styles = {
    container: {
        paddingTop: 5,
        paddingLeft: 5,
        paddingRight: 5,
    },
}

const AppJobCreate = () => {
    const urlParams = useParams()

    return (
        <div className="workorder-wrapper">
            <div style={styles.container}>
                <JobCreate existingWorkOrderId={urlParams.id}/>
            </div>
        </div>
    )
}

export default AppJobCreate
