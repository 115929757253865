import React from 'react'
import ContentLoader from 'react-content-loader'
import {Card} from 'material-ui/Card'

const GrowerCardLoader = (props) => (
    <Card>
        <ContentLoader
            speed={2}
            width={'100%'}
            height={113.6}
            viewBox="0 0 100% 113.6"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
            <rect x="16" y="20" rx="3" ry="3" width="45%" height="15" />
            <rect x="16" y="44" rx="3" ry="3" width="30%" height="15" />
            <rect x="16" y="86" rx="3" ry="3" width="50%" height="15" />
        </ContentLoader>
    </Card>
)

export default GrowerCardLoader
