import React from 'react'

import IconButton from 'material-ui/IconButton'
import TextField from 'material-ui/TextField'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton'

import InfoIcon from 'material-ui/svg-icons/action/info'

import { getMenuItemsForProductDDI } from '../commonComponents/unitMenuItems'

class ProductDetails extends React.Component {
    handleInfo = () => {
        this.props.mixReviewRequest(this.props.data.id)
    }

    getRateLabel = () => {
        if (this.props.data.mode === 'targetRate') { return 'Target Rate' }
        else if (this.props.data.mode === 'rxMap') { return 'Default Rate' }
    }
    getRxSection = (rateUnits) => {
        if (this.props.data.mode !== 'rxMap') { return }

        return (
            <div>
                <TextField
                    className="selectField"
                    value={this.props.data.rxMapName || this.props.data.rxMapId}
                    floatingLabelText="Rx Map"
                    disabled={this.props.disabled}
                    name="rxMapSelect"
                >
                </TextField>
                <TextField
                    className="selectField"
                    floatingLabelText="Map Column"
                    floatingLabelFixed={true}
                    value={this.props.data.rxMapColumnId}
                    disabled={this.props.disabled}
                    name="rxMapColumn"
                />
                <TextField
                    className="selectField"
                    floatingLabelText="Map Unit"
                    value={this.props.data.rxMapUnit}
                    disabled={this.props.disabled}
                    name="rxMapUnit"
                />
            </div>
        )
    }
    renderRateInfo = () => {
        const rateUnits = getMenuItemsForProductDDI(this.props.data.ddiId)
        const rateLabel = this.getRateLabel()
        const rxSection = this.getRxSection(rateUnits)

        return (
            <div className="rates" name="rateInfo">
                {rxSection}
                <TextField
                    className="textField"
                    floatingLabelText={rateLabel}
                    defaultValue={this.props.data.displayRate}
                    disabled={this.props.disabled}
                    name="displayRate"
                />
                <TextField
                    className="selectField"
                    value={this.props.data.userDisplayUnit}
                    disabled={this.props.disabled}
                    name="rateUnits"
                />
            </div>
        )

    }

    renderRateMode = () => {
        return (
            <div className="radioButtonGroup" name="rateRadioButtons">
                <label>Rate:</label>
                <RadioButtonGroup
                    name="rateMode"
                    valueSelected={this.props.data.mode}
                >
                    <RadioButton
                        className="radioButton"
                        value="targetRate"
                        disabled={this.props.disabled}
                        checkedIcon={
                            <img className="clickedIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_Target.svg`}/>
                        }
                        uncheckedIcon={
                            <img className="clickableIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_Target.svg`}/>
                        }
                        name="targetRateButton"
                    />
                    <RadioButton
                        className="radioButton"
                        value="rxMap"
                        disabled={this.props.disabled}
                        checkedIcon={
                            <img className="clickedIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_Rx.svg`}/>
                        }
                        uncheckedIcon={
                            <img className="clickableIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_Rx.svg`}/>
                        }
                        name="rxMapButton"
                    />
                </RadioButtonGroup>
            </div>
        )
    }

    render() {
        const rateEntry = this.renderRateInfo()
        const rateMode = this.renderRateMode()

        return (
            <div style={{borderBottom: 'solid'}}>
                <TextField
                    className="mixSelectField"
                    value={this.props.data.mix.name}
                    style={{marginTop: '10px'}}
                    disabled={this.props.disabled}
                    name={`Mix:${this.props.data.mix.guid}`}
                />
                <IconButton
                    className="mixInfoButton_review"
                    name="mixInfoButton"
                    onClick={this.handleInfo}
                >
                    <InfoIcon/>
                </IconButton>
                <div className="rowFlex">
                    {rateMode}
                    <div style={{ display: 'inline-block', width: '10%' }} />
                    {rateEntry}
                </div>
            </div>
        )
    }
}

export default (ProductDetails)
