import update from 'immutability-helper'
import { combineReducers } from 'redux'
import {
    AUTH0_TOKEN_FAILURE,
    AUTH0_TOKEN_PENDING,
    AUTH0_TOKEN_SUCCESS,
    AUTH0_FF_PENDING,
    AUTH0_FF_DONE,
} from './auth0Constants'

const authTokenState = {
    isFetching: true,
    authTokens: {},
    error: false,
}

function authTokenReducer(state = authTokenState, action) {
    switch (action.type) {
        case AUTH0_TOKEN_PENDING:
            return update(state, {
                isFetching: { $set: true },
            })
        case AUTH0_TOKEN_SUCCESS:
            return update(state, {
                isFetching: { $set: false },
                authTokens: { $set: action.payload },
            })
        case AUTH0_TOKEN_FAILURE:
            return update(state, {
                isFetching: { $set: false },
                error: { $set: true },
            })
        default:
            return state
    }
}

const authTypeState = {
    isFetching: true,
    isAuth0Enabled: false,
}

function authTypeReducer(state = authTypeState, action) {
    switch (action.type) {
        case AUTH0_FF_PENDING:
            return update(state, {
                isFetching: { $set: true },
            })
        case AUTH0_FF_DONE:
            return update(state, {
                isFetching: { $set: false},
                isAuth0Enabled: { $set: action.payload },
            })
        default:
            return state
    }
}

const auth0Reducer = combineReducers({
    authTokenReducer,
    authTypeReducer,
})

export default auth0Reducer
