import React, { useEffect, useState } from 'react'

import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import FloatingActionButton from 'material-ui/FloatingActionButton'
import { Tab, Tabs } from 'material-ui/Tabs'
import AddIcon from 'material-ui/svg-icons/content/add'

import JobCard from './jobCard'
import JobCardLoader from './jobCardLoader'
import JobListFilter from './jobListFilter'
import JobListSummary from './jobListSummary'
import JobListEmpty from './jobListEmpty'
import ListPaging from '../commonComponents/listPaging'
import {fetchWorkOrders, setWorkOrderArchived } from './workOrderActions'
import './jobListStyles.css'
import RaisedButton from 'material-ui/RaisedButton/RaisedButton'
import { useDispatch, useSelector } from 'react-redux'

const JobList = () => {
    const dispatch = useDispatch()
    const [archiveDialogOpen, setArchiveDialogOpen] = useState(false)
    const [filteredCards, setFilteredCards] = useState([])
    const [viewList, setViewList] = useState([])
    const [viewMode, setViewMode] = useState('list')
    const [isLoaded, setIsLoaded] = useState(false)

    const workOrderStore = useSelector(store => store.workOrder)
    const {filteredJobs, jobListFilter: filterOptions, isFetching} = workOrderStore
    const unarchivedJobCount = filteredJobs.filter(j => !j.archived).length

    const createCard = (data, i) => {
        return <JobCard key={data.guid} guid={data.guid} />
    }

    const onViewListChanged = (newItems) => {
        setViewList([...newItems])
        setIsLoaded(true)
    }

    const archiveAllJobs = () => {
        for (const job of filteredJobs) {
            if (!job.archived) {
                dispatch(setWorkOrderArchived(job.id, true))
            }
        }
        setArchiveDialogOpen(false)
    }

    const handleArchiveAllRequest = () => {
        setArchiveDialogOpen(true)
    }

    const handleCancel = () => {
        setArchiveDialogOpen(false)
    }

    const renderArchiveDialog = () => {
        if (!archiveDialogOpen) { return }
        const archiveActions = [
            <FlatButton
                label="Cancel"
                onClick={handleCancel}
                name="cancelArchive"
                primary={true}
            />,
            <FlatButton
                label="Archive"
                icon={<img className="standardIcon" src={`${process.env.PUBLIC_URL}/images/jobCard/JobCard_BtnArchiveJob.svg`}/>}
                primary={true}
                onClick={archiveAllJobs}
                name="confirmArchive"
            />,
        ]

        return (
            <Dialog
                actions={archiveActions}
                modal={false}
                open={true}
                onRequestClose={handleCancel}
            >
                <p>Do you wish to archive available jobs?</p>
                <p>Filtered Total: {unarchivedJobCount}</p>
            </Dialog>
        )
    }

    const renderPageHeader = () => {
        return (
            <div className="jobListLayout">
                <Tabs
                    style={{flexGrow: 2}}
                    name="viewModeToggle"
                    value={viewMode}
                    onChange={(mode) => setViewMode(mode)}
                >
                    <Tab
                        value="list"
                        label="List"
                        buttonStyle={viewMode === 'list' ? {} : {backgroundColor: 'var(--secondary)'}}
                        name="listViewButton"
                    />
                    <Tab
                        value="summary"
                        label="Summary"
                        buttonStyle={viewMode === 'summary' ? {} : {backgroundColor: 'var(--secondary)'}}
                        name="summaryViewButton"
                    />
                </Tabs>
                <span name="searchPlaceholder" style={{flexGrow: 1}}/>
                <RaisedButton
                    label={`Archive (${unarchivedJobCount})`}
                    primary={true}
                    icon={<img className="filterOption" src={`${process.env.PUBLIC_URL}/images/jobCard/JobCard_BtnArchiveJob${unarchivedJobCount ? 'White' : 'Disabled'}.svg`}/>}
                    onClick={handleArchiveAllRequest}
                    className="archiveAll desktopOnly"
                    disabled={!unarchivedJobCount}
                />
            </div>
        )
    }

    const renderBody = () => {
        if (viewMode == 'list') {
            return (
                <div style={{width: '100%'}}>
                    <div className="jobList" name="jobList">
                        {!isLoaded && !filterOptions.error.value && Array(8).fill().map((item, index) => (<JobCardLoader key={index}/>))}
                        {!isFetching && !filterOptions.error.value && isLoaded && filteredJobs.length > 0 && viewList}
                        {!isFetching && isLoaded && (filteredJobs.length === 0 || filterOptions.error.value) && <JobListEmpty error={filterOptions.error}/>}
                    </div>
                    <ListPaging
                        itemList={filteredCards}
                        viewListChanged={onViewListChanged}
                        countPerPage={20}
                    />
                </div>
            )
        }
        else if (viewMode == 'summary') {
            return (
                <div style={{width: '100%', margin: '15px 0px'}}>
                    <h2 className="desktopOnly">Summary</h2>
                    <JobListSummary/>
                </div>

            )
        }
    }

    useEffect(() => {
        if (Object.keys(filterOptions).length > 0) {
            dispatch(fetchWorkOrders(filterOptions))
        }
    }, [filterOptions])

    useEffect(() => {
        if (isFetching) {
            setIsLoaded(false)
        }
    }, [isFetching])

    useEffect(() => {
        if (!isFetching) {
            setFilteredCards(filteredJobs.map(createCard))
        }
    }, [filteredJobs])

    useEffect(() => {
        const jobInterval = setInterval(() => dispatch(fetchWorkOrders(filterOptions)), 60000)

        return () => clearInterval(jobInterval)
    }, [filterOptions])

    return (
        <div>
            <div className="jobListLayout">
                <JobListFilter />
                <div style={{width: '100%'}}>
                    {renderPageHeader()}
                    {renderBody()}
                </div>
            </div>
            <FloatingActionButton
                className="floatingArchiveMobile"
                onClick={handleArchiveAllRequest}
                disabled={!unarchivedJobCount}
                style={unarchivedJobCount ? {} : {display: 'none'}}
                mini={true}
            >
                <img className={'mobileArchiveIcon'} src={`${process.env.PUBLIC_URL}/images/jobCard/JobCard_BtnArchiveJob${unarchivedJobCount ? 'White' : 'Disabled'}.svg`}/>
            </FloatingActionButton>
            <FloatingActionButton
                className="floatingAddMobile"
                href={'/jobs/create'}
                mini={true}
            >
                <AddIcon/>
            </FloatingActionButton>
            {renderArchiveDialog()}
        </div>
    )
}

export default JobList
