const units = require('./units')
const ddiConstants = require('./ddiConstants')

// shorthand the known unit sets
const volumeUnits = units.volumeUnits
const volumePerAreaUnits = units.volumePerAreaUnits
const massUnits = units.massUnits
const massPerAreaUnits = units.massPerAreaUnits
const countUnits = units.countUnits
const countPerAreaUnits = units.countPerAreaUnits
const areaUnits = units.areaUnits
const lengthUnits = units.lengthUnits

const ISO_LONG_MAX = 2147483647
const ISO_UNSIGNED_LONG_MAX = 4294967294
// make a singe group of all units
let combinedUnits = []
for (const unitSet of Object.values(units)) {
    combinedUnits = combinedUnits.concat(unitSet)
}

function areaFromRateUnit(rateUnitName) {
    // find the quantity per area seperator and retrieve just the area name
    // trimming any whitespaces from the ends before looking up the unit object
    // return early if they didn't give us a rate unit
    if (!isRateUnit(rateUnitName)) { return }
    const slashIndex = rateUnitName.indexOf('/') + 1

    let areaName = rateUnitName.slice(slashIndex)
    // handle known edge case where rates are determined per 1000ft2, but the area label is only 'ft2'
    if (areaName.includes('1000')) {
        areaName = areaName.slice(areaName.indexOf('1000') + 4)
    }
    areaName = areaName.trim()

    return findUnitByName(areaName, areaUnits)
}

function isRateUnit(unitName) {
    if (!unitName || unitName.length === 1) { return false }
    const slashIndex = unitName.indexOf('/')

    return ((slashIndex >= 0) && ((slashIndex + 1) != unitName.length))
}

function getBaseUnitFromDdi(ddiId) {
    return this.getUnitSetFromDDI(ddiId).find(unit => unit.base === true)
}

function getUnitEquivalentForDDI(oldUnitAbbreviation, ddiId) {
    const unitSet = getUnitSetFromDDI(ddiId)
    let unitObject = findUnitByName(oldUnitAbbreviation)
    if (unitObject) {
        unitObject = getMatchFromSet(unitObject, unitSet)

        return unitObject.abbreviation
    }

    return oldUnitAbbreviation
}

function getUnitSetFromDDI(ddiId) {
    let unitSet = []
    switch (ddiId) {
        case ddiConstants.ACTUAL_VOLUME_CONTENT_DDI:
            unitSet = volumeUnits
            break
        case ddiConstants.ACTUAL_MASS_CONTENT_DDI:
            unitSet = massUnits
            break
        case ddiConstants.ACTUAL_COUNT_CONTENT_DDI:
            unitSet = countUnits
            break
        case ddiConstants.SETPOINT_VOLUME_PER_AREA_DDI:
        case ddiConstants.DEFAULT_VOLUME_PER_AREA_DDI:
            unitSet = volumePerAreaUnits
            break
        case ddiConstants.SETPOINT_MASS_PER_AREA_DDI:
        case ddiConstants.DEFAULT_MASS_PER_AREA_DDI:
            unitSet = massPerAreaUnits
            break
        case ddiConstants.SETPOINT_COUNT_PER_AREA_DDI:
        case ddiConstants.DEFAULT_COUNT_PER_AREA_DDI:
            unitSet = countPerAreaUnits
            break
        case ddiConstants.TOTAL_AREA_DDI:
            unitSet = areaUnits
            break
        default:
            unitSet = combinedUnits
            break
    }

    return unitSet
}

function ddiFromUnit(unitAbbreviation) {
    const unitObject = findUnitByName(unitAbbreviation)
    if (!unitObject) { return }

    if (volumeUnits.includes(unitObject)) {
        return ddiConstants.ACTUAL_VOLUME_CONTENT_DDI
    }
    else if (volumePerAreaUnits.includes(unitObject)) {
        return ddiConstants.SETPOINT_VOLUME_PER_AREA_DDI
    }
    else if (massUnits.includes(unitObject)) {
        return ddiConstants.ACTUAL_MASS_CONTENT_DDI
    }
    else if (massPerAreaUnits.includes(unitObject)) {
        return ddiConstants.SETPOINT_MASS_PER_AREA_DDI
    }
    else if (countUnits.includes(unitObject)) {
        return ddiConstants.ACTUAL_COUNT_CONTENT_DDI
    }
    else if (countPerAreaUnits.includes(unitObject)) {
        return ddiConstants.SETPOINT_COUNT_PER_AREA_DDI
    }
    else if (areaUnits.includes(unitObject)) {
        return ddiConstants.TOTAL_AREA_DDI
    }
    else if (lengthUnits.includes(unitObject)) {
        return ddiConstants.TOTAL_AREA_DDI
    }
}


function ddiFromMixState(entryMode, userDisplayUnit, carrier = null) {
    if (carrier && entryMode == 'rateMode') {
        return ddiConstants.SETPOINT_VOLUME_PER_AREA_DDI
    }
    else if (carrier && entryMode == 'qtyMode') {
        return ddiConstants.ACTUAL_VOLUME_CONTENT_DDI
    }

    return ddiFromUnit(userDisplayUnit)
}

function findUnitByName(name, unitSet = combinedUnits) {
    for (const unit of unitSet) {
        if (unit.abbreviation === name || unit.text === name) {
            return unit
        }
    }
}

function getMatchFromSet(oldUnit, unitSet) {
    for (const unit of unitSet) {
        if (unit.system === oldUnit.system && unit.size === oldUnit.size) {
            return unit
        }
    }
    for (const unit of unitSet) {
        if (unit.system === oldUnit.system) {
            return unit
        }
    }

    for (const unit of unitSet) {
        if (unit.base) {
            return unit
        }
    }

    return unitSet[0]
}

function convertToBase(value, currentUnit) {
    if (!value) return 0
    if (isNaN(value)) return 0
    const unit = findUnitByName(currentUnit)
    if (!unit) return 0

    return value * unit.fromDisplayToBase
}

function convertToDisplay(value, displayUnit) {
    if (!value) return 0
    if (isNaN(value)) return 0
    const unit = findUnitByName(displayUnit)
    if (!unit) return 0

    return value * unit.fromBaseToDisplay
}

function checkProductLimits(value, displayUnit, allowZero = false) {
    const minValue = convertToDisplay(1, displayUnit)
    const maxValue = convertToDisplay(ISO_LONG_MAX, displayUnit)
    let errorText = ''
    if (value == 0) {
        if (!allowZero) {
            errorText = 'Required Field'
        }
    }
    else if (isNaN(value)) {
        errorText = 'Invalid Number'
    }
    else if (value < minValue) {
        errorText = `Min Value: ${minValue} ${displayUnit}`
    }
    else if (value > maxValue) {
        errorText = `Max Value: ${maxValue} ${displayUnit}`
    }

    return errorText
}

function checkFieldLimits(value, displayUnit) {
    const minValue = convertToDisplay(1000, displayUnit)
    const maxValue = convertToDisplay(ISO_UNSIGNED_LONG_MAX, displayUnit)
    let errorText = ''
    if (value == 0) {
        errorText = 'Required Field'
    }
    else if (isNaN(value)) {
        errorText = 'Invalid Number'
    }
    else if (value < minValue) {
        errorText = `Min Value: ${minValue} ${displayUnit}`
    }
    else if (value > maxValue) {
        errorText = `Max Value: ${maxValue} ${displayUnit}`
    }

    return errorText
}

module.exports = {
    areaFromRateUnit: areaFromRateUnit,
    isRateUnit: isRateUnit,
    convertToBase: convertToBase,
    convertToDisplay: convertToDisplay,
    ddiFromMixState: ddiFromMixState,
    ddiFromUnit: ddiFromUnit,
    getBaseUnitFromDdi: getBaseUnitFromDdi,
    getUnitSetFromDDI: getUnitSetFromDDI,
    getUnitEquivalentForDDI: getUnitEquivalentForDDI,
    findUnitByName: findUnitByName,
    checkProductLimits: checkProductLimits,
    checkFieldLimits: checkFieldLimits,
}
