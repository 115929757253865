import React from 'react'
import MixReview from './mixReview'
import ProductDetails from './productDetails'

class RateReview extends React.Component {
    state = {
        mixReviewOpen: false,
        tempMix: {},
    }

    handleMixReviewRequest = id => {
        const product = this.props.products.find(element => {
            return element.id === id
        })

        this.setState({
            tempMix: product.mix,
            mixReviewOpen: true,
        })
    }

    handleEditCancel = () => {
        this.setState({
            mixReviewOpen: false,
            tempMix: {},
        })
    }
    renderProducts = () => {
        if (!this.props.products || !this.props.products.length) {
            return <label>No Products</label>
        }

        return this.props.products.map((product, i) => {
            return (
                <ProductDetails
                    disabled={true}
                    data={product}
                    key={`${product.id}-${i}`}
                    mixReviewRequest={this.handleMixReviewRequest}
                />
            )
        })
    }
    mixDialog = () => {
        if (this.state.mixReviewOpen) {
            return (
                <MixReview
                    data={this.state.tempMix}
                    open={true}
                    onClose={this.handleEditCancel}
                    disabled={true}
                />
            )
        }
    }

    render() {
        const productList = this.renderProducts()
        const mixReviewDialog = this.mixDialog()

        return (
            <div style={{ paddingTop: 10 }} name="rateSetup">
                {productList}
                <div style={{ paddingTop: 10 }} />
                {mixReviewDialog}
            </div>
        )
    }
}
// be sure to connect the component to the Redux state so it can use it!
export default (RateReview)
