import './jobCardStyles.css'

import {Card, CardHeader, CardText} from 'material-ui/Card'
import React, { useEffect, useState } from 'react'
import { errorCatch, errorCheck } from '../lib/actionErrorHandler'

import CloseIcon from 'material-ui/svg-icons/content/add-box'
import OpenIcon from 'material-ui/svg-icons/toggle/indeterminate-check-box'
import RaisedButton from 'material-ui/RaisedButton'
import SelectField from 'material-ui/SelectField'
import { areaMenuItems } from '../commonComponents/unitMenuItems'
import axios from '../lib/axios'
import { convertToDisplay } from '../lib/ddiUnits'
import { useSelector } from 'react-redux'
import { addDays } from 'date-fns'

const JobListSummary = ({name = '', guid = ''}) => {

    const workOrderStore = useSelector(store => store.workOrder)
    const hardwareStore = useSelector(store => store.hardware)
    const gffStore = useSelector(store => store.gff)

    const {filteredJobs: jobs = [], jobListFilter: filters} = workOrderStore
    const growerIds = jobs.map(job => job.growerId)
    const systemIds = []

    for (const job of jobs) {
        for (const participant of job.participants) {
            systemIds.push(participant.systemId)
        }
    }

    const {dispatchSystems: systemList = []} = hardwareStore
    const growers = gffStore.growers.filter(g => growerIds.includes(g.id))
    const systems = systemList.filter(s => systemIds.includes(s.id))

    const [areaLabel, setAreaLabel] = useState('ac')
    const [loading, setLoading] = useState(false)
    const [jobRads, setJobRads] = useState([])

    const getJobRads = async() => {
        // Get SystemId from "System"(hardwareId)
        setLoading(true)
        let systemIds = []
        let partStatus = null
        let jobArchived = null
        let startDate = filters.startDate
        let endDate = filters.endDate

        if (filters.selectedSystems && filters.selectedSystems.length > 0) {
            systemIds = filters.selectedSystems.map(systemId => {
                return systems.find(system => system.id == systemId).id
            })
        }

        if (!startDate) {
            startDate = new Date(0)
        }
        if (!endDate) {
            endDate = addDays(new Date(), 1)
        }

        switch (filters.selectedParticipantActivity) {
            case 'joined':
                partStatus = 'InProgress'
                break
            case 'left':
                partStatus = 'Inactive'
                break
            default:
                partStatus = null
        }

        switch (filters.selectedAvailability) {
            case 'archived':
                jobArchived = true
                break
            case 'not archived':
                jobArchived = false
                break
            default:
                jobArchived = null

        }

        try {
            const response = await axios.post('/jobs/net-api/jobs/summary', {
                farmIds: filters.selectedFarms,
                fieldIds: filters.selectedFields,
                growerIds: filters.selectedGrowers,
                systemIds: systemIds,
                jobGuids: jobs.map(j => j.guid),
                startDate: startDate,
                endDate: endDate,
                participantStatus: partStatus,
                jobArchived: jobArchived,
            })

            const rads = response.data

            // Since we're iterating the currrent workorderlist; in the event we receive
            // a job from .Net that was not in our current job-list it will be skipped
            // When the job list updates (on it's interval) and the report is re-ran
            // it will be accurate
            setLoading(false)
            setJobRads(rads.jobs || [])
        }
        catch (e) {
            errorCheck(e.response)
            errorCatch(e)
        }
    }

    const generateCSV = () => {
        let csv = 'JobGuid,JobName,JobUsedRxMap,JobTotalArea,JobTotalAreaUnit,JobArchived,ParticipantSystemId,ParticipantSystemName,ParticipantStatus,ParticipantTotalArea,AreaUnit\n'
        for (const rad of jobRads) {
            for (const part of rad.participants) {
                csv += [
                    rad.jobGuid,
                    jobs.find(j => j.guid == rad.jobGuid).name,
                    rad.rxMapUsed,
                    convertToDisplay(rad.totalArea, areaLabel).toFixed(2),
                    areaLabel,
                    rad.jobArchived,
                    part.systemID,
                    part.systemName,
                    part.status,
                    convertToDisplay(part.area, areaLabel).toFixed(2),
                    areaLabel + '\n',
                ].join(',')
            }
        }

        let { startDate, endDate } = filters
        if (!startDate) {
            startDate = new Date(0)
        }
        if (!endDate) {
            endDate = addDays(new Date(), 1)
        }

        const download = document.createElement('a')
        const file = new Blob([csv], {type: 'text/plain'})
        download.href = URL.createObjectURL(file)
        download.download = `jobs${startDate.toISOString().substring(0, 10)}-${endDate.toISOString().substring(0, 10)}.csv`
        download.click()
    }

    const renderOverall = () => {
        let totalArea = 0
        let rxArea = 0
        let straightArea = 0

        for (const job of jobs) {
            const rad = jobRads.find(r => r.jobGuid == job.guid)
            if (!rad) { continue }

            totalArea += rad.totalArea
            if (rad.rxMapUsed) {
                rxArea += rad.totalArea
            }
            else {
                straightArea += rad.totalArea
            }
        }

        return (
            <table className="summaryCardTable">
                <tbody>
                    <tr style={{backgroundColor: 'var(--foreground-light)'}} key={`overall-total`}>
                        <td className="leftEdge">{`Total Area`}</td>
                        <td/>
                        <td className="rightEdge">{ `${convertToDisplay(totalArea, areaLabel).toFixed(2)} ${areaLabel}`}</td>
                    </tr>
                    <tr key={`overall-rx`}>
                        <td> </td>
                        <td>{`Rx Map`}</td>
                        <td className="rightEdge">{ `${convertToDisplay(rxArea, areaLabel).toFixed(2)} ${areaLabel}`}</td>
                    </tr>
                    <tr key={`$overall-straightRate`}>
                        <td> </td>
                        <td>{`Non-Rx`}</td>
                        <td className="rightEdge">{ `${convertToDisplay(straightArea, areaLabel).toFixed(2)} ${areaLabel}`}</td>
                    </tr>
                </tbody>
            </table>
        )
    }

    const renderGrowerLine = (grower) => {
        let totalArea = 0
        let rxArea = 0
        let straightArea = 0
        for (const job of jobs) {
            const rad = jobRads.find(r => r.jobGuid == job.guid)
            if (!rad) { continue }

            if (job.growerId == grower.id) {
                totalArea += rad.totalArea
                if (rad.rxMapUsed) {
                    rxArea += rad.totalArea
                }
                else {
                    straightArea += rad.totalArea
                }
            }
        }

        return ([
            <tr style={{backgroundColor: 'var(--foreground-light)'}} key={`${grower.id}-total`}>
                <td className="leftEdge">{grower.name || 'N/A'}</td>
                <td/>
                <td className="rightEdge">{ `${convertToDisplay(totalArea, areaLabel).toFixed(2)} ${areaLabel}`}</td>
            </tr>,
            <tr key={`${grower.id}-rx`}>
                <td> </td>
                <td>{`Rx Map`}</td>
                <td className="rightEdge">{ `${convertToDisplay(rxArea, areaLabel).toFixed(2)} ${areaLabel}`}</td>
            </tr>,
            <tr key={`${grower.id}-straightRate`}>
                <td> </td>
                <td>{`Non-Rx`}</td>
                <td className="rightEdge">{ `${convertToDisplay(straightArea, areaLabel).toFixed(2)} ${areaLabel}`}</td>
            </tr>,
        ])
    }

    const renderGrowers = () => {
        let growersJSX
        const growerD = {}

        if (growers.length) {
            for (const j of jobs) {
                const rad = jobRads.find(r => r.jobGuid == j.guid)
                if (rad && rad.growerId) {
                    growerD[rad.growerId] = growers.find(g => g.id == rad.growerId)
                }
            }
        }

        const growersWithJobs = Object.values(growerD)

        if (growersWithJobs.length == 0) {
            growersJSX = <span>No Growers</span>
        }
        else {
            growersJSX = (
                <table className="summaryCardTable" >
                    <tbody>
                        {growersWithJobs.map(renderGrowerLine)}
                    </tbody>
                </table>
            )
        }

        return (
            <Card style={{marginBottom: 5}} initiallyExpanded={true}>
                <CardHeader
                    style={growers.length ? {backgroundColor: '#666666'} : {backgroundColor: '#EEEEEE'}}
                    title={`Area By Grower`}
                    titleColor={growers.length ? 'white' : 'black'}
                    actAsExpander={!!growers.length}
                    showExpandableButton={!!growers.length}
                    closeIcon={<CloseIcon color="white"/>}
                    openIcon={<OpenIcon color="white"/>}
                />
                <CardText expandable={true} style={{paddingLeft: 0, paddingRight: 0}}>
                    {growersJSX}
                </CardText>
            </Card>
        )
    }

    const renderSystemLine = (system, i) => {
        let totalArea = 0
        let rxArea = 0
        let straightArea = 0
        for (const job of jobs) {
            const rad = jobRads.find(r => r.jobGuid == job.guid)
            if (!rad || !rad.participants) { continue }

            for (const participant of rad.participants) {
                if (participant.systemID == system.id) {
                    totalArea += participant.area
                    if (rad.rxMapUsed) {
                        rxArea += participant.area
                    }
                    else {
                        straightArea += participant.area
                    }
                }
            }
        }

        return ([
            <tr style={{backgroundColor: 'var(--foreground-light)'}} key={`${system.id}-total`}>
                <td className="leftEdge">{system.name || system.id || 'N/A'}</td>
                <td/>
                <td className="rightEdge">{ `${convertToDisplay(totalArea, areaLabel).toFixed(2)} ${areaLabel}`}</td>
            </tr>,
            <tr key={`${system.id}-rx`}>
                <td> </td>
                <td>{`Rx Map`}</td>
                <td className="rightEdge">{ `${convertToDisplay(rxArea, areaLabel).toFixed(2)} ${areaLabel}`}</td>
            </tr>,
            <tr key={`${system.id}-straightRate`} style={{padding: '2px'}}>
                <td> </td>
                <td>{`Non-Rx`}</td>
                <td className="rightEdge">{ `${convertToDisplay(straightArea, areaLabel).toFixed(2)} ${areaLabel}`}</td>
            </tr>,
        ])
    }

    const renderSystems = () => {
        let systemsJSX

        const jobParticipants = {}
        for (const job of jobs) {
            const rad = jobRads.find(r => r.jobGuid == job.guid)
            if (!rad) { continue }

            for (const part of rad.participants) {
                jobParticipants[part.systemID] = {
                    id: part.systemID,
                    name: part.systemName,
                }
            }
        }
        const participants = Object.values(jobParticipants)

        if (!participants.length) {
            systemsJSX = <span>No Systems</span>
        }
        else {
            systemsJSX = (
                <table className="summaryCardTable">
                    <tbody>
                        {
                            participants.map(renderSystemLine)
                        }
                    </tbody>
                </table>
            )
        }

        return (
            <Card style={{marginBottom: 5}} initiallyExpanded={true}>
                <CardHeader
                    style={systems.length ? {backgroundColor: '#666666'} : {backgroundColor: '#EEEEEE'}}
                    title={`Area By System`}
                    titleColor={systems.length ? 'white' : 'black'}
                    actAsExpander={!!systems.length}
                    showExpandableButton={!!systems.length}
                    closeIcon={<CloseIcon color="white"/>}
                    openIcon={<OpenIcon color="white"/>}
                />
                <CardText expandable={true} style={{paddingLeft: 0, paddingRight: 0}}>
                    {systemsJSX}
                </CardText>
            </Card>
        )
    }

    useEffect(() => {
        getJobRads()
    }, [jobs, filters])

    return (
        <div name="jobSummaryArea" style={{width: '100%', maxWidth: '800px'}}>
            <h2 id="loading" style={{display: loading ? 'inherit' : 'none', color: 'red'}}>Loading Job Files...</h2>
            <SelectField
                labelStyle={{textAlign: 'right'}}
                menuItemStyle={{textAlign: 'right'}}
                floatingLabelText="Area Units"
                fullWidth={true}
                value={areaLabel}
                onChange={(event, key, areaLabel) => { setAreaLabel(areaLabel) }}
                name="areaDisplayUnit"
            >
                {areaMenuItems}
            </SelectField>
            {renderOverall()}
            {renderGrowers()}
            {renderSystems()}
            <br/>
            <RaisedButton
                disabled={loading || !jobRads.length }
                label="Download CSV"
                primary={true}
                onClick={generateCSV}
            />
        </div>
    )
}

export default JobListSummary
