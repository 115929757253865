import {BottomNavigation, BottomNavigationItem} from 'material-ui/BottomNavigation'
import {
    Table,
    TableBody,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table'

import React from 'react'
import { getHost } from '../lib/redirect'

class LiveViewSidebar extends React.Component {
    static defaultProps = {
        participants: [],
        products: [],
        files: [],
    }

    state = {
        mobileTab: '',
    }
    selectTab = (tab) => {
        if (this.state.mobileTab === tab) {
            this.setState({mobileTab: ''})
        }
        else {
            this.setState({mobileTab: tab})
        }
    }
    renderParticipants() {
        if (this.state.mobileTab !== 'participants') return

        return (
            <div>
                <div className="sectionHeader">
                    Participants
                </div>
                <Table selectable={false} className="liveViewTable">
                    <TableBody displayRowCheckbox={false} stripedRows={true}>
                        {this.renderParticipantRows()}
                    </TableBody>
                </Table>
            </div>
        )
    }
    renderParticipantRows() {

        if (!this.props.participants.length) {
            return <TableRow><TableRowColumn>None</TableRowColumn></TableRow>
        }

        return this.props.participants.map((part, i) => {
            return <TableRow key={part.id}>
                <TableRowColumn>{part.name}</TableRowColumn>
                <TableRowColumn className="participantStatus">{part.status}</TableRowColumn>
                <TableRowColumn><div style={{backgroundColor: part.color}} className="colorKey"/></TableRowColumn>
            </TableRow>
        })
    }

    renderProducts() {
        if (this.state.mobileTab !== 'products') return

        return (
            <div>
                <div className="sectionHeader">
                    Products
                </div>
                <Table selectable={false} className="liveViewTable">
                    <TableBody displayRowCheckbox={false} stripedRows={true}>
                        {this.renderProductRows()}
                    </TableBody>
                </Table>
            </div>
        )
    }
    renderProductRows() {
        if (!this.props.products.length) {
            return <TableRow><TableRowColumn>None</TableRowColumn></TableRow>
        }

        return this.props.products.map((prod, i) => {
            return <TableRow key={`${prod.id}-${i}`}>
                <TableRowColumn>{prod.mix.name}</TableRowColumn>
                <TableRowColumn>{prod.displayRate + ' ' + prod.userDisplayUnit}</TableRowColumn>
            </TableRow>
        })
    }

    renderFiles() {
        if (this.state.mobileTab !== 'files') return

        return (
            <div>
                <div className="sectionHeader">
                    Files
                </div>
                <Table selectable={false} className="liveViewTable">
                    <TableBody displayRowCheckbox={false} stripedRows={true}>
                        {this.renderFileRows()}
                    </TableBody>
                </Table>
            </div>
        )
    }
    renderFileRows() {
        if (!this.props.files.length) {
            return <TableRow><TableRowColumn>None</TableRowColumn></TableRow>
        }

        return this.props.files.map((file, i) => {
            if (file.hasDetailView) {
                // we are feature flagging the new rx map file viewer with the conversion flag
                const href = file.fileTypeId === 4 && !file.requiresConversion ? `/jobs/rxmap/${file.id}` : `https://${getHost()}/index.php?r=fileMgmt/viewFile&id=${file.id}`

                return (
                    <TableRow key={file.id}>
                        <TableRowColumn>
                            <a
                                href={href}
                                target={`File_${file.id}`}
                            >
                                {file.name || file.id}
                            </a>
                        </TableRowColumn>
                    </TableRow>
                )
            }

            return (
                <TableRow key={file.id}>
                    <TableRowColumn>{file.name || file.id}</TableRowColumn>
                </TableRow>
            )

        })
    }

    render() {
        return (
            <div>
                <div className="liveViewSidebar">
                    <div className="sectionHeader">
                        Participants
                    </div>
                    <Table selectable={false} className="liveViewTable">
                        <TableBody displayRowCheckbox={false} stripedRows={true}>
                            {this.renderParticipantRows()}
                        </TableBody>
                    </Table>
                    <div className="sectionHeader">
                        Products
                    </div>
                    <Table selectable={false} className="liveViewTable">
                        <TableBody displayRowCheckbox={false} stripedRows={true}>
                            {this.renderProductRows()}
                        </TableBody>
                    </Table>
                    <div className="sectionHeader">
                        Files
                    </div>
                    <Table selectable={false} className="liveViewTable">
                        <TableBody displayRowCheckbox={false} stripedRows={true}>
                            {this.renderFileRows()}
                        </TableBody>
                    </Table>
                </div>
                <div className="liveViewMobileFooter">
                    {this.renderParticipants()}
                    {this.renderProducts()}
                    {this.renderFiles()}
                    <BottomNavigation style={{height: 'auto'}}>
                        <BottomNavigationItem
                            label="Participants"
                            icon={<img className="liveMobileBtn" src={`${process.env.PUBLIC_URL}/images/jobIcons/Live_Job_Mobile_Participants.svg`}/>}
                            onClick={() => { this.selectTab('participants') }}
                        />
                        <BottomNavigationItem
                            label="Products"
                            icon={<img className="liveMobileBtn" src={`${process.env.PUBLIC_URL}/images/jobIcons/Live_Job_Mobile_Products.svg`}/>}
                            onClick={() => { this.selectTab('products') }}
                        />
                        <BottomNavigationItem
                            label="Files"
                            icon={<img className="liveMobileBtn" src={`${process.env.PUBLIC_URL}/images/jobIcons/Live_Job_Mobile_Files.svg`}/>}
                            onClick={() => { this.selectTab('files') }}
                        />
                    </BottomNavigation>
                </div>
            </div>
        )
    }
}
export default LiveViewSidebar
