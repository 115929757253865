const ddiConstants = require('./ddiConstants')

function ddiFromDdiAndEntryMode(currentDdi, entryMode) {
    if (isMassDdi(currentDdi)) {
        if (entryMode === 'rateMode' || entryMode === 'targetRate') {
            return ddiConstants.SETPOINT_MASS_PER_AREA_DDI
        }
        else if (entryMode === 'rxMap') {
            return ddiConstants.DEFAULT_MASS_PER_AREA_DDI
        }

        return ddiConstants.ACTUAL_MASS_CONTENT_DDI

    }
    else if (isVolumeDdi(currentDdi)) {
        if (entryMode === 'rateMode' || entryMode === 'targetRate') {
            return ddiConstants.SETPOINT_VOLUME_PER_AREA_DDI
        }
        else if (entryMode === 'rxMap') {
            return ddiConstants.DEFAULT_VOLUME_PER_AREA_DDI
        }

        return ddiConstants.ACTUAL_VOLUME_CONTENT_DDI
    }
    else if (isCountDdi(currentDdi)) {
        if (entryMode === 'rateMode' || entryMode === 'targetRate') {
            return ddiConstants.SETPOINT_COUNT_PER_AREA_DDI
        }
        else if (entryMode === 'rxMap') {
            return ddiConstants.DEFAULT_COUNT_PER_AREA_DDI
        }

        return ddiConstants.ACTUAL_COUNT_CONTENT_DDI
    }
}

function rateDdiEquivalent(currentDdi) {
    if (isMassDdi(currentDdi)) {

        return ddiConstants.SETPOINT_MASS_PER_AREA_DDI
    }
    else if (isVolumeDdi(currentDdi)) {

        return ddiConstants.SETPOINT_VOLUME_PER_AREA_DDI
    }
    else if (isCountDdi(currentDdi)) {

        return ddiConstants.SETPOINT_COUNT_PER_AREA_DDI
    }
}

function defaultRateDdiEquivalent(currentDdi) {
    if (isMassDdi(currentDdi)) {

        return ddiConstants.DEFAULT_MASS_PER_AREA_DDI
    }
    else if (isVolumeDdi(currentDdi)) {

        return ddiConstants.DEFAULT_VOLUME_PER_AREA_DDI
    }
}

function productModeFromDdi(ddi) {
    if (ddi == ddiConstants.DEFAULT_MASS_PER_AREA_DDI ||
        ddi == ddiConstants.DEFAULT_VOLUME_PER_AREA_DDI ||
        ddi == ddiConstants.DEFAULT_COUNT_PER_AREA_DDI
    ) {
        return 'rxMap'
    }

    return 'targetRate'
}

function mixEntryModeFromDdi(ddi) {
    if (isRateDdi(ddi)) {
        return 'rateMode'
    }

    return 'qtyMode'
}


function actualContentDdi(ddi) {
    if (isVolumeDdi(ddi)) {
        return ddiConstants.ACTUAL_VOLUME_CONTENT_DDI
    }
    else if (isMassDdi(ddi)) {
        return ddiConstants.ACTUAL_MASS_CONTENT_DDI
    }
    else if (isCountDdi(ddi)) {
        return ddiConstants.ACTUAL_COUNT_CONTENT_DDI
    }
}

function isLiquidDDI(isLiquid) {
    if (isLiquid) {
        return ddiConstants.ACTUAL_VOLUME_CONTENT_DDI
    }

    return ddiConstants.ACTUAL_MASS_CONTENT_DDI
}

function isVolumeDdi(ddi) {
    switch (ddi) {
        case ddiConstants.ACTUAL_VOLUME_CONTENT_DDI:
        case ddiConstants.SETPOINT_VOLUME_PER_AREA_DDI:
        case ddiConstants.DEFAULT_VOLUME_PER_AREA_DDI:
            return true
        default:
            return false
    }
}

function isMassDdi(ddi) {
    switch (ddi) {
        case ddiConstants.ACTUAL_MASS_CONTENT_DDI:
        case ddiConstants.SETPOINT_MASS_PER_AREA_DDI:
        case ddiConstants.DEFAULT_MASS_PER_AREA_DDI:
            return true
        default:
            return false
    }
}
function isCountDdi(ddi) {
    switch (ddi) {
        case ddiConstants.ACTUAL_COUNT_CONTENT_DDI:
        case ddiConstants.SETPOINT_COUNT_PER_AREA_DDI:
        case ddiConstants.DEFAULT_COUNT_PER_AREA_DDI:
            return true
        default:
            return false
    }
}

function isRateDdi(ddi) {
    switch (ddi) {
        case ddiConstants.SETPOINT_VOLUME_PER_AREA_DDI:
        case ddiConstants.DEFAULT_VOLUME_PER_AREA_DDI:
        case ddiConstants.SETPOINT_MASS_PER_AREA_DDI:
        case ddiConstants.DEFAULT_MASS_PER_AREA_DDI:
        case ddiConstants.SETPOINT_COUNT_PER_AREA_DDI:
        case ddiConstants.DEFAULT_COUNT_PER_AREA_DDI:
            return true
        default:
            return false
    }
}
function isProductDdi(ddi) {
    switch (ddi) {
        case ddiConstants.TOTAL_AREA_DDI:
            return false
        default:
            return true
    }
}
module.exports = {
    actualContentDdi: actualContentDdi,
    ddiFromDdiAndEntryMode: ddiFromDdiAndEntryMode,
    isVolumeDdi: isVolumeDdi,
    isMassDdi: isMassDdi,
    isCountDdi: isCountDdi,
    isRateDdi: isRateDdi,
    ddiFromIsLiquid: isLiquidDDI,
    isProductDdi: isProductDdi,
    mixEntryModeFromDdi: mixEntryModeFromDdi,
    productModeFromDdi: productModeFromDdi,
    rateDdiEquivalent: rateDdiEquivalent,
    defaultRateDdiEquivalent: defaultRateDdiEquivalent,
}
