import {
    Navigate,
    Route,
    BrowserRouter as Router,
    Routes,
    useLocation,
} from 'react-router-dom'
import { ravenDarkTheme, ravenLightTheme } from '@atd/design.themes.base-theme'

import AppBarSlingshot from './system/AppBar'
import AppGffManager from './gffManager/App.gffmanager'
import AppJobCreate from './workorder/App.jobCreate'
import AppJobList from './workorder/App.jobList'
import AppJobSyncDebug from './engineeringTools/App.jobSyncDebug'
import AppLiveView from './m2m/App.LiveView'
import AppMixManager from './productManager/App.MixManager'
import AppWorkOrderDetails from './workorder/App.workOrderDetails'
import ApplicationReport from './workorder/applicationReport/applicationReport'
import Auth0Callback from './auth0/Auth0Callback'
import ErrorBoundary from './commonComponents/errorBoundary'
import { ErrorPage } from './commonComponents/errorPage'
import Footer from './system/footer'
import React from 'react'
import RxMapViewer from './scoutManager/rxMapViewer/rxMapViewer'
import { ThemeContext } from '@atd/design.theme-context'
import frontEndRoutes from './frontEndRouteTemplates'
import { history } from './store'

const APPS_PAGE_TITLE = {
    [frontEndRoutes.id]: 'Details',
    [frontEndRoutes.jobDetailsGuid]: 'Details',
    [frontEndRoutes.jobsList]: 'Jobs List',
    [frontEndRoutes.jobsLive]: 'Job Live View',
    [frontEndRoutes.gffManager]: 'GFF Manager',
    [frontEndRoutes.map]: 'Hardware map',
    [frontEndRoutes.mixManager]: 'Mix Manager',
    [frontEndRoutes.jobsCreate]: 'Job Generator',
    [frontEndRoutes.jobsTemplate]: 'Job Generator',
    [frontEndRoutes.JobSyncDebug]: 'Job Sync Debug',
    [frontEndRoutes.rxMapViewer]: 'Rx Map',
}

// Using theme from @mui instead of material-ui(older version) for now
// this will be replaced with a global theme provider when upgraded to @mui.
const Layout = ({ children, pageTitle}) => {
    const location = useLocation().pathname
    if (!pageTitle) {
        pageTitle = APPS_PAGE_TITLE[location]
    }

    return (
        <div style={{minHeight: '100vh', display: 'flex', flexDirection: 'column'}}>
            <ThemeContext
                lightTheme={ravenLightTheme}
                darkTheme={ravenDarkTheme}
                initialMode="light"
            >
                <AppBarSlingshot pageTitle={pageTitle}/>
                <ErrorBoundary>
                    {children}
                </ErrorBoundary>
                <div style={{marginTop: 'auto'}}>
                    <Footer/>
                </div>
            </ThemeContext>
        </div>
    )
}

const ApplicationReportLayout = () => (
    <Layout pageTitle="Application Report">
        <ApplicationReport />
    </Layout>
)

const RxMapViewerLayout = () => (
    <Layout>
        <RxMapViewer />
    </Layout>
)

const Details = () => (
    <Layout>
        <AppWorkOrderDetails/>
    </Layout>
)

const GffManager = () => (
    <Layout>
        <AppGffManager />
    </Layout>
)
const HardwareMap = () => (
    <Navigate to="/machineview"/>
)

const MixManager = () => (
    <Layout>
        <AppMixManager />
    </Layout>
)

const QuickPackage = () => (
    <Navigate to="/jobs/create"/>
)
const JobCreate = () => (
    <Layout>
        <AppJobCreate/>
    </Layout>
)

const JobList = () => (
    <Layout>
        <AppJobList />
    </Layout>
)

const LiveView = () => (
    <Layout>
        <AppLiveView/>
    </Layout>
)

const JobSyncDebug = () => (
    <Layout>
        <AppJobSyncDebug />
    </Layout>
)

const mainStyle = {transition: 'margin-left 450ms cubic-bezier(0.23, 1, 0.32, 1)'}

const Routing = () => {

    return (
        <Router history={history}>

            <div style={mainStyle}>
                <Routes>
                    <Route path={frontEndRoutes.auth0Callback} element={<Auth0Callback />} />
                    <Route path={frontEndRoutes.gffManager} element={<GffManager />} />
                    <Route path={frontEndRoutes.map} element={<HardwareMap />} />
                    <Route path={frontEndRoutes.mixManager} element={<MixManager />} />

                    <Route path={frontEndRoutes.jobDetailsId} element={<Details />} />
                    <Route path={frontEndRoutes.jobDetailsGuid} element={<Details />} />
                    <Route path={frontEndRoutes.jobsList} element={<JobList />} />
                    <Route path={frontEndRoutes.jobsLive} element={<LiveView />} />

                    <Route path={frontEndRoutes.jobsQuick} element={<QuickPackage />} />
                    <Route exact path={frontEndRoutes.jobsCreate} element={<JobCreate />} />

                    <Route path={frontEndRoutes.jobsTemplate} element={<JobCreate />} />
                    <Route path={frontEndRoutes.jobSync} element={<JobSyncDebug />} />

                    <Route path={frontEndRoutes.rxMapViewer} element={<RxMapViewerLayout />} />
                    <Route path={frontEndRoutes.consolidatedApplicationReport}
                        element={<ApplicationReportLayout />} />
                    <Route path={frontEndRoutes.applicationReport}
                        element={<ApplicationReportLayout />} />
                    <Route path={'*'} element={<ErrorPage is404={true}/>} />
                </Routes>
            </div>
        </Router>
    )
}

export default Routing
