import { combineReducers } from 'redux'
import update from 'immutability-helper'
import {getSelectedCompanyCookie, getIsJobFilterFromURLCookie, defaultUserPreferences} from './systemActions'

export function cloaked(state = [], action) {
    switch (action.type) {
        case 'RECEIVE_COMPANY_INFO':
            return update(state, {
                $set: action.cloaked,
            })

        default:
            return state
    }
}

export function availableCompanies(state = [], action) {
    switch (action.type) {
        case 'RECEIVE_COMPANY_INFO':
            return update(state, {
                $set: action.availableCompanies,
            })

        default:
            return state
    }
}

export function selectedCompanyId(state = [], action) {
    switch (action.type) {
        case 'SELECT_COMPANY':
            return update(state, {
                $set: action.selectedCompanyId,
            })
        default:
            return state
    }
}

export function isJobFilterFromURL(state = [], action) {
    switch (action.type) {
        case 'FILTER_FROM_URL':
            return update(state, {
                $set: action.isJobFilterFromURL,
            })
        default:
            return state
    }
}

export function fileTypes(state = [], action) {
    switch (action.type) {
        case 'FILE_TYPES_LOADED':
            return update(state, {
                $set: action.fileTypes,
            })
        default:
            return state
    }
}

export function maintenanceAlerts(state = [], action) {
    switch (action.type) {
        case 'MAINTENANCE_ALERTS_LOADED':
            return update(state, {
                $set: action.maintenanceAlerts,
            })
        default:
            return state
    }
}

export function languages(state = [], action) {
    switch (action.type) {
        case 'LANGUAGES_LOADED':
            return update(state, {
                $set: action.languages,
            })
        default:
            return state
    }
}

export function unitsOfMeasurement(state = [], action) {
    switch (action.type) {
        case 'UNITS_OF_MEASUREMENT_LOADED':
            return update(state, {
                $set: action.unitsOfMeasurement,
            })
        default:
            return state
    }
}

export function userPreferences(state = [], action) {
    switch (action.type) {
        case 'USER_PREFERENCES_LOADED':
            return update(state, {
                $set: action.userPreferences,
            })
        default:
            return state
    }
}

export function contactInfo(state = [], action) {
    switch (action.type) {
        case 'CONTACT_INFO_LOADED':
            return update(state, {
                $set: action.contactInfo,
            })
        default:
            return state
    }
}

const companyId = getSelectedCompanyCookie()
const isFromURL = getIsJobFilterFromURLCookie()
export const defaultSystemState = {
    // begin with null companies instead of an empty array to indicate we haven't loaded the current list yet.
    availableCompanies: null,
    cloaked: {},
    fileTypes: [],
    maintenanceAlerts: [],
    selectedCompanyId: companyId,
    isJobFilterFromURL: isFromURL,
    languages: [],
    unitsOfMeasurement: [],
    userPreferences: defaultUserPreferences,
    contactInfo: {},
}
const systemReducer = combineReducers({
    availableCompanies,
    cloaked,
    fileTypes,
    maintenanceAlerts,
    languages,
    unitsOfMeasurement,
    userPreferences,
    selectedCompanyId,
    isJobFilterFromURL,
    contactInfo,
})

export default systemReducer
