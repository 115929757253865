export const DEFAULT_DISPLAY_VALUE = 'n/a'
export const METRIC = 'metric'
export const IMPERIAL = 'standard'
export const LIQUID = 'LIQUID'
export const BAR_TO_PSI = 14.50
export const BAR_TO_KPA = 100
export const KPA_TO_BAR = (1.0 / BAR_TO_KPA)
export const KPA_TO_PSI = (KPA_TO_BAR * BAR_TO_PSI)
export const PSI_TO_KPA = (1.0 / KPA_TO_PSI)
export const RATE_COLOR = 'RateColor'
export const PRESSURE_COLOR = 'PressureColor'
export const VARIETY_COLOR = 'VarietyColor'
