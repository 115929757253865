import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'
import SelectField from 'material-ui/SelectField'
import TextField from 'material-ui/TextField'

import { areaMenuItems } from '../commonComponents/unitMenuItems'
import { checkFieldLimits } from '../lib/ddiUnits'

class FieldEdit extends React.Component {
    static propTypes = {
        data: PropTypes.object,
    }

    static defaultProps = {
        data: {
            id: -1,
            growerId: -1,
            farmId: -1,
            name: '',
            section: '',
            township: '',
            county: '',
            region: '',
            area: 0,
            displayArea: '0',
            areaDisplayUnit: 'ac',
            cropType: '',
            cropVariety: '',
            comments: '',
        },
        disabled: false,
    }

    state = {
        ...this.props.data,
        errorMessage: '',
    }

    handleChange = (event) => {
        const formData = {...this.state}
        formData[event.target.name] = event.target.value
        this.setState(formData)
    }
    handleAreaDisplayUnit = (event, index, value) => {
        this.setState({areaDisplayUnit: value})
    }

    // eslint-disable-next-line
    handleSubmit = () => {
        if (!this.state.name || this.state.name.length > 32 ||
            (this.state.section && this.state.section.length > 32) ||
            (this.state.township && this.state.township.length > 32) ||
            (this.state.region && this.state.region.length > 32) ||
            (this.state.county && this.state.county.length > 32) ||
            (this.state.cropType && this.state.cropType.length > 32) ||
            (this.state.cropVariety && this.state.cropVariety.length > 32) ||
            (this.state.cropVariety && !this.state.cropType) ||
            (this.state.displayArea && this.state.displayArea != 0 && checkFieldLimits(this.state.displayArea, this.state.areaDisplayUnit)) ||
            (!this.state.areaDisplayUnit) ||
            (this.state.comments && this.state.comments.length > 330)
        ) {
            this.setState({errorMessage: 'Invalid Data'})
        }
        else {
            this.props.onSubmit({ ...this.state })
        }
    }
    handleCancel = () => {
        this.props.onCancel()
        this.setState({
            ...this.props.data,
            errorMessage: '',
        })
    }

    renderActions = () => {
        if (this.props.disabled) {
            return [
                <RaisedButton
                    label={'Close'}
                    primary={true}
                    onClick={this.handleSubmit}
                    name="closeButton"
                />,
            ]
        }

        return [
            <div style={{display: 'inline-block', color: 'red'}}>{this.state.errorMessage}</div>,
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.handleCancel}
                name="cancelButton"
            />,
            <RaisedButton
                icon={<img className="standardIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_SaveWhite.svg`}/>}
                label={'Save'}
                primary={true}
                onClick={this.handleSubmit}
                name="saveButton"
            />,
        ]
    }

    renderFieldLocation = () => {
        return <Fragment>
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="Field Name"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.name}
                name="name"
                errorText={(!this.state.name && 'Required Field') || (this.state.name.length > 32 && 'Max Length 32')}
            />
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="Section"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.section}
                name="section"
                errorText={this.state.section && this.state.section.length > 32 && 'Max Length 32'}
            />
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="Township"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.township}
                name="township"
                errorText={this.state.township && this.state.township.length > 32 && 'Max Length 32'}
            />
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="County"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.county}
                name="county"
                errorText={this.state.county && this.state.county.length > 32 && 'Max Length 32'}
            />
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="State"
                fullWidth={true}
                onChange={this.handleChange}
                value={this.state.region}
                name="region"
                errorText={this.state.region && this.state.region.length > 32 && 'Max Length 32'}
            />
        </Fragment>
    }

    renderCropFields = () => {
        return <Fragment>
            <div style={{display: 'flex'}}>
                <TextField
                    disabled={this.props.disabled}
                    floatingLabelText="Area"
                    onChange={this.handleChange}
                    value={this.state.displayArea}
                    name="displayArea"
                    errorText={this.state.displayArea && this.state.displayArea != 0 && checkFieldLimits(this.state.displayArea, this.state.areaDisplayUnit)}
                    style={{marginTop: '0px', marginRight: '10px', flexGrow: 2, flexShrink: 0.5}}
                />
                <SelectField
                    className="unitSelectionInline"
                    style={{marginTop: '0px'}}
                    value={this.state.areaDisplayUnit}
                    onChange={this.handleAreaDisplayUnit}
                    errorText={!this.state.areaDisplayUnit && 'Required Field'}
                    disabled={this.props.disabled}
                    name="areaDisplayUnit"
                >
                    {areaMenuItems}
                </SelectField>
            </div>
            <TextField
                disabled={this.props.disabled}
                floatingLabelText="Crop Type"
                fullWidth={true}
                hintText="Ex: Wheat"
                onChange={this.handleChange}
                value={this.state.cropType}
                name="cropType"
                errorText={this.state.cropType && this.state.cropType.length > 32 && 'Max Length 32'}
            />
            <TextField
                disabled={this.props.disabled || !this.state.cropType}
                floatingLabelText="Crop Variety"
                fullWidth={true}
                hintText="Ex: Ritmo B"
                onChange={this.handleChange}
                value={this.state.cropVariety}
                name="cropVariety"
                errorText={
                    (this.state.cropVariety && !this.state.cropType && 'Crop Type Required') ||
                    (this.state.cropVariety && this.state.cropVariety.length > 32 && 'Max Length 32')
                }
            />
        </Fragment>
    }

    render() {
        const editActions = this.renderActions()

        return (
            <Dialog
                actions={editActions}
                title="Field Information"
                modal={true}
                open={this.props.open}
                onRequestClose={this.handleCancel}
                autoScrollBodyContent={true}
                contentClassName="wodialog"
            >
                {this.renderFieldLocation()}
                {this.renderCropFields()}
                <h3>Comments</h3>
                <TextField
                    disabled={this.props.disabled}
                    fullWidth={true}
                    onChange={this.handleChange}
                    value={this.state.comments}
                    multiLine={true}
                    name="comments"
                    errorText={this.state.comments && this.state.comments.length > 330 && 'Max Length 330'}
                />
            </Dialog>
        )
    }
}
export default FieldEdit
