import axios from '../lib/axios'
import Cookies from 'universal-cookie'
import {errorCheck, errorCatch} from '../lib/actionErrorHandler'
import * as redirect from '../lib/redirect'
import * as jwt from 'jsonwebtoken'

import * as farmActions from '../gffManager/farmActions'
import * as fieldActions from '../gffManager/fieldActions'
import * as growerActions from '../gffManager/growerActions'
import * as ingredientActions from '../productManager/ingredientActions'
import * as mixActions from '../productManager/mixActions'
import * as rxMapActions from '../productManager/rxMapActions'
import * as workOrderActions from '../workorder/workOrderActions'
import store from '../store'

export function fetchCompanyInfo() {
    return dispatch => {
        dispatch(requestCompanyInfo())

        return axios.get('/jobs/api/user/company-info')
            .then(response => response.data)
            .then(json => {
                dispatch(receiveCompanyInfo(json))

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(receiveCompanyInfo([]))

                return []
            })
    }
}

function requestCompanyInfo() {
    return {
        type: 'REQUEST_COMPANY_INFO',
    }
}

function receiveCompanyInfo(companyInfo) {
    if (!Array.isArray(companyInfo.availableCompanies)) { companyInfo.availableCompanies = [] }
    if (!companyInfo.cloaked) { companyInfo.cloaked = {} }

    return {
        type: 'RECEIVE_COMPANY_INFO',
        availableCompanies: companyInfo.availableCompanies,
        cloaked: companyInfo.cloaked,
    }
}

export function selectCompany(company = null) {
    return dispatch => {
        const cookies = new Cookies()
        cookies.remove('scid', { path: '/', domain: redirect.getDomain() })

        if (company) {
            cookies.set('scid', company, { path: '/', domain: redirect.getDomain(), sameSite: 'lax' })
        }
        dispatch(refreshCompanySensativeInfo())
        dispatch({
            type: 'SELECT_COMPANY',
            selectedCompanyId: company,
        })
    }
}

export function isJobFilterFromURL(value = null) {
    return dispatch => {
        const cookies = new Cookies()
        cookies.remove('isJobFilterFromURL', { path: '/', domain: redirect.getDomain() })

        if (value != null) {
            cookies.set('isJobFilterFromURL', value, { path: '/', domain: redirect.getDomain(), sameSite: 'lax' })
        }
        dispatch({
            type: 'FILTER_FROM_URL',
            isJobFilterFromURL: value,
        })
    }
}

export function refreshCompanySensativeInfo() {
    return dispatch => {
        dispatch(farmActions.fetchFarms())
        dispatch(fieldActions.fetchFields())
        dispatch(growerActions.fetchGrowers())
        dispatch(ingredientActions.fetchCarriers())
        dispatch(ingredientActions.fetchIngredients())
        dispatch(mixActions.fetchMixes())
        dispatch(rxMapActions.fetchRxMaps())
        dispatch(workOrderActions.fetchWorkOrders())
    }
}


// The cookie scid holds the ID of the Selected Company
export function getSelectedCompanyCookie() {
    const cookies = new Cookies()
    const scid = cookies.get('scid')
    if (scid) {
        // cookies return a string, but the ID is an integer
        return parseInt(scid, 10)
    }

    return null
}

export function getIsJobFilterFromURLCookie() {
    const cookies = new Cookies()
    const isURLFilter = cookies.get('isJobFilterFromURL')
    if (isURLFilter) {
        // cookies return a string, but isSubmitted is a bool
        return isURLFilter === 'true'
    }

    return null
}

export function userHasDeveloperAccess() {
    return _userHasRole(['Engineering'])
}
export function userHasRavenServiceAccess() {
    return _userHasRole([
        'Engineering',
        'NOC Administrators',
        'Slingshot Administrator',
        'Slingshot Service',
    ])
}
function _userHasRole(roles = []) {
    try {
        if (!Array.isArray(roles)) roles = [roles]

        const { auth0 } = store.getState()
        const {authTypeReducer, authTokenReducer} = auth0

        let userRoles
        if (authTypeReducer.isAuth0Enabled) {
            const {authTokens} = authTokenReducer
            const parsedToken = jwt.decode(authTokens.idToken)

            const host = window.location.hostname
            const isProd = host.includes('apps.ravenslingshot')
            const url = isProd ? 'https://auth.ravenslingshot.com' : 'https://auth.raven.engineering'
            userRoles = parsedToken[`${url}/slingshot-roles`]
        }
        else {
            const cookies = new Cookies()
            const parsedToken = jwt.decode(cookies.get('jwt'))
            userRoles = parsedToken.user_roles
        }

        if (!userRoles) return false

        // check if any of the user's roles are in the list of desired roles
        const roleFound = userRoles.find(r => roles.includes(r))
        // coerce result to a boolean

        return !!roleFound
    }
    catch (e) {
        return false
    }
}

export function uncloak() {
    return dispatch => {
        // API & new JWT
        axios.post('/jobs/api/user/uncloak')
            .then(() => {
                dispatch(selectCompany())
                window.location.reload()
            }).catch(err => {
                errorCheck(err.response)
            })
    }
}

export function fetchFileTypes() {
    return dispatch => {
        dispatch({
            type: 'LOADING_FILE_TYPES',
        })

        return axios.get('/jobs/net-api/files/file-type-feature-flags')
            .then(response => response.data)
            .then(json => {
                dispatch(receiveFileTypes(json))

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(receiveFileTypes([]))

                return []
            })
    }
}

function receiveFileTypes(fileTypes) {
    if (!Array.isArray(fileTypes)) { fileTypes = [] }

    return {
        type: 'FILE_TYPES_LOADED',
        fileTypes,
    }
}

export function fetchMaintenanceAlerts() {
    return dispatch => {
        dispatch({
            type: 'LOADING_MAINTENANCE_ALERTS',
        })

        return axios.get('/jobs/net-api/administration/maintenance-alerts')
            .then(response => response.data)
            .then(json => {
                dispatch(receiveMaintenanceAlerts(json))

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(receiveMaintenanceAlerts([]))

                return []
            })
    }
}

function receiveMaintenanceAlerts(maintenanceAlerts) {
    if (!Array.isArray(maintenanceAlerts)) { maintenanceAlerts = [] }

    return {
        type: 'MAINTENANCE_ALERTS_LOADED',
        maintenanceAlerts,
    }
}

export function fetchLanguages() {
    return dispatch => {
        dispatch({
            type: 'LOADING_LANGUAGES',
        })

        return axios.get('/jobs/net-api/party/language')
            .then(response => response.data)
            .then(json => {
                dispatch(receiveLanguages(json))

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(receiveLanguages([]))

                return []
            })
    }
}

function receiveLanguages(languages) {
    if (!Array.isArray(languages)) { languages = [] }

    return {
        type: 'LANGUAGES_LOADED',
        languages,
    }
}

export function fetchUnitsOfMeasurement() {
    return dispatch => {
        dispatch({
            type: 'LOADING_UNITS_OF_MEASUREMENT',
        })

        return axios.get('/jobs/net-api/party/unit-of-measurement')
            .then(response => response.data)
            .then(json => {
                dispatch(receiveUnitsOfMeasurement(json))

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(receiveUnitsOfMeasurement([]))

                return []
            })
    }
}

function receiveUnitsOfMeasurement(unitsOfMeasurement) {
    if (!Array.isArray(unitsOfMeasurement)) { unitsOfMeasurement = [] }

    return {
        type: 'UNITS_OF_MEASUREMENT_LOADED',
        unitsOfMeasurement,
    }
}

export const defaultUserPreferences = {
    language: '',
    unitOfMeasurement: '',
    mapRefreshIntervalMilliseconds: '',
    idleSessionTimeoutSeconds: '',
}

export function fetchUserPreferences() {
    return dispatch => {
        dispatch({
            type: 'LOADING_USER_PREFERENCES',
        })

        return axios.get('/jobs/net-api/party/user/preferences')
            .then(response => response.data)
            .then(json => {
                dispatch(receiveUserPreferences(json))

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(receiveUserPreferences(defaultUserPreferences))

                return defaultUserPreferences
            })
    }
}

function receiveUserPreferences(userPreferences) {
    if (!userPreferences) { userPreferences = defaultUserPreferences }

    return {
        type: 'USER_PREFERENCES_LOADED',
        userPreferences,
    }
}

export function saveUserPreferences(userPreferences) {
    return dispatch => {
        dispatch({
            type: 'SAVING_USER_PREFERENCES',
        })

        return axios.post('/jobs/net-api/party/user/preferences', userPreferences)
            .then(response => {
                if (response.status == 204 || response.status == 200) {
                    dispatch(receiveUserPreferences(userPreferences))
                    dispatch(fetchUserPreferences())

                    return {status: 'OK'}
                }
                const err = new Error('Save Failed')
                err.response = response

                throw err
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)

                return {status: 'ERROR'}
            })
    }
}

export function fetchContactInfo() {
    return dispatch => {
        dispatch({
            type: 'LOADING_CONTACT_INFO',
        })

        return axios.get('/jobs/api/user/contact')
            .then(response => response.data)
            .then(json => {
                dispatch(receiveContactInfo(json))

                return json
            })
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(receiveContactInfo({}))

                return {}
            })
    }
}

function receiveContactInfo(contactInfo) {
    if (!contactInfo) { contactInfo = {} }

    return {
        type: 'CONTACT_INFO_LOADED',
        contactInfo,
    }
}
