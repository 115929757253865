import axios from '../lib/axios'
import {errorCheck, errorCatch} from '../lib/actionErrorHandler'
import {alphanumSanitize} from '../lib/stringValidation'

export function selectFarm(farm = {id: -1}) {
    return {
        type: 'SELECT_FARM',
        farm,
    }
}

export function addFarm(farm) {
    return dispatch => {
        farm.postalCode = alphanumSanitize(farm.postalCode)

        return axios.post('/jobs/api/farms/', farm)
            .then(response => response.data)
            .then(savedFarm => dispatch(selectFarm(savedFarm)))
            .then(() => dispatch(fetchFarms()))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
            })
    }
}

export function updateFarm(farm) {
    return dispatch => {
        farm.postalCode = alphanumSanitize(farm.postalCode)

        return axios.put(`/jobs/api/farms/${farm.id}`, farm)
            .then(response => response.data)
            .then(() => dispatch(fetchFarms()))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
            })
    }
}

export function deleteFarm(farmId) {
    return dispatch => {

        return axios.delete(`/jobs/api/farms/${farmId}`)
            .then(() => dispatch(fetchFarms()))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
            })
    }
}

export function fetchFarms() {
    return dispatch => {
        dispatch(requestFarms())

        return axios.get('/jobs/api/farms')
            .then(response => response.data)
            .then(json => dispatch(receiveFarms(json)))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(receiveFarms([]))
            })
    }
}

function requestFarms() {
    return {
        type: 'REQUEST_FARMS',
    }
}

function receiveFarms(farms) {
    if (!Array.isArray(farms)) { farms = [] }

    return {
        type: 'RECEIVE_FARMS',
        farms: farms,
    }
}
