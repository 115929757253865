import React from 'react'

const EmptyState = ({children, className}) => {
    const emptyStateImage = `${process.env.PUBLIC_URL}/images/Slingshot_1200x600.png`

    return (
        <div className={className} style={{width: '100%', textAlign: 'center'}}>
            {children}
            <div style={{
                backgroundColor: 'white',
                backgroundImage: 'url(' + emptyStateImage + ')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'top',
                backgroundSize: 'contain',
                width: '100%',
                minHeight: '500px',
            }}/>
        </div>
    )
}

export default EmptyState
