import React from 'react'

import IconButton from 'material-ui/IconButton'
import MenuItem from 'material-ui/MenuItem'
import SelectField from 'material-ui/SelectField'
import SwapOrderIcon from 'material-ui/svg-icons/action/swap-vertical-circle'
import { jobStatusSort} from './jobStatus'
import { searchQueryString } from '../workorder/queryString'
import { isBefore, isAfter } from 'date-fns'

class JobListSort extends React.Component {
    defaultState = {
        sortSelection: 0, // name
        sortedJobs: [],
        // Sort order is used to flip results of sorting functions
        // I.e. swap ascending and descending sorts
        sortOrder: 1,
        sortOptions: [
            {
                name: 'Job Status',
                sortFunc: (a, b) => {
                    const statusVal = jobStatusSort(a, b)
                    // These jobs have the same state. Apply a secondary sort within the state.
                    if (statusVal == 0) {
                        return this.dateCompare(a.createdTime, b.createdTime)
                    }

                    return statusVal * this.state.sortOrder
                },
            },
            {
                name: 'Job Name',
                sortFunc: (a, b) => {
                    return this.stringCompare(a.name, b.name)
                },
            },
            {
                name: 'Creation Date',
                sortFunc: (a, b) => {
                    return this.dateCompare(a.createdTime, b.createdTime)
                },
            },
            {
                name: 'Grower/Farm/Field',
                sortFunc: (a, b) => {
                    return this.stringCompare(
                        `${a.metadata.grower ? a.metadata.grower.name : ''}${a.metadata.farm ? a.metadata.farm.name : ''}${a.metadata.field ? a.metadata.field.name : ''}`,
                        `${b.metadata.grower ? b.metadata.grower.name : ''}${b.metadata.farm ? b.metadata.farm.name : ''}${b.metadata.field ? b.metadata.field.name : ''}`
                    )
                },
            },
        ],
    }
    state = {
        ...this.defaultState,
    }

    dateCompare = (dateA, dateB) => {
        if (isBefore(dateA, dateB)) {
            return -1 * this.state.sortOrder
        }
        if (isAfter(dateA, dateB)) {
            return 1 * this.state.sortOrder
        }

        return 0
    }
    stringCompare = (stringA, stringB) => {
        if (!stringA || stringA.toLowerCase() < stringB.toLowerCase()) {
            return -1 * this.state.sortOrder
        }
        if (!stringB || stringA.toLowerCase() > stringB.toLowerCase()) {
            return 1 * this.state.sortOrder
        }

        return 0
    }

    static getDerivedStateFromProps(props, state) {
        if (props.jobs !== state.prevJobs) {
            const sortBy = Object.assign({}, state, {})
            let sortedJobs = [...props.jobs]

            sortedJobs = [...sortedJobs.sort(sortBy.sortOptions[sortBy.sortSelection].sortFunc)]
            props.onJobsSorted(sortedJobs, sortBy.sortSelection, sortBy.sortOrder, false)

            return {
                ...state,
                sortedJobs: sortedJobs,
                prevJobs: props.jobs,
            }
        }

        return null
    }

    selectSort = (event, index, sortSelection, isSortFilterChanged) => {
        this.sortItems({ sortSelection }, isSortFilterChanged)
        this.setState({ sortSelection }, this.sortItems)
    }
    sortOrderChanged = (event) => {
        const sortOrder = this.state.sortOrder * -1
        this.setState({ sortOrder }, this.sortItems)
    }
    sortItems = (options = {}, isSortFilterChanged, jobs = this.props.jobs) => {
        // combine specific options with the current state to get the full set of filters
        const sortBy = Object.assign({}, this.state, options)
        let sortedJobs = [...jobs]

        sortedJobs = [...sortedJobs.sort(sortBy.sortOptions[sortBy.sortSelection].sortFunc)]
        this.setState({ sortedJobs })
        this.props.onJobsSorted(sortedJobs, sortBy.sortSelection, sortBy.sortOrder, isSortFilterChanged)
    }

    optionsToMenuItems = () => {
        return this.state.sortOptions.map((data, i) => {
            return (
                <MenuItem
                    key={i}
                    value={i}
                    primaryText={data.name}
                    name={data.name}
                />
            )
        })
    }

    handleSortSelect = (event, index, sortSelection) => {
        this.selectSort(event, index, sortSelection, true)
    }

    componentDidMount = (event) => {
        let sortOrder = parseInt(searchQueryString('sortOrder'), 10)
        if (sortOrder < 0 || sortOrder > 3 || isNaN(sortOrder)) {
            sortOrder = 0
        }
        this.selectSort(event, sortOrder, sortOrder, false)
    }

    render() {
        return (
            <div className="filterGroup">
                <div style={{ alignSelf: 'flex-start' }}>Sort By:</div>
                <div style={{ display: 'flex', width: '95%' }}>
                    <SelectField
                        className="autoCompleteFilter"
                        value={this.state.sortSelection}
                        onChange={this.handleSortSelect}
                        name="sortDropdown"
                        selectedMenuItemStyle={{ color: 'var(--primary)' }}
                        underlineStyle={{borderColor: 'var(--secondary)'}}
                        iconStyle={{fill: 'var(--secondary)'}}
                    >
                        {this.optionsToMenuItems()}
                    </SelectField>
                    <IconButton
                        className="clearSelection"
                        name="sortOrder"
                        onClick={this.sortOrderChanged}
                    >
                        <SwapOrderIcon />
                    </IconButton>
                </div>
            </div>
        )
    }
}
export default (JobListSort)
