// Conversion values are set up to be used as a multiplier
// EX You display in gallons and store in base
// User enters: 8
// 8 * fromDisplayToBase = stored value of 30483.28
// 30283.27 * fromBaseToDisplay = 8.000000

// const constants = require("./unitConstants")

// base unit: milliters
const METRIC = 0
const USC = 1 // United States customary units
const TURF = 2 // alternative rate for USC

const SMALL_UNIT = 1
const MEDIUM_UNIT = 2
const LARGE_UNIT = 3

// Constant values and derived values for conversion
const MILLILITERS_PER_GALLON = 3785.41178
const MM3_PER_GALLON = MILLILITERS_PER_GALLON * 1000
const FLUID_OUNCES_PER_GALLON = 128.0
const PINTS_PER_GALLON = 8.0

const METERSQ_PER_ACRE = 4046.8564224
const ACRES_PER_METERSQ = (1.0 / METERSQ_PER_ACRE) // 0.0002471044
const METERSQ_TO_ACRES = (ACRES_PER_METERSQ) // 0.0002471044

const FEET_PER_METER = 3.2808398950131235
const FEETSQ_PER_METERSQ = (FEET_PER_METER * FEET_PER_METER) // 10.76391
const METERSQ_TO_FEETSQ = FEETSQ_PER_METERSQ

const OUNCES_PER_POUND = 16.0
const POUNDS_PER_GRAM = 0.002204622621848776
const POUNDS_PER_MILLIGRAM = POUNDS_PER_GRAM / 1000
const MILLIGRAMS_PER_POUND = 1 / POUNDS_PER_MILLIGRAM
const GRAMS_TO_OUNCES = POUNDS_PER_GRAM * OUNCES_PER_POUND
const OUNCES_TO_GRAMS = 1 / GRAMS_TO_OUNCES


const GALLONS_PER_ACRE_TO_MM3_PER_METERSQ = (MM3_PER_GALLON * METERSQ_TO_ACRES)
const MM3_PER_METERSQ_TO_GALLONS_PER_ACRE = (1.0 / GALLONS_PER_ACRE_TO_MM3_PER_METERSQ)
const GALLONS_PER_1000FTSQ_TO_MM3_PER_METERSQ = (MM3_PER_GALLON * METERSQ_TO_FEETSQ) / 1000

const POUNDS_PER_ACRE_TO_MILLIGRAMS_PER_METERSQ = (MILLIGRAMS_PER_POUND * METERSQ_TO_ACRES)
const MILLIGRAM_PER_METERSQ_TO_POUNDS_PER_ACRE = (1.0 / POUNDS_PER_ACRE_TO_MILLIGRAMS_PER_METERSQ)
const POUNDS_PER_1000FTSQ_TO_MG_PER_METERSQ = (MILLIGRAMS_PER_POUND * METERSQ_TO_FEETSQ) / 1000

const lengthUnits = [
    {
        text: 'meters',
        abbreviation: 'm',
        fromDisplayToBase: 1,
        fromBaseToDisplay: 1,
        system: METRIC,
        size: MEDIUM_UNIT,
        base: true,
    },
    {
        text: 'feet',
        abbreviation: 'ft',
        fromDisplayToBase: 1 / FEET_PER_METER,
        fromBaseToDisplay: FEET_PER_METER,
        system: USC,
        size: MEDIUM_UNIT,
    },
]

function getUnits(units, system, size) {
    return units.filter(u => u.system === system && u.size === size)[0]
}

const volumeUnits = [
    {
        text: 'fluid ounces',
        abbreviation: 'fl oz',
        fromDisplayToBase: MILLILITERS_PER_GALLON / FLUID_OUNCES_PER_GALLON, // 29.57353
        fromBaseToDisplay: FLUID_OUNCES_PER_GALLON / MILLILITERS_PER_GALLON, // 0.03381402
        system: USC,
        size: SMALL_UNIT,
    },
    {
        text: 'liquid pints',
        abbreviation: 'pt',
        fromDisplayToBase: MILLILITERS_PER_GALLON / PINTS_PER_GALLON, // 473.1765
        fromBaseToDisplay: PINTS_PER_GALLON / MILLILITERS_PER_GALLON, // 0.002113376
        system: USC,
        size: MEDIUM_UNIT,
    },
    {
        text: 'gallons',
        abbreviation: 'gal',
        fromDisplayToBase: MILLILITERS_PER_GALLON, // 3785.41
        fromBaseToDisplay: 1 / MILLILITERS_PER_GALLON, // 0.000264172
        system: USC,
        size: LARGE_UNIT,
        toSmallUnit: (value) => ({
            value: value * FLUID_OUNCES_PER_GALLON,
            units: getUnits(volumeUnits, USC, SMALL_UNIT),
        }),
    },
    {
        text: 'milliliters',
        abbreviation: 'mL',
        fromDisplayToBase: 1,
        fromBaseToDisplay: 1,
        system: METRIC,
        size: SMALL_UNIT,
        base: true,
    },
    {
        text: 'liters',
        abbreviation: 'L',
        fromDisplayToBase: 1000,
        fromBaseToDisplay: 0.001,
        system: METRIC,
        size: LARGE_UNIT,
    },
]

// base unit: mm3/m2
const volumePerAreaUnits = [
    {
        text: 'fluid ounces/acre',
        abbreviation: 'fl oz/ac',
        fromDisplayToBase: GALLONS_PER_ACRE_TO_MM3_PER_METERSQ / FLUID_OUNCES_PER_GALLON, // 7.30778
        fromBaseToDisplay: MM3_PER_METERSQ_TO_GALLONS_PER_ACRE * FLUID_OUNCES_PER_GALLON, // 0.13684
        system: USC,
        size: SMALL_UNIT,
    },
    {
        text: 'liquid pints/acre',
        abbreviation: 'pt/ac',
        fromDisplayToBase: GALLONS_PER_ACRE_TO_MM3_PER_METERSQ / PINTS_PER_GALLON, // 116.924
        fromBaseToDisplay: MM3_PER_METERSQ_TO_GALLONS_PER_ACRE * PINTS_PER_GALLON, // 0.00855253
        system: USC,
        size: MEDIUM_UNIT,
    },
    {
        text: 'gallons/acre',
        abbreviation: 'gal/ac',
        fromDisplayToBase: GALLONS_PER_ACRE_TO_MM3_PER_METERSQ, // 935.395623
        fromBaseToDisplay: MM3_PER_METERSQ_TO_GALLONS_PER_ACRE, // 0.00106907
        system: USC,
        size: LARGE_UNIT,
        toSmallUnit: (value) => ({
            value: value * FLUID_OUNCES_PER_GALLON,
            units: getUnits(volumePerAreaUnits, USC, SMALL_UNIT),
        }),
    },
    {
        text: 'fluid ounces/1000 square feet',
        abbreviation: 'fl oz/1000 ft2',
        fromDisplayToBase: GALLONS_PER_1000FTSQ_TO_MM3_PER_METERSQ / FLUID_OUNCES_PER_GALLON, // 318.326823
        fromBaseToDisplay: FLUID_OUNCES_PER_GALLON / GALLONS_PER_1000FTSQ_TO_MM3_PER_METERSQ, // 0.0031414255
        system: TURF,
        size: SMALL_UNIT,
    },
    {
        text: 'liquid pints/1000 square feet',
        abbreviation: 'pt/1000 ft2',
        fromDisplayToBase: GALLONS_PER_1000FTSQ_TO_MM3_PER_METERSQ / PINTS_PER_GALLON, // 5093.22917
        fromBaseToDisplay: PINTS_PER_GALLON / GALLONS_PER_1000FTSQ_TO_MM3_PER_METERSQ, // 0.000196339094
        system: TURF,
        size: MEDIUM_UNIT,
    },
    {
        text: 'gallons/1000 square feet',
        abbreviation: 'gal/1000 ft2',
        fromDisplayToBase: GALLONS_PER_1000FTSQ_TO_MM3_PER_METERSQ, // 40745.8333
        fromBaseToDisplay: 1 / GALLONS_PER_1000FTSQ_TO_MM3_PER_METERSQ, // 0.0000245423867
        system: TURF,
        size: LARGE_UNIT,
        toSmallUnit: (value) => ({
            value: value * FLUID_OUNCES_PER_GALLON,
            units: getUnits(volumePerAreaUnits, TURF, SMALL_UNIT),
        }),
    },
    {
        text: 'milliliters/hectare',
        abbreviation: 'mL/ha',
        fromDisplayToBase: 0.1,
        fromBaseToDisplay: 10,
        system: METRIC,
        size: SMALL_UNIT,
    },
    {
        text: 'microliters/square meter',
        abbreviation: 'mm3/m2',
        fromDisplayToBase: 1,
        fromBaseToDisplay: 1,
        system: METRIC,
        size: SMALL_UNIT,
        base: true,
    },
    {
        text: 'liters/hectare',
        abbreviation: 'L/ha',
        fromDisplayToBase: 100,
        fromBaseToDisplay: 0.01,
        system: METRIC,
        size: MEDIUM_UNIT,
    },
    {
        text: 'milliliters/square meter',
        abbreviation: 'mL/m2',
        fromDisplayToBase: 1000,
        fromBaseToDisplay: 0.001,
        system: METRIC,
        size: LARGE_UNIT,
    },
]

// base unit: grams
const massUnits = [
    {
        text: 'ounces',
        abbreviation: 'oz',
        fromDisplayToBase: OUNCES_TO_GRAMS, // 28.3495
        fromBaseToDisplay: GRAMS_TO_OUNCES, // 0.03527396
        system: USC,
        size: SMALL_UNIT,
    },
    {
        text: 'pounds',
        abbreviation: 'lb',
        fromDisplayToBase: 1 / POUNDS_PER_GRAM, // 453.5924
        fromBaseToDisplay: POUNDS_PER_GRAM, // 0.002204622621848776
        system: USC,
        size: MEDIUM_UNIT,
        toSmallUnit: (value) => ({
            value: value * OUNCES_PER_POUND,
            units: getUnits(massUnits, USC, SMALL_UNIT),
        }),
    },
    // {
    //     text: 'tons (US)',
    //     abbreviation: 'ton',
    //     fromDisplayToBase: 907184.74,
    //     fromBaseToDisplay: 0.000001102311310924388,
    //     system: USC,
    //     size: LARGE_UNIT,
    // },
    {
        text: 'grams',
        abbreviation: 'g',
        fromDisplayToBase: 1,
        fromBaseToDisplay: 1,
        system: METRIC,
        size: SMALL_UNIT,
        base: true,
    },
    {
        text: 'kilograms',
        abbreviation: 'kg',
        fromDisplayToBase: 1000,
        fromBaseToDisplay: 0.001,
        system: METRIC,
        size: MEDIUM_UNIT,
    },
    // {
    //     text: 'metric tons',
    //     abbreviation: 't',
    //     fromDisplayToBase: 1000000,
    //     fromBaseToDisplay: 0.000001,
    //     system: METRIC,
    //     size: LARGE_UNIT,
    // },
]

// base unit: mg/m2
const massPerAreaUnits = [
    {
        text: 'ounces/acre',
        abbreviation: 'oz/ac',
        fromDisplayToBase: POUNDS_PER_ACRE_TO_MILLIGRAMS_PER_METERSQ / OUNCES_PER_POUND, // 7.00532,
        fromBaseToDisplay: MILLIGRAM_PER_METERSQ_TO_POUNDS_PER_ACRE * OUNCES_PER_POUND, // 0.142749,
        system: USC,
        size: SMALL_UNIT,
    },
    {// OFF BY 6 DECIMALS
        text: 'ounces/1000 square feet',
        abbreviation: 'oz/1000 ft2',
        fromDisplayToBase: POUNDS_PER_1000FTSQ_TO_MG_PER_METERSQ / OUNCES_PER_POUND, // 305.151727,
        fromBaseToDisplay: OUNCES_PER_POUND / POUNDS_PER_1000FTSQ_TO_MG_PER_METERSQ, // 0.0032770583,
        system: TURF,
        size: SMALL_UNIT,
    },
    {
        text: 'pounds/acre',
        abbreviation: 'lb/ac',
        fromDisplayToBase: POUNDS_PER_ACRE_TO_MILLIGRAMS_PER_METERSQ, // 112.08511562
        fromBaseToDisplay: MILLIGRAM_PER_METERSQ_TO_POUNDS_PER_ACRE, // 0.00892179,
        system: USC,
        size: MEDIUM_UNIT,
        toSmallUnit: (value) => ({
            value: value * OUNCES_PER_POUND,
            units: getUnits(massPerAreaUnits, USC, SMALL_UNIT),
        }),
    },
    {
        text: 'pounds/1000 square feet',
        abbreviation: 'lb/1000 ft2',
        fromDisplayToBase: POUNDS_PER_1000FTSQ_TO_MG_PER_METERSQ, // 4882.42764,
        fromBaseToDisplay: 1 / POUNDS_PER_1000FTSQ_TO_MG_PER_METERSQ, // 0.00020481644,
        system: TURF,
        size: MEDIUM_UNIT,
        toSmallUnit: (value) => ({
            value: value * OUNCES_PER_POUND,
            units: getUnits(massPerAreaUnits, TURF, SMALL_UNIT),
        }),
    },
    // {
    //     text: 'tons (US)/acre',
    //     abbreviation: 'ton/ac',
    //     fromDisplayToBase: 224170,
    //     fromBaseToDisplay: 0.000004460895,
    //     system: USC,
    //     size: LARGE_UNIT,
    // },
    // {
    //     text: 'tons (US)/1000 square feet',
    //     abbreviation: 'ton/1000 ft2',
    //     fromDisplayToBase: 9764855.28,
    //     fromBaseToDisplay: 0.00000010240822,
    //     system: TURF,
    //     size: LARGE_UNIT,
    // },
    {
        text: 'grams/hectare',
        abbreviation: 'g/ha',
        fromDisplayToBase: 0.1,
        fromBaseToDisplay: 10,
        system: METRIC,
        size: SMALL_UNIT,
    },
    {
        text: 'milligram/square meter',
        abbreviation: 'mg/m2',
        fromDisplayToBase: 1,
        fromBaseToDisplay: 1,
        system: METRIC,
        size: SMALL_UNIT,
        base: true,
    },
    {
        text: 'kilograms/hectare',
        abbreviation: 'kg/ha',
        fromDisplayToBase: 100,
        fromBaseToDisplay: 0.01,
        system: METRIC,
        size: MEDIUM_UNIT,
    },
    // {
    //     text: 'metric tons/hectare',
    //     abbreviation: 't/ha',
    //     fromDisplayToBase: 100000,
    //     fromBaseToDisplay: 0.00001,
    //     system: METRIC,
    //     size: LARGE_UNIT,
    // },
]

const countUnits = [
    {
        text: 'count',
        abbreviation: '#',
        fromDisplayToBase: 1,
        fromBaseToDisplay: 1,
        system: METRIC,
        size: SMALL_UNIT,
        base: true,
    },
    {
        text: 'thousand',
        abbreviation: 'k',
        fromDisplayToBase: 1000,
        fromBaseToDisplay: 0.001,
        system: METRIC,
        size: LARGE_UNIT,
    },
]

const countPerAreaUnits = [

    {
        text: 'count/square foot',
        abbreviation: '/ft2',
        fromDisplayToBase: FEETSQ_PER_METERSQ,
        fromBaseToDisplay: 1 / FEETSQ_PER_METERSQ,
        system: USC,
        size: LARGE_UNIT,
    },
    {
        text: 'count/acre',
        abbreviation: '/ac',
        fromDisplayToBase: 1 / METERSQ_PER_ACRE,
        fromBaseToDisplay: METERSQ_PER_ACRE,
        system: USC,
        size: LARGE_UNIT,
    },
    {
        text: 'count/1000 square feet',
        abbreviation: '/1000 ft2',
        fromDisplayToBase: FEETSQ_PER_METERSQ / 1000,
        fromBaseToDisplay: 1000 / FEETSQ_PER_METERSQ,
        system: USC,
        size: LARGE_UNIT,
    },
    {
        text: 'count/square meter',
        abbreviation: '/m2',
        fromDisplayToBase: 1,
        fromBaseToDisplay: 1,
        system: METRIC,
        size: SMALL_UNIT,
        base: true,
    },
    {
        text: 'count/hectare',
        abbreviation: '/ha',
        fromDisplayToBase: 0.0001,
        fromBaseToDisplay: 10000.0,
        system: METRIC,
        size: MEDIUM_UNIT,
    },
]

const areaUnits = [
    {
        text: 'square feet',
        abbreviation: 'ft2',
        fromDisplayToBase: 1 / FEETSQ_PER_METERSQ,
        fromBaseToDisplay: FEETSQ_PER_METERSQ,
        system: USC,
        size: SMALL_UNIT,
    },
    {
        text: 'acre',
        abbreviation: 'ac',
        fromDisplayToBase: METERSQ_PER_ACRE,
        fromBaseToDisplay: 1 / METERSQ_PER_ACRE,
        system: USC,
        size: LARGE_UNIT,
    },
    {
        text: 'square meter',
        abbreviation: 'm2',
        fromDisplayToBase: 1,
        fromBaseToDisplay: 1,
        system: METRIC,
        size: SMALL_UNIT,
        base: true,
    },
    {
        text: 'hectare',
        abbreviation: 'ha',
        fromDisplayToBase: 10000.0,
        fromBaseToDisplay: 0.0001,
        system: METRIC,
        size: LARGE_UNIT,
    },
]

module.exports = {
    lengthUnits: lengthUnits,
    volumeUnits: volumeUnits,
    massUnits: massUnits,
    countUnits: countUnits,
    volumePerAreaUnits: volumePerAreaUnits,
    massPerAreaUnits: massPerAreaUnits,
    countPerAreaUnits: countPerAreaUnits,
    areaUnits: areaUnits,
}
