import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
    Stepper,
    Step,
    StepContent,
    StepLabel,
} from 'material-ui/Stepper'
import TextField from 'material-ui/TextField'
import RaisedButton from 'material-ui/RaisedButton'

import JobCreateBar from './dispatch/jobCreateBar'
import WorkOrderGFF from '../gffManager/gffSelect'
import WorkOrderRates from '../productManager/rateSelection'
import WorkOrderScoutFeatures from '../scoutManager/scoutFeaturesUI'

import * as workOrderActions from './workOrderActions'
import './stepperStyles.css'
const styles = {
    defaultContentcolor: {
        backgroundColor: 'rgba(234,234,234, 0.25)',
    },
    iconStyle: {
        position: 'absolute',
        zIndex: 5,
        paddingLeft: '5px',
    },
}

const stepValues = {
    FIRST_STEP: 0,
    WO_NAME: 0,
    GFF_SELECTION: 1,
    PRODUCT_INFO: 2,
    SCOUT_FEATURES: 3,
    LAST_STEP: 3,
}

function mapStateToProps(state, ownProps) {
    return {
        selectedCompanyId: state.system.selectedCompanyId,
        workOrderName: state.workOrder.workOrderName,
        workOrderUuid: state.workOrder.workOrderUuid,
        originationId: state.workOrder.originationId,
        cloudJob: state.workOrder.cloudJob,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(workOrderActions, dispatch),
    }
}

class WorkOrderStepper extends React.Component {
    state = {
        stepIndex: stepValues.FIRST_STEP,
        visited: [stepValues.FIRST_STEP],
        saved: false,
    }

    componentDidMount() {
        if (!this.props.workOrderUuid) {
            this.props.generateWorkOrderUuid()
            if (this.props.existingWorkOrderId) {
                this.setState({ visited: [
                    stepValues.WO_NAME,
                    stepValues.GFF_SELECTION,
                    stepValues.PRODUCT_INFO,
                    stepValues.SCOUT_FEATURES],
                })
                this.props.loadWorkOrder(this.props.existingWorkOrderId)
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // When the selected company changes we need to reload base information or
        // clear the state to prevent the user from creating a workorder with information
        // they don't have access to, or does not belong together
        if (this.props.selectedCompanyId && this.props.selectedCompanyId !== prevProps.selectedCompanyId) {
            // Component is in create from template mode, reload the base workorder
            if (prevProps.existingWorkOrderId && !prevState.saved) {
                this.props.generateWorkOrderUuid()
                this.props.loadWorkOrder(prevProps.existingWorkOrderId)
            }
            // We are in basic workorder creation mode, clear selected information.
            else {
                this.clearState()
            }

            return
        }

        // Update which step is currently selected and which steps have all been visited
        const { stepIndex, visited } = this.state
        if (!visited.includes(stepIndex)) {
            this.setState({ visited: visited.concat(stepIndex) })
        }
    }

    handleNext = () => {
        const { stepIndex } = this.state
        if (stepIndex < stepValues.LAST_STEP) {
            this.setState({ stepIndex: stepIndex + 1 })
        }
    }

    handlePrev = () => {
        const { stepIndex } = this.state
        if (stepIndex > stepValues.FIRST_STEP) {
            this.setState({ stepIndex: stepIndex - 1 })
        }
    }

    onProcessed = () => {
        this.setState({saved: true})
    }
    clearState = () => {
        this.props.clearWorkOrder()
        this.setState({
            stepIndex: stepValues.FIRST_STEP,
            visited: [stepValues.FIRST_STEP],
            errorMessage: '',
            saved: false,
        })
        window.scrollTo(0, 0)
    }
    createAnother = () => {
        this.setState({
            stepIndex: stepValues.FIRST_STEP,
            saved: false,
        })
        this.props.setWorkOrderName('')
        this.props.generateWorkOrderUuid()
        window.scrollTo(0, 0)
    }
    getDisabled = () => {
        return !this.props.selectedCompanyId
    }
    getStepContent(stepIndex) {
        switch (stepIndex) {
            case stepValues.WO_NAME:
                return (
                    <div name="nameSectionContent" className="stepContent-Indented">
                        <TextField
                            floatingLabelText="Job Name"
                            fullWidth={true}
                            onChange={(event, value) => {
                                this.props.setWorkOrderName(value)
                            }}
                            value={this.props.workOrderName}
                            errorText={
                                (!this.props.workOrderName && 'Required Field') ||
                                (this.props.workOrderName.length > 32 && 'Max Length 32')
                            }
                            disabled={this.getDisabled() || this.state.saved}
                            name="nameEntry"
                        />
                    </div>
                )
            case stepValues.GFF_SELECTION:
                return (
                    <div name="gffSectionContent" className="stepContent-Indented">
                        <WorkOrderGFF disabled={this.getDisabled() || this.state.saved}/>
                    </div>
                )
            case stepValues.PRODUCT_INFO:
                return (
                    <div name="productSectionContent" className="stepContent-Indented">
                        <WorkOrderRates disabled={this.getDisabled() || this.state.saved}/>
                    </div>
                )
            case stepValues.SCOUT_FEATURES:
                return (
                    <div name="scoutSectionContent">
                        <WorkOrderScoutFeatures disabled={this.getDisabled() || this.state.saved}/>
                    </div>
                )
            default:
                return '404: Page not found - stepper out of bounds'
        }
    }

    renderStep = (stepLabel, stepValue) => {
        return (
            <Step active={this.state.visited.includes(stepValue)} className="stepperStep" >
                <StepLabel
                    iconContainerStyle={styles.iconStyle}
                    name={`${stepLabel}-Label`}
                    className="stepHeader"
                >
                    <RaisedButton
                        fullWidth={true}
                        label={stepLabel}
                        buttonStyle= {{textAlign: 'left'}}
                        labelStyle= {{marginLeft: '20px'}}
                        name={`${stepLabel}-StepButton`}
                        primary={this.state.stepIndex === stepValue}
                        onClick={() =>
                            this.setState({ stepIndex: stepValue })}
                        secondary={this.state.stepIndex !== stepValue}
                        disabled={this.getDisabled() || this.state.saved}
                    />
                </StepLabel>
                <StepContent>{this.getStepContent(stepValue)}</StepContent>
            </Step>
        )
    }

    render() {
        if (this.props.existingWorkOrderId && this.props.originationId == 'FC' && !this.props.cloudJob) {
            throw new Error('Cannot use an as-applied job as a template')
        }

        return (
            <div
                className="stepper"
                name="verticalStepper"
            >
                <Stepper
                    linear={false}
                    orientation="vertical"
                    style={styles.defaultContentcolor}
                >
                    {this.renderStep('Job Name', stepValues.WO_NAME)}
                    {this.renderStep('Grower, Farm, Field', stepValues.GFF_SELECTION)}
                    {this.renderStep('Product Config', stepValues.PRODUCT_INFO)}
                    {this.renderStep('Field Information', stepValues.SCOUT_FEATURES)}
                </Stepper>
                <JobCreateBar
                    saved={this.state.saved}
                    onSaved={this.onProcessed}
                    onTemplate={this.createAnother}
                    onReset={this.clearState}
                    disabled={this.getDisabled()}
                />
                <br/><br/>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderStepper)
