import React from 'react'
import frontEndRoutes from '../frontEndRouteTemplates'
import { Button } from '@atd/rui-base.mui.button'

const styles = {
    container: {
        paddingTop: 5,
        paddingLeft: 5,
        paddingRight: 5,
        textAlign: 'center',
    },
}
export const ErrorPage = ({is404}) => {
    const message = is404 ? 'The requested page could not be found.' : 'An error has occured. Please return to the home page.'

    return (
        <div className="workorder-wrapper" style={{marginTop: '20vh'}}>
            <div style={styles.container}>
                <img src={`${process.env.PUBLIC_URL}/images/Error_Tractor_Sm.png`}/><br />
                <h1>Sorry!</h1>
                <p>{message}</p>
                <Button
                    variant="outlined"
                    href={frontEndRoutes.jobsList}
                    sx={{ '&:hover': { textDecoration: 'none !important' }}}
                >
                    Return Home
                </Button>
            </div>
        </div>
    )
}
