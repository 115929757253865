import {Card} from 'material-ui/Card'
import ContentLoader from 'react-content-loader'
import React from 'react'

const JobCardLoader = (props) => (
    <div className="jobCard">
        <Card containerStyle={{paddingBottom: 0}}>
            <ContentLoader
                speed={2}
                width={'100%'}
                height={96}
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                {...props}
            >
                <rect x="16" y="20" rx="3" ry="3" width="45%" height="15" />
                <rect x="16" y="44" rx="3" ry="3" width="30%" height="14" />
                <rect x="16" y="68" rx="3" ry="3" width="60%" height="14" />
            </ContentLoader>
        </Card>
    </div>
)

export default JobCardLoader
