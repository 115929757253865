// Data stub for default values pre async calls to pull from the server.
// Demo purposes only
let growerId = 0
export function growerPrototype(
    name,
    externalId = '',
    address = '',
    address2 = '',
    city = '',
    county = '',
    region = '',
    country = '',
    postalCode = '',
    phoneNumber = '',
    mobileNumber = '',
    faxNumber = '',
    email = '',
    firstName = '',
    middleName = '',
    lastName = '',
    comments = ''
) {
    return {
        id: growerId++,
        name: name,
        externalId: externalId,
        address: address,
        address2: address2,
        city: city,
        county: county,
        region: region,
        country: country,
        postalCode: postalCode,
        phoneNumber: phoneNumber,
        mobileNumber: mobileNumber,
        faxNumber: faxNumber,
        email: email,
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        comments: comments,
    }
}
