import redirect from './redirect'
import store from '../store'
import {TrackJS} from 'trackjs'

export function errorCheck(response) {
    // more than likely the user navigated away from the page
    // and we got a "request aborted" error
    if (!response) {
        return
    }

    const url = response.request && response.request.url
    const respString = `${response.status} (${response.statusText}): ${url}`
    if (response.status === 401) {
        if (store) {
            const { auth0 } = store.getState()
            const {authTypeReducer} = auth0

            if (!authTypeReducer.isAuth0Enabled) redirect.toPortal()
        }
        else redirect.toPortal()
    }
    else if (TrackJS) {
        TrackJS.track(respString)

        const text = response.data

        TrackJS.console.log(text)
    }
}

export function errorCatch(error) {
    console.warn(error)
    error.error = true
}
