import React from 'react'
import { useParams } from 'react-router-dom'
import WorkOrderDetails from './workOrderDetails'

const styles = {
    container: {
        paddingTop: 5,
        paddingLeft: 5,
        paddingRight: 5,
    },
}
const AppWorkOrders = () => {
    const urlParams = useParams()

    return (
        <div className="workorder-wrapper">
            <h1>{'Details'}</h1>
            <div style={styles.container}>
                <WorkOrderDetails existingWorkOrderId={urlParams.id}/>
            </div>
        </div>
    )
}

export default AppWorkOrders
