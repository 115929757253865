import React from 'react'
import RaisedButton from 'material-ui/RaisedButton'
import AddIcon from 'material-ui/svg-icons/content/add'
import EmptyState from '../commonComponents/emptyState'
import ResetFilters from './resetFilters'

const styles = {
    resetButton: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '225px',
    },
    topMarginCenter: {
        marginTop: 40 + 'px',
        textAlign: 'center',
    },
}

const JobListEmpty = (props) => (
    <EmptyState style={{width: '100%'}} className="job-list-empty">
        {!props.error.value &&
            <div className="job-list-empty-text">
                <h3 style={ styles.topMarginCenter }>No jobs were found</h3>
                <div style={{paddingTop: 20 + 'px', paddingBottom: 20 + 'px', textAlign: 'center'}}
                    className="floatingAdd">
                    <RaisedButton
                        label="Create a New Job"
                        href={'/jobs/create'}
                        icon={<AddIcon/>}
                        primary={true}
                        fullWidth={false}
                    />
                </div>
                <p style={{textAlign: 'center'}}>Create a new job or adjust your filter settings.</p>
            </div>
        }
        {props.error.value &&
            <div>
                <h3 style={{ ...styles.topMarginCenter, color: 'red'}}>Error: Invalid Item(s) in URL</h3>
                <p style={{whiteSpace: 'pre-line'}}>{props.error.value}</p>
                <h4 style={{ color: 'red'}}>Click 'Reset Filters' to Clear the Errors and Start Again</h4>
                <div style={ styles.resetButton }>
                    <ResetFilters />
                </div>
            </div>
        }
    </EmptyState>
)

export default JobListEmpty
