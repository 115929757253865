import React from 'react'
import Dialog from 'material-ui/Dialog'
import DropDownMenu from 'material-ui/DropDownMenu'
import FlatButton from 'material-ui/FlatButton'
import MenuItem from 'material-ui/MenuItem'
import RaisedButton from 'material-ui/RaisedButton'
import TextField from 'material-ui/TextField'

import PropTypes from 'prop-types'

import { ingredientTypes } from './productPrototypes'
import { ACTUAL_MASS_CONTENT_DDI, ACTUAL_VOLUME_CONTENT_DDI } from '../lib/ddiConstants'
import {getMenuItemsForProductDDI} from '../commonComponents/unitMenuItems'
import {getUnitEquivalentForDDI} from '../lib/ddiUnits'

const typeMenuItems = []
for (let i = 0; i < ingredientTypes.length; i++) {
    typeMenuItems.push(<MenuItem value={ingredientTypes[i].name} key={ingredientTypes[i].name} primaryText={ingredientTypes[i].name}/>)
}
class IngredientEdit extends React.Component {
    static propTypes = {
        data: PropTypes.object,
    }

    static defaultProps = {
        data: {
            id: -1,
            name: '',
            typeId: 'Herbicide',
            ddiId: ACTUAL_VOLUME_CONTENT_DDI,
            manufacturer: '',
            defaultDisplayUnit: '',
            registration: '',
            note: '',
            composition: '',
            deprecated: false,
        },
    }

    state = {
        ...this.props.data,
        errorMessage: '',
    }

    handleTextChange = (event) => {
        const formData = {...this.state}
        formData[event.target.name] = event.target.value
        this.setState(formData)
    }

    handleDdi = (event, index, value) => {
        const displayUnit = getUnitEquivalentForDDI(this.state.defaultDisplayUnit, value)
        this.setState({ ddiId: value, defaultDisplayUnit: displayUnit })
    }
    handleTypeId = (event, index, value) => {
        this.setState({typeId: value})
    }
    handleDefaultUnit = (event, index, value) => {
        this.setState({defaultDisplayUnit: value})
    }
    handleSave = () => {
        if (!this.state.name || this.state.name.length > 32 ||
            this.state.manufacturer.length > 32 ||
            this.state.registration.length > 32 ||
            this.state.note.length > 330 ||
            this.state.composition.length > 330
        ) {
            this.setState({errorMessage: 'Invalid Data'})
        }
        else {
            this.props.onSave({ ...this.state })
        }
    }
    handleCancel = () => {
        this.props.onCancel()
    }

    render() {
        const units = getMenuItemsForProductDDI(this.state.ddiId)
        const editActions = [
            <div style={{display: 'inline-block', color: 'red'}}>{this.state.errorMessage}</div>,
            <FlatButton
                label="Cancel"
                primary={true}
                onClick={this.handleCancel}
                name="cancelButton"
            />,
            <RaisedButton
                icon={<img className="standardIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_SaveWhite.svg`}/>}
                label={'Save'}
                labelPosition="after"
                primary={true}
                onClick={this.handleSave}
                name="saveButton"
            />,
        ]

        return (
            <Dialog
                actions={editActions}
                title="Ingredient Information"
                modal={true}
                open={this.props.open}
                onRequestClose={this.handleSave}
                autoScrollBodyContent={true}
                contentClassName="wodialog"
                name="ingredientDialog"
            >
                <TextField
                    floatingLabelText="Ingredient Name"
                    fullWidth={true}
                    onChange={this.handleTextChange}
                    value={this.state.name}
                    name="name"
                    disabled={this.props.disabled}
                    errorText={(!this.state.name && 'Required Field') || (this.state.name.length > 32 && 'Max Length 32')}
                />
                <TextField
                    floatingLabelText="Manufacturer"
                    fullWidth={true}
                    onChange={this.handleTextChange}
                    value={this.state.manufacturer}
                    name="manufacturer"
                    disabled={this.props.disabled}
                    errorText={this.state.manufacturer && (this.state.manufacturer.length > 32 && 'Max Length 32')}
                />
                <DropDownMenu
                    value={this.state.typeId}
                    onChange={this.handleTypeId}
                    style={{ left: '-24px', top: '14px', width: '185px' }}
                    name="ingredientType"
                    disabled={this.props.disabled}
                >
                    {typeMenuItems}
                </DropDownMenu>
                <DropDownMenu
                    value={this.state.ddiId}
                    onChange={this.handleDdi}
                    style={{ display: 'inline-block', left: '-24px', top: '14px', width: '185px' }}
                    name="granularLiquidDropdown"
                    disabled={this.props.disabled}
                >
                    <MenuItem value={ACTUAL_MASS_CONTENT_DDI} primaryText={'Granular'}/>
                    <MenuItem value={ACTUAL_VOLUME_CONTENT_DDI} primaryText={'Liquid'}/>
                </DropDownMenu>
                <br/>
                Unit:
                <DropDownMenu
                    value={this.state.defaultDisplayUnit}
                    onChange={this.handleDefaultUnit}
                    style={{ display: 'inline-block', left: '-10px', top: '14px', width: '185px' }}
                    name="defaultUnitDropDown"
                    disabled={this.props.disabled}
                >
                    {units}
                </DropDownMenu>
                <TextField
                    floatingLabelText="Registration"
                    fullWidth={true}
                    onChange={this.handleTextChange}
                    value={this.state.registration}
                    name="registration"
                    disabled={this.props.disabled}
                    errorText={this.state.registration && (this.state.registration.length > 32 && 'Max Length 32')}
                />
                <TextField
                    floatingLabelText="Composition"
                    fullWidth={true}
                    onChange={this.handleTextChange}
                    value={this.state.composition}
                    name="composition"
                    disabled={this.props.disabled}
                    multiLine={true}
                    errorText={this.state.composition && (this.state.composition.length > 330 && 'Max Length 330')}
                />
                <TextField
                    floatingLabelText="Notes"
                    fullWidth={true}
                    onChange={this.handleTextChange}
                    value={this.state.note}
                    name="note"
                    disabled={this.props.disabled}
                    multiLine={true}
                    errorText={this.state.note && (this.state.note.length > 330 && 'Max Length 330')}
                />
            </Dialog>
        )
    }
}
export default IngredientEdit
