import { combineReducers } from 'redux'
import update from 'immutability-helper'

export function currentPosition(state = [], action) {
    switch (action.type) {
        case 'RECEIVE_POSITION':
            return update(state, {
                $set: action.currentPosition,
            })
        case 'GEOLOCATION_UNAVAILABLE':
        case 'REQUEST_POSITION':
        default:
            return state
    }
}

export function geolocation(state = [], action) {
    switch (action.type) {
        case 'SELECT_GEOLOCATION':
            return update(state, {
                $set: action.geolocation,
            })
        default:
            return state
    }
}

export const defaultLocation = {
    currentPosition: [],
    geolocation: {},
}
const locationReducer = combineReducers({
    currentPosition,
    geolocation,
})
export default locationReducer
