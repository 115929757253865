import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import geolib from 'geolib'
import ReactMapboxGl, { Feature, Layer, ZoomControl, GeoJSONLayer } from 'react-mapbox-gl'
import * as scoutActions from './scoutActions'
import * as locationActions from './locationActions'

function mapStateToProps(state, ownProps) {
    return {
        selectedField: state.gff.selectedField,
        selectedCentroid: state.scout.selectedCentroid,
        entrancePoints: state.scout.entrancePoints,
        boundaryPoints: state.scout.boundaryPoints,
        boundaryGeoJson: state.scout.boundaryGeoJson,
        currentPosition: state.scout.currentPosition,
        geolocation: state.scout.geolocation,
        currentProducts: state.product.currentProducts || [],
        rxFiles: state.product.rxFiles || [],
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(scoutActions, dispatch),
        ...bindActionCreators(locationActions, dispatch),
    }
}

const Map = ReactMapboxGl({
    accessToken: process.env.MAP_KEY,
})

const HIGH_SHAPE_LIMIT = 50000
const HIGH_SHAPE_WARNING = 25000
class ScoutFeatureMap extends React.Component {
    static defaultProps = {
        containerStyle: {
            width: '100%',
            height: '50vh',
            marginTop: '30px',
        },
        handleClick: () => {},
        interactive: true,
        initCenter: [-98.5795, 39.8283],
        initBbox: [-124.771694, 24.446667, -66.949778, 49.384472],
        zoom: [3],
    }

    state = {
        scoutBbox: null,
    }

    componentDidMount() {
        this.props.fetchCurrentPosition()
    }

    static getDerivedStateFromProps(props, state) {
        // we want a change in field or rxmap to override whatever is currently set for our bbox
        // In the event the user searches for something, treat it like a reset
        if (props.currentProducts !== state.prevProducts ||
            props.rxFiles !== state.prevRxFiles ||
            props.selectedField !== state.prevSelectedField ||
            props.geolocation !== state.prevGeoLocation
        ) {

            return {
                ...state,
                prevProducts: props.currentProducts,
                prevRxFiles: props.rxFiles,
                prevSelectedField: props.selectedField,
                prevGeoLocation: props.geolocation,
                scoutBbox: ScoutFeatureMap.getScoutBounds(props),
            }
        }
        // When they first set a scout point, go ahead and save a bbox to keep the map from moving
        else if (!state.scoutBbox && (props.selectedCentroid.length > 0 ||
            props.entrancePoints.length > 0 ||
            props.boundaryPoints.length > 0)
        ) {
            return {
                ...state,
                scoutBbox: ScoutFeatureMap.getScoutBounds(props),
            }
        }

        return null
    }

    // eslint-disable-next-line
    static getScoutBounds = (scoutData) => {
        const points = []

        if (scoutData.selectedCentroid && scoutData.selectedCentroid.length === 2) {
            points.push({latitude: scoutData.selectedCentroid[1], longitude: scoutData.selectedCentroid[0]})
        }
        if (scoutData.boundaryPoints && scoutData.boundaryPoints.length > 0) {
            for (const point of scoutData.boundaryPoints) {
                points.push({latitude: point[1], longitude: point[0]})
            }
        }
        if (scoutData.entrancePoints && scoutData.entrancePoints.length > 0) {
            for (const point of scoutData.entrancePoints) {
                points.push({latitude: point[1], longitude: point[0]})
            }
        }
        if (scoutData.currentProducts) { // ~10 miles (in meters))
            for (const product of scoutData.currentProducts) {
                // do not draw the rxMap if the product is not set to use it
                if (product.mode !== 'rxMap') continue

                const rxMap = scoutData.rxFiles.find(file => file.id === product.rxMapId) || {}
                if (rxMap && rxMap.shapes && rxMap.shapes.features && rxMap.shapes.features.length > HIGH_SHAPE_LIMIT) continue
                const bBox = rxMap.boundingBox
                if (bBox && bBox.length) {
                    const productPoints = [
                        {latitude: bBox[1], longitude: bBox[0]},
                        {latitude: bBox[3], longitude: bBox[2]},
                    ]
                    points.push(...productPoints)
                }
            }
        }

        if (points.length) {
            const bounds = geolib.getBounds(points)
            if (!Number.isNaN(bounds.longitude) && !Number.isNaN(bounds.latitude)) {
                return [bounds.minLng, bounds.minLat, bounds.maxLng, bounds.maxLat]
            }
        }

        return null
    }
    getDefaultBounds = (scoutData = this.props) => {
        if (scoutData.geolocation.bbox) {
            return [...scoutData.geolocation.bbox]
        }
        else if (scoutData.geolocation.center) {
            return [...scoutData.geolocation.center, ...scoutData.geolocation.center]
        }
        else if (scoutData.currentPosition.length === 2) {
            return [...scoutData.currentPosition, ...scoutData.currentPosition]
        }

        return [...scoutData.initBbox]
    }

    resetMapView = () => {
        this.setState({
            scoutBbox: ScoutFeatureMap.getScoutBounds(this.props),
        })
    }

    buildBoundaryCornerFeatures = () => {
        return this.props.boundaryPoints.map((data, i) => {

            return <Feature coordinates={data} key={i} />
        })
    }

    // Though this is a geojson object, we are going to render it using mapbox Layer not GeoJsonLayer
    // We can add Layer to the DOM immediatly, even if there aren't points, which allows us to keep
    // the layers rendered at the proper Z-index. Using GeoJsonLayer the polygons would end up on
    // top of the corner icons.
    buildBoundaryFeature = () => {
        if (!this.props.boundaryGeoJson || !this.props.boundaryGeoJson.features) {
            return
        }
        const features = []
        let key = 0
        // Feature will automaticially handle both Polygons and MutltiPolygons
        for (const feature of this.props.boundaryGeoJson.features) {
            features.push(<Feature coordinates={feature.geometry.coordinates} key={key} />)
            ++key
        }


        return features
    }
    buildFieldCenterLayer = () => {
        if (this.props.selectedCentroid.length === 2) {
            return (
                <Layer
                    type="symbol"
                    id="location"
                    layout={{ 'icon-image': 'Centroid' }}
                    paint={{ 'icon-color': '#FFFFFF', 'icon-halo-color': '#FFFFFF', 'icon-halo-width': 5 }}
                >
                    <Feature coordinates={this.props.selectedCentroid} />
                </Layer>
            )
        }
    }
    buildFieldEntranceLayer = () => {
        if (this.props.entrancePoints.length > 0) {
            const features = this.props.entrancePoints.map(
                (data, i) => { return <Feature coordinates={data} key={i} /> }
            )

            return (
                <Layer
                    type="symbol"
                    id="entrancePoints"
                    layout={{ 'icon-image': 'FieldEntry' }}
                >
                    {features}
                </Layer>
            )
        }
    }
    buildRxMaps = () => {
        if (this.props.currentProducts) {
            const layers = []
            const rxMaps = []
            for (const product of this.props.currentProducts) {
                // do not draw the rxMap if the product is not set to use it
                if (product.mode !== 'rxMap') continue
                // only draw an rx map one time, even if used by multiple products
                if (!product.rxMapId || rxMaps.includes(product.rxMapId)) { continue }
                rxMaps.push(product.rxMapId)
                const rxMap = this.props.rxFiles.find(file => file.id === product.rxMapId) || {}
                if (!rxMap || !rxMap.shapes || !rxMap.shapes.features || rxMap.shapes.features.length > HIGH_SHAPE_LIMIT) { continue }
                const geoId = `rxmap-${rxMap.id}-geoJson-${product.id}`
                layers.push(
                    <GeoJSONLayer
                        id={geoId}
                        key={geoId}
                        data={rxMap.shapes}
                        fillPaint={{ 'fill-outline-color': '#000000', 'fill-color': '#4bf442', 'fill-opacity': 0.5 }}
                    />
                )
            }

            return layers
        }
    }
    renderRxMapDisclaimer = () => {
        if (this.props.currentProducts) {
            let warn = false
            for (const product of this.props.currentProducts) {
                const rxMap = this.props.rxFiles.find(file => file.id === product.rxMapId) || {}
                if (rxMap && rxMap.shapes && rxMap.shapes.features && rxMap.shapes.features.length > HIGH_SHAPE_WARNING) {
                    warn = true
                    break
                }
            }
            if (warn) {
                const disclaimerString = `NOTE: One or more of your maps contains a high number of shapes. 
                    Rendering them within your web browser may not have ideal performance or be disabled.`

                return (
                    <div style={{color: 'orange'}}>
                        {disclaimerString}
                    </div>
                )
            }
        }
    }

    render() {
        const boundaryCorners = this.buildBoundaryCornerFeatures()
        const boundaryFeature = this.buildBoundaryFeature()
        const centerLayer = this.buildFieldCenterLayer()
        const entryLayer = this.buildFieldEntranceLayer()
        const rxMaps = this.buildRxMaps()

        // Scout bounding box is saved in state. Do NOT recalculate each time to prevent the map constantly moving on user
        // If no scouting box is set, check if we have other info to focus on (say a searched location)
        const bbox = this.state.scoutBbox || ScoutFeatureMap.getScoutBounds(this.props) || this.getDefaultBounds()

        return (
            <div style={{width: '100%', height: '100%'}}>
                <Map
                    style="mapbox://styles/slingshot/cj795wknd7v162ro6waofdhl8"
                    center={this.props.initCenter}
                    fitBounds={bbox}
                    fitBoundsOptions={{padding: 24}}
                    containerStyle={this.props.containerStyle}
                    onClick={this.props.handleClick}
                    zoom={this.props.zoom}
                    interactive={this.props.interactive}
                >
                    <link href="https://api.mapbox.com/mapbox-gl-js/v0.38.0/mapbox-gl.css" rel="stylesheet" />
                    <script src="https://api.tiles.mapbox.com/mapbox.js/v2.3.0/mapbox.js" />
                    <ZoomControl />
                    <button
                        type="button"
                        className="mapReset"
                        onClick={this.resetMapView}
                    >
                        <img className="mapResetIcon" src={`${process.env.PUBLIC_URL}/images/mapbox/Map_CenterZoom.svg`}/>
                    </button>
                    <Layer
                        type="fill"
                        id="boundary"
                        layout={{}}
                        paint={{ 'fill-outline-color': '#000000', 'fill-color': '#0026FF', 'fill-opacity': 0.5 }}
                    >
                        {boundaryFeature}
                    </Layer>
                    {rxMaps}
                    <Layer
                        type="symbol"
                        id="boundaryPoints"
                        layout={{'icon-image': 'Flag', 'icon-allow-overlap': true, 'icon-anchor': 'bottom', 'icon-offset': [5, 5]}}
                    >
                        {boundaryCorners}
                    </Layer>
                    {centerLayer}
                    {entryLayer}
                </Map>
                {this.renderRxMapDisclaimer()}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoutFeatureMap)
