// import Avatar from 'material-ui/Avatar'

export const jobStates = {
    NEW_JOB: 0,
    IN_PROGRESS: 1,
    INACTIVE: 2,
    ARCHIVED: 3,
}

export function getJobStatus(job = {}) {
    if (job.archived) {
        return jobStates.ARCHIVED
    }
    else if (job.originationId == 'USB') {
        // USB originated jobs are already applied jobs
        return jobStates.INACTIVE
    }
    else if (job.participants && job.participants.length) {
        for (const participant of job.participants) {
            if (participant.status === 'joined') {
                return jobStates.IN_PROGRESS
            }
        }

        return jobStates.INACTIVE
    }

    return jobStates.NEW_JOB
}

export function jobIsInProgress(job) {
    if (!job.participants || !job.participants.length) {
        return false
    }
    for (const participant of job.participants) {
        if (participant.status === 'joined') {
            return true
        }
    }

    return false
}

export function jobIsInactive(job) {
    if (!job.participants || !job.participants.length) {
        return false
    }
    for (const participant of job.participants) {
        if (participant.status === 'joined') {
            return false
        }
    }

    return true
}

export function jobStatusSort(jobA, jobB) {
    const statusA = getJobStatus(jobA)
    const statusB = getJobStatus(jobB)

    if (statusA == statusB) {
        return 0
    }
    // We want to show currently active jobs first
    if (statusA == jobStates.IN_PROGRESS) {
        return -1
    }
    else if (statusB == jobStates.IN_PROGRESS) {
        return 1
    }

    // Archived jobs are the lowest priority
    if (statusA == jobStates.ARCHIVED) {
        return 1
    }
    else if (statusB == jobStates.ARCHIVED) {
        return -1
    }

    // Our 'Coverage Applied' group
    if (statusA == jobStates.INACTIVE) {
        return -1
    }
    else if (statusB == jobStates.INACTIVE) {
        return 1
    }

    // New jobs
    if (statusA == jobStates.NEW_JOB) {
        return -1
    }
    else if (statusB == jobStates.NEW_JOB) {
        return 1
    }

    return -1
}
