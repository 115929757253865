import React from 'react'
import { useParams } from 'react-router-dom'
import LiveView from './liveView'

const styles = {
    container: {
        paddingTop: 5,
        paddingLeft: 15,
        paddingRight: 15,
    },
}

const AppLiveView = () => {
    const urlParams = useParams()

    return (
        <div className="workorder-wrapper">
            <div style={styles.container}>
                <LiveView jobUuid={urlParams.id}/>
            </div>
        </div>
    )
}

export default AppLiveView
