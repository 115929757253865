// Data stub for default values pre async calls to pull from the server.
// Demo purposes only
let fieldId = 0
export function fieldPrototype(
    growerId,
    farmId,
    name,
) {
    return {
        id: fieldId++,
        growerId: growerId,
        farmId: farmId,
        name: name,
        section: '',
        township: '',
        county: '',
        region: '',
        area: 0,
        displayArea: '0',
        areaDisplayUnit: 'ac',
        cropType: '',
        cropVariety: '',
        comments: '',
    }
}
