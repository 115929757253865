import { combineReducers } from 'redux'
import update from 'immutability-helper'

function dispatchSystems(state = [], action) {
    switch (action.type) {
        case 'RECEIVE_DISPATCH_SYSTEMS':
            return update(state, {
                $set: action.dispatchSystems,
            })
        default:
            return state
    }
}

export const defaultHardware = {
    dispatchSystems: [],
}
const hardwareReducer = combineReducers({
    dispatchSystems,
})

export default hardwareReducer
