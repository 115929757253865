import React from 'react'
import Checkbox from 'material-ui/Checkbox'
import RaisedButton from 'material-ui/RaisedButton'
import TextField from 'material-ui/TextField'
import axios from '../lib/axios'

export default class LiveView extends React.Component {
    state = {
        blob: {},
        error: '',
        useSinceTimestamp: true,
        companyIds: '',
        since: '1970-01-01T00:00:00Z',
    }
    errorCheck = (response) => {
        const url = response.request && response.request.url
        const respString = `${response.status} (${response.statusText}): ${url}`

        const text = response.data

        return {error: respString, body: text}
    }

    getJobList = () => {
        const axiosRequestConfig = {
            headers: {
                'company-party-ids': `[${this.state.companyIds}]`,
            },
        }

        return axios.get(`/jobs/net-api/jobsync${this.state.useSinceTimestamp ? `?since=${this.state.since}` : ''}`, axiosRequestConfig)
            .then(res => res.data)
            .then(json => {
                this.setState({
                    blob: {...json},
                    since: json.timestamp || this.state.since,
                })
            })
            .catch(err => {
                console.log(err)
                this.errorCheck(err.response)
                this.setState({blob: {error: err} })
            })
    }

    render() {
        return (
            <div>
                <div style={{ width: '100%', maxWidth: 700, margin: 'auto' }}>
                    <Checkbox
                        label="Use timestamp"
                        checked={this.state.useSinceTimestamp}
                        onCheck={() => this.setState({useSinceTimestamp: !this.state.useSinceTimestamp})}
                        name="sinceTimestampCheckbox"
                    />
                    <TextField
                        floatingLabelText="Last Event Timestamp"
                        floatingLabelFixed={true}
                        fullWidth={true}
                        hintText="Ex: 1970-01-01T00:00:00Z"
                        onChange={(e, value) => this.setState({since: value})}
                        value={this.state.since}
                        name="sinceTextbox"
                        disabled={!this.state.useSinceTimestamp}
                    />
                    <TextField
                        floatingLabelText="Company IDs"
                        floatingLabelFixed={true}
                        fullWidth={true}
                        hintText="Ex: 7557, 1, 24070, 24071"
                        onChange={(e, value) => this.setState({companyIds: value})}
                        value={this.state.companyIds}
                        name="companyIdTextbox"
                    />
                    <RaisedButton
                        label={'Send'}
                        onClick={this.getJobList}
                        name="submitButton"
                    />
                    <pre>
                        {JSON.stringify(this.state.blob, null, 2)}
                    </pre>
                </div>
            </div>
        )
    }
}
