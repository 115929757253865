import React from 'react'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'
import RaisedButton from 'material-ui/RaisedButton'

class DeleteButtonWithDialog extends React.Component {
    static defaultProps = {
        deleteFunction: () => {}, // called on delete put your handler here
        itemType: '', // used in verification text
        customContent: null, // override default verification
        primary: false, // color options. default black, true -> blue, takes priority over secondary
        secondary: false, // color options. default black, true -> grey
        disabled: false, // disable the button
        flat: true, // specify button styling. default flat, false -> raised
    }
    constructor(props) {
        super(props)
        this.state = {
            deleteDialogOpen: false,
        }
    }

    handleDeleteRequest = () => {
        this.setState({ deleteDialogOpen: true })
    }
    handleDelete = () => {
        this.props.deleteFunction()
        this.setState({ deleteDialogOpen: false })
    }
    handleCancel = () => {
        this.setState({ deleteDialogOpen: false })
    }

    renderContent = () => {
        if (this.props.customContent) {
            return this.props.customContent
        }

        return `Are you sure you wish to delete this ${this.props.itemType}?`
    }
    renderDialog = () => {
        if (!this.state.deleteDialogOpen) { return }
        const deleteActions = [
            <FlatButton
                label="Cancel"
                onClick={this.handleCancel}
                name="cancelDelete"
            />,
            <FlatButton
                label="Delete"
                icon={<img className="standardIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_DeleteRed.svg`}/>}
                labelStyle={{color: '#bf2727'}}
                onClick={this.handleDelete}
                name="confirmDelete"
            />,
        ]
        const content = this.renderContent()

        return (
            <Dialog
                actions={deleteActions}
                modal={false}
                open={true}
                onRequestClose={this.handleCancel}
            >
                {content}
            </Dialog>
        )
    }

    getDeleteIcon = () => {
        let color = 'White'
        if (this.props.disabled) {
            color = 'Disabled'
        }
        else if (this.props.primary && this.props.flat) {
            color = 'Blue'
        }
        else if (!this.props.primary && !this.props.secondary) {
            color = 'Black'
        }
        else if (this.props.secondary && this.props.flat) {
            // A dark grey would be a closer match
            color = 'Black'
        }

        return <img className="standardIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_Delete${color}.svg`}/>
    }

    getButton = () => {
        if (this.props.flat) {
            return (
                <FlatButton
                    icon={this.getDeleteIcon()}
                    label="Delete"
                    primary={this.props.primary}
                    secondary={this.props.secondary}
                    onClick={this.handleDeleteRequest}
                    name={`delete${this.props.itemType}`}
                    disabled={this.props.disabled}
                />
            )
        }

        return (
            <RaisedButton
                icon={this.getDeleteIcon()}
                label="Delete"
                primary={this.props.primary}
                secondary={this.props.secondary}
                onClick={this.handleDeleteRequest}
                name={`delete${this.props.itemType}`}
                disabled={this.props.disabled}
            />
        )
    }

    render() {
        const button = this.getButton()
        const dialog = this.renderDialog()

        return (
            <span>
                {button}
                {dialog}
            </span>
        )
    }
}
export default DeleteButtonWithDialog
