import React, { Component } from 'react'
import { ErrorPage } from './errorPage'
import { TrackJS } from 'trackjs'

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error, errorInfo) {
        if (errorInfo && errorInfo.componentStack) {
            // The component stack is sometimes useful in development mode
            // In production it can be somewhat obfuscated, so feel free to omit this line.
            console.log(errorInfo.componentStack)
        }

        // TrackJS should be configured and available before making this call
        // Either as an imported module or a global (ESLint global ignore is used above)
        TrackJS.track(error)

        this.setState({ error })
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <ErrorPage is404={false}/>
        }

        return this.props.children
    }
}
