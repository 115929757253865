import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import RaisedButton from 'material-ui/RaisedButton'
import AddIcon from 'material-ui/svg-icons/content/add'

import MixDialog from './mixCreation'
import MixReview from './mixReview'

import OneProduct from './oneProduct'

import * as ingredientActions from './ingredientActions'
import * as mixActions from './mixActions'
import * as rxMapActions from './rxMapActions'
import * as workOrderActions from '../workorder/workOrderActions'
import { selectMixes } from './productReducer'

import { productPrototype, mixPrototype } from './productPrototypes'
import { getUnitEquivalentForDDI } from '../lib/ddiUnits'
import { ddiFromDdiAndEntryMode } from '../lib/ddiConversions'

function mapStateToProps(state, ownProps) {
    return {
        mixes: selectMixes(state),
        currentProducts: state.product.currentProducts,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(workOrderActions, dispatch),
        ...bindActionCreators(ingredientActions, dispatch),
        ...bindActionCreators(mixActions, dispatch),
        ...bindActionCreators(rxMapActions, dispatch),
    }
}

// container for rateSelection. Handles creating and removing product lines
// during job, as well as marshaling updates between redux and the rendering components
class RateSelection extends React.Component {
    state = {
        mixEditOpen: false,
        mixReviewOpen: false,
        productIndex: -1,
        tempMix: {},
    }

    componentDidMount() {
        this.props.fetchCarriers()
        this.props.fetchIngredients()
        this.props.fetchMixes()
        this.props.fetchRxMaps()
    }

    handleNewMixRequest = id => {
        this.setState({
            tempMix: new mixPrototype(''),
            mixEditOpen: true,
            productIndex: id,
        })
    }
    handleMixReviewRequest = id => {
        const product = this.props.currentProducts.find(element => {
            return element.id === id
        })
        const mix = this.props.mixes.find(element => {
            return element.id === product.mixId
        })
        this.setState({
            tempMix: mix,
            mixReviewOpen: true,
        })
    }
    handleNewMixSave = newData => {
        this.props.addMix(newData).then(savedMix => {
            if (!savedMix || !savedMix.id) { return }
            const tempProducts = [...this.props.currentProducts]
            const i = tempProducts.findIndex(element => {
                return element.id === this.state.productIndex
            })
            tempProducts[i].mixId = savedMix.id
            tempProducts[i].ddiId = ddiFromDdiAndEntryMode(newData.ddiId, tempProducts[i].mode)
            tempProducts[i].userDisplayUnit = getUnitEquivalentForDDI(newData.userDisplayUnit, tempProducts[i].ddiId)
            tempProducts[i].rxMapUnit = getUnitEquivalentForDDI(newData.userDisplayUnit, tempProducts[i].ddiId)
            this.props.setProducts(tempProducts)
            this.setState({
                mixEditOpen: false,
                productIndex: -1,
                tempMix: {},
            })
        })
    }
    handleEditCancel = () => {
        this.setState({
            mixEditOpen: false,
            mixReviewOpen: false,
            tempMix: {},
        })
    }

    handleNewProduct = () => {
        const tempProducts = [...this.props.currentProducts, new productPrototype()]
        this.props.setProducts(tempProducts)
    }
    handleDeleteProduct = id => {
        const tempProducts = this.props.currentProducts.filter(mix => mix.id !== id)
        this.props.setProducts(tempProducts)
    }
    updateSelectedMix = (id, mixId) => {
        const tempProducts = [...this.props.currentProducts]
        const i = tempProducts.findIndex(element => {
            return element.id === id
        })
        tempProducts[i].mixId = mixId

        const selectedMix = this.props.mixes.find(mix => { return mix.id === mixId })
        if (selectedMix !== undefined) {
            tempProducts[i].ddiId = ddiFromDdiAndEntryMode(selectedMix.ddiId, tempProducts[i].mode)
            tempProducts[i].userDisplayUnit = getUnitEquivalentForDDI(selectedMix.userDisplayUnit, tempProducts[i].ddiId)
            tempProducts[i].rxMapUnit = getUnitEquivalentForDDI(selectedMix.userDisplayUnit, tempProducts[i].ddiId)
        }
        this.props.setProducts(tempProducts)
    }
    setProductProperty = (id, property, value) => {
        const tempProducts = [...this.props.currentProducts]
        const i = tempProducts.findIndex(element => {
            return element.id === id
        })
        tempProducts[i][property] = value
        this.props.setProducts(tempProducts)
    }
    updateMode = (id, mode) => {
        const tempProducts = [...this.props.currentProducts]
        const i = tempProducts.findIndex(element => {
            return element.id === id
        })
        tempProducts[i].mode = mode

        const selectedMix = this.props.mixes.find(mix => { return mix.id === tempProducts[i].mixId })
        if (selectedMix !== undefined) {
            tempProducts[i].ddiId = ddiFromDdiAndEntryMode(selectedMix.ddiId, mode)
        }
        this.props.setProducts(tempProducts)
    }

    renderMixDialog = () => {
        if (this.state.mixEditOpen) {
            return (
                <MixDialog
                    data={this.state.tempMix}
                    open={true}
                    onCancel={this.handleEditCancel}
                    onSave={this.handleNewMixSave}
                    disabled={this.props.disabled}
                />
            )
        }
        else if (this.state.mixReviewOpen) {
            return (
                <MixReview
                    data={this.state.tempMix}
                    open={true}
                    onClose={this.handleEditCancel}
                    disabled={true}
                />
            )
        }
    }
    renderProducts = () => {
        return this.props.currentProducts.map(product => {
            return (
                <OneProduct
                    key={product.id}
                    data={product}
                    selectMix={this.updateSelectedMix}
                    setProductProperty={this.setProductProperty}
                    selectMode={this.updateMode}
                    deleteProduct={this.handleDeleteProduct}
                    newMixRequest={this.handleNewMixRequest}
                    mixReviewRequest={this.handleMixReviewRequest}
                    disabled={this.props.disabled}
                />
            )
        })
    }

    render() {
        const productList = this.renderProducts()
        const mixEditDialog = this.renderMixDialog()

        return (
            <div style={{ paddingTop: 10 }} name="rateSetup">
                {productList}
                <div style={{ paddingTop: 10 }} />
                <RaisedButton
                    icon={<AddIcon />}
                    label="Add Product"
                    onClick={this.handleNewProduct}
                    disabled={this.props.currentProducts.length >= 10 || this.props.disabled}
                    className="mobileButton"
                    name="addProductButton"
                />
                {mixEditDialog}
            </div>
        )
    }
}
// be sure to connect the component to the Redux state so it can use it!
export default connect(mapStateToProps, mapDispatchToProps)(RateSelection)
