import React from 'react'
import { connect } from 'react-redux'

import IconButton from 'material-ui/IconButton'
import MenuItem from 'material-ui/MenuItem'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton'
import RaisedButton from 'material-ui/RaisedButton'
import SelectField from 'material-ui/SelectField'
import TextField from 'material-ui/TextField'

import AddIcon from 'material-ui/svg-icons/content/add'
import InfoIcon from 'material-ui/svg-icons/action/info'

import OneProductRx from './oneProductRx'

import { checkProductLimits } from '../lib/ddiUnits'
import { getMenuItemsForProductDDI } from '../commonComponents/unitMenuItems'
import { selectMixes } from './productReducer'

function mapStateToProps(state, ownProps) {
    return {
        mixes: selectMixes(state),
        currentProducts: state.product.currentProducts,
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

class OneProduct extends React.Component {
    static defaultProps = {
        data: {},
    }
    componentDidMount() {
        this.autoSelectMix(this.props.mixes, this.props.data.mixId)
    }

    componentDidUpdate(prevProps) {
        if (this.props.mixes !== prevProps.mixes || this.props.data.mixId !== prevProps.data.mixId) {
            this.autoSelectMix(this.props.mixes, this.props.data.mixId)
        }
    }

    state = {
        warningDialogOpen: false,
    }

    autoSelectMix(mixes, mixId) {
        // current mix is good
        if (mixId > 0 &&
            mixes.find(mix => { return mix.id === mixId })
        ) {
            return
        }

        // if no mix is selected, or the selected mi
        const water = mixes.find(mix => { return mix.name === 'Water' })
        if (water !== undefined) {
            this.props.selectMix(this.props.data.id, water.id)
        }
        else if (mixes.length > 0) {
            this.props.selectMix(this.props.data.id, mixes[0].id)
        }
    }
    handleNewMixRequest = () => {
        this.props.newMixRequest(this.props.data.id)
    }
    handleInfo = () => {
        this.props.mixReviewRequest(this.props.data.id)
    }
    handleMixSelect = (event, index, value) => {
        if (value >= 0) {
            this.props.selectMix(this.props.data.id, value)
        }
        else {
            this.handleNewMixRequest()
        }
    }
    handleTextChange = (event) => {
        this.props.setProductProperty(this.props.data.id, event.target.name, event.target.value)
    }

    handleUnit = (event, index, value) => {
        this.props.setProductProperty(this.props.data.id, 'userDisplayUnit', value)
    }

    handleModeChange = (event, value) => {
        this.props.selectMode(this.props.data.id, value)
    }
    handleDelete = event => {
        this.props.deleteProduct(this.props.data.id)
    }
    createMixDropDownList = () => {
        const addButton = (
            <MenuItem value={-1} key={'new'} label={'Select a Mix'} name="newMix">
                <RaisedButton
                    primary={true}
                    fullWidth={true}
                    icon={<AddIcon />}
                    onClick={this.handleNewMixRequest}
                    name="addMixButton"
                />
            </MenuItem>
        )

        return [
            addButton,
            ...this.props.mixes.map((data) => {
                return <MenuItem key={data.id} value={data.id} primaryText={data.name} name={data.id}/>
            }),
        ]
    }

    renderDelete = () => {
        if (this.props.disabled) return

        return (
            <IconButton
                className="productDelete"
                name="deleteProduct"
                onClick={this.handleDelete}
            >
                <img src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_DeleteBlack.svg`}/>
            </IconButton>
        )
    }
    getRateLabel = () => {
        if (this.props.data.mode === 'targetRate') { return 'Target Rate' }
        else if (this.props.data.mode === 'rxMap') { return 'Default Rate' }
    }
    getRxSection = (rateUnits) => {
        if (this.props.data.mode !== 'rxMap') { return }

        return (
            <OneProductRx
                data={this.props.data}
                rateUnits={rateUnits}
                setProductProperty={this.props.setProductProperty}
                disabled={this.props.disabled}
            />)
    }
    renderRateInfo = () => {
        const rateUnits = getMenuItemsForProductDDI(this.props.data.ddiId)
        const errorText = checkProductLimits(this.props.data.displayRate, this.props.data.userDisplayUnit, this.props.data.mode == 'rxMap')
        const rateLabel = this.getRateLabel()
        const rxSection = this.getRxSection(rateUnits)

        return (
            <div className="rates" name="rateInfo">
                {rxSection}
                <TextField
                    className="textField"
                    floatingLabelText={rateLabel}
                    onChange={this.handleTextChange}
                    defaultValue={this.props.data.displayRate}
                    disabled={this.props.disabled}
                    errorText={errorText}
                    name="displayRate"
                />
                <SelectField
                    className="selectField"
                    value={this.props.data.userDisplayUnit}
                    onChange={this.handleUnit}
                    errorText={!this.props.data.userDisplayUnit && 'Required Field'}
                    disabled={this.props.disabled}
                    name="rateUnits"
                >
                    {rateUnits}
                </SelectField>
            </div>
        )

    }

    render() {
        const availMixes = this.createMixDropDownList()
        const rateEntry = this.renderRateInfo()
        const deleteButton = this.renderDelete()

        return (
            <div style={{borderBottom: 'solid'}} name={`Product-${this.props.data.id}`}>
                <SelectField
                    className="mixSelectField"
                    value={this.props.data.mixId}
                    style={{marginTop: '10px'}}
                    onChange={this.handleMixSelect}
                    errorText={this.props.data.mixId <= 0 && 'Required Field'}
                    disabled={this.props.disabled}
                    floatingLabelText="Mix"
                    name="mixSelect"
                >
                    {availMixes}
                </SelectField>
                <IconButton
                    className="mixInfoButton"
                    name="mixInfoButton"
                    onClick={this.handleInfo}
                >
                    <InfoIcon/>
                </IconButton>
                {deleteButton}
                <div className="rowFlex">
                    <div className="radioButtonGroup" name="rateRadioButtons">
                        <label>Rate:</label>
                        <RadioButtonGroup
                            name="rateMode"
                            valueSelected={this.props.data.mode}
                            onChange={this.handleModeChange}
                        >
                            <RadioButton
                                className="radioButton"
                                value="targetRate"
                                disabled={this.props.disabled}
                                checkedIcon={
                                    <img className="clickedIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_Target.svg`}/>
                                }
                                uncheckedIcon={
                                    <img className="clickableIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_Target.svg`}/>
                                }
                                name="targetRateButton"
                            />
                            <RadioButton
                                className="radioButton"
                                value="rxMap"
                                disabled={this.props.disabled}
                                checkedIcon={
                                    <img className="clickedIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_Rx.svg`}/>
                                }
                                uncheckedIcon={
                                    <img className="clickableIcon" src={`${process.env.PUBLIC_URL}/images/jobIcons/Job_Rx.svg`}/>
                                }
                                name="rxMapButton"
                            />
                        </RadioButtonGroup>
                    </div>
                    {rateEntry}
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OneProduct)
