import './appBarStyles.css'

import * as jwt from 'jsonwebtoken'
import * as systemActions from './systemActions'

import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'

import { AppsHeader } from '@atd/one-slingshot.components.apps-header'
import Cookies from 'universal-cookie'
import FlatButton from 'material-ui/FlatButton'
import { TrackJS } from 'trackjs'
import axios from 'axios'
import { getHost } from '../lib/redirect'
import {searchQueryString} from '../workorder/queryString'

const createNavSections = ({
    showServiceMenu,
}) => ([
    {
        navLinks: [
            {
                name: 'GFF Manager',
                href: '/gff-manager',
                active: window.location.pathname === '/gff-manager',
            },
            {
                name: 'Job Generator',
                href: '/jobs/create',
                active: window.location.pathname === '/jobs/create',
            },
            {
                name: 'Job List',
                href: '/jobs/list',
                // no active, this is covered by the main menu section and we don't want two lines highlighted
            },
            {
                name: 'Mix Manager',
                href: '/mix-manager',
                active: window.location.pathname === '/mix-manager',
            },
        ],
    },
    ...(showServiceMenu ? [{
        navLinks: [
            {
                name: 'Bundle Management',
                href: '/bundles',
                active: window.location.pathname === '/bundles',
            },
            {
                name: 'Job Sync Debug',
                href: '/jobs/job-sync-debug',
                active: window.location.pathname === '/jobs/job-sync-debug',
            },
        ],
    }] : []),
])

const AppBarSlingShot = ({
    pageTitle = 'Slingshot',
}) => {
    const dispatch = useDispatch()
    const systemStore = useSelector(store => store.system)
    const auth0Store = useSelector(store => store.auth0)

    const {availableCompanies, selectedCompanyId, cloaked, maintenanceAlerts, languages, unitsOfMeasurement, userPreferences, contactInfo} = systemStore

    const sortedCompanies = []
    if (Array.isArray(availableCompanies)) {
        sortedCompanies.push(...availableCompanies)
        sortedCompanies.sort((a, b) => {
            if (a.companyName < b.companyName) return -1
            if (a.companyName > b.companyName) return 1

            return 0
        })
    }

    const [urlCompanyID, setUrlCompanyID] = useState(null)

    const serviceAccess = systemActions.userHasRavenServiceAccess()
    const engineeringAccess = systemActions.userHasDeveloperAccess()

    const checkSelectedCompany = (companies = availableCompanies, scid = selectedCompanyId) => {
        // Wait until we have received a list of companies from our backend to validate the selected company ID.
        if (!companies) {
            console.log('Companies not loaded yet')

            return
        }

        TrackJS.console.debug('Available Companies', companies)
        if (companies.length > 0) {
            if (scid && companies.find(company => { return company.id === scid })) {
                if (selectedCompanyId !== scid) {
                    dispatch(systemActions.selectCompany(scid))
                }
            }
            else {
                dispatch(systemActions.selectCompany(companies[0].id))
            }
        }
        else if (scid) {
            dispatch(systemActions.selectCompany(null))
        }
    }

    const handleCompanySelect = (value) => {
        if (value.id != selectedCompanyId) {
            dispatch(systemActions.selectCompany(value.id))
            dispatch(systemActions.isJobFilterFromURL(false))
            setUrlCompanyID(value.id)
            TrackJS.addMetadata('selectedCompanyId', value.id)
        }
    }

    const handleUncloak = (event) => {
        if (event === 'clickaway') { return }

        const { isAuth0Enabled } = auth0Store.authTypeReducer
        // User id param is optional, setting it to zero for backwards compatibility
        let userId = 0
        if (!isAuth0Enabled) {
            // Portal
            const cookies = new Cookies()
            const userInfo = jwt.decode(cookies.get('jwt'))
            userId = userInfo.user_id
        }

        const host = getHost()
        const portalUrl = `https://${host}/index.php?r=site/unCloakUser&original_user_party_id=${userId}`
        const uninterceptedAxiosInstance = axios.create()
        uninterceptedAxiosInstance.get(portalUrl)
        dispatch(systemActions.uncloak())
    }

    const renderCloak = () => {
        if (cloaked?.username) {
            return (
                <div name="cloakHeader" className="cloakWrapper">
                    <div className="cloakStatus">
                        Currently cloaking {cloaked.username} ({cloaked.id}).
                        <FlatButton
                            key="close"
                            onClick={handleUncloak}
                            label="Uncloak"
                        />
                    </div>
                </div>
            )
        }
    }

    const renderMaintenanceAlerts = () => {
        if (!maintenanceAlerts || !maintenanceAlerts.length) return

        const alerts = []
        for (const alert of maintenanceAlerts) {
            if (alert.MaintenanceScope && alert.MaintenanceScope.DisplayName == '*') {
                alerts.push(<div key={alert.id} className="alertStatus" dangerouslySetInnerHTML={{__html: alert.MessageText}}></div>)
            }
            else if (alert.maintenanceScope && alert.maintenanceScope.displayName == '*') {
                alerts.push(<div key={alert.id} className="alertStatus" dangerouslySetInnerHTML={{__html: alert.messageText}}></div>)
            }
        }

        if (!alerts.length) return

        return (
            <div name="maintenanceHeader" className="maintenanceWrapper">
                {alerts}
            </div>
        )
    }

    const navSections = createNavSections({
        showServiceMenu: serviceAccess,
    })

    const companySelect = {
        label: '',
        value: {
            id: urlCompanyID || selectedCompanyId,
            label: sortedCompanies.find(company => company.id === (urlCompanyID || selectedCompanyId))?.companyName || 'Unknown',
        },
        onChange: handleCompanySelect,
        items: sortedCompanies.map(company => ({label: company.companyName, id: company.id})),
    }

    const userPrefs = {
        language: userPreferences.language,
        unitOfMeasurement: userPreferences.unitOfMeasurement,
        mapRefreshInterval: userPreferences.mapRefreshIntervalMilliseconds,
        sessionTimeout: userPreferences.idleSessionTimeoutSeconds,
    }

    const onSave = async(userPreferences) => {
        const result = await dispatch(systemActions.saveUserPreferences({
            language: userPreferences.language,
            unitOfMeasurement: userPreferences.unitOfMeasurement,
            mapRefreshIntervalMilliseconds: userPreferences.mapRefreshInterval,
            idleSessionTimeoutSeconds: userPreferences.sessionTimeout,
            newPassword: userPreferences.newPassword,
        }))

        return result
    }

    useEffect(() => {
        dispatch(systemActions.fetchFileTypes())
        dispatch(systemActions.fetchMaintenanceAlerts())
        dispatch(systemActions.fetchLanguages())
        dispatch(systemActions.fetchUnitsOfMeasurement())
        dispatch(systemActions.fetchUserPreferences())
        dispatch(systemActions.fetchContactInfo())

        dispatch(systemActions.fetchCompanyInfo()).then((companies) => {
            const selectedCompanyId = systemActions.getSelectedCompanyCookie()
            checkSelectedCompany(companies.availableCompanies, selectedCompanyId)
        }).catch(() => { dispatch(systemActions.selectCompany(null)) })
    }, [])

    useEffect(() => {
        checkSelectedCompany(availableCompanies, selectedCompanyId)
    }, [availableCompanies, selectedCompanyId])

    useEffect(() => {
        dispatch(systemActions.isJobFilterFromURL(true))
        const id = parseInt(searchQueryString('selectedCompanyId'), 10)
        if (id && !isNaN(id)) {
            setUrlCompanyID(id)
            dispatch(systemActions.selectCompany(id))
        }
    }, [])

    const userAccountInfo = {
        name: contactInfo.firstName + ' ' + contactInfo.lastName,
        email: contactInfo.emailAddress,
    }

    return (
        <div id="app-bar">
            {renderCloak()}
            {renderMaintenanceAlerts()}
            <AppsHeader
                pageTitle={pageTitle}
                showEngineeringMenu={engineeringAccess}
                showServiceMenu={serviceAccess}
                bottomNavTitle={'Job List Shortcuts'}
                bottomNavSections={navSections}
                companySelectProps={companySelect}
                languages={languages.map(l => ({label: l.name, value: l.code}))}
                unitsOfMeasure={unitsOfMeasurement.map(uom => ({label: uom, value: uom}))}
                userPreferences={userPrefs}
                saveUserPreferences={onSave}
                userAccountInfo={userAccountInfo}
            />
        </div>
    )
}

export default AppBarSlingShot
