import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import IconButton from 'material-ui/IconButton'
import RaisedButton from 'material-ui/RaisedButton'
import {
    Table,
    TableBody,
    TableHeader,
    TableHeaderColumn,
    TableRow,
    TableRowColumn,
} from 'material-ui/Table'
import AddIcon from 'material-ui/svg-icons/content/add'
import EditIcon from 'material-ui/svg-icons/content/create'

import DeleteButton from '../commonComponents/deleteDialogButton'
import ListPaging from '../commonComponents/listPaging'
import MixEdit from './mixCreation'
import TableRowColumnWrapper from '../commonComponents/tableRowColumnWrapper'
import ProductLoader from './productLoader'
import EmptyState from '../commonComponents/emptyState'
import ProductError from './productError'

import * as ingredientActions from './ingredientActions'
import * as mixActions from './mixActions'
import { mixPrototype } from './productPrototypes'

function mapStateToProps(state, ownProps) {
    // Users are only allowed to manage/edit mixes that belong to their company
    // not universal mixes like water
    const mixProps = state.product.mixes || {}

    return {
        isLoading: mixProps.isLoading,
        error: mixProps.error,
        mixes: mixProps.data.filter(m => m.ownerPartyId == state.system.selectedCompanyId),
        selectedCompanyId: state.system.selectedCompanyId,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(ingredientActions, dispatch),
        ...bindActionCreators(mixActions, dispatch),
    }
}

class MixManager extends React.Component {
    state = {
        editMixId: null,
        newMixOpen: false,
        selectedMixes: [],
        viewList: [],
        startViewIndex: 0,
        lastViewIndex: 0,
    }
    getDisabled = () => {
        return !this.props.selectedCompanyId
    }
    componentDidMount() {
        this.props.fetchCarriers()
        this.props.fetchIngredients()
        this.props.fetchMixes()
    }

    editMixRequest = (id) => {
        this.setState({editMixId: id})
    }
    onEditMixCancel = () => {
        this.setState({editMixId: null, newMixOpen: false})
    }
    onEditMixSave = (newMixData) => {
        this.props.updateMix(newMixData)
        this.setState({editMixId: null, selectedMixes: []})
    }
    newMixRequest = () => {
        this.setState({ newMixOpen: true })
    }
    onNewMixSave = (newData) => {
        this.props.addMix(newData)
        this.setState({ newMixOpen: false, selectedMixes: [] })
    }
    onMixDelete = () => {
        this.props.deleteMixes(this.state.selectedMixes).then(rVal => {
            if (rVal && !rVal.error) {
                this.setState({selectedMixes: []})
            }
        })
    }
    onViewListChanged = (newList, startIndex, lastIndex) => {
        this.setState({
            viewList: [...newList],
            startViewIndex: startIndex,
            lastViewIndex: lastIndex,
        })
    }

    handleMixRowSelect = (selection) => {
        // selection only contains information for the current page
        // we want to maintain any already selected mixes
        const mixes = this.props.mixes.filter((mix, i) => {
            return ((i < this.state.startViewIndex || i > this.state.lastViewIndex) &&
                this.state.selectedMixes.includes(mix.id)
            )
        }).map(ing => { return ing.id })
        // We don't support select all on this page so selection will
        // contain an array of the selected row indexes
        for (const row of selection) {
            mixes.push(this.state.viewList[row].id)
        }
        this.setState({selectedMixes: mixes})
    }
    mapMixesToTableRows = (mix, index) => {
        let ingredients = 'none'
        if (mix.mixIngredients) {
            const ingNames = mix.mixIngredients.map((ing) => {
                return ing.ingredient ? (ing.ingredient.name || ing.ingredientId) : ing.ingredientId
            })
            ingredients = `(${mix.mixIngredients.length}) ${ingNames.join(', ')}`
        }

        return (
            <TableRow key={mix.id} selected={this.state.selectedMixes.includes(mix.id)} name={mix.id}>
                <TableRowColumn className="pmColumn">{mix.name}</TableRowColumn>
                <TableRowColumn className="pmColumn">{mix.carrier ? mix.carrier.name : ''}</TableRowColumn>
                <TableRowColumn className="pmColumn">{ingredients}</TableRowColumn>
                <TableRowColumnWrapper className="pmColumn">
                    <IconButton
                        disabled={this.getDisabled()}
                        onClick={() => { this.editMixRequest(mix.id) }}
                        name={`edit-${mix.id}`}
                    ><EditIcon />
                    </IconButton>
                </TableRowColumnWrapper>
            </TableRow>
        )
    }
    renderMixEdit = () => {
        if (!this.state.editMixId && !this.state.newMixOpen) return
        let mix = {}

        if (this.state.editMixId) {
            mix = this.props.mixes.find(element => {
                return element.id === this.state.editMixId
            })
        }
        else {
            mix = new mixPrototype('')
        }

        return (
            <MixEdit
                open={true}
                data={mix}
                onSave={this.state.editMixId ? this.onEditMixSave : this.onNewMixSave}
                onCancel={this.onEditMixCancel}
            />
        )
    }
    renderEmptyState = (mixEdit) => {
        return (
            <EmptyState>
                <div style={{paddingTop: '40px'}} >
                    <h3>No mixes found</h3>
                    <div style={{margin: '20px 0'}}>
                        <RaisedButton
                            label="Create Mix"
                            onClick={this.newMixRequest}
                            icon={<AddIcon/>}
                            primary={true}
                            fullWidth={false}
                        />
                    </div>
                    {mixEdit}
                </div>
            </EmptyState>
        )
    }
    renderTable = (mixEdit) => {
        const mixRows = this.state.viewList.map(this.mapMixesToTableRows)

        return (
            <div>
                <RaisedButton
                    primary={true}
                    icon={<AddIcon />}
                    label="Add Mix"
                    onClick={this.newMixRequest}
                    disabled={this.getDisabled()}
                    name="addMix"
                    style={{marginBottom: 10, marginRight: 10}}
                />
                <DeleteButton
                    itemType="mix"
                    deleteFunction={this.onMixDelete}
                    primary={true}
                    disabled={!this.state.selectedMixes.length || this.getDisabled()}
                    customContent={'Are you sure you wish to delete these mixes? This will not affect any generated jobs'}
                    flat={false}
                />
                <Table
                    fixedHeader={false}
                    className="pmTable"
                    style={{ tableLayout: 'auto', whiteSpace: 'normal' }}
                    selectable={!this.getDisabled()}
                    multiSelectable={true}
                    onRowSelection={this.handleMixRowSelect}
                    name="pmTable"
                >
                    <TableHeader displaySelectAll={false}>
                        <TableRow>
                            <TableHeaderColumn className="pmColumn">{'Name'}</TableHeaderColumn>
                            <TableHeaderColumn className="pmColumn">{'Carrier'}</TableHeaderColumn>
                            <TableHeaderColumn className="pmColumn">{'Ingredients'}</TableHeaderColumn>
                            <TableHeaderColumn className="pmColumn">{''}</TableHeaderColumn>
                        </TableRow>
                    </TableHeader>
                    <TableBody deselectOnClickaway={false} stripedRows={true}>
                        {mixRows}
                    </TableBody>
                </Table>
                <ListPaging
                    countPerPage={15}
                    disabled={this.getDisabled()}
                    itemList={this.props.mixes}
                    viewListChanged={this.onViewListChanged}
                />
                {mixEdit}
            </div>
        )
    }
    render() {
        const mixEdit = this.renderMixEdit()

        return (
            <>
                {this.props.isLoading && !this.props.error && <ProductLoader />}
                {!this.props.isLoading && !this.props.error && !this.props.mixes.length && this.renderEmptyState(mixEdit)}
                {!this.props.isLoading && this.props.error && <ProductError error={this.props.error} />}
                {!this.props.isLoading && !this.props.error && this.props.mixes.length !== 0 && this.renderTable(mixEdit)}
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MixManager)
