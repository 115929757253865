import axios from '../lib/axios'
import {errorCheck, errorCatch} from '../lib/actionErrorHandler'
import {alphanumSanitize} from '../lib/stringValidation'

export function selectGrower(grower = {id: -1}) {
    return {
        type: 'SELECT_GROWER',
        grower,
    }
}

export function addGrower(grower) {
    return dispatch => {
        grower.postalCode = alphanumSanitize(grower.postalCode)

        return axios.post('/jobs/api/growers/', grower)
            .then(response => response.data)
            .then(savedGrower => dispatch(selectGrower(savedGrower)))
            .then(() => dispatch(fetchGrowers()))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
            })
    }
}

export function updateGrower(grower) {
    return dispatch => {
        grower.postalCode = alphanumSanitize(grower.postalCode)

        return axios.put(`/jobs/api/growers/${grower.id}`, grower)
            .then(response => response.data)
            .then(() => dispatch(fetchGrowers()))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
            })
    }
}

export function deleteGrower(growerId) {
    return dispatch => {
        return axios.delete(`/jobs/api/growers/${growerId}`)
            .then(() => dispatch(fetchGrowers()))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
            })
    }
}

export function fetchGrowers() {
    return dispatch => {
        dispatch(requestGrowers())

        return axios.get('/jobs/api/growers')
            .then(response => response.data)
            .then(json => dispatch(receiveGrowers(json)))
            .catch(err => {
                errorCheck(err.response)
                errorCatch(err)
                dispatch(receiveGrowers([]))
            })
    }
}

function requestGrowers() {
    return {
        type: 'REQUEST_GROWERS',
    }
}

function receiveGrowers(growers) {
    if (!Array.isArray(growers)) { growers = [] }

    return {
        type: 'RECEIVE_GROWERS',
        growers: growers,
    }
}
