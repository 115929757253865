import './rxMapViewerStyles.css'

import React, {useEffect, useState} from 'react'
import { areaFromRateUnit, findUnitByName } from '../../lib/ddiUnits'
import { countPerAreaUnits, massPerAreaUnits, volumePerAreaUnits } from '../../lib/units'

import { Button } from '@atd/rui-base.mui.button'
import { CircularProgress } from '@atd/rui-base.mui.circular-progress'
import FileFileDownload from 'material-ui/svg-icons/file/file-download'
import { ReportSectionParentFlex } from '@atd/one-slingshot.report.report-section-parent-flex'
import { RuiRatelegend } from '@atd/rui.components.rui-ratelegend'
import { RuiRatemap } from '@atd/rui.components.rui-ratemap'
import { RuiSelect } from '@atd/rui.components.rui-select'
import axios from '../../lib/axios'
import { useParams } from 'react-router-dom'
import {format} from 'date-fns'

const rateUnitOptions = [
    ...volumePerAreaUnits,
    ...massPerAreaUnits,
    ...countPerAreaUnits,
].map(unit => { return {label: unit.abbreviation, value: unit.abbreviation} })

const RxMapViewer = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [isCriticalError, setIsCriticalError] = useState(false)
    const [mapNotFound, setMapNotFound] = useState(false)
    const [geoJson, setGeoJson] = useState({})
    const [columns, setColumns] = useState([])
    const [fileName, setFileName] = useState('')
    const [fileTime, setFileTime] = useState('')
    const [rateUnit, setRateUnit] = useState(findUnitByName('gal/ac'))
    const [areaUnit, setAreaUnit] = useState(findUnitByName('ac'))

    const urlParams = useParams()
    const rxMapId = urlParams.id || null

    useEffect(() => {
        if (!rxMapId) {
            setIsCriticalError(true)
            setIsLoading(false)

            return
        }

        async function getRxMapMetaData() {
            try {
                const response = await axios.get(`/jobs/api/rxmaps/${rxMapId}/meta`)
                handleMapResponse(response.data)
            }
            catch (e) {
                if (e.response.status === 404) {
                    setMapNotFound(true)
                }
                else {
                    setIsCriticalError(true)
                }
            }
            setIsLoading(false)
        }

        getRxMapMetaData()
    }, [])

    function handleMapResponse(data) {
        if (data && data.shapes && data.shapes.features && data.shapes.features.length) {
            setGeoJson(data.shapes)
            setColumns(data.columns.sort())
            setFileName(data.name)
            setFileTime(data.lastModified)
        }
        else {
            setIsCriticalError(true)
        }
    }
    async function fetchShapefile() {
        const response = await axios.get('/jobs/net-api/files/' + rxMapId, { responseType: 'blob' })
        const blob = new Blob([response.data], { type: 'application/zip; charset=UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = fileName.endsWith('.zip') ? fileName : fileName + '.zip'
        link.click()
    }

    function selectMapUnit(unitName) {
        const unit = findUnitByName(unitName)
        if (unit) {
            setAreaUnit(areaFromRateUnit(unitName))
            setRateUnit(unit)
        }
        else {
            setRateUnit({abbreviation: '', fromBaseToDisplay: 1})
            setAreaUnit({abbreviation: '', fromBaseToDisplay: 1})
        }
    }

    function renderRateMap(column) {
        return (
            <div key={column} className="rxmap-section">
                <TableWrapper>
                    <ReportSectionParentFlex title={column}>
                        <div className="rxmap-legend">
                            <RuiRatelegend
                                data={geoJson}
                                rateProperty={column}
                                theme="primary"
                                customUnitProps={{
                                    rateLabel: rateUnit.abbreviation,
                                    areaLabel: areaUnit.abbreviation,
                                    baseToDisplayRate: 1,
                                    m2ToDisplayArea: areaUnit.fromBaseToDisplay,
                                }}
                            />
                        </div>
                        <div className="rxmap-map">
                            <RuiRatemap
                                data={geoJson}
                                mapboxAccessToken="pk.eyJ1Ijoic2xpbmdzaG90IiwiYSI6IndqUS1ELVUifQ.saxKOBK4ETcj29XHe_eqrA"
                                rateProperty={column}
                                navigationControl={true}
                            />
                        </div>
                    </ReportSectionParentFlex>
                </TableWrapper>
            </div>
        )
    }

    return (
        <div className="rxmap-page" >
            {isLoading &&
            <div id="loading-container">
                <CircularProgress sx={{marginLeft: '50%'}}/>
            </div>
            }

            {(!isLoading && mapNotFound) &&
            <h2 className="rxmap-status-text">No Rx Map found</h2>
            }

            {(!isLoading && isCriticalError) &&
            <h2 className="rxmap-status-text">An error occurred while loading the Rx Map</h2>
            }


            {(!isLoading && !mapNotFound && !isCriticalError) &&
            <>
                <p className="rxmap-right-aligned">File Date: {format(new Date(fileTime), 'P p')}</p>
                <div className="hide-print rxmap-right-aligned report-buttons">
                    <RuiSelect
                        selectStyle={{minWidth: '150px', marginRight: '5px'}}
                        label="Select Rx Map Units"
                        value={rateUnit.abbreviation}
                        items={rateUnitOptions}
                        onChange={selectMapUnit}
                        includeNoneOption={true}
                        fullWidth={false}
                    />
                    <Button sx={{margin: '5px'}} variant="outlined" onClick={() => fetchShapefile()}>
                        <FileFileDownload /> Shapefile
                    </Button>
                    <Button sx={{margin: '5px'}} variant="outlined" onClick={() => window.print()}>
                        <FileFileDownload /> Print/PDF
                    </Button>
                </div>
                <h3 className="header">{`Rx Map: ${fileName.endsWith('.zip') ? fileName.slice(0, -4) : fileName}`}</h3>
                {columns.map((column) => renderRateMap(column))}
            </>
            }
        </div>
    )
}

// The only way to get a footer on every page and not have to
// worry about the content overflowing into the footer is to
// wrap everything in a table and use display: table-footer-group on the tfoot
// so that's what this mess is for
function TableWrapper({ children }) {
    return (
        <table style={{width: '100%'}}>
            <tbody>
                <tr>
                    <td>
                        {children}
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>
                        <div className="rxmap-footer-printview">
                            <div className="slingshot-copyright">Copyright {new Date().getFullYear()} Slingshot&reg; All Rights Reserved</div>
                            <img className="powered-by-raven-img" src={`${process.env.PUBLIC_URL}/images/Powered_By_Raven.png`} alt="Powered By Raven" />
                        </div>
                    </td>
                </tr>
            </tfoot>
        </table>
    )
}

export default RxMapViewer
