import React from 'react'

import RaisedButton from 'material-ui/RaisedButton'
import IconButton from 'material-ui/IconButton'

// Usage: Generate a row of buttons that automatically handle desktop and mobile sizes
//
// Arguments
// children: information to populate the buttons
//      children should be compatible with the button prototype.
//

// Either a material icon component or the path of an svg icon is expected.
// If both are provided the complete component will take precedence.
// iconPath, mobileIconPath, and disabledIconPath should start inside the public/images directory and exclude the extension
// ex. iconPath = 'jobIcons/Job_SaveBlue' will resolve to
// 'public/images/jobIcons/Job_SaveBlue.svg'
// mobileIconPath & disabledIconPath can be used to override the primary image in the appropriate cases but are not required.

export function buttonPrototype(proto) {
    return Object.assign({
        icon: null,
        iconStyle: null,
        disabledIconStyle: null,
        iconPath: '',
        mobileIconPath: '',
        disabledIconPath: '',
        label: '',
        onClick: () => {},
        href: null,
        disabled: false,
        visible: true,
    }, proto)
}

class ButtonBar extends React.Component {
    static defaultProps = {
        children: [],
        disabled: false, // disables entire bar, overrides individual button states
    }
    renderNonMobileChildren = () => {
        const buttons = []
        let i = 0
        for (const buttonInfo of this.props.children) {
            i++
            let icon = buttonInfo.icon
            if (!icon && buttonInfo.disabled && buttonInfo.disabledIconPath) {
                icon = <img className="standardIcon"
                    src={`${process.env.PUBLIC_URL}/images/${buttonInfo.disabledIconPath}.svg`}
                />
            }
            else if (!icon) {
                icon = <img className="standardIcon"
                    src={`${process.env.PUBLIC_URL}/images/${buttonInfo.iconPath}.svg`}
                />
            }
            if (buttonInfo.visible) {
                buttons.push(
                    <RaisedButton
                        icon={icon}
                        label={buttonInfo.label}
                        onClick={buttonInfo.onClick}
                        href={buttonInfo.href}
                        disabled={buttonInfo.disabled || this.props.disabled}
                        name={`${buttonInfo.label}Button`}
                        primary={true}
                        className="nonMobileButton"
                        key={`button${i}`}
                    />
                )
            }
        }

        return buttons
    }

    renderMobileAltChildren = () => {
        const buttons = []
        let i = 0
        for (const buttonInfo of this.props.children) {
            i++
            let icon = buttonInfo.icon

            if (!icon && buttonInfo.disabled && buttonInfo.disabledIconPath) {
                icon = <img className="standardIcon"
                    src={`${process.env.PUBLIC_URL}/images/${buttonInfo.disabledIconPath}.svg`}
                />
            }
            else if (!icon && buttonInfo.mobileIconPath) {
                icon = <img className="standardIcon"
                    src={`${process.env.PUBLIC_URL}/images/${buttonInfo.mobileIconPath}.svg`}
                />
            }
            else if (!icon) {
                icon = <img className="standardIcon"
                    src={`${process.env.PUBLIC_URL}/images/${buttonInfo.iconPath}.svg`}
                />
            }

            let iconStyle = buttonInfo.iconStyle
            if (buttonInfo.disabled && buttonInfo.disabledIconStyle) {
                iconStyle = buttonInfo.disabledIconStyle
            }

            if (buttonInfo.visible) {
                buttons.push(
                    <IconButton
                        onClick={buttonInfo.onClick}
                        href={buttonInfo.href}
                        disabled={buttonInfo.disabled}
                        name={`${buttonInfo.label}Button`}
                        className="mobileAltButton"
                        key={`button${i}`}
                        iconStyle={iconStyle}
                    >
                        {icon}
                    </IconButton>
                )
            }
        }

        return buttons
    }

    render() {
        return (
            <div name="buttonBar">
                <div name="desktopButtons" className="nonMobileButtonGroup">
                    {this.renderNonMobileChildren()}
                </div>
                <div name="mobileButtons" className="mobileAltButtonGroup">
                    {this.renderMobileAltChildren()}
                </div>
            </div>
        )
    }
}

export default ButtonBar
