import {ACTUAL_VOLUME_CONTENT_DDI, SETPOINT_VOLUME_PER_AREA_DDI} from '../lib/ddiConstants'
let productIds = 0
export function productPrototype(
    mixId = -1,
) {
    return {
        id: productIds++,
        mixId: mixId,
        rate: 0,
        displayRate: 0,
        userDisplayUnit: 'gal/ac',
        ddiId: SETPOINT_VOLUME_PER_AREA_DDI,
        mode: 'targetRate',
        rxMapId: 0,
        rxMapColumnId: '',
        rxMapUnit: 'gal/ac',
    }
}

let mixIds = 0
export function mixPrototype(
    name,
    carrierId = null,
    userDisplayUnit = 'gal/ac',
    mixIngredients = [new mixIngredientPrototype('')],
    entryMode = 'rateMode',
) {
    return {
        id: mixIds++,
        name: name,
        carrierId: carrierId,
        carrierQuantity: 0,
        carrierDisplayQuantity: 0,
        userDisplayUnit: userDisplayUnit,
        mixIngredients: mixIngredients,
        entryMode: entryMode,
        ddiId: SETPOINT_VOLUME_PER_AREA_DDI,
    }
}

let mixIngId = 0
export function mixIngredientPrototype(
    name = '',
    userDisplayUnit = 'gal/ac'
) {
    return {
        id: mixIngId++,
        ingredientId: -1,
        name: name,
        quantity: 0,
        displayQuantity: 0,
        ddiId: SETPOINT_VOLUME_PER_AREA_DDI,
        userDisplayUnit: userDisplayUnit,
    }
}

let ingId = 0
export function ingredientPrototype(
    name = '',
    typeId = 'Herbicide',
    defaultDisplayUnit = 'gal'
) {
    return {
        id: ingId++,
        name: name,
        typeId: typeId,
        ddiId: ACTUAL_VOLUME_CONTENT_DDI,
        manufacturer: '',
        defaultDisplayUnit: 'gal',
        registration: '',
        note: '',
        composition: '',
        deprecated: false,
    }
}
export const ingredientTypes = [
    {name: 'Herbicide', id: 1},
    {name: 'Insecticide', id: 2},
    {name: 'Growth Regulator', id: 3},
    {name: 'Seed Treatment', id: 4},
    {name: 'Fertilizer', id: 5},
    {name: 'N Stabilizer', id: 6},
    {name: 'Additive', id: 7},
]

let carrierId = 0
export function carrierPrototype(name = '') {
    return { id: carrierId++, name: name }
}
