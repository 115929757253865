import React, { Component } from 'react'
import {userHasRavenServiceAccess} from '../system/systemActions'

import JobSyncDebug from './jobSyncDebug'

const styles = {
    container: {
        paddingTop: 5,
        paddingLeft: 5,
        paddingRight: 5,
    },
}

class AppJobCreate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            serviceAccess: userHasRavenServiceAccess(),
        }
    }
    getBody() {
        if (this.state.serviceAccess) {
            return <JobSyncDebug />
        }

        return <div>Error. Page not found.</div>
    }
    render() {
        return (
            <div className="workorder-wrapper">
                <div style={styles.container}>
                    {this.getBody()}
                </div>
            </div>
        )
    }
}

export default AppJobCreate
